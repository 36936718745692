// import { Tooltip } from 'antd'
import { ProjectSection } from 'features/Project/types'
// import { ProjectPermissions } from 'features/Settings/UsersAndGroups'
// import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import styled from 'styled-components'
import { ItemRow } from '../ItemRow/ItemRow'
// import { GhostInput } from 'components/UI/GhostInput/GhostInput'

interface Props {
  data: ProjectSection
  assignee: number[]
  onSectionDescriptionChange: (value: string) => void
}

export const InvoiceSection = ({
  data,
}: // assignee,
// onSectionDescriptionChange,
Props) => {
  return (
    <Wrapper>
      {/* <Heading>
        <Title>{data.title}</Title>
      </Heading>

      <Description>
        <Label>Section Description</Label>
        <GhostInput
          value={data.description}
          onChange={onSectionDescriptionChange}
          placeholder='{Empty}'
          maxLength={9000}
          showCount
        />
      </Description> */}

      <Content>
        <Table>
          {!!data.items.length && (
            <TableHeading>
              <p>Description</p>

              {/* <AccessControl
                author={assignee}
                scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
              >
                <Tooltip mouseLeaveDelay={0} title='Retail price'>
                  <p>Price </p>
                </Tooltip>
              </AccessControl>

              <Tooltip mouseLeaveDelay={0} title='Qty'>
                <p>Qty</p>
              </Tooltip>

              <Tooltip mouseLeaveDelay={0} title='Unit'>
                <p>Unit</p>
              </Tooltip>
              <AccessControl
                author={assignee}
                scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
              >
                <Tooltip mouseLeaveDelay={0} title='Total'>
                  <p>Total</p>
                </Tooltip>
              </AccessControl> */}
            </TableHeading>
          )}

          <TableContent>
            {data.items.length ? (
              data.items
                ?.filter(item =>
                  item.option_group_id ? item.checked_option : item,
                )
                ?.map(item => <ItemRow key={item.id} data={item} />)
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: 'grey',
                  marginTop: 10,
                }}
              >
                No items yet
              </p>
            )}
          </TableContent>
        </Table>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* border: 1px solid #e6e8ec;
  border-radius: 4px; */
`
/* const Heading = styled.div`
  padding: 8px 24px;
  background-color: #efefef;
` */
const Content = styled.div`
  /* padding: 12px 32px; */
`
/* const Title = styled.p`
  font-size: 18px;
  letter-spacing: 0.01em;
  font-weight: 500;
` */

const Table = styled.div``

const TableHeading = styled.p`
  /*  display: grid;
  grid-template-columns: 1fr 100px 80px 100px 100px;
  gap: 5px; */
  background-color: #fafafa;
  font-weight: 500;
  padding: 8px 0;
  color: gray;

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-right: 1px solid #ededed;
    padding: 0 3px;
  }
`
const TableContent = styled.p``
/* const Label = styled.p`
  color: ${props => props.theme.font.secondary};
  margin-bottom: 5px;
`
const Description = styled.div`
  margin-bottom: 10px;
  padding: 8px;
`
 */
