import { FormBuilderContext } from 'features/Forms/FormBuilderContext'
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Modal, Tooltip } from 'antd'
import ComponentEditor from './components/ComponentEditor'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  updateFormTemplate,
  createFormTemplate,
  getRelatedProposalIdsToForm,
  duplicateForm,
} from 'api/CustomForms'
import { FORM_WIDTH } from 'features/Forms/utils/constants'
import { Component } from 'features/Forms/types'
import { Select, TextField } from 'components/UIKit'
import { InfoCircleOutlined } from '@ant-design/icons'

const reorderArray = (arr: any[], source: number, destination: number) => {
  if (!source && !destination) return arr
  const newArr = [...arr]
  const [removed] = newArr.splice(source, 1)
  newArr.splice(destination, 0, removed)

  return newArr
}

const confirmText =
  'The original template is already associated with the projects. Would you like to relate this template to the same projects as the original one?'

export type SaveHandler = {
  save: () => void
}

interface Props {
  handleClose: () => void
  onCancel: () => void
}

export const FormEditor = forwardRef<SaveHandler, Props>(
  ({ handleClose, onCancel }, ref) => {
    const { form, updateForm, resetForm } = useContext(FormBuilderContext)
    const { componentIds, body } = form

    const [loading, setLoading] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [proposalIds, setProposalIds] = useState([])

    useImperativeHandle(ref, () => ({
      save() {
        form?.id ? handleUpdateForm() : handleSaveForm()
      },
    }))

    const mainFieldOptions = Object.values(body)
      ?.filter(
        component =>
          !['picture', 'upload', 'note', 'signature'].includes(component.type),
      )
      ?.map(component => ({
        value: component.id,
        label: component.label,
      }))

    useEffect(() => {
      return () => resetForm()
    }, [])

    useEffect(() => {
      if (form?.main_field_id && !componentIds.includes(form?.main_field_id)) {
        handleChangeFormTemplate({
          target: {
            name: 'main_field_id',
            value: null,
          },
        })
      }
    }, [componentIds])

    const handleDrop = (result: DropResult) => {
      const { destination, type, source } = result

      if (!destination) return

      if (type === 'DEFAULT') {
        return updateForm({
          ...form,
          componentIds: reorderArray(
            componentIds,
            source?.index,
            destination?.index,
          ),
        })
      }

      if (source.droppableId === destination?.droppableId) {
        updateForm({
          ...form,
          body: {
            ...form.body,
            [source.droppableId]: {
              ...form.body[source.droppableId],
              options: reorderArray(
                form.body[source.droppableId].options,
                source?.index,
                destination?.index,
              ),
            },
          },
        })
      }
    }

    const handleChangeFormTemplate = (e: {
      target: {
        name: keyof Component
        value: unknown
      }
    }) => {
      const { name, value } = e.target

      updateForm({ ...form, [name]: value })
    }

    const reorderBodyByComponentIds = (
      body: Record<number, Component>,
      componentIds: string[],
    ) => {
      const reorderedBody = {}
      componentIds.forEach((id, index) => {
        reorderedBody[id] = { ...body[id], position: index }
      })
      return reorderedBody
    }

    const handleSaveForm = async () => {
      setLoading(true)
      try {
        await createFormTemplate({
          title: form.title,
          body: reorderBodyByComponentIds(form.body, componentIds),
          main_field_id: form.main_field_id,
          form_name: form.form_name,
        })

        resetForm()
        openNotificationWithIcon('success', {
          message: 'Template was created successfully.',
        })
        handleClose()
      } catch (error) {
        console.error(error)

        const message = Object.values(error?.response?.data?.errors || {})[0]
        openNotificationWithIcon('error', {
          message: message || 'Something went wrong!',
        })
      } finally {
        setLoading(false)
      }
    }

    const handleUpdateForm = async () => {
      setLoading(true)

      try {
        await updateFormTemplate({
          id: form.id,
          title: form.title,
          body: reorderBodyByComponentIds(form.body, componentIds),
          main_field_id: form.main_field_id,
          form_name: form.form_name,
        })
        setConfirmModal(false)
        resetForm()
        openNotificationWithIcon('success', {
          message: 'Template was updated successfully.',
        })
        handleClose()
      } catch (error) {
        console.error(error)

        const message = Object.values(error?.response?.data?.errors || {})[0]
        openNotificationWithIcon('error', {
          message: message || 'Something went wrong!',
        })
      } finally {
        setLoading(false)
      }
    }

    const handleDuplicateForm = async (confirm: boolean) => {
      try {
        const res = await createFormTemplate({
          title: form.title,
          body: reorderBodyByComponentIds(form.body, componentIds),
        })
        if (confirm) {
          await duplicateForm(res.data.id, confirm ? proposalIds : [])
        }

        setConfirmModal(false)
        resetForm()
        setProposalIds([])
        openNotificationWithIcon('success', {
          message: 'Template has been duplicated successfully.',
        })
        setConfirmModal(false)
        handleClose()
      } catch (error) {
        console.error(error)

        const message = Object.values(error?.response?.data?.errors || {})[0]
        openNotificationWithIcon('error', {
          message: message || 'Something went wrong!',
        })
      }
    }

    const onClickDuplicate = async () => {
      const { proposals } = await getRelatedProposalIdsToForm(form.id)
      if (proposals?.length) {
        setProposalIds(proposals)
        return setConfirmModal(true)
      } else {
        handleDuplicateForm(false)
      }
    }

    return (
      <Wrapper>
        <h6>Form Editor</h6>
        <TextField
          name='title'
          label='Template name'
          title='Form template name'
          value={form.title}
          onChange={handleChangeFormTemplate}
          required
          style={{ marginBottom: '20px' }}
        />

        {!!mainFieldOptions.length && (
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <Select
              allowClear
              label='Primary field'
              name='main_field_id'
              placeholder='Select the primary field'
              value={form.main_field_id}
              options={mainFieldOptions}
              onChange={handleChangeFormTemplate}
            />
          </div>
        )}

        {!!mainFieldOptions.length && (
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <Select
              mode='multiple'
              allowClear
              label='PDF Name'
              titleRight={
                <Tooltip title='Select which component values should be included in the PDF name of the form created from this template'>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              name='form_name'
              placeholder='Select the form name'
              value={form.form_name}
              options={mainFieldOptions}
              onChange={handleChangeFormTemplate}
            />
          </div>
        )}

        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId='editor'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {componentIds?.map((id, index) => (
                  <ComponentEditor
                    component={body[id]}
                    index={index}
                    key={id}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!!componentIds.length && (
          <div
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'end',
              marginTop: 10,
            }}
          >
            <MainButton type='cancel' title={'Close'} onClick={onCancel} />
            <MainButton
              title={'Save form'}
              isFetching={loading}
              onClick={
                form?.action === 'duplicate'
                  ? onClickDuplicate
                  : form?.id
                  ? handleUpdateForm
                  : handleSaveForm
              }
            />
          </div>
        )}

        <Modal
          title='Confirmation'
          centered
          visible={confirmModal}
          destroyOnClose
          width={500}
          onCancel={() => {
            setConfirmModal(false)
          }}
          footer={
            <ControlsRow>
              <MainButton
                onClick={() => handleDuplicateForm(false)}
                type='cancel'
                title='Create only'
              />
              <MainButton
                onClick={() => handleDuplicateForm(true)}
                title='Create and relate'
              />
            </ControlsRow>
          }
        >
          <p>{confirmText}</p>
        </Modal>
      </Wrapper>
    )
  },
)

FormEditor.displayName = 'FormEditor'

const Wrapper = styled.div`
  min-width: 500px;
  max-width: ${FORM_WIDTH}px;
`

const ControlsRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`
