import styled from 'styled-components'

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    border-bottom: 1px solid #f1f2fd;
  }
`
export const Section = styled.div`
  padding: 20px 0;
`
export const SectionSettings = styled.div`
  margin-left: 12px;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
export const SectionSettingsItem = styled.div`
  display: flex;
`
export const SectionTitle = styled.p`
  margin-bottom: 10px;
  font-weight: 500;
`

export const Title = styled.p`
  margin-left: 10px;
`
export const Description = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  color: #707683;
  white-space: break-spaces;

  p {
    margin-bottom: 5px;

    span {
      font-weight: 600;
    }
  }
`
