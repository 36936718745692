import { Divider } from 'antd'
import { CustomerInformation } from 'containers/MainContent/Orcatec/Proposals/components/CustomerInformation/CustomerInformation'
import InstallersInfoBlock from 'containers/MainContent/Orcatec/Proposals/components/Form/TabsContent/TabInfoBlock/InstallersInfoBlock/InstallersInfoBlock'

// import { updateProjectField } from 'features/Project/slices/projectSlice'
import { Project } from 'features/Project/types'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { useState } from 'react'
// import { useAppDispatch } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { Media } from 'types/Media'

interface Props {
  project: Project
  onChange: (fieldsToUpdate: Partial<Project>) => void
  isLoading: boolean
}

export const InformationForCustomer = ({
  project,
  isLoading,
  onChange,
}: Props) => {
  // const dispatch = useAppDispatch()

  // const project = useAppSelector(selectProject)
  // const { show_information } = useAppSelector(selectProjectSettings)

  const [state, setState] = useState<{
    documents: Media[]
    currentFileIdx: number
    showDocViewerModal: boolean
  }>({
    documents: [],
    currentFileIdx: 0,
    showDocViewerModal: false,
  })

  const toggleOpenDocViewerModal = () => {
    setState(prev => ({
      ...prev,
      showDocViewerModal: !prev.showDocViewerModal,
    }))
  }

  const getFileForModalDocViewer = (
    documents: Media[],
    currentFileIdx: number,
  ) => {
    setState({ documents, currentFileIdx, showDocViewerModal: true })
  }

  const handleCustomerInfoUpdate = (filesToUpdate: Partial<Project>) => {
    // dispatch(updateProjectField(filesToUpdate))
    onChange(filesToUpdate)
  }

  return (
    <Wrapper>
      {(project.info_for_customer || !!project.files?.length) && (
        <Divider className='proposal-preview-divider'>
          <span style={{ fontSize: '0.8rem' }}>Additional Information</span>
        </Divider>
      )}

      <div className='proposal-form-information_for_customer'>
        <InstallersInfoBlock
          fieldValue={project.info_for_customer}
          files={project.files}
          fieldLabel='Information for customer'
          getFileForModalDocViewer={getFileForModalDocViewer}
        />
      </div>

      <Divider
        className={`proposal-preview-divider ${
          !!project?.info_from_customer?.length ||
          !!project?.files_from_customer?.length
            ? ''
            : 'print-hidden'
        }`}
      >
        <span style={{ fontSize: '0.8rem' }}>
          Please Feel Free To Add Comments
        </span>
      </Divider>

      <div className={`proposal-form-information_from_customer`}>
        <CustomerInformation
          dataSource={project}
          label='Information from customer'
          notesFieldName='info_from_customer'
          filesFieldName='files_from_customer'
          getFileForModalDocViewer={getFileForModalDocViewer}
          onUpdate={handleCustomerInfoUpdate}
          filesLimit={100}
          isLoading={isLoading}
        />
      </div>

      {state.showDocViewerModal && (
        <DocViewer
          documents={state.documents}
          currentDocument={state.currentFileIdx}
          isModalVisible
          handleCancel={toggleOpenDocViewerModal}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .proposal-form-tabs_content-info_block-installers_info {
    border: none;
    margin-top: 0;
  }

  .placeholder,
  .field-label {
    display: none;
  }
`
