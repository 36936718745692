import { Select } from 'components/UIKit'
import { PaymentMethod } from 'features/Project/types'

interface Props {
  value: string
  disabled: boolean
  options: PaymentMethod[]
  onChange: (value: string) => void
}

export const PaymentMethods = ({
  value,
  disabled,
  options,
  onChange,
}: Props) => {
  return (
    <Select
      name='payment_method'
      label='Payment Method'
      value={value}
      onChange={(_, value) => onChange(value)}
      options={options.map(method => ({
        value: method.method_name,
        label: method.method_name,
      }))}
      disabled={disabled}
    />
  )
}
