import { Select } from 'components/UIKit'
import dayjs from 'dayjs'
import { PaymentBalance } from 'features/Project/types'

interface Props {
  data: PaymentBalance
  disabled: boolean
  allRejected: boolean
  onChange: (value: number) => void
}

export const BalanceStatus = ({
  data,
  disabled,
  allRejected,
  onChange,
}: Props) => {
  return (
    <Select
      label='Status'
      name='status'
      value={data.status}
      disabled={disabled}
      options={[
        {
          value: 1,
          label: 'Due',
          disabled: dayjs(data.due_date).isBefore(
            dayjs(new Date()).subtract(1, 'days'),
          ),
        },
        {
          value: 2,
          label: 'Overdue',
          disabled: !dayjs(data.due_date).isBefore(
            dayjs(new Date()).subtract(1, 'days'),
          ),
        },
        {
          value: 3,
          label: 'Paid',
          disabled: !!data.payment_transactions?.length,
        },
        {
          value: 4,
          label: 'Cancelled',
          disabled: !allRejected,
        },
        {
          value: 5,
          label: 'Pending',
          disabled: true,
        },
      ]}
      onChange={(_, value) => onChange(value)}
    />
  )
}
