import { CustomSelect } from 'components/UIKit'
import { BalanceName as IBalanceName } from 'features/Project/types'

interface Props {
  label: string
  value: string
  loading: boolean
  options: IBalanceName[]
  disabled: boolean
  onChange: (value: string) => void
  onDelete: (option: number) => void
  onAdd: (newValue: string) => Promise<void>
}

export const BalanceName = ({
  label,
  value,
  loading,
  options,
  disabled,
  onChange,
  onDelete,
  onAdd,
}: Props) => {
  return (
    <CustomSelect
      label={label}
      value={value}
      onChange={onChange}
      options={options.map(type => ({
        label: type.name_balance,
        value: type.name_balance,
        id: type.id,
        key: type.id,
      }))}
      onAddOption={onAdd}
      onDeleteOption={(_, option) => onDelete(option?.id)}
      loading={loading}
      disabled={disabled}
    />
  )
}
