import styled from 'styled-components'
import { InvoicePreviewActions } from './Actions'

export const Heading = () => {
  return (
    <Wrapper>
      <Title>Invoice Preview</Title>

      <InvoicePreviewActions />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const Title = styled.h4`
  margin-bottom: 0;
`
