import { Popconfirm, Tooltip } from 'antd'
import {
  BankCheckIcon,
  CreditCardIcon,
  DeleteIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { PaymentBalance } from 'features/Project/types'
import styled from 'styled-components'

interface Props {
  data: PaymentBalance
  availablePaymentMethods: {
    bank_check: boolean
    credit_card: boolean
  }
  hasActiveMerchant: boolean
  disabled: boolean
  onDelete?: () => void
  onChange: (field: Partial<PaymentBalance>) => void
}

export const PaymentActions = ({
  data,
  hasActiveMerchant,
  disabled,
  onChange,
  availablePaymentMethods,
  onDelete,
}: Props) => {
  return (
    <Wrapper disabled={disabled}>
      <Tooltip
        title={`${
          !hasActiveMerchant
            ? 'To process credit card payments please enable it with CloverConnect'
            : data.accept_card
            ? 'Disable Payment by CC'
            : 'Enable Payment by CC'
        }`}
      >
        <Popconfirm
          placement='left'
          title={
            'Are you sure? Your client will not be able to pay this balance with Credit Card'
          }
          disabled={
            disabled || !availablePaymentMethods.credit_card || data.amount < 0
          }
          onConfirm={() => onChange({ accept_card: !data.accept_card })}
          okText='Yes'
          cancelText='No'
        >
          <CreditCardIcon
            fill={
              hasActiveMerchant
                ? data?.accept_card && +data?.amount >= 0
                  ? '#4c53ef'
                  : '#f5222d'
                : '#b6b6b6'
            }
          />
        </Popconfirm>
      </Tooltip>

      <Tooltip
        title={`${
          !availablePaymentMethods?.bank_check || !hasActiveMerchant
            ? 'To process check payments please enable it with CloverConnect'
            : data.can_pay_by_check
            ? 'Disable Payment by ACH'
            : 'Enable Payment by ACH'
        }`}
      >
        <Popconfirm
          placement='left'
          title={
            'Are you sure? Your client will not be able to pay this balance with bank check'
          }
          disabled={
            disabled || !availablePaymentMethods.bank_check || data.amount < 0
          }
          onConfirm={() =>
            onChange({ can_pay_by_check: !data.can_pay_by_check })
          }
          okText='Yes'
          cancelText='No'
        >
          <BankCheckIcon
            fill={
              availablePaymentMethods?.bank_check
                ? data?.can_pay_by_check && +data?.amount >= 0
                  ? '#4c53ef'
                  : '#f5222d'
                : '#b6b6b6'
            }
          />
        </Popconfirm>
      </Tooltip>

      {onDelete && (
        <Tooltip title='Delete Payment'>
          <Popconfirm
            title='Are you sure to delete this payment?'
            onConfirm={onDelete}
            okText='Yes'
            cancelText='No'
            disabled={disabled}
          >
            <span>
              <DeleteIcon />
            </span>
          </Popconfirm>
        </Tooltip>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`
