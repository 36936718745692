import TabsLayout from '../TabsLayout'
import { InvoicesList } from 'features/Project/components/Invoices/components/InvoicesList/InvoicesList'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  selectWorkOrder,
  selectWorkOrderTotal,
  workOrderFieldUpdated,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { InvoicesOperation } from 'features/Invoice/types'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  getInvoicesList,
  selectInovoicesSlice,
} from 'features/Invoice/store/invoicesSlice'
import InvoiceAPI from 'features/Invoice/api'
import { InvoiceData } from 'features/Project/components/PaymentSchedule/components/InvoiceData/InvoiceData'
import {
  selectBalancesTotal,
  selectProject,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import { getProjectPayment } from 'features/Project/slices/projectPaymentSlice'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useState } from 'react'

export const Invoices = () => {
  const { CREATE } = usePermissionsByModule(
    InvoicesOperation,
    false,
    ModuleName.INVOICES,
  )

  const dispatch = useAppDispatch()

  const workOrder = useAppSelector(selectWorkOrder)
  const project = useAppSelector(selectProject)
  const { invoicesList } = useAppSelector(selectInovoicesSlice)
  const sumOfBalances = useAppSelector(selectBalancesTotal)
  const workOrderTotal = useAppSelector(selectWorkOrderTotal)
  const projectTotal = useAppSelector(selectProjectTotal)

  const [confirmModal, showConfirmModal] = useState(false)

  async function handleInvoiceCreate(ignoreOverPaid?: boolean) {
    if (!CREATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    if (
      !ignoreOverPaid &&
      (sumOfBalances + workOrderTotal > projectTotal || workOrderTotal === 0)
    ) {
      return showConfirmModal(true)
    }

    try {
      const invoiceData = await InvoiceAPI.generateInvoice({
        work_order_id: workOrder.id,
        proposal_id: workOrder.parent_id || workOrder.id,
      })

      if (confirmModal) {
        showConfirmModal(false)
      }

      openNotificationWithIcon('success', {
        message: 'The invoice has been generated successfully',
      })

      dispatch(
        getInvoicesList({
          proposal_id: workOrder.parent_id || workOrder.id,
          page: invoicesList.pagination.current_page,
          per_page: invoicesList.pagination.per_page,
        }),
      )

      dispatch(
        getProjectPayment({
          projectId: project.id,
          tabId: project.signed_tab_id || project.activeTabId,
        }),
      )

      dispatch(
        workOrderFieldUpdated({
          invoice: invoiceData,
        }),
      )

      return invoiceData
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
      return Promise.reject(error)
    }
  }

  return (
    <TabsLayout title='Invoices'>
      <div style={{ margin: '20px 0' }}>
        <InvoiceData
          data={workOrder?.invoice}
          onGenerateInvoice={handleInvoiceCreate}
          primary
        />
      </div>

      <p style={{ fontWeight: 500, fontSize: 16 }}>
        Invoices for Project #{project.code}
      </p>
      <InvoicesList projectId={workOrder.parent_id || 1} />

      <Modal
        open={confirmModal}
        onOk={() => handleInvoiceCreate(true)}
        onCancel={() => showConfirmModal(false)}
        title={
          workOrderTotal
            ? 'Potential Payment Discrepancy'
            : 'Creating a zero-total Invoice'
        }
        okText='Generate invoice'
        width={600}
      >
        {workOrderTotal ? (
          <>
            <p>
              Creating this invoice will result in total payments exceeding the
              Project Total. This may cause reporting inconsistencies.
            </p>
            <p>Do you still want to proceed?</p>
          </>
        ) : (
          <>
            <p>
              Since this Work Order has a total of $0, the invoice will also be
              $0.
            </p>
            <p>Would you like to proceed?</p>
          </>
        )}
      </Modal>
    </TabsLayout>
  )
}
