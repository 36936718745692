import { Button, Select, TextField } from 'components/UIKit'
import { ImageUploader } from 'components/UIKit/ImageUploader/ImageUploader'
import SearchAddress from 'containers/MainContent/Orcatec/components/SearchAddress'
import { PropertyAddress } from 'containers/MainContent/Orcatec/components/SearchAddress/types'
import states from 'helpers/states'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
// import FileUploadService from 'services/FileUploadService'
import {
  AddressWrapper,
  Error,
  Info,
  Row,
  Section,
  SectionTitle,
  Text,
  TimezoneWrapper,
  // Title,
} from './CompanyForm.style'
import { TimezonePicker } from 'containers/MainContent/Orcatec/TimeCards/components/TimezonePicker/TimezonePicker'
import { OrganizationsIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { useRef } from 'react'

const industries = [
  { label: 'Home Cleaning', value: 'Home Cleaning' },
  { label: 'Electrician', value: 'Electrician' },
  { label: 'Plumbing', value: 'Plumbing' },
  { label: 'Landscaping and Lawn', value: 'Landscaping and Lawn' },
  { label: 'HVAC', value: 'HVAC' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Home Renovation', value: 'Home Renovation' },
  { label: 'Handyman', value: 'Handyman' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Pest Control', value: 'Pest Control' },
  { label: 'Custom Home Building', value: 'Custom Home Building' },
  { label: 'Appliance Repair', value: 'Appliance Repair' },
  { label: 'Garage Door', value: 'Garage Door' },
  { label: 'Window Cleaning', value: 'Window Cleaning' },
  { label: 'Carpet Cleaning', value: 'Carpet Cleaning' },
  { label: 'Pool and Spa Service', value: 'Pool and Spa Service' },
  { label: 'Locksmith', value: 'Locksmith' },
  { label: 'Fireplace and Chimney', value: 'Fireplace and Chimney' },
  { label: 'Roofing', value: 'Roofing' },
  { label: 'Water Treatment', value: 'Water Treatment' },
  { label: 'Alarm and Security', value: 'Alarm and Security' },
  { label: 'Irrigation', value: 'Irrigation' },
  { label: 'Painting', value: 'Painting' },
  { label: 'Contractors', value: 'Contractors' },
  { label: 'Fire Safety', value: 'Fire Safety' },
  { label: 'Junk Removal', value: 'Junk Removal' },
  { label: 'Computer Service', value: 'Computer Service' },
  { label: 'Moving', value: 'Moving' },
  { label: 'Snow Removal', value: 'Snow Removal' },
  { label: 'Air Duct Cleaning', value: 'Air Duct Cleaning' },
  { label: 'Property Maintenance', value: 'Property Maintenance' },
  { label: 'Remodeling', value: 'Remodeling' },
  { label: 'Concrete', value: 'Concrete' },
  { label: 'Janitorial Services', value: 'Janitorial Services' },
  { label: 'Excavation', value: 'Excavation' },
  { label: 'Demolition Contractor', value: 'Demolition Contractor' },
  { label: 'Tree Care', value: 'Tree Care' },
  { label: 'Restoration', value: 'Restoration' },
  { label: 'Flooring', value: 'Flooring' },
  { label: 'Tiling', value: 'Tiling' },
  { label: 'Drywall', value: 'Drywall' },
  { label: 'Pressure Washing', value: 'Pressure Washing' },
  { label: 'Other', value: 'Other' },
]

export const CompanyForm = () => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext()

  const uploadRef = useRef(null)
  const companyLogo = useWatch({ name: 'company_logo' })
  // const selectedIndustry = useWatch({ name: 'industry' })
  const officeAddressObj = useWatch({ name: 'address' })
  const fullAddressInput = officeAddressObj?.address
    ? `${officeAddressObj.address}, ${officeAddressObj.zip}, ${
        officeAddressObj.city
      }, ${states[officeAddressObj.state] || officeAddressObj.state}`
    : ''

  const handleUploadImage = async (image: File) => {
    setValue('company_logo', image)
  }

  const handleDeleteImage = async () => {
    setValue('company_logo', null)
  }

  const handleChooseAddress = (address: PropertyAddress) => {
    const addressObj = {
      address: address?.address,
      city: address?.city,
      state: address?.state,
      zip: address?.postcode,
      lat: address?.lat,
      lng: address?.lng,
    }
    setValue('address', { ...officeAddressObj, ...addressObj })
    clearErrors('address')
  }

  const handleButtonClick = () => {
    const syntheticEvent = {
      target: uploadRef.current?.upload?.uploader?.fileInput,
    }

    uploadRef.current?.upload?.uploader?.onClick(syntheticEvent)
  }

  return (
    <>
      <Row style={{ alignItems: 'center' }}>
        <ImageUploader
          image={companyLogo ? URL.createObjectURL(companyLogo) : ''}
          onUploadFinish={handleUploadImage}
          onDeleteImage={handleDeleteImage}
          customIcon={<OrganizationsIcon style={{ color: '#206FED' }} />}
          ref={uploadRef}
        />

        <Info>
          {/* <Title>Upload company&apos;s logo</Title> */}
          <Button secondary onClick={handleButtonClick}>
            Upload company&apos;s logo
          </Button>
          <Text>We recommend a photo JPG, PNG, max size 20 Mb.</Text>
        </Info>
      </Row>

      <Controller
        name='company_name'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label='Company name'
            required
            error={errors?.company_name?.message as string}
            placeholder='Enter the company name'
          />
        )}
      />
      <Controller
        name='industry'
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label='Company industry'
            required
            error={errors?.industry?.message as string}
            placeholder='Select company industry'
            options={industries}
            mode='tags'
          />
        )}
      />

      {/* {selectedIndustry === 'Other' && (
        <Controller
          name='manual_industry'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Other industry'
              required
              error={errors?.manual_industry?.message as string}
              placeholder='Enter the main industry'
            />
          )}
        />
      )} */}

      <Row>
        <Controller
          name='company_phone'
          control={control}
          render={({ field: { value, onChange } }) => (
            <ReactInputMask
              mask='(999) 999-9999'
              value={value}
              onChange={onChange}
              maskChar=''
            >
              {() => (
                <TextField
                  label='Office phone'
                  // required
                  error={errors?.company_phone?.message}
                  placeholder='(000) 000-0000'
                />
              )}
            </ReactInputMask>
          )}
        />

        <Controller
          name='company_email'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Office email'
              // required
              error={errors?.company_email?.message as string}
              placeholder='Enter the office email'
            />
          )}
        />
      </Row>

      <Section>
        <SectionTitle>Office address</SectionTitle>

        <AddressWrapper error={!!errors.address?.address}>
          <SearchAddress
            required
            initialValue={fullAddressInput ?? ''}
            label='Address line 1'
            placeholder='Type at least 2 characters to start searching'
            onChooseAddress={handleChooseAddress}
            forPublicUse
            publicView
          />
          {errors?.address?.address && (
            <Error>Please enter the office address</Error>
          )}
        </AddressWrapper>

        <Controller
          name='address.unit'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Address line 2'
              placeholder='Enter the apt, suite, unit, building, floor, etc.'
            />
          )}
        />
      </Section>

      <Section>
        <SectionTitle>Company Timezone</SectionTitle>

        <Controller
          name='timezone'
          control={control}
          render={({ field: { value, onChange } }) => (
            <TimezoneWrapper>
              <TimezonePicker
                error={errors?.timezone?.message}
                timezone={value}
                setTimezone={(val: string) => {
                  onChange(val)
                }}
              />
            </TimezoneWrapper>
          )}
        />
      </Section>
    </>
  )
}
