import { configureStore, combineReducers } from '@reduxjs/toolkit'

import dispatchSlice from 'features/Dispatch/dispatchSlice'
import * as types from './Orcatec/types'
import { store } from 'index'
import app from './Orcatec/reducers/app'
import appointment from './Orcatec/reducers/appointment'
import articles from './Orcatec/reducers/article'
import attachment from './Orcatec/reducers/modals/attachment'
import client from './Orcatec/reducers/client'
import clientActivity from './Orcatec/reducers/modals/clientActivity'
import company from './Orcatec/reducers/settings/company'
import contactSlice from 'features/Contacts/slice/contactSlice'
import crmSlice from 'features/CRM/store/crmSlice'
import feedback from './Orcatec/reducers/clientFeedback'
import importItems from './Orcatec/reducers/modals/importItems'
import installationDate from './Orcatec/reducers/modals/installationDate'
import invoicesSlice from 'features/Invoice/store/invoicesSlice'
import item from './Orcatec/reducers/settings/item'
import jobs from './Orcatec/reducers/jobs'
import map from './Orcatec/reducers/map'
import marketing from './Orcatec/reducers/marketing'
import matrix from './Orcatec/reducers/settings/matrix'
import mcFolders from './Orcatec/reducers/mail-client/folder'
import mcMessage from './Orcatec/reducers/mail-client/message'
import mcMessages from './Orcatec/reducers/mail-client/messages'
import modalBillingAddress from './Orcatec/reducers/modals/modalBillingAddress'
import modalEquipment from './Orcatec/reducers/modals/modalEquipment'
import modalOptional1 from './Orcatec/reducers/modals/modalOptional1'
import modalOptional2 from './Orcatec/reducers/modals/modalOptional2'
import modalOptional3 from './Orcatec/reducers/modals/modalOptional3'
import modalPayment from './Orcatec/reducers/modals/modalPayment'
import modalTimeoff from './Orcatec/reducers/modals/modalTimeoff'
import negativeBalance from './Orcatec/reducers/modals/negativeBalance'
import organizations from './Orcatec/reducers/organizations'

import paymentMethodsSlice from 'features/Settings/Subscription/slices/paymentMethodsSlice'
import property from './Orcatec/reducers/property'
import pricePagesSlice from 'features/Items/pricePagesSlice'
import projectSlice from 'features/Project/slices/projectSlice'
import projectTabsSlice from 'features/Project/slices/projectTabsSlice'
import projectSectionsSlice from 'features/Project/slices/projectSectionsSlice'
import projectItemsSlice from 'features/Project/slices/projectItemsSlice'
import projectGroupsSlice from 'features/Project/slices/projectGroupsSlice'
import projectDiscountsSlice from 'features/Project/slices/projectDiscountsSlice'
import projectPaymentSlice from 'features/Project/slices/projectPaymentSlice'
import projectRebatesSlice from 'features/Project/slices/projectRebatesSlice'
import projectStatusSlice from 'features/Project/slices/projectStatusSlice'
import projectTaxAndFeesSlice from 'features/Project/slices/projectTaxAndFeesSlice'
import projectContactsSlice from 'features/Project/slices/projectContactsSlice'
import projectWorkOrderContactsSlice from 'features/Project/slices/projectWorkOrderContactsSlice'
import proposal from './Orcatec/reducers/settings/proposal'
import proposalContact from './Orcatec/reducers/proposalContact'
import proposalForm from './Orcatec/reducers/proposal/proposalForm'
import proposalModal from './Orcatec/reducers/proposal/proposalModal'
import proposalModalFiles from './Orcatec/reducers/proposal/proposalModalFiles'
import proposalTable from './Orcatec/reducers/proposal/proposalTable'
import queueSlice from 'features/Dispatch/queueSlice'
import quickBooksSlice from 'features/Settings/QuickBooks/quickBooksSlice'
import schedule from './Orcatec/reducers/schedule'
import serviceContract from './Orcatec/reducers/service_contract'
import serviceContractSettings from './Orcatec/reducers/settings/serviceContract'
import settings from './Orcatec/reducers/matrix/settings'
import subscription from './Orcatec/reducers/settings/subscription'
import subscriptionSlice from 'features/Settings/Subscription/slices/subscriptionSlice'
import supplier from './Orcatec/reducers/settings/supplier'
import supportTickets from './Orcatec/reducers/supportTickets'
import system from './Orcatec/reducers/system'
import table from './Orcatec/reducers/table'
import time from './Orcatec/reducers/matrix/time'
import timecards from './Orcatec/reducers/timecards'
import timeoff from './Orcatec/reducers/timeoff'
import todo from './Orcatec/reducers/todo'
import tourGide from 'features/Support/tourSlice'
import ui from './Orcatec/reducers/ui/modals/modals'
import uiSlice from './Orcatec/UI/uiSlice'
import user from './Orcatec/reducers/settings/user'
import usersSlice from 'features/Settings/UsersAndGroups/userSlice'
import permissions from 'features/Settings/UsersAndGroups/permissionSlice'
import userTransition from './Orcatec/reducers/routing'
import websiteBuilder from './Orcatec/reducers/settings/websiteBuilder'
import workers from './Orcatec/reducers/matrix/workers'

import appointmentSlice from 'features/Appointment/appointmentSlice'
// SA
import commonData from './SuperAdmin/reducers/commonReducer'
import users from './SuperAdmin/reducers/usersReducer'
import companies from './SuperAdmin/reducers/companiesReducer'
import mailClient from './SuperAdmin/reducers/mailClientReducer'
import mailAccounts from './SuperAdmin/reducers/mailAccountsReducer'
import support from './SuperAdmin/reducers/supportReducer'
import projectFwoSlice from 'features/Project/slices/projectFwoSlice'
import projectWorkOrdersSlice from 'features/Project/slices/projectWorkOrdersSlice'
import emailsSlice from 'features/Email/emailsSlice'

import { listenerMiddleware } from './listenerMiddleware'
import { api } from 'app/api'
import authSlice from 'features/Auth/authSlice'
import opportunityContactsSlice from 'features/CRM/store/opportunityContactsSlice'

const appReducer = combineReducers({
  orcatec: combineReducers({
    [api.reducerPath]: api.reducer,
    app,
    appointment,
    appointmentSlice,
    authSlice,
    articles,
    attachment,
    client,
    clientActivity,
    company,
    contactSlice,
    crmSlice,
    dispatchSlice,
    feedback,
    importItems,
    installationDate,
    invoicesSlice,
    item,
    jobs,
    map,
    marketing,
    matrix,
    mcFolders,
    mcMessage,
    mcMessages,
    modalBillingAddress,
    modalEquipment,
    modalOptional1,
    modalOptional2,
    modalOptional3,
    modalPayment,
    modalTimeoff,
    negativeBalance,
    organizations,
    opportunityContactsSlice,
    paymentMethodsSlice,
    pricePagesSlice,
    projectSlice,
    projectTabsSlice,
    projectSectionsSlice,
    projectDiscountsSlice,
    projectPaymentSlice,
    projectRebatesSlice,
    projectStatusSlice,
    projectItemsSlice,
    projectTaxAndFeesSlice,
    projectGroupsSlice,
    projectContactsSlice,
    projectWorkOrderContactsSlice,
    projectFwoSlice,
    projectWorkOrdersSlice,
    property,
    proposal,
    proposalContact,
    proposalForm,
    proposalModal,
    proposalModalFiles,
    proposalTable,
    quickBooksSlice,
    queueSlice,
    schedule,
    serviceContract,
    serviceContractSettings,
    settings,
    subscription,
    subscriptionSlice,
    supplier,
    supportTickets,
    system,
    table,
    time,
    timecards,
    timeoff,
    todo,
    tourGide,
    ui,
    uiSlice,
    user,
    usersSlice,
    userTransition,
    websiteBuilder,
    workers,
    permissions,
    emailsSlice,
  }),
  superAdmin: combineReducers({
    commonData,
    users,
    companies,
    mailClient,
    mailAccounts,
    support,
  }),
})

type RootReducerType = typeof appReducer

export type AppStateType = ReturnType<RootReducerType>

interface IActionType {
  type: typeof types.app.USER_LOGOUT
}

export const rootReducer = (state: AppStateType, action: IActionType) => {
  if (action.type === types.app.USER_LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default function configureAppStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .concat(api.middleware)
        .prepend(listenerMiddleware.middleware),
  })
}

export type AppDispatch = typeof store.dispatch
