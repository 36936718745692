import { Tooltip } from 'antd'
import {
  IconActivity,
  IconEnvelope,
  SettingsIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import {
  selectIsPriceVisible,
  // selectUserPermissionsByModule,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import ModalClientActivity from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalClientActivity'
import {
  NotificationModalType,
  NotificationTemplateType,
} from 'features/Notification/types'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import styled from 'styled-components'
import { selectInovoicesSlice } from 'features/Invoice/store/invoicesSlice'
import InvoiceSettingsModal from '../InvoiceSettingsModal'

export const InvoiceActions = () => {
  const { currentInvoice: invoice } = useAppSelector(selectInovoicesSlice)
  const isPriceVisible = useAppSelector(
    selectIsPriceVisible([
      invoice.user_id,
      ...(invoice?.extraAssignedUsers || []),
    ]),
  )

  const [settingsModal, showSettingsModal] = useState(false)
  const [activityModal, showActivityModal] = useState(false)
  const [emailModal, showEmailModal] = useState(false)

  const openSettingsModal = () => {
    showSettingsModal(true)
  }

  const ACTIONS = {
    settings: {
      name: 'settings',
      icon: <SettingsIcon />,
      tooltip: 'Invoice Settings',
      hasPermissions: true,
      visible: true,
      onClick: openSettingsModal,
    },
    clientActivity: {
      name: 'clientActivity',
      icon: (
        <IconActivity
          style={{
            color: invoice.had_been_viewed ? '#28a745' : '#4D4D4D',
          }}
        />
      ),
      tooltip: 'Viewing History',
      hasPermissions: true,
      visible: true,
      onClick: () => showActivityModal(true),
    },
    projectSend: {
      name: 'projectSend',
      icon: (
        <IconEnvelope
          style={{
            color: invoice.had_been_sent ? '#28a745' : '#4D4D4D',
          }}
        />
      ),
      tooltip: 'Send Invoice',
      hasPermissions: !!isPriceVisible,
      visible: true,
      onClick: () => showEmailModal(true),
    },
  }

  const handleActionClick = (actionName: string) => {
    if (!ACTIONS[actionName]?.hasPermissions)
      return openNotificationWithIcon('warning', {
        message: 'You do not have permission to perform this action',
      })

    ACTIONS[actionName]?.onClick()
  }

  return (
    <Wrapper>
      {Object.values(ACTIONS)
        .filter(action => action.visible)
        .map(action => (
          <Tooltip key={action.name} title={action.tooltip}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleActionClick(action.name)}
              data-testid={`project--actions--${action.name}`}
            >
              {action.icon}
            </span>
          </Tooltip>
        ))}

      {settingsModal && (
        <InvoiceSettingsModal
          onClose={() => showSettingsModal(false)}
          projectId={invoice.id}
        />
      )}

      {activityModal && (
        <ModalClientActivity
          activity={invoice.activity}
          onCancel={() => showActivityModal(false)}
          entityName='invoice'
        />
      )}

      {emailModal && (
        <NotificationModal
          title='Send Invoice'
          showModal={emailModal}
          setShowModal={() => showEmailModal(false)}
          proposalForm={invoice}
          templateType={NotificationTemplateType.INVOICE}
          notificationType={NotificationModalType.INVOICE}
          contactsSourceId={invoice.parent_id}
        />
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  span {
    font-size: 22px;
    line-height: 0;

    &:hover {
      color: #4285f4;
    }
  }
`
