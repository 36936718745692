import { InputFieldV2 } from 'containers/MainContent/Orcatec/components/UI/InputField'
import { Switcher } from 'containers/MainContent/Orcatec/components/UI/Switcher/Switcher'
import {
  GlobalItem,
  GlobalItem as IItem,
  ItemAttachment,
} from 'features/Items/types'
import React, { useEffect, useState, useRef } from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import { Attachments } from './components/Attachments/Attachments'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { Checkbox, Popconfirm, Spin } from 'antd'
import ItemsAPI from 'api/Items'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { ItemUnit } from './components/ItemUnit/ItemUnit'
import { CategorySelector } from './components/CategorySelector/CategorySelector'
import ItemsSearch from './components/ItemSearch'
import { ProjectItem } from 'features/Project/types'
import { Error } from 'types/Error'
import { useDispatch } from 'react-redux'
import {
  resetErrors,
  selectPricePagesSlice,
} from 'features/Items/pricePagesSlice'
import { resetProjectItemsErrors } from 'features/Project/slices/projectItemsSlice'
// import { useParams } from 'react-router-dom'
import { round } from 'helpers/Math'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProject } from 'features/Project/projectSelectors'

import { formatStringToNumber } from 'helpers/numbers'

interface Props {
  categoryId?: number
  error: Error | null
  data?: IItem | ProjectItem
  limited?: boolean
  status: 'loading' | 'idle' | 'error'
  title?: string
  onSave: (item: IItem | ProjectItem) => void
  onClose: () => void
  onDelete: (item: IItem | ProjectItem) => void
  onDuplicate?: (item: IItem | ProjectItem) => void
}

const initialItem: IItem = {
  attachments: [],
  barcode: '',
  name: '',
  description: '',
  category_id: null,
  unit_id: null,
  net_price: 0,
  retail_price: 0,
  gross_margin: null,
  is_material: false,
  taxable: false,
  qty: 1,
}

export const Item = ({
  data,
  error,
  limited,
  status,
  title, // TODO: if there is a title, <Item /> component using on Project Page, else on Price Pages
  onSave,
  onClose,
  onDelete,
  onDuplicate,
}: Props) => {
  const dispatch = useDispatch()
  const descriptionRef = useRef<HTMLInputElement>(null)

  const { user_id, extraAssignedUsers } = useAppSelector(selectProject)
  const { choosenCategory } = useAppSelector(selectPricePagesSlice)
  const isCostVisible = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_READ_COST,
    ),
  )

  const [item, setItem] = useState(
    data || {
      ...initialItem,
      category_id: title
        ? undefined
        : choosenCategory
        ? choosenCategory.id
        : null,
    },
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      if (error) {
        dispatch(!title ? resetErrors() : resetProjectItemsErrors())
      }
    }
  }, [error])
  useEffect(() => {
    if (data?.id) setItem(data)
  }, [data?.id])

  const getGlobalItem = async (id: number) => {
    try {
      setLoading(true)
      const res = await ItemsAPI.getItemById(id)

      const item = {
        ...(data || {}),
        ...res,
        global_item_id: res.id,
        id: data?.id || 0,
        // option_group_id: data?.option_group_id || null,
      }
      // delete data.id
      setItem(item)
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.respose?.data?.message || 'Something went wrong',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeItem = (event: {
    target: { name: keyof IItem; value: unknown }
  }) => {
    const { name, value } = event.target

    if (
      [
        'name',
        'description',
        'unit_id',
        'net_price',
        'gross_margin',
        'retail_price',
        'is_material',
        'taxable',
      ].includes(name)
    ) {
      if (item.gross_margin !== null) {
        if (name === 'net_price') {
          const calculatedRetailPrice =
            value + round((value * item.gross_margin || 0) / 100)

          setItem(prevItem => ({
            ...prevItem,
            retail_price: calculatedRetailPrice,
          }))
        }
        if (name === 'gross_margin') {
          const calculatedRetailPrice =
            item.net_price + round((item.net_price * value || 0) / 100)

          setItem(prevItem => ({
            ...prevItem,
            retail_price: calculatedRetailPrice,
          }))
        }
      }

      setItem(prev => ({
        ...prev,
        global_item_id: title ? null : undefined,
      }))
    }

    setItem(prev => ({
      ...prev,
      [name]: value,
    }))

    if (error?.[name]) {
      dispatch(!title ? resetErrors([name]) : resetProjectItemsErrors([name]))
    }
  }

  const handleBlurNumberField = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (value) return

    setItem(prev => ({
      ...prev,
      [name]: name === 'qty' ? 1 : 0,
    }))
  }

  const handleZeroFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e?.target
    if (item?.[name as keyof GlobalItem] === 0)
      handleChangeItem({
        target: {
          name,
          value: '',
        },
      })
  }

  const handleSaveItem = async () => {
    onSave?.(item)
  }

  const handleUploadFiles = (files: ItemAttachment[]) => {
    setItem(prev => ({
      ...prev,
      attachments: files,
    }))
  }

  const handleDeleteAttachment = async (attachment: ItemAttachment) => {
    const deletingFile = item.attachments?.find(file => file === attachment)

    if (typeof deletingFile === 'string')
      setItem(prev => ({
        ...prev,
        attachments: item.attachments.filter(file => file !== deletingFile),
      }))
  }

  const handleChooseCategory = (categoryId: number | null) => {
    setItem(prev => ({
      ...prev,
      category_id: categoryId,
    }))
  }

  const handleChooseItem = async (item: IItem) => {
    if (!item.id) return

    if (error) {
      dispatch(!title ? resetErrors() : resetProjectItemsErrors())
    }

    getGlobalItem(item.id)
  }

  const handleFocusDescription = () => {
    const input = descriptionRef.current
    if (input && !item.description) {
      setItem(prev => ({
        ...prev,
        description: item.name,
      }))

      setTimeout(() => {
        input.select()
      }, 0)
    }
  }

  return (
    <Modal
      visible
      title={title || (item.id ? 'Update item' : 'Create item')}
      // onOk={handleSaveItem}
      onCancel={onClose}
      width={800}
      footer={[
        <Footer key='footer'>
          {!!item.id && !item?.checked_option && !!onDelete && (
            <Popconfirm
              title={'Are you sure you want to delete this item?'}
              onConfirm={() => onDelete(item)}
            >
              <MainButton key='delete' title='Delete Item' type='delete' />
            </Popconfirm>
          )}
          {!!item.id && !!onDuplicate && (
            <MainButton
              title='Duplicate Item'
              type='cancel'
              onClick={() => onDuplicate(item)}
              isFetching={status === 'loading'}
            />
          )}
          <MainButton
            key='cancel'
            title='Cancel'
            type='cancel'
            onClick={onClose}
            style={{ marginLeft: 'auto' }}
          />
          <MainButton
            key='save'
            title='Save'
            onClick={handleSaveItem}
            isFetching={status === 'loading'}
          />
        </Footer>,
      ]}
    >
      <Spin spinning={loading} data-testid='project-item--modal'>
        {!title && (
          <CategorySelector
            categoryId={item.category_id}
            onChooseCategory={handleChooseCategory}
          />
        )}

        {!!title && (
          <SearchSection>
            <ItemsSearch
              onChooseItem={handleChooseItem}
              catId={item.category_id}
            />
          </SearchSection>
        )}

        <Info>
          <InfoBlock>
            <InputFieldV2
              label='Item name'
              value={item.name}
              name='name'
              onChange={handleChangeItem}
              error={!!error?.name}
              helperText={!!error && error?.name}
              required
              multiline
              data-testid='item-name'
            />
            <AccessControl
              author={[user_id, ...(extraAssignedUsers || [])]}
              scopes={[ProjectPermissions.PROJECT_CAN_VIEW_ITEM_DESCRIPTON]}
            >
              <InputFieldV2
                ref={descriptionRef}
                label='Item description'
                value={item.description}
                onChange={handleChangeItem}
                onFocus={handleFocusDescription}
                name='description'
                multiline
                minRows={11}
              />
            </AccessControl>
          </InfoBlock>

          <InfoBlock>
            <ItemUnit
              label='Unit'
              name='unit_id'
              value={item.unit_id}
              onChange={handleChangeItem}
            />

            <AccessControl
              additionalAccess={!!isCostVisible}
              author={[user_id, ...(extraAssignedUsers || [])]}
              scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
            >
              <NumberFormat
                allowNegative={false}
                value={item.net_price}
                onChange={e => {
                  handleChangeItem({
                    target: {
                      name: 'net_price',
                      value: formatStringToNumber(e),
                    },
                  })
                }}
                onBlur={handleBlurNumberField}
                customInput={InputFieldV2}
                name='net_price'
                label='Cost'
                prefix='$'
                thousandSeparator
                decimalScale={3}
                onFocus={handleZeroFieldFocus}
              />
            </AccessControl>

            <AccessControl
              additionalAccess={!!isCostVisible}
              author={[user_id, ...(extraAssignedUsers || [])]}
              scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
            >
              <GrossMargin>
                <Checkbox
                  checked={item.gross_margin !== null}
                  onChange={() =>
                    setItem(prev => ({
                      ...prev,
                      gross_margin:
                        item.gross_margin !== null
                          ? null
                          : !item.retail_price || !item.net_price
                          ? 0
                          : round(
                              (prev.retail_price - prev.net_price) /
                                prev.net_price,
                            ) * 100,
                    }))
                  }
                  style={{
                    position: 'absolute',
                    left: -28,
                    top: 28,
                  }}
                />
                {item.gross_margin === null ? (
                  <InputFieldV2 label='Gross margin' value='N/A' disabled />
                ) : (
                  <NumberFormat
                    // allowNegative={false}
                    value={item.gross_margin}
                    onChange={e => {
                      handleChangeItem({
                        target: {
                          name: 'gross_margin',
                          value: formatStringToNumber(e),
                        },
                      })
                    }}
                    onBlur={handleBlurNumberField}
                    name='gross_margin'
                    customInput={InputFieldV2}
                    label='Gross margin'
                    suffix='%'
                    decimalScale={2}
                    onFocus={handleZeroFieldFocus}
                  />
                )}
              </GrossMargin>
            </AccessControl>

            <AccessControl
              author={[user_id, ...(extraAssignedUsers || [])]}
              scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
            >
              <NumberFormat
                allowNegative={false}
                value={item.retail_price}
                onChange={e => {
                  handleChangeItem({
                    target: {
                      name: 'retail_price',
                      value: formatStringToNumber(e),
                    },
                  })
                }}
                onFocus={handleZeroFieldFocus}
                onBlur={handleBlurNumberField}
                name='retail_price'
                customInput={InputFieldV2}
                label='Retail price'
                prefix='$'
                disabled={item.gross_margin !== null}
                thousandSeparator
                decimalScale={3}
              />
            </AccessControl>

            {!!title && (
              <NumberFormat
                allowNegative={false}
                value={item.qty}
                onValueChange={valuees =>
                  handleChangeItem({
                    target: { name: 'qty', value: valuees.floatValue },
                  })
                }
                onBlur={handleBlurNumberField}
                onFocus={handleZeroFieldFocus}
                name='qty'
                customInput={InputFieldV2}
                label='Qty'
                thousandSeparator
                decimalScale={2}
              />
            )}

            {!limited && (
              <>
                <Switcher
                  label='Material'
                  checked={item.is_material}
                  onChange={value =>
                    handleChangeItem({
                      target: { name: 'is_material', value: value },
                    })
                  }
                />
                <Switcher
                  label='Taxable'
                  checked={item.taxable}
                  onChange={value =>
                    handleChangeItem({
                      target: { name: 'taxable', value: value },
                    })
                  }
                />
              </>
            )}
          </InfoBlock>
        </Info>

        <Attachments
          title='Attachments'
          data={item.attachments}
          onUpload={handleUploadFiles}
          onDelete={handleDeleteAttachment}
        />
      </Spin>
    </Modal>
  )
}

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  gap: 50px;
`

const GrossMargin = styled.div`
  position: relative;
`

const SearchSection = styled.div`
  margin-bottom: 10px;
`
const InfoBlock = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`

const Footer = styled.div`
  display: flex;
  gap: 10px;
`
