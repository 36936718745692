import { InputNumberProps } from 'antd'
import withLabelAndError from '../helpers/Wrapper'
import NumberFormat from 'react-number-format'
import { TextField } from '..'
import { formatStringToNumber } from 'helpers/numbers'

interface Props {
  decimalScale?: number
  allowNegative?: boolean
  onChange: (value: number | '') => void
}

const NumberField = ({
  allowNegative = true,
  decimalScale = 2,
  onChange,
  ...props
}: Props) => {
  return (
    <NumberFormat
      allowNegative={allowNegative}
      customInput={TextField}
      thousandSeparator
      decimalScale={decimalScale}
      {...props}
      onChange={e => {
        onChange(formatStringToNumber(e, allowNegative))
      }}
    />
  )
}

export const NumberFieldWithLabelAndError = withLabelAndError<InputNumberProps>(
  NumberField,
)
