import { DATE_FORMAT } from 'constants/dateFormats'
import dayjs from 'dayjs'
import { Invoice } from 'features/Invoice/types'
import { BalanceStatusEnum, PaymentBalance } from 'features/Project/types'
import styled from 'styled-components'

interface Props {
  data: Invoice
  balance: PaymentBalance
}

export const InvoiceInfo = ({ data, balance }: Props) => {
  return (
    <Wrapper>
      <Columns>
        <div>
          <p>
            Invoice ID: <span>{data.code}</span>
          </p>
          <p>
            Invoice name: <span>{data.name || '-'}</span>
          </p>
        </div>

        <div>
          <p>
            Invoice Date: <span>{data.issue_date}</span>
          </p>
          <p>
            {balance.status === BalanceStatusEnum.Paid ? 'Payment' : 'Due'}{' '}
            Date:{' '}
            <span>
              {balance?.due_date
                ? dayjs(balance.due_date).format(DATE_FORMAT)
                : '-'}
            </span>
          </p>
        </div>
      </Columns>

      <p>
        This invoice is for project:{' '}
        <span>{data.project_code || data.proposal_code}</span>
      </p>
    </Wrapper>
  )
}

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`

const Wrapper = styled.div`
  span {
    font-weight: 700;
  }
`
