import styled from 'styled-components'
import { Spin, Tooltip } from 'antd'
import { getProposalLink } from 'api/NotificationModal'
import { getProposalPdf } from 'api/Proposal'
import {
  IconEnvelope,
  IconEye,
  IconPDF,
  IconPrint,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
/* import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors' */
import { downloadBlob } from 'helpers/downloadBlob'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationModalType,
  NotificationTemplateType,
} from 'features/Notification/types'
// import { getSelectedClients } from 'containers/MainContent/Orcatec/Proposals/helpers/getSelectedClients'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { selectInovoicesSlice } from 'features/Invoice/store/invoicesSlice'

export const InvoicePreviewActions = () => {
  const { currentInvoice: invoice } = useAppSelector(selectInovoicesSlice)
  const currentTab = invoice?.proposal_tabs?.[0]
  const isPriceVisible = useAppSelector(
    selectIsPriceVisible([
      invoice?.user_id,
      ...(invoice?.extraAssignedUsers || []),
    ]),
  )

  const [publicLink, setPublicLink] = useState('')
  const [downloadPdfLoader, setDownloadPdfLoader] = useState(false)
  const [generatePdf, setGeneratePdf] = useState(false)
  const [emailModal, setEmailModal] = useState(false)

  useEffect(() => {
    const getPublicLink = async () => {
      const link = await getProposalLink(invoice.id)
      setPublicLink(link)
    }

    if (invoice.id) getPublicLink()
  }, [invoice.id])

  const tabId = currentTab?.id || ''
  // const selectedClients = getSelectedClients(project)

  const downloadPdf = async () => {
    const pdfName = `${invoice?.code}`

    setDownloadPdfLoader(true)

    try {
      if (!invoice.id) return
      const dataURI = await getProposalPdf(invoice.id, tabId)

      await downloadBlob(dataURI, pdfName)
    } catch (error) {
      console.error(error)
    } finally {
      setDownloadPdfLoader(false)
    }
  }

  const handlePrint = async () => {
    const pdfName =
      invoice?.code +
      '-' +
      (currentTab?.title || '') +
      '-' +
      moment().toISOString()

    setGeneratePdf(true)
    const iframe = await document.createElement('iframe')

    try {
      const dataURI = await getProposalPdf(invoice.id, tabId)

      iframe.src = window.URL.createObjectURL(dataURI)
      iframe.name = pdfName
      iframe.id = 'print_frame'

      iframe.onload = () => {
        const pdfFrame = window.frames[pdfName]
        pdfFrame.focus()
        pdfFrame.print()
      }
      document.body.appendChild(iframe)
    } catch (error) {
      openNotificationWithIcon('error', {
        message:
          'Access to the frame is blocked. Please allow access in your browser settings.',
      })
      window.open(iframe.src, '_blank')

      console.error(error)
    } finally {
      setGeneratePdf(false)
    }
  }

  const handleCustomerViewOpen = () => {
    if (!publicLink) return

    window.open(publicLink, '_blank')
  }

  const toggleProposalEmailModal = () => setEmailModal(!emailModal)
  const ACTIONS = {
    projectPrint: {
      name: 'projectPrint',
      icon: <IconPrint />,
      tooltip: 'Print Invoice',
      visible: true,
      loading: generatePdf,
      hasPermissions: !!isPriceVisible,
      onClick: handlePrint,
    },
    projectDownload: {
      name: 'projectDownload',
      icon: <IconPDF />,
      tooltip: 'Download Invoice as PDF',
      visible: true,
      loading: downloadPdfLoader,
      hasPermissions: !!isPriceVisible,
      onClick: downloadPdf,
    },
    projectPreview: {
      name: 'projectPreview',
      icon: <IconEye />,
      tooltip: 'Customer View',
      visible: true,
      onClick: handleCustomerViewOpen,
      hasPermissions: !!isPriceVisible,
    },
    projectSend: {
      name: 'projectSend',
      icon: (
        <IconEnvelope
          style={{
            color: invoice.had_been_sent ? '#28a745' : '#4D4D4D',
          }}
        />
      ),
      tooltip: 'Send Invoice',
      visible: true,
      hasPermissions: !!isPriceVisible,
      onClick: toggleProposalEmailModal,
    },
  }

  const handleActionClick = (actionName: keyof typeof ACTIONS) => {
    if (!ACTIONS[actionName]?.hasPermissions)
      return openNotificationWithIcon('warning', {
        message: 'You do not have permission to perform this action',
      })

    ACTIONS[actionName]?.onClick()
  }

  return (
    <Wrapper>
      {Object.values(ACTIONS)
        .filter(action => action.visible)
        .map(action => (
          <Tooltip key={action.name} title={action.tooltip}>
            <Spin spinning={!!action?.loading}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => handleActionClick(action.name)}
              >
                {action.icon}
              </span>
            </Spin>
          </Tooltip>
        ))}

      {emailModal && (
        <NotificationModal
          title='Send Invoice'
          showModal={emailModal}
          setShowModal={toggleProposalEmailModal}
          proposalForm={invoice}
          templateType={NotificationTemplateType.INVOICE}
          notificationType={NotificationModalType.INVOICE}
          contactsSourceId={invoice.parent_id}
        />
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
  position: relative;
  top: 7px;

  span {
    font-size: 24px;
    line-height: 0;

    &:hover {
      color: #4285f4;
    }
  }
`
