import { BalanceStatusEnum } from 'features/Project/types'
import styled from 'styled-components'

interface Props {
  balanceStatus: BalanceStatusEnum
}

export const InvoiceStatus = ({ balanceStatus }: Props) => {
  const status = [BalanceStatusEnum.Paid].includes(balanceStatus)
    ? 'Receipt'
    : 'Invoice'

  return <Title>{status}</Title>
}

const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.33px;
  text-align: center;
`
