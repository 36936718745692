import { priceToView } from '../../../../../../helpers/thousandSeparator'
import { Balance } from './components/Balance'

import './styles.scss'

const BalanceTable = ({
  balances,
  remaining = 0,
  radio = false,
  remainigTitle = 'Remaining Balance:',
  onClickPay,
  customerView,
}) => {
  return (
    <div className={`payment-balances${radio ? ' radio' : ''}`}>
      <div className='table'>
        <div className='table-row heading'>
          <p>Payment name</p>
          <p>Payment Description</p>
          <p>Due Date</p>
          <p>Amount</p>
          <p>Status</p>
          {!!onClickPay && !!balances?.length && <p></p>}
        </div>

        {balances?.map(balance => (
          <Balance
            key={balance?.id}
            data={balance}
            isCustomerView={customerView}
            onPay={onClickPay}
          />
        ))}

        {remaining !== 0 && (
          <div className='table-row remaining-balance'>
            <p>{remainigTitle}</p>
            <p />
            <p />
            <p>{priceToView(remaining)}</p>
            <p>Due</p>
            {!!onClickPay && !!balances?.length && <p></p>}
          </div>
        )}
      </div>
    </div>
  )
}

export default BalanceTable
