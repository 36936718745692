import { Button } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import BalanceTable from 'containers/MainContent/Orcatec/Proposals/components/BalanceTable'
import {
  selectInvoiceBalance,
  updateInvoicePayment,
} from 'features/Invoice/store/invoicesSlice'
import { PaymentBalance } from 'features/Project/components/PaymentSchedule/components/PaymentBalance/PaymentBalance'
import { useAvailablePaymentMethods } from 'features/Project/components/PaymentSchedule/hooks/useAvailablePaymentMethods'
import { useBalanceNames } from 'features/Project/components/PaymentSchedule/hooks/useBalanceNames'
import { usePaymentMethods } from 'features/Project/components/PaymentSchedule/hooks/usePaymentMethods'
import { ProjectStatusGroup } from 'features/Project/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'

export const PaymentInfo = ({ onPay }: { onPay: () => void }) => {
  const dispatch = useAppDispatch()

  const initialBalance = useAppSelector(selectInvoiceBalance)
  const { paymentMethods } = usePaymentMethods()
  const { availablePaymentMethods, hasMerchant } = useAvailablePaymentMethods()
  const {
    balanceNames,
    loading: loadingBalanceNames,
    addBalanceName,
    deleteBalanceName,
  } = useBalanceNames()

  const [paymentModal, showPaymentModal] = useState(false)
  const [balance, setBalance] = useState(initialBalance)
  const [isEdited, setIsEdited] = useState(false)

  useEffect(() => {
    setBalance(initialBalance)
  }, [initialBalance])

  async function handlePaymentUpdate() {
    try {
      const result = await dispatch(updateInvoicePayment(balance))

      if (result.meta.requestStatus === 'rejected') {
        return Promise.reject(result.payload)
      }

      showPaymentModal(false)
      setIsEdited(false)

      return result
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.reponse?.data?.error || 'Something went wrong',
      })
    }
  }

  if (!balance) return null

  return (
    <Wrapper>
      <Heading>
        <Title>Payment Info</Title>

        <Button
          type='primary'
          onClick={() => {
            setBalance(initialBalance)
            showPaymentModal(true)
          }}
        >
          Edit
        </Button>
      </Heading>

      {initialBalance && (
        <BalanceTable
          balances={[initialBalance]}
          remainigTitle='Remaining Balance to split:'
          onClickPay={onPay}
        />
      )}

      {paymentModal && (
        <Modal
          open
          title='Edit Payment'
          onOk={handlePaymentUpdate}
          onCancel={() => {
            showPaymentModal(false)
            setIsEdited(false)
          }}
          showConfirmationOnExit={isEdited}
        >
          <PaymentBalance
            projectStatus={ProjectStatusGroup.Contract}
            data={balance}
            disabled={false}
            paymentMethods={paymentMethods}
            balanceNames={balanceNames}
            loadingBalanceNames={loadingBalanceNames}
            hasActiveMerchant={hasMerchant}
            availablePaymentMethods={availablePaymentMethods}
            onChange={balance => {
              setIsEdited(true)
              setBalance(balance)
            }}
            onBalanceNameAdd={addBalanceName}
            onBalanceNameDelete={deleteBalanceName}
            isInvoice
          />
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`
const Title = styled.p`
  letter-spacing: 0.01em;
  font-weight: 700;
`
