import styled from 'styled-components'
import { Heading } from './components/Heading'
import { InvoiceCustomerViewContent } from './components/InvoiceCustomerViewContent'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  getInvoice,
  selectInovoicesSlice,
  updateProjectInvoiceField,
} from 'features/Invoice/store/invoicesSlice'

export const InvoiceCustomerView = () => {
  const dispatch = useAppDispatch()

  const { currentInvoice: invoice, status } = useAppSelector(
    selectInovoicesSlice,
  )

  async function getData() {
    dispatch(getInvoice(invoice.id))
  }

  return (
    <Wrapper>
      <Heading />

      <ContentWrapper>
        <InvoiceCustomerViewContent
          data={invoice}
          onChange={filesToUpdate =>
            dispatch(updateProjectInvoiceField(filesToUpdate))
          }
          isLoading={status === 'loading'}
          onBalancePaid={getData}
        />
      </ContentWrapper>
    </Wrapper>
  )
}

export const Wrapper = styled.div``

export const ContentWrapper = styled.div`
  min-height: 1000px;
  margin-top: 37px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px 1px;
`
