import { TypeNotificationNum } from '../types'

export const DEFAULT_TEMPLATES: { [index: string]: any } = {
  upcoming_appointment: {
    type: 1,
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: 'Upcoming Appointment with [company_name]',
      body:
        '<p>Hello,</p>' +
        '<p>This is a friendly reminder about your upcoming appointment on [date],</p>' +
        '<p>Estimated arrival time is: [promised_arrival_time]</p>' +
        '<p>If you have any questions, please contact us at [company_phone_number], or [company_email]</p>' +
        '<p>Thank you!&nbsp;</p>',
    },
    sms: {
      body:
        'Your appointment is set for [date] Time of arrival: [promised_arrival_time]. Thank you, [company_name]',
      media: [],
    },
  },
  upcoming_visit: {
    type: 1,
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: 'Upcoming Visit with [company_name]',
      body:
        '<p>Hello,</p>' +
        '<p>This is a friendly reminder about your upcoming visit on [date],</p>' +
        '<p>Estimated arrival time is: [dispatch_time]</p>' +
        '<p>If you have any questions, please contact us at [company_phone_number], or [company_email]</p>' +
        '<p>Thank you!&nbsp;</p>',
    },
    sms: {
      body:
        'Your appointment is set for [date] Time of arrival: [dispatch_time]. Thank you, [company_name]',
      media: [],
    },
  },
  request_for_review: {
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: 'Request for review from [company_name]',
      body:
        '<p>Dear client.</p>' +
        '<p>This is [tech_name], with [company_name].</p>' +
        '<p>We had recently completed some work for you. Please share your experience with us.</p>' +
        '<p>Thank you, [company_email] [company_phone_number]&nbsp;</p>',
    },
    sms: {
      body:
        'We had recently completed some work for you. Please share your experience with us.',
    },
  },
  in_route: {
    type: 1,
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: 'Technician from [company_name] is on his way',
      body:
        '<p>Dear client,</p>' +
        '<p>This is [tech_name], with [company_name].</p>' +
        '<p>We have an appointment scheduled, and I want to let you know that I am on my way.</p>' +
        '<p>Estimated arrival time: 30min</p>' +
        '<p>If you have any questions, please contact me at [user_email] or [user_phone]</p>' +
        '<p>Office [company_email] or [company_phone_number]</p>' +
        '<p>Thank you!&nbsp;</p>',
    },
    sms: {
      body:
        '[tech_name] with [company_name] is on his way. Estimated arrival time is 30 min.',
      media: [],
    },
  },
  automatic_follow_up: {
    from_name: '',
    reply_to: '',
    subject: 'Follow up from [company_name]',
    body:
      '<p>Dear client,</p>' +
      '<p>This is [tech_name], with [company_name].</p>' +
      '<p>I would like to follow up on the estimate that I recently submitted.</p>' +
      '<p>Please contact me at your earliest avalability</p>' +
      '<p>[user_email] or [user_phone]</p>' +
      '<p>Office [company_email] or [company_phone_number]</p>' +
      '<p>Thank you!&nbsp;</p>',
  },
  email_proposal: {
    type: 2,
    email: {
      from_name: '[user_name]',
      reply_to: '[user_email]',
      subject: '[ProjectHeader (Estimate, Invoice, etc.)] from [company_name]',

      body:
        '<p>Dear client.</p>' +
        '<p>This is [tech_name], with [company_name].</p>' +
        '<p>Please follow this [link_to_project] to see your [ProjectHeader (Estimate, Invoice, etc.)]</p>' +
        '<p>If you have any questions, please contact us at [company_email] or [company_phone_number]</p>' +
        '<p>Thank you!&nbsp;</p>',
    },
    sms: {
      body:
        '[ProjectHeader (Estimate, Invoice, etc.)] from [company_name] Please follow the link below to see your [ProjectHeader (Estimate, Invoice, etc.)] [link_to_project]',
    },
  },
  email_invoice: {
    type: 5,
    email: {
      from_name: '[company_name]',
      reply_to: '[company_email]',
      subject: '[invoice_header] [invoice_id] from [company_name]',

      body:
        '<p>Dear [recipient_name].</p>' +
        '<p>This is [user_name], with [company_name].</p>' +
        '<p>Please find your [invoice_header] ([invoice_id]) by following this link: [link_to_invoice]</p>' +
        '<p>If you have any questions, feel free to contact us at [company_email] or [company_phone_number]</p>' +
        '<p>Thank you!&nbsp;</p>',
    },
    sms: {
      body:
        '[invoice_header] [invoice_id] from [company_name]. Please follow the link below to see your [invoice_header] [invoice_id] from [company_name] [link_to_invoice]',
    },
  },
  appointment_reminder: {
    type: 1,
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: 'Reminder appointment with [company_name]',
      body:
        '<p>Hello, This is a friendly reminder about your upcoming appointment on [date] with [company_name].</p>' +
        '<p>Estimated arrival time is: [promised_arrival_time] </p>' +
        '<p>If you have any questions, please contact us at [company_phone_number], or [company_email] </p>' +
        '<p>Thank you!</p>',
    },
    sms: {
      body:
        '<p>This is a friendly reminder about your upcoming appointment on [date] with [company_name]. </p>' +
        '<p>Estimated arrival time is: [promised_arrival_time] </p>' +
        '<p>If you have any questions, please contact us at [company_phone_number], or [company_email]</p>',
      media: [],
    },
  },
  canceled: {
    type: 1,
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: 'Your appointment for [date] had been cancelled',
      body:
        '<p>Hello,</p>' +
        '<p>This is a confirmation that your upcoming appointment on [date],</p>' +
        '<p>at [promised_arrival_time] with [company_name] had been canceled.</p>' +
        '<p>If you have any questions, please contact us at [company_phone_number], or [company_email].' +
        '<p>Thank you!&nbsp;</p>',
    },
    sms: {
      body:
        'This is a confirmation that your upcoming appointment on [date] at [promised_arrival_time] with [company_name] had been canceled. If you have any questions, please contact us at [company_phone_number], or [company_email] ',
      media: [],
    },
  },
  sent_form: {
    type: 1,
    email: {
      from_name: '[tech_name]',
      reply_to: '[tech_email]',
      subject: '[company_name] - Forms for Your Review',
      body:
        '<p>Dear client,</p>' +
        '<p> Please find attached the completed forms as requested. If you have any questions or need further assistance, feel free to reach out,</p>' +
        '<p>[company_phone_number]</p>' +
        '<p>[company_email]' +
        '<p>Thank you!&nbsp;</p>',
    },
  },
}

export const EditorVariables: {
  [key: string]: string[]
} = {
  [TypeNotificationNum.TYPE_APPOINTMENT]: [
    '[appointment_date]',
    '[tech_name]',
    '[tech_email]',
    '[tech_phone_number]',
    '[company_name]',
    '[company_email]',
    '[company_phone_number]',
    '[user_name]',
    '[user_email]',
    '[user_phone]',
    '[promised_arrival_time]',
  ],

  [TypeNotificationNum.TYPE_PROPOSAL]: [
    '[date]',
    '[tech_name]',
    '[tech_email]',
    '[tech_phone_number]',
    '[company_name]',
    '[company_email]',
    '[company_phone_number]',
    '[user_name]',
    '[user_email]',
    '[user_phone]',
    '[project_id]',
    '[ProjectHeader (Estimate, Invoice, etc.)]',
    '[link_to_project]',
  ],
  [TypeNotificationNum.TYPE_GENERAL]: [
    '[date]',
    '[tech_name]',
    '[tech_email]',
    '[tech_phone_number]',
    '[company_name]',
    '[company_email]',
    '[company_phone_number]',
    '[user_name]',
    '[user_email]',
    '[user_phone]',
    '[project_id]',
    '[ProjectHeader (Estimate, Invoice, etc.)]',
    '[link_to_project]',
    '[appointment_date]',
    '[promised_arrival_time]',
  ],
  [TypeNotificationNum.TYPE_WORK_ORDER]: [
    '[recipient_name]',
    '[tech_email]',
    '[tech_phone_number]',
    '[company_name]',
    '[company_email]',
    '[company_phone_number]',
    '[user_name]',
    '[user_email]',
    '[user_phone]',
    '[work_order_name]',
    '[work_order_id]',
    '[work_order_scope_of_work]',
    '[notes_for_worker]',
    '[work_order_primary_property]',
    '[visit_date(s)]',
  ],
  [TypeNotificationNum.TYPE_INVOICE]: [
    '[date]',
    '[tech_name]',
    '[tech_email]',
    '[tech_phone_number]',
    '[company_name]',
    '[company_email]',
    '[company_phone_number]',
    '[user_name]',
    '[user_email]',
    '[user_phone]',
    '[invoice_id]',
    '[invoice_header (Invoice/Receipt)]',
    '[link_to_invoice]',
    '[recipient_name]',
  ],
}

export const timeFrom = [
  { value: '06:00', label: '6:00 AM' },
  { value: '07:00', label: '7:00 AM' },
  { value: '08:00', label: '8:00 AM' },
  { value: '09:00', label: '9:00 AM' },
  { value: '10:00', label: '10:00 AM' },
  { value: '11:00', label: '11:00 AM' },
]
export const timeTo = [
  { value: '15:00', label: '3:00 PM' },
  { value: '16:00', label: '4:00 PM' },
  { value: '17:00', label: '5:00 PM' },
  { value: '18:00', label: '6:00 PM' },
  { value: '19:00', label: '7:00 PM' },
  { value: '20:00', label: '8:00 PM' },
]

export const projectLink =
  'https://app.orcatec.com/preview?eyJpdiI6IlJxZWh0Y3lWWm91KzcxZVZwZHsSEd5VkE9PSGEIsInZhbHVlIjoVderGrenGeBWdE1aUGlUUT09IiwibWFjIjoiMTUxZTUwNDlmMTEzZTkwZjFiN2U1MzU0MmE1YmVlODA3NmFiYzNhOTFlYjExMzc2OWJlZjdmZWZiM2RmZjdkYSJ9'
