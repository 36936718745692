import * as types from '../types'
import { getProjectPayment } from '../../../api/Payment'
import { paymentSet } from 'features/Project/slices/projectPaymentSlice'

export const setPayments = response => {
  return {
    type: types.payments.SET_PAYMENTS,
    response,
  }
}

export const fetchPaymentsList = (id, type, proposalTabId) => dispatch => {
  return getProjectPayment({
    entity_id: id,
    entity_type: type,
    tab_id: proposalTabId,
  }).then(response => dispatch(paymentSet(response)))
  // }).then(response => dispatch(setPayments(response)))
}

// export const fetchPaymentsList = (id, data) => {
//     return getPaymentsModal(id, data).then(responce => action.response)
// }
