import { FC, useEffect, useState } from 'react'
// import { ContentContainerInner } from 'features/Project/components/EditProject/EditProject.styles'
import { Empty, TablePaginationConfig } from 'antd'
import { priceToView } from 'helpers/thousandSeparator'
// import styled from 'styled-components'
// import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { BalanceStatusEnum, WorkOrderRowData } from 'features/Project/types'
import styled from 'styled-components'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import dayjs from 'dayjs'
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'constants/dateFormats'
import { CheckCircleOutlined } from '@ant-design/icons'
import Table from 'containers/MainContent/Orcatec/components/Table'
import { ColumnProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import { useHistory } from 'react-router-dom'
import {
  getInvoicesList,
  selectInovoicesSlice,
} from 'features/Invoice/store/invoicesSlice'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { InvoicesOperation } from 'features/Invoice/types'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
// import { setWorkOrderListTotal } from 'features/Project/slices/projectWorkOrdersSlice'

interface IProps {
  projectId: number
  currentInvoiceId?: number
  disabled?: boolean
}

export const InvoicesList: FC<IProps> = ({
  disabled,
  projectId,
  currentInvoiceId,
}) => {
  const { UPDATE } = usePermissionsByModule(
    InvoicesOperation,
    false,
    ModuleName.INVOICES,
  )

  const history = useHistory()
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const isPriceVisible = useAppSelector(selectIsPriceVisible(me.id))

  const { invoicesList, status } = useAppSelector(selectInovoicesSlice)

  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)

  const getData = async params => {
    dispatch(
      getInvoicesList({
        proposal_id: projectId,
        ...params,
      }),
    )
  }

  useEffect(() => {
    if (projectId)
      getData({
        page: invoicesList.pagination.current_page,
        per_page: invoicesList.pagination.per_page,
      })
  }, [])

  const onOpenDetails = (record: WorkOrderRowData) => {
    if (disabled) return

    history.push(`/invoices/${record.id}`)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Partial<Record<keyof WorkOrderRowData, string[]>>,
    sorter: SorterResult<WorkOrderRowData>,
  ) => {
    getData({
      page: pagination.current,
      per_page: pagination.pageSize,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      ...filters,
    })
  }

  const onRow = (_: WorkOrderRowData, rowIndex: number) => ({
    onClick: () => {
      setClickedRowIndex(rowIndex)
    },
  })

  const columns: ColumnProps<WorkOrderRowData[]> = [
    {
      title: 'ID',
      dataIndex: 'code',
      sorter: true,
      noFilter: true,
      key: 'code',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (code: string, record) => (
        <Link
          disabled={!UPDATE}
          onClick={() => {
            if (!UPDATE) return
            onOpenDetails(record)
          }}
        >
          {code}
        </Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      noFilter: true,
      key: 'name',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (name: string | null) => name ?? '-',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: true,
      noFilter: true,
      key: 'amount',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (value: number) => (isPriceVisible ? priceToView(value) : '-'),
      defaultWidth: 120,
    },
    {
      title: 'Payment status',
      dataIndex: 'payment_status',
      sorter: true,
      noFilter: true,
      key: 'payment_status',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      // render: (status: number) => BalanceStatusEnum[status],
      render: (status: number) => (
        <ProjectStatus color={InvoiceStatus[status]?.color}>
          {InvoiceStatus[status]?.display_name}
        </ProjectStatus>
      ),
      defaultWidth: 120,
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoice_date',
      sorter: true,
      noFilter: true,
      key: 'invoice_date',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (date: string) =>
        date ? dayjs(date).format(FULL_DATE_FORMAT) : '-',
    },
    {
      title: 'Due date',
      dataIndex: 'due_date',
      sorter: true,
      noFilter: true,
      key: 'due_date',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      defaultWidth: 100,
      render: (date: string) => (date ? dayjs(date).format(DATE_FORMAT) : '-'),
    },
    {
      title: 'Primary contact',
      dataIndex: 'contacts',
      sorter: true,
      noFilter: true,
      key: 'contacts',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (contact: string) => contact || '-',
    },
    {
      title: 'Primary address',
      dataIndex: 'full_address',
      sorter: true,
      noFilter: true,
      key: 'full_address',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (address: string) => address || '-',
    },
    {
      title: 'Payment method',
      dataIndex: 'payment_method',
      sorter: true,
      noFilter: true,
      key: 'payment_method',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      render: (method: string) => method || '-',
      defaultWidth: 100,
    },
    {
      title: 'Sent',
      dataIndex: 'had_been_sent',
      sorter: true,
      noFilter: true,
      key: 'had_been_sent',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      align: 'center',
      defaultWidth: 55,
      render: (value: string) =>
        value ? (
          <CheckCircleOutlined style={{ color: '#2ebda3', fontSize: 20 }} />
        ) : (
          '-'
        ),
    },
    {
      title: 'Read',
      dataIndex: 'had_been_viewed',
      sorter: true,
      noFilter: true,
      key: 'had_been_viewed',
      onCell: (record: IProposalsTableItem) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      align: 'center',
      defaultWidth: 55,
      render: (value: string) =>
        value ? (
          <CheckCircleOutlined style={{ color: '#2ebda3', fontSize: 20 }} />
        ) : (
          '-'
        ),
    },
  ]

  return (
    <Wrapper>
      <Table
        autoHeight
        size='small'
        name='invoices-list'
        dataSource={invoicesList.data}
        columns={columns}
        loading={status === 'loading'}
        pagination={
          invoicesList.pagination.total > 10
            ? {
                current: invoicesList.pagination.current_page,
                total: invoicesList.pagination.total,
                pageSize: invoicesList.pagination.per_page,
                showSizeChanger: true,
              }
            : false
        }
        onChange={handleTableChange}
        locale={{ emptyText: <Empty description='No data yet' /> }}
        rowClassName={record =>
          record.id === currentInvoiceId ? 'current' : ''
        }
        onRow={onRow}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .current {
    background-color: #f1f1f1;

    span {
      color: initial;
      pointer-events: none;
    }
  }
`

const Link = styled.span<{ disabled: boolean }>`
  color: ${props => (props.disabled ? 'initial' : '#1890ff')};
  cursor: pointer;
`

const ProjectStatus = styled.span<{ color: string }>`
  background-color: ${props => props.color}33;
  color: ${props => props.color};
  padding: 4px 8px;
  border-radius: 4px;
`

const InvoiceStatus = {
  [BalanceStatusEnum.Due]: {
    id: BalanceStatusEnum.Due,
    display_name: 'Due',
    color: '#206FED',
  },
  [BalanceStatusEnum.Overdue]: {
    id: BalanceStatusEnum.Overdue,
    display_name: 'Overdue',
    color: '#F3A533',
  },
  [BalanceStatusEnum.Paid]: {
    id: BalanceStatusEnum.Paid,
    display_name: 'Paid',
    color: '#00A643',
  },
  [BalanceStatusEnum.Cancelled]: {
    id: BalanceStatusEnum.Cancelled,
    display_name: 'Cancelled',
    color: '#F1453D',
  },
  [BalanceStatusEnum.Pending]: {
    id: BalanceStatusEnum.Pending,
    display_name: 'Pending',
    color: '#9d9d9d',
  },
}
