import { useState, ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'

import { InputField } from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'
// import { useDispatch } from 'react-redux'

import { EditIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  ProjectPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
// import {
//   selectProjectSlice,
//   updateProjectField,
// } from 'features/Project/slices/projectSlice'
// import { useAppSelector } from 'store/Orcatec/hooks'
import { Project } from 'features/Project/types'

interface Props {
  disabled: boolean
  project: Project
  loading: boolean
  entityName?: string
  onSave: (code: string) => void
}

export const EditProjectCode = ({
  disabled,
  project,
  loading,
  entityName = 'Project',
  onSave,
}: Props) => {
  //   const dispatch = useDispatch()
  //
  //   const { project, status } = useAppSelector(selectProjectSlice)

  const [state, setState] = useState('')
  const [error, setError] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    setState(project?.code)
  }, [project?.code])

  const handleTextClick = () => {
    setIsEdit(true)
  }

  const validateInput = () => {
    if (!state?.trim()) {
      return {
        valid: false,
        error: `${entityName} ID is required`,
      }
    }

    return {
      valid: true,
      error: '',
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setError('')
    setState(value)
  }

  const handleSave = async () => {
    const { valid, error } = validateInput()
    if (!valid) return setError(error)

    // const res = await dispatch(updateProjectField({ code: state }))

    try {
      await onSave(state)
    } catch (error) {
      return setError(error.message)
    }

    // if (res.payload.errors) return setError(res.payload.errors)

    setIsEdit(false)
  }

  const onCancel = () => {
    setState(project?.code)
    setError('')
    setIsEdit(false)
  }
  return (
    <Wrapper>
      {isEdit ? (
        <Content className='editable-content' data-testid='content'>
          <InputField
            name='code'
            onChange={onChange}
            error={error}
            value={state}
          />
          {/* <InfoIcon title={error?.code} /> */}
          <Buttons>
            <MainButton
              title='Save'
              onClick={handleSave}
              isFetching={loading}
            />
            <MainButton type='cancel' title='Cancel' onClick={onCancel} />
          </Buttons>
        </Content>
      ) : (
        <ValueWrapper data-testid='project--projectId'>
          <Value>{state || '--'}</Value>
          <AccessControl
            // additionalAccess={[1, 5].includes(project.status)}
            additionalAccess={!disabled}
            module={ModuleName.PROJECT}
            scopes={[ProjectPermissions.PROJECT_CAN_CHANGE_CODE]}
            author={[project.user_id, ...(project?.extraAssignedUsers || [])]}
          >
            <Edit onClick={handleTextClick} data-testid='edit-icon'>
              <EditIcon size={12} />
            </Edit>
          </AccessControl>
        </ValueWrapper>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  margin: 0 10px;
`
const Buttons = styled.div`
  display: flex;
  gap: 10px;
`
const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
`
const Value = styled.p`
  font-size: 20px;
  word-break: break-all;
`
const Edit = styled.span`
  position: 'relative';
  top: -2px;
  cursor: pointer;
`
