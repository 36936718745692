import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import SettingsRow from 'components/UI/SettingsRow'
import { Button, Popover, Spin, Switch, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { ProjectSettings } from 'features/Project/types'
import { useState } from 'react'
import { WarningOutlined } from '@ant-design/icons'
import {
  Footer,
  Section,
  Settings,
  Title,
  Wrapper,
} from './InvoiceSettingsModal.styles'
import PaymentProcessingFee from './components/PaymentProcessingFee/PaymentProcessingFee'
import DefaultPaymentDue from './components/DefaultPaymentDue/DefaultPaymentDue'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import useInvoiceSettings from './hooks/useInvoiceSettings'
import {
  selectInvoiceSettings,
  updateInvoiceSettings,
} from 'features/Invoice/store/invoicesSlice'

interface Props {
  projectId: number
  onClose: () => void
}

export const InvoiceSettingsModal = ({ onClose, projectId }: Props) => {
  const dispatch = useAppDispatch()
  const projectSettings = useAppSelector(selectInvoiceSettings)

  const settings = useInvoiceSettings()

  const [loading, setLoading] = useState(false)

  const handleSettingsChange = async (field: Partial<ProjectSettings>) => {
    setLoading(true)

    await dispatch(updateInvoiceSettings({ projectId, field }))

    setLoading(false)
  }

  return (
    <Modal
      open
      onCancel={onClose}
      footer={
        <Footer>
          <Button onClick={onClose} type='primary'>
            Done
          </Button>
        </Footer>
      }
      title='Invoice Settings'
      width={550}
      bodyStyle={{
        maxHeight: '85vh',
        overflow: 'auto',
      }}
    >
      <ErrorBoundary>
        <Spin spinning={loading}>
          <Wrapper>
            {settings.map(section => (
              <Section key={section.title}>
                <Title>{section.title}</Title>

                <Settings>
                  {section?.settings
                    ?.filter(item => !item?.hide)
                    ?.map(item => (
                      <SettingsRow
                        key={item.field_name}
                        description={item.description}
                        title={item.title}
                        width={250}
                        withInfoIcon
                        align='right'
                      >
                        <Tooltip
                          title={
                            item.field_name === 'require_signature' &&
                            item.disabled
                              ? 'This project has already been signed'
                              : item.field_name === 'hide_zero_price' &&
                                item.disabled
                              ? 'Can be changed if "Show price per item" enabled'
                              : item.disabled
                              ? 'Can be changed if project is in Estimate status or Edit Contract mode'
                              : ''
                          }
                        >
                          {item.field_name === 'payment_processing_fee' ? (
                            <PaymentProcessingFee
                              value={projectSettings.payment_processing_fee}
                              hasPermission={section.hasPermissions}
                              disabled={!!item.disabled}
                              onChange={field => handleSettingsChange(field)}
                            />
                          ) : item.field_name === 'default_payment_due_date' ? (
                            <DefaultPaymentDue
                              value={projectSettings.default_payment_due_date}
                              onChange={field => handleSettingsChange(field)}
                              hasPermission={section.hasPermissions}
                              disabled={!!item.disabled}
                            />
                          ) : (
                            <div style={{ textAlign: 'right' }}>
                              {item.helperText && (
                                <Popover
                                  title='You have no permissions'
                                  content={item.helperText}
                                >
                                  <WarningOutlined
                                    style={{
                                      color: '#faad14',
                                      fontSize: 20,
                                      marginRight: 10,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Popover>
                              )}
                              <Switch
                                checked={!!projectSettings[item.field_name]}
                                disabled={item.disabled}
                                onChange={checked =>
                                  handleSettingsChange({
                                    [item.field_name]: checked,
                                  })
                                }
                              />
                            </div>
                          )}
                        </Tooltip>
                      </SettingsRow>
                    ))}
                </Settings>
              </Section>
            ))}
          </Wrapper>
        </Spin>
      </ErrorBoundary>
    </Modal>
  )
}
