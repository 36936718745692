import { Warning } from '@material-ui/icons'
import { Modal, Tooltip } from 'antd'
import BalanceTable from 'containers/MainContent/Orcatec/Proposals/components/BalanceTable'
import {
  selectProject,
  selectProjectActiveTab,
  selectProjectPayment,
  selectProjectSettings,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import { ProjectStatusGroup } from 'features/Project/types'
import { round } from 'helpers/Math'
import { priceToView } from 'helpers/thousandSeparator'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { Companies } from 'types/Company'
import { PaymentBalance, ProjectPayment } from 'types/Proposal'
import ConfirmModal from 'containers/MainContent/Orcatec/components/ConfirmModal/ConfirmModal'
import { useEffect, useState } from 'react'
import { selectPrimaryProjectClient } from 'features/Project/slices/projectContactsSlice'
import states from 'helpers/states'
import PaymentProcess from 'containers/MainContent/Orcatec/Proposals/components/PaymentProcess'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { useParams } from 'react-router-dom'
import { getProject } from 'features/Project/slices/projectSlice'

export const PaymentInfo = () => {
  const { id } = useParams<{
    id: string
    preview: string | undefined
  }>()
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)
  const projectTotal = useAppSelector(selectProjectTotal)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const payment: ProjectPayment = useAppSelector(selectProjectPayment)
  const primaryClient = useAppSelector(selectPrimaryProjectClient)
  const me = useAppSelector(selectAuthUser)

  const [selectedBalance, setSelectedBalance] = useState<PaymentBalance | null>(
    null,
  )
  const [showConfirm, setShowConfrim] = useState(false)

  const showPriece = true

  const showWarning =
    payment?.payment_balances?.some(bal => bal.status === 2) &&
    project?.status !== 1 &&
    ![Companies.WestCost, Companies.SoCal].includes(project?.user?.company_id)

  const isSignedTab =
    !project.signed_tab_id || currentTab.id === project.signed_tab_id

  useEffect(() => {
    if (showWarning) {
      setShowConfrim(true)
    }
  }, [showWarning])

  const sumOfTransactions = payment?.payment_balances
    ?.filter(bal => bal.status !== 4)
    ?.map(bal => Number(bal.amount))
    ?.reduce((a, b) => a + b, 0)
  const paidSum = payment?.payment_balances
    ?.filter(bal => bal.status === 3)
    ?.reduce((a, b) => a + b.amount, 0)

  const totalDue = round(projectTotal - paidSum)
  const remainingBalanceToSplit = round(projectTotal - sumOfTransactions)

  const handleBalancePay = (balance: PaymentBalance) => {
    setSelectedBalance(balance)
  }

  const handleBalancePaid = () => {
    if (id) dispatch(getProject({ projectId: +id }))
  }

  if (!showPriece || !payment?.payment_balances?.length) return null

  return (
    <Wrapper>
      {isSignedTab && (
        <Heading>
          <Title>Payment Schedule</Title>

          {showWarning && (
            <Tooltip title='Your project has some overdue balances. Please pay now to avoid additional fees. '>
              <Warning
                style={{
                  color: '#faad14',
                  fontSize: '2rem',
                }}
              />
            </Tooltip>
          )}
        </Heading>
      )}

      <BalanceTable
        balances={payment?.payment_balances}
        remaining={remainingBalanceToSplit}
        onClickPay={
          project.status === ProjectStatusGroup.Contract && handleBalancePay
        }
        customerView
      />

      {project.status !== 1 && project.status !== 5 && (
        <table className='proposal-form-tabs_content-end_of_system-total_table'>
          <tbody>
            <tr>
              {totalDue !== 0 ? (
                <>
                  <td>Total Balance Due:</td>
                  <td>{priceToView(totalDue)}</td>
                </>
              ) : (
                <>
                  <td>Paid in full:</td>
                  <td>{priceToView(projectTotal)}</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      )}

      {!!selectedBalance && (
        <Modal
          className={'paymenta-modal'}
          width='auto'
          // closable={false}
          title={`Payment for Project - ${project.code}`}
          footer={null}
          visible
          centered
          destroyOnClose
          // bodyStyle={{ marginTop: '10px' }}
          onCancel={() => {
            setSelectedBalance(null)
          }}
          maskClosable={false}
        >
          <PaymentProcess
            balance={selectedBalance}
            cardInfo={{
              owner_user_id: project.owner_user_id,
              card_holder: primaryClient?.name || '',
              billing_address: {
                address: project?.properties?.[0]?.address || '',
                city: project?.properties?.[0]?.city || '',
                region: states[project?.properties?.[0]?.state] || '',
                postal: project?.properties?.[0]?.postcode || '',
              },
            }}
            settings={{
              disableTips: !projectSettings?.show_tips,
            }}
            transactionFee={+project?.setting?.payment_processing_fee}
            onCancel={() => {
              setSelectedBalance(null)
            }}
            onPaid={handleBalancePaid}
            payer={me?.full_name}
          />
        </Modal>
      )}

      {showConfirm && (
        <ConfirmModal
          title='Balance Overdue'
          onConfirm={() => setShowConfrim(false)}
          okText='Ok'
          content={
            'Your project has some overdue balances. Please pay now to avoid additional fees. '
          }
          type='warning'
          visible
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
`
const Title = styled.p`
  font-weight: 700;
`
