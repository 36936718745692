import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { TextField } from 'components/UIKit'
import EditableBlock from 'containers/MainContent/Orcatec/components/UI/EditableBlock'
import { ProjectSettings } from 'features/Project/types'
import { formatStringToNumber } from 'helpers/numbers'
import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

interface Props {
  hasPermission: boolean
  value: number
  disabled: boolean
  title?: string
  description?: string
  onChange: (field: Partial<ProjectSettings>) => void
}

const PaymentProcessingFee = ({
  hasPermission,
  value,
  disabled,
  title,
  description,
  onChange,
}: Props) => {
  const [fee, setFee] = useState(value)

  useEffect(() => {
    setFee(value)
  }, [value])

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFee(e.target.value)
  }

  const handleFeeSave = () => {
    onChange({
      payment_processing_fee: fee || 0,
    })
  }

  const handleFeeBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) setFee(0)
  }

  return (
    <>
      {!!title && (
        <Heading>
          <p>{title}</p>

          {description && (
            <Popover
              title={title}
              content={<Description>{description}</Description>}
              placement='right'
              trigger={'click'}
            >
              <span
                style={{
                  marginLeft: 5,
                  cursor: 'help',
                }}
              >
                <QuestionCircleOutlined />
              </span>
            </Popover>
          )}
        </Heading>
      )}
      <EditableBlock
        value={`${fee} %`}
        onSave={handleFeeSave}
        disabled={disabled || !hasPermission}
        disabledText={
          !hasPermission
            ? 'You do not have permissions'
            : 'Can be changed if project is in Estimate status or Edit Contract mode'
        }
        onCancel={() => setFee(value)}
        saveOnBlur
      >
        <NumberFormat
          value={fee}
          thousandSeparator
          suffix='%'
          customInput={TextField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleValueChange({
              target: {
                name: 'amount',
                value: formatStringToNumber(e),
              },
            })
          }
          onBlur={handleFeeBlur}
          maxLength={6}
        />
      </EditableBlock>
    </>
  )
}

export default PaymentProcessingFee

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const Description = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  color: #707683;
  white-space: break-spaces;

  p {
    margin-bottom: 5px;

    span {
      font-weight: 600;
    }
  }
`
