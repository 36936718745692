import { getPaymentMethods } from 'api/Payment'
import { PaymentMethod } from 'features/Project/types'
import { useEffect, useState } from 'react'

export const usePaymentMethods = () => {
  const [paymentMethods, setPaymentsMethods] = useState<PaymentMethod[]>([])
  const [loading, setLoading] = useState(false)

  async function fetchPaymentMethods() {
    setLoading(true)
    try {
      const data = await getPaymentMethods()

      setPaymentsMethods(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchPaymentMethods()
  }, [])

  return { paymentMethods, loading, refetch: fetchPaymentMethods }
}
