import axiosOrcatec from './axiosInstance'
import axios from 'axios'

/**
 * Fetch properties list.
 *
 * @return {*}
 */
export const refreshPaymentToken = id => {
  // props - object with query params
  return axiosOrcatec
    .get(`/payment/intuit/refresh-token?user_id=${id}`)
    .then(response => response.data)
}

export const createCreditCard = (id, data) => {
  // props - object with query params
  return axiosOrcatec
    .post(`/payment/intuit/get-card-token?user_id=${id}`, data)
    .then(response => response.data)
}

export const getMerchantPaymentMethods = merchantID =>
  axiosOrcatec.get(`/merchants/${merchantID}`).then(response => response.data)

// export const runPaymentTransaction = data =>
//   axiosOrcatec.post(`/pay/run-payment-transaction`, data).then(response => response.data)
export const runPaymentTransaction = data =>
  axios
    .post(`${process.env.REACT_APP_API_URL}pay/run-payment-transaction`, data)
    .then(response => response.data)

// export const sendACHPayment = (balanceID, data) =>
//   axiosOrcatec.post(`/payments/payment-balances/${balanceID}/transactions/ach`, data).then(response => response.data)
export const sendACHPayment = (balanceID, data) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}payments/payment-balances/${balanceID}/transactions/ach`,
      data,
    )
    .then(response => response.data)

/* export const getPaymentsModal = (id, type, proposalTabId) => {
  return axiosOrcatec
    .get(
      `/payments/payment-data?entity_id=${id}&entity_type=${type}${
        proposalTabId ? `&tab_id=${proposalTabId}` : ''
      }`,
    )
    .then(response => response.data)
} */
export const getProjectPayment = params => {
  return axiosOrcatec
    .get(`/payments/payment-data`, { params })
    .then(response => response.data)
}

export const putPayments = (id, data) => {
  return axiosOrcatec
    .put(`/payments/${id}`, data)
    .then(response => response.data)
}

export const postPayments = data => {
  return axiosOrcatec.post(`/payments`, data).then(response => response.data)
}

export const deletePayments = data => {
  return axiosOrcatec
    .post(`/payments/delete-payment-balances`, { ids: data })
    .then(response => response.data)
}

export const getPaymentBalanceNames = () =>
  axiosOrcatec.get('/balance-names').then(response => response.data)
export const createPaymentBalanceName = data =>
  axiosOrcatec.post('/balance-names', data).then(response => response.data)
export const deletePaymentBalanceName = balanceNameId =>
  axiosOrcatec
    .delete(`/balance-names/${balanceNameId}`)
    .then(response => response.data)

export const getPaymentMethods = () =>
  axiosOrcatec.get('/payment-methods').then(response => response.data)
export const createPaymentMethod = data =>
  axiosOrcatec.post('/payment-methods', data).then(response => response.data)
export const deletePaymentMethod = id =>
  axiosOrcatec.delete(`/payment-methods/${id}`).then(response => response.data)

const ProjectPaymentAPI = {
  getProjectPayment,
  deletePayments,
  postPayments,
  putPayments,
}

export default ProjectPaymentAPI
