import {
  CopyOutlined,
  DeleteFilled,
  DragOutlined,
  EditFilled,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Checkbox, Radio, Select, Tooltip } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'
import { Component, PictureSize } from 'features/Forms/types'

import { FormBuilderContext } from 'features/Forms/FormBuilderContext'
import { useContext, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import {
  ComponentSettings,
  Controls,
  Option,
  Options,
  Wrapper,
} from './ComponentEditor.styles'
import { getComponentByTypeSettings } from 'features/Forms/utils/getComponentByTypeSettings'
import { Picture } from 'features/Forms/components/FormElements'
import { Media } from 'types/Media'
import { uuid4 } from '@sentry/utils'
import { DragIndicator } from '@material-ui/icons'

interface Props {
  component: Component
  index: number
}

export const ComponentEditor = ({ component, index }: Props) => {
  const { updateForm, form } = useContext(FormBuilderContext)
  const { componentIds, body } = form

  const [edit, setEdit] = useState(true)

  const handleDeleteComponent = () => {
    const newComponents = { ...body }
    delete newComponents[component.id]

    const newComponentsIds = componentIds.filter(id => id !== component.id)

    updateForm({
      ...form,
      body: newComponents,
      componentIds: newComponentsIds,
      form_name: form?.form_name?.filter(id => id !== component.id),
    })
  }

  const handleDuplicateComponent = () => {
    const id = uuid4()

    updateForm({
      ...form,
      body: { ...body, [id]: { ...component, id } },
      componentIds: [...componentIds, id],
    })
  }

  const toggleEdit = () => setEdit(!edit)

  const handleChangeComponentSettings = (
    value: any,
    fieldName: keyof Component,
  ) => {
    const newComponent = { ...component, [fieldName]: value }
    const newComponents = { ...body, [component.id]: newComponent }

    updateForm({ ...form, body: newComponents })
  }

  const handleAddOption = () => {
    if (!component.options) return
    handleChangeComponentSettings(
      [
        ...component.options,
        {
          id: uuid4(),
          label: `Option ${component.options.length + 1}`,
          value: component.options.length + 1,
        },
      ],
      'options',
    )
  }

  const handleChangeOption = (value: string, index: number) => {
    if (!component.options) return

    const newOptions = [...component.options]
    newOptions[index] = { ...newOptions[index], label: value }

    handleChangeComponentSettings(newOptions, 'options')
  }

  const handleDeleteOption = (index: number) => {
    handleChangeComponentSettings(
      component.options?.filter((_, idx) => idx !== index),
      'options',
    )
  }

  return (
    <Draggable draggableId={component.id.toString()} index={index}>
      {provided => (
        <Wrapper {...provided.draggableProps} ref={provided.innerRef}>
          <Controls>
            <Tooltip title='Move'>
              <DragOutlined {...provided.dragHandleProps} />
            </Tooltip>
            {component.type !== 'signature' && (
              <Tooltip title='Duplicate'>
                <CopyOutlined onClick={handleDuplicateComponent} />
              </Tooltip>
            )}
            <Tooltip title='Edit'>
              <EditFilled onClick={toggleEdit} />
            </Tooltip>
            <Tooltip title='Delete'>
              <DeleteFilled onClick={handleDeleteComponent} />
            </Tooltip>
          </Controls>
          {getComponentByTypeSettings(component)}

          {edit && (
            <ComponentSettings>
              {Object.hasOwn(component, 'required') && (
                <>
                  <p>Required</p>
                  <Checkbox
                    value={component.required}
                    checked={component.required}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.checked,
                        'required',
                      )
                    }
                  />
                </>
              )}

              {component.type === 'text' && (
                <>
                  <p>Display in email</p>
                  <Checkbox
                    value={component.show_in_email}
                    checked={component.show_in_email}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.checked,
                        'show_in_email',
                      )
                    }
                  />
                </>
              )}

              {component.type === 'text' && (
                <>
                  <p>Caps Lock only</p>
                  <Checkbox
                    value={component.uppercase}
                    checked={component.uppercase}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.checked,
                        'uppercase',
                      )
                    }
                  />
                </>
              )}

              {component.type === 'text' && (
                <>
                  <p>
                    Barcode scanner{' '}
                    <Tooltip title='To enable barcode scanning in the mobile app, select this checkbox'>
                      <InfoCircleOutlined style={{ color: '#4285f4' }} />
                    </Tooltip>
                  </p>

                  <Checkbox
                    value={component.barcode_scanner}
                    checked={component.barcode_scanner}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.checked,
                        'barcode_scanner',
                      )
                    }
                  />
                </>
              )}

              {Object.hasOwn(component, 'without_label') && (
                <>
                  <p>Without label</p>
                  <Checkbox
                    value={component.without_label}
                    checked={component.without_label}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.checked,
                        'without_label',
                      )
                    }
                  />
                </>
              )}

              {!component.without_label && (
                <>
                  <p>Label</p>
                  <InputField
                    value={component.label}
                    onChange={e =>
                      handleChangeComponentSettings(e.target.value, 'label')
                    }
                  />
                </>
              )}

              {component.type === 'note' && (
                <>
                  <p>Text</p>
                  <InputField
                    multiline
                    value={component.value}
                    onChange={e =>
                      handleChangeComponentSettings(e.target.value, 'value')
                    }
                  />
                </>
              )}

              {component.type === 'picture' && (
                <>
                  <p>Upload Image</p>
                  <Picture
                    value={component.value}
                    onUpload={(image: Media) =>
                      handleChangeComponentSettings(image, 'value')
                    }
                  />
                </>
              )}

              {Object.hasOwn(component, 'size') && (
                <>
                  <p>Picture size</p>
                  <Radio.Group
                    onChange={e =>
                      handleChangeComponentSettings(e.target.value, 'size')
                    }
                    value={component.size}
                  >
                    <Radio value={PictureSize.S}>S</Radio>
                    <Radio value={PictureSize.M}>M</Radio>
                    <Radio value={PictureSize.L}>L</Radio>
                  </Radio.Group>
                </>
              )}

              {Object.hasOwn(component, 'pictureAlign') && (
                <>
                  <p>Picture align</p>
                  <Radio.Group
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.value,
                        'pictureAlign',
                      )
                    }
                    value={component.pictureAlign}
                  >
                    <Radio value={'flex-start'}>Left</Radio>
                    <Radio value={'center'}>Center</Radio>
                    <Radio value={'flex-end'}>Right</Radio>
                  </Radio.Group>
                </>
              )}

              {Object.hasOwn(component, 'description') && (
                <>
                  <p>Picture Description</p>
                  <InputField
                    multiline
                    value={component.description}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.value,
                        'description',
                      )
                    }
                    placeholder='Picture Description...'
                  />
                </>
              )}

              {Object.hasOwn(component, 'descriptionSize') && (
                <>
                  <p>
                    {Object.hasOwn(component, 'description')
                      ? 'Description'
                      : 'Font'}{' '}
                    size
                  </p>
                  <Select
                    value={component.descriptionSize}
                    options={Array.from(
                      { length: 31 },
                      (_, index) => index + 10,
                    ).map(el => ({
                      label: el,
                      value: el,
                    }))}
                    onChange={value =>
                      handleChangeComponentSettings(value, 'descriptionSize')
                    }
                    style={{ maxWidth: 80 }}
                  />
                </>
              )}

              {Object.hasOwn(component, 'descriptionWeight') && (
                <>
                  <p>
                    {Object.hasOwn(component, 'description')
                      ? 'Description'
                      : 'Font'}{' '}
                    weight
                  </p>
                  <Radio.Group
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.value,
                        'descriptionWeight',
                      )
                    }
                    value={component.descriptionWeight}
                  >
                    <Radio value={400}>Regular</Radio>
                    <Radio value={500}>Medium</Radio>
                    <Radio value={700}>Bold</Radio>
                  </Radio.Group>
                </>
              )}

              {Object.hasOwn(component, 'descriptionAlign') && (
                <>
                  <p>
                    {Object.hasOwn(component, 'description')
                      ? 'Description'
                      : 'Text'}{' '}
                    align
                  </p>
                  <Radio.Group
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.value,
                        'descriptionAlign',
                      )
                    }
                    value={component.descriptionAlign}
                  >
                    <Radio value={'left'}>Left</Radio>
                    <Radio value={'center'}>Center</Radio>
                    <Radio value={'right'}>Right</Radio>
                  </Radio.Group>
                </>
              )}

              {component.type === 'text' && (
                <>
                  <p>Type</p>
                  <Select
                    value={component.subtype}
                    options={[
                      {
                        label: 'Text',
                        value: 'text',
                      },
                      /*   {
                        label: 'Email',
                        value: 'email',
                      }, */
                      {
                        label: 'Number',
                        value: 'number',
                      },
                    ]}
                    onChange={value =>
                      handleChangeComponentSettings(value, 'subtype')
                    }
                  />
                </>
              )}
              {!!component.options && (
                <>
                  <p style={{ alignSelf: 'start' }}>Options</p>
                  <Droppable
                    droppableId={component.id.toString()}
                    type='options'
                  >
                    {provided => (
                      <Options
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {component?.options?.map((option, index) => (
                          <Draggable
                            draggableId={`${component.id}${option.id ||
                              option.label}`}
                            index={index}
                            key={option.id || option.label}
                          >
                            {provided => (
                              <Option
                                ref={provided?.innerRef}
                                {...provided?.draggableProps}
                              >
                                <span {...provided?.dragHandleProps}>
                                  <Tooltip
                                    title={'Move option'}
                                    mouseLeaveDelay={0}
                                  >
                                    <DragIndicator
                                      style={{
                                        cursor: 'grab',
                                        fontSize: 20,
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                                <InputField
                                  value={option.label}
                                  onChange={e =>
                                    handleChangeOption(e.target.value, index)
                                  }
                                />
                                <DeleteFilled
                                  onClick={() => handleDeleteOption(index)}
                                />
                              </Option>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </Options>
                    )}
                  </Droppable>
                  <span></span>
                  <MainButton
                    title='+ Add Option'
                    onClick={handleAddOption}
                    style={{ margin: '0 15px 0 auto' }}
                    type='cancel'
                  />
                </>
              )}
              {/* {component.type === 'upload' && (
                <>
                  <p>Multiple files</p>
                  <Checkbox
                    value={!!component.multiple}
                    onChange={e =>
                      handleChangeComponentSettings(
                        e.target.checked,
                        'multiple',
                      )
                    }
                  />
                </>
              )} */}
            </ComponentSettings>
          )}
        </Wrapper>
      )}
    </Draggable>
  )
}
