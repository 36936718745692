import numberFormatter from './NumberFormatter'

export const formatStringToNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  allowNegative?: boolean,
) => {
  const { value } = e.target
  const pattern = `[^0-9.${allowNegative ? '-' : ''}]`
  const regex = new RegExp(pattern, 'g')
  const filteredValue = value.replace(regex, '')

  return filteredValue ? +filteredValue : ''
}

export function priceNumber(number = 0) {
  return numberFormatter.format(number)
}
