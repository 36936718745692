const getColorByStatus = status => {
  switch (status) {
    case 1:
      return '#191919'
    case 2:
      return '#e53655'
    case 3:
      return '#4c53ef'
    case 4:
      return '#ffc000'
    case 5:
      return '#2ebda3'

    default:
      return '#191919'
  }
}

export const PaymentModuleIcon = ({ status, overpaid }) => {
  const getColorByStatus = status => {
    switch (status) {
      case 1:
        return '#191919'
      case 2:
        return '#4c53ef'
      case 3:
        return '#e53655'
      case 4:
        return '#2ebda3'

      default:
        return '#191919'
    }
  }

  return (
    <svg
      width='21'
      height='28'
      viewBox='0 0 21 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.6172 5.74219L15.2633 0.382812C15.0172 0.136719 14.6836 0 14.3336 0H14V7H21V6.66641C21 6.32188 20.8633 5.98828 20.6172 5.74219ZM12.25 7.4375V0H1.3125C0.585156 0 0 0.585156 0 1.3125V26.6875C0 27.4148 0.585156 28 1.3125 28H19.6875C20.4148 28 21 27.4148 21 26.6875V8.75H13.5625C12.8406 8.75 12.25 8.15938 12.25 7.4375ZM3.5 3.9375C3.5 3.69578 3.69578 3.5 3.9375 3.5H8.3125C8.55422 3.5 8.75 3.69578 8.75 3.9375V4.8125C8.75 5.05422 8.55422 5.25 8.3125 5.25H3.9375C3.69578 5.25 3.5 5.05422 3.5 4.8125V3.9375ZM3.5 8.3125V7.4375C3.5 7.19578 3.69578 7 3.9375 7H8.3125C8.55422 7 8.75 7.19578 8.75 7.4375V8.3125C8.75 8.55422 8.55422 8.75 8.3125 8.75H3.9375C3.69578 8.75 3.5 8.55422 3.5 8.3125ZM11.375 22.7434V24.0625C11.375 24.3042 11.1792 24.5 10.9375 24.5H10.0625C9.82078 24.5 9.625 24.3042 9.625 24.0625V22.7341C9.00758 22.7024 8.40711 22.487 7.90945 22.1134C7.69617 21.9532 7.68523 21.6338 7.87828 21.4495L8.52086 20.8365C8.67234 20.6921 8.89766 20.6855 9.07484 20.7966C9.28648 20.9289 9.52656 21 9.77594 21H11.3132C11.6687 21 11.9585 20.6763 11.9585 20.2787C11.9585 19.9533 11.7611 19.6667 11.4789 19.5825L9.01797 18.8442C8.00133 18.5391 7.29094 17.5634 7.29094 16.4713C7.29094 15.1304 8.33274 14.041 9.62445 14.0066V12.6875C9.62445 12.4458 9.82023 12.25 10.062 12.25H10.937C11.1787 12.25 11.3745 12.4458 11.3745 12.6875V14.0159C11.9919 14.0476 12.5923 14.2625 13.09 14.6366C13.3033 14.7968 13.3142 15.1162 13.1212 15.3005L12.4786 15.9135C12.3271 16.0579 12.1018 16.0645 11.9246 15.9534C11.713 15.8205 11.4729 15.75 11.2235 15.75H9.68625C9.33078 15.75 9.04094 16.0737 9.04094 16.4713C9.04094 16.7967 9.23836 17.0833 9.52055 17.1675L11.9815 17.9058C12.9981 18.2109 13.7085 19.1866 13.7085 20.2787C13.7085 21.6202 12.6667 22.709 11.375 22.7434Z'
        fill={overpaid ? '#ffcc00' : getColorByStatus(status)}
      />
    </svg>
  )
}
export const EquipmentModuleIcon = ({ status }) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.19627 16.4325C2.2969 16.8044 2.6819 17.0275 3.05377 16.9269L12.5213 14.3894C12.8931 14.2887 13.1163 13.9037 13.0156 13.5319L10.8413 5.41625C10.7406 5.04437 10.3556 4.82125 9.98377 4.92188L6.60627 5.83187L7.69127 9.8875L4.98752 10.6094L3.90252 6.55375L0.520649 7.45937C0.148774 7.56 -0.0743511 7.945 0.0262739 8.31688L2.19627 16.4325ZM16.8 0C16.0256 0 15.4 0.625625 15.4 1.4V15.5575L0.258149 19.6875C0.0700239 19.74 -0.0393511 19.9325 0.0131489 20.1162L0.564399 22.1419C0.616899 22.33 0.809399 22.4394 0.993149 22.3869L18.2175 17.6881C18.3225 20.3044 20.4575 22.4 23.1 22.4C25.8081 22.4 28 20.2081 28 17.5V0H16.8ZM23.1 19.6C21.9406 19.6 21 18.6594 21 17.5C21 16.3406 21.9406 15.4 23.1 15.4C24.2594 15.4 25.2 16.3406 25.2 17.5C25.2 18.6594 24.2594 19.6 23.1 19.6Z'
      fill={getColorByStatus(status)}
    />
  </svg>
)
export const ModuleOneIcon = ({ status }) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.426 7.812L11.2 17.038L6.174 12.026L4.2 14L11.2 21L22.4 9.8L20.426 7.812ZM14 0C6.272 0 0 6.272 0 14C0 21.728 6.272 28 14 28C21.728 28 28 21.728 28 14C28 6.272 21.728 0 14 0ZM14 25.2C7.812 25.2 2.8 20.188 2.8 14C2.8 7.812 7.812 2.8 14 2.8C20.188 2.8 25.2 7.812 25.2 14C25.2 20.188 20.188 25.2 14 25.2Z'
      fill={getColorByStatus(status)}
    />
  </svg>
)
export const ModuleTwoIcon = ({ status }) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.8889 3.11111H18.3867C17.7333 1.30667 16.0222 0 14 0C11.9778 0 10.2667 1.30667 9.61333 3.11111H3.11111C1.4 3.11111 0 4.51111 0 6.22222V28C0 29.7111 1.4 31.1111 3.11111 31.1111H24.8889C26.6 31.1111 28 29.7111 28 28V6.22222C28 4.51111 26.6 3.11111 24.8889 3.11111ZM14 3.11111C14.8556 3.11111 15.5556 3.81111 15.5556 4.66667C15.5556 5.52222 14.8556 6.22222 14 6.22222C13.1444 6.22222 12.4444 5.52222 12.4444 4.66667C12.4444 3.81111 13.1444 3.11111 14 3.11111ZM17.1111 24.8889H6.22222V21.7778H17.1111V24.8889ZM21.7778 18.6667H6.22222V15.5556H21.7778V18.6667ZM21.7778 12.4444H6.22222V9.33333H21.7778V12.4444Z'
      fill={getColorByStatus(status)}
    />
  </svg>
)
export const ModuleThreeIcon = ({ status }) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.6667 13.3333H22.6667V8C22.6667 6.53333 21.4667 5.33333 20 5.33333H14.6667V3.33333C14.6667 1.49333 13.1733 0 11.3333 0C9.49333 0 8 1.49333 8 3.33333V5.33333H2.66667C1.2 5.33333 0.0133333 6.53333 0.0133333 8V13.0667H2C3.98667 13.0667 5.6 14.68 5.6 16.6667C5.6 18.6533 3.98667 20.2667 2 20.2667H0V25.3333C0 26.8 1.2 28 2.66667 28H7.73333V26C7.73333 24.0133 9.34667 22.4 11.3333 22.4C13.32 22.4 14.9333 24.0133 14.9333 26V28H20C21.4667 28 22.6667 26.8 22.6667 25.3333V20H24.6667C26.5067 20 28 18.5067 28 16.6667C28 14.8267 26.5067 13.3333 24.6667 13.3333Z'
      fill={getColorByStatus(status)}
    />
  </svg>
)
