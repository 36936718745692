import { Project } from 'features/Project/types'

export interface Invoice extends Project {
  proposal_amount: number
  amount_paid: number
  amount_unpaid: number
  amount_remaining: number
  invoice_amount: number
  proposal_code: string
  project_code: string
}

export enum InvoicesOperation {
  CREATE = 'can_create',
  READ = 'can_view',
  UPDATE = 'can_edit',
  DELETE = 'can_delete',
}
