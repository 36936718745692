import { Tooltip } from 'antd'
import { ComponentType } from 'react'
import styled from 'styled-components'

interface WithLabelAndErrorProps {
  label?: string
  error?: string
  required?: boolean
  width?: string
  helperText?: string
  titleRight?: React.ReactNode
  disabled?: boolean
  disabledText?: string
}

function withLabelAndError<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  return function WithLabelAndError(props: T & WithLabelAndErrorProps) {
    const {
      label,
      error,
      required,
      width = '100%',
      helperText,
      titleRight,
      ...restProps
    } = props

    return (
      <Wrapper width={width}>
        {label && (
          <Label>
            <span>
              {label} {required && <Required>*</Required>}
            </span>
            {titleRight && <span>{titleRight}</span>}
          </Label>
        )}

        <Tooltip
          title={props.disabled && props.disabledText ? props.disabledText : ''}
        >
          <WrappedComponent {...({ ...restProps, error } as T)} />
        </Tooltip>

        {error ? (
          <Error>{error}</Error>
        ) : helperText ? (
          <HelperText>{helperText}</HelperText>
        ) : (
          false
        )}
      </Wrapper>
    )
  }
}

export default withLabelAndError

const Wrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`

export const Label = styled.p`
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`
export const Error = styled.span`
  font-size: 12px;
  color: #f12832;
  font-weight: 400;
`
export const HelperText = styled.span`
  font-size: 12px;
  color: grey;
`
export const Required = styled.span`
  color: #f1453d;
`
