import React, { FC, useState, useEffect } from 'react'
import { Modal, Spin } from 'antd'
import styled from 'styled-components'
import { getProjectLogs } from 'api/Project'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { Avatar } from 'containers/MainContent/Orcatec/components/Avatar/Avatar'
import { getMediaURL } from 'helpers/getMediaURL'
import ProposalStatus from 'containers/MainContent/Orcatec/components/ProposalStatus/ProposalStatus'
import { KeyboardBackspace } from '@material-ui/icons'
import moment from 'moment-timezone'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'

const format = 'MM/DD/YYYY [at] h:mma (z)'

interface IProps {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  proposalId: number
  timezone: string
}
export interface ILogs {
  action_label: string
  created_at: string
  prev_status: number
  prev_status_label: string
  status: number
  status_label: string
  additional_text: string
  user: {
    company_id: number
    full_name: string
    id: number
    profile_picture: string
  }
  actor: {
    full_name: string | null
    profile_picture: string | null
    is_internal_user: boolean
  }
}

const ProposalStatusCard = ({
  item,
  timezone,
}: {
  item: ILogs
  timezone: string
}) => {
  const clientName = item?.actor?.full_name || 'Client'
  return (
    <Row>
      <AvatarWrapper>
        <Avatar
          pictureURL={getMediaURL(item?.user?.profile_picture)}
          color={'#626ed4'}
          title={clientName}
          size={36}
        >
          {clientName}
        </Avatar>
      </AvatarWrapper>
      <StatusWrapper>
        <Title>{item.action_label}</Title>
        <Statuses>
          {!!item?.prev_status && (
            <ProposalStatus
              isStatusChanging={false}
              withStatusName={true}
              statusId={item.prev_status}
              disableToolTip={false}
              customName={null}
            />
          )}
          {!!item.prev_status && (
            <KeyboardBackspace style={{ transform: 'rotate(180deg)' }} />
          )}
          <ProposalStatus
            isStatusChanging={false}
            withStatusName={true}
            statusId={item.status}
            disableToolTip={false}
            customName={null}
          />{' '}
        </Statuses>
        {!!item?.additional_text && (
          <TitleCreated style={{ wordBreak: 'break-all' }}>
            {item?.additional_text}
          </TitleCreated>
        )}

        <TitleCreated>
          {`${clientName} on ${moment(item.created_at)
            .tz(timezone)
            .format(format)}`}
        </TitleCreated>
      </StatusWrapper>
    </Row>
  )
}

export const ModalProjectHistory: FC<IProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  proposalId,
}) => {
  const [data, setData] = useState<ILogs[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchProjectLogs = async () => {
      setLoading(true)
      try {
        const res = await getProjectLogs(proposalId)
        setData(res.data.status_logs)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    if (isModalOpen) fetchProjectLogs()
  }, [isModalOpen])

  const timezone = useAppSelector(selectCompanyTimezone)

  return (
    <Modal
      title='Project History'
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      destroyOnClose
      bodyStyle={{ maxHeight: '70vh', overflow: 'scroll' }}
      footer={
        <MainButton
          onClick={handleOk}
          style={{ marginLeft: 'auto' }}
          title='Close'
        />
      }
    >
      <Spin spinning={loading}>
        <Wrapper data-testid='project-history--modal'>
          {data?.map((item, idx) => (
            <ProposalStatusCard item={item} key={idx} timezone={timezone} />
          ))}
        </Wrapper>
      </Spin>
    </Modal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Row = styled.div`
  display: flex;
  gap: 6px;
  padding: 10px;
`

const AvatarWrapper = styled.div`
  width: 50px;
  display: flex;
  align-items: start;
  gap: 6px;
`
const TitleCreated = styled.p`
  color: #00000080;
  font-size: 12px;
`
const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
`

const Statuses = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
`
const Title = styled.div`
  display: block;
`
