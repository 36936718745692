import { InvoicesPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { ModuleName } from '../../module_permissions/types'

export const invoices = {
  [IKey.INVOICES_CAN_READ]: {
    name: 'View Invoices',
    with_access_scope: false,
    with_access_checkbox: true,
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.INVOICES_CAN_READ,
    parent_permission_key: IKey.INVOICES_CAN_READ,
    related_modules: ModuleName.INVOICES,
    description: '',
    is_module: false,
    related_key: [
      IKey.INVOICES_CAN_CREATE,
      IKey.INVOICES_CAN_DELETE,
      IKey.INVOICES_CAN_EDIT,
    ],
    children: [
      {
        name: 'Create Invoice',
        with_access_scope: false,
        with_access_checkbox: true,
        permission_key: IKey.INVOICES_CAN_CREATE,
        parent_permission_key: IKey.INVOICES_CAN_READ,
      },
      {
        name: 'Edit Invoice',
        with_access_scope: false,
        with_access_checkbox: true,
        permission_key: IKey.INVOICES_CAN_EDIT,
        parent_permission_key: IKey.INVOICES_CAN_READ,
      },
      {
        name: 'Delete Invoice',
        with_access_scope: false,
        with_access_checkbox: true,
        permission_key: IKey.INVOICES_CAN_DELETE,
        parent_permission_key: IKey.INVOICES_CAN_READ,
      },
    ],
  },
}
