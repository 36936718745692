import { Editor } from '@tinymce/tinymce-react'
import { Checkbox, Divider, Modal, Popover, Spin, Tabs, Tooltip } from 'antd'
import { getEmailHistory } from 'api/emailHistory'
import moment from 'moment-timezone'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { MenuItem } from '@material-ui/core'
import validator from 'validator'

import {
  getContacts,
  getProjectSMSNotification,
  sendEmailNotification,
  sendProjectSMSNotification,
  sendSMSNotification,
  fetchEmailHistory,
  fetchReviewsHistory,
  fetchSMSHistory,
  getTemplatesListByType,
  getTemplateByTypyAndIndustry,
  getTemplateById,
  getProposalLink,
  getTimeSettings,
  updateNotifyStatus,
  storeNotificationLog,
  sendRequestForReviewNotification,
  sendFormsNotification,
} from 'api/NotificationModal'

import { emailPost } from 'api/Proposal'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
// import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'

import EmailsList from './components/EmailsList'
import { NotificationModalV2Props } from './types'
import {
  entityType,
  initEditorSettings,
  parseEmailProposalHistory,
  parsePhoneNumbersBeforeSend,
  parseProjectSMSHistory,
  currentTimeIsBetweenTzTime,
} from './utils'

import HistoryList from './components/HistoryList'
import ReactInputMask from 'react-input-mask'

import { useMessagingPhones } from 'features/Messaging/hooks/useMessagingPhones'
import { useAppSelector } from 'store/Orcatec/hooks'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import Attachment from './components/Attachment/Attachment'

import { selectCompany, selectMe } from './selectors'
import { parseMessageVariables } from './parseMessageVariables'
import {
  parsePhoneNumber,
  MESSAGING_SERVICE_IS_NOT_SETUP_TEXT,
} from 'features/Messaging/utils'
import { isMessagingSubmitted } from 'store/Orcatec/selectors/messaging'
import {
  NotificationModalType,
  // NotificationTemplateType,
  ProposalActionLog,
} from 'features/Notification/types'

import MessagesCount from 'features/Messaging/components/MessagesCount/MessagesCount'
import styled from 'styled-components'
// import Select from 'containers/MainContent/Orcatec/components/UI/Select'
import { DEFAULT_TEMPLATES } from 'features/Notification/conts'
import './NotificationModal.scss'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { PreviewHOC } from 'features/Notification/hoc/PreviewHOC'
// import { onSetField } from 'store/Orcatec/actions/proposal/proposalForm'
import { updateAppointmentReviewsStatus } from 'api/Appointment'
import { projectFieldUpdated } from 'features/Project/slices/projectSlice'
import { TextField, Select } from 'components/UIKit'
import AppointmentNotificationHistory from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentNotificationHistory/AppointmentNotificationHistory'
import { useTranslation } from 'react-i18next'
import { invoiceFieldUpdated } from 'features/Invoice/store/invoicesSlice'

export const removeSpace = str => {
  if (str === null || str === '') return false
  const patern = /&nbsp;/g //remove all tags with empty symbols
  const removedSpaceString = str?.replaceAll(patern, ' ')
  return removedSpaceString
}
export const removeTagsSms = str => {
  if (str === null || str === '') return false
  else str = str.toString()
  return str.replace(/(<([^>]+)>)/gi, '')
}
export const NotificationModal: React.FC<NotificationModalV2Props> = ({
  title = '',
  // clients = [],
  showModal = false,
  setShowModal,
  appointment,
  customSubmit = false,
  customSubmitFunc,
  createAppointmentView = false,
  proposalForm,
  templateType,
  notificationType,
  changeStatus,
  entityIds,
  otherClientEmails,
  contactsSourceId,
}) => {
  const dispatch = useDispatch()
  const editorRef = useRef()

  const { t } = useTranslation(['notifications', 'common'])
  const { phones } = useMessagingPhones()
  const canUseMessaging = useAppSelector(isMessagingSubmitted)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.SCHEDULE),
  )
  const timezone = useAppSelector(
    s => s.orcatec?.company?.timezone || moment.tz.guess(),
  )

  const company = useAppSelector(selectCompany)
  const me = useSelector(selectMe)

  const [clientsList, setClientsList] = useState<ContactI[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState({
    clients: [],
    other: [],
  })
  const [selectedPhones, setSelectedPhones] = useState<RecipientsInterface>({
    clients: [],
    other: [],
  })

  const [notificationHistory, setNotificationHistory] = useState([])
  const [showNotificationHistory, setShowNotificationHistory] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)
  const [isSubmitDisabledByErrors, setIsSubmitDisabledByErrors] = useState(
    false,
  )
  const [proposalLink, setProposalLink] = useState('')
  const [didLoad, setDidLoad] = useState(false)
  const [selectedCompanyPhone, setSelectedCompanyPhone] = useState('')
  const [proposalViewErrors, setProposalViewErrors] = useState({
    from_name: '',
    reply_to: '',
    subject: '',
    message: '',
  })

  const [emailData, setEmailData] = useState({
    subject: '',
    message: '',
    data: {
      from_name: '',
      reply_to: '',
    },
    settings: { time_from: '', time_to: '', timezone },
    link: '',
    has_attachments: false,
    pdf_tab_ids: [],
  })

  const [templatesList, setTemplatesList] = useState([])

  const [SMSData, setSMSData] = useState({
    body: '',
    media: [],
  })

  const [otherEmails, setOtherEmails] = useState({
    isChecked: false,
    emails: [],
    inputValue: '',
    isValid: true,
    error: {},
  })
  const [otherPhone, setOtherPhone] = useState({
    phone: '',
    error: false,
    isChecked: false,
  })

  const [showViewModal, setShowViewModal] = useState(false)
  const [emailFiles, setEmailFiles] = useState([])
  const [currentFileIdx, setCurrentFileIdx] = useState(null)
  const [serverError, setServerError] = useState('')

  const { parseEmailMessage } = parseMessageVariables({
    appointment,
    proposalForm,
    proposalLink,
  })

  const onCloseViewModal = () => {
    setShowViewModal(false)
    setEmailFiles([])
    setCurrentFileIdx(null)
  }
  const onOpenFileHeandler = (files, idx) => {
    setEmailFiles(files)
    setCurrentFileIdx(idx)
    setShowViewModal(true)
  }

  const onLoadAndOpenFileHeandler = (files, idx) => {
    setEmailFiles([files])
    setCurrentFileIdx(idx)
    setShowViewModal(true)
  }

  const handleOk = (): void => {
    setShowModal(false)
  }

  const handleCancel = (): void => {
    setShowModal(false)
    customSubmit && customSubmitFunc?.()
  }

  const handleConfirmOk = (): void => {
    setShowConfirmModal(false)
  }

  const handleConfirmCancel = (): void => {
    setShowConfirmModal(false)
  }

  const handleCopyLinkToClipboard = (): void => {
    navigator.clipboard
      .writeText(proposalLink)
      // .then(() => console.log('success'))
      .catch(err => console.error('error', err))
  }

  const validateCheckboxes = (): boolean =>
    !(
      !!selectedEmails.clients.length ||
      otherEmails.isChecked ||
      !![...selectedPhones.clients, ...selectedPhones.other].length
    )

  const handleSubmitSendProposalEmail = () => {
    setLoading(true)

    let smsNotificationData

    if ([...selectedPhones.clients, ...selectedPhones.other].length) {
      smsNotificationData = {
        body: removeSpace(removeTagsSms(SMSData.body)),
        media: SMSData.media,
        from_number: selectedCompanyPhone,
        recipients: {
          clients: parsePhoneNumbersBeforeSend(selectedPhones.clients),
          other: parsePhoneNumbersBeforeSend(selectedPhones.other),
        },
      }
    }

    const promises = []

    phones.length &&
      smsNotificationData &&
      promises.push(
        sendProjectSMSNotification(proposalForm.id, smsNotificationData),
      )
    // Here
    const data = {
      from_name: emailData?.data?.from_name || proposalForm?.industry_name,
      reply_to: emailData?.data?.reply_to || company?.company_email,
      subject: emailData.subject ?? '',
      // clients: clients.map(client => {
      clients: clientsList.map(client => {
        return {
          ...client,
          emails: client.emails.map((email: string) => ({
            email,
            checked: selectedEmails.clients.includes(email),
          })),
        }
      }),

      link: proposalLink,
      entity_id: proposalForm?.id,
      other:
        otherEmails?.emails?.length && otherEmails?.isChecked
          ? otherEmails.emails
          : [],
      user_id: me.id,
      entity_type: entityType,
      data: `${parseEmailMessage(emailData.message)}` /* + messageBrand*/,
      has_attachments: emailData.has_attachments,
      pdf_tab_ids: emailData?.pdf_tab_ids,
    }

    if (
      selectedEmails?.clients?.length ||
      (otherEmails?.emails?.length && otherEmails?.isChecked)
    ) {
      promises.push(emailPost(data))
    }

    Promise.all(promises)
      .then(() => setShowModal(false))
      .then(() => {
        openNotificationWithIcon('success', {
          message: t('notifications.messageSent'),
          description: '',
          key: 'send-email-success',
        })
        // dispatch(onSetField(true, 'had_been_sent'))
        dispatch(projectFieldUpdated({ had_been_sent: true }))
        storeNotificationLog(proposalForm.id, {
          action: ProposalActionLog.SENT_NOTIFICATION,
        }).catch(e => console.error(e))
      })
      .catch(error => {
        console.error(error)
        if (error?.response?.data?.errors?.['recipients.clients.0']) {
          return openNotificationWithIcon('error', {
            message:
              error?.response?.data?.errors?.['recipients.clients.0'] ||
              'Something went wrong',
          })
        }

        openNotificationWithIcon('error', {
          message: error?.response?.data?.message || 'Something went wrong',
        })

        if (error?.response?.data?.other)
          setServerError(error.response.data.other)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmitRequestForReview = () => {
    setLoading(true)

    let smsNotificationData
    let emailNotificationData
    const data = {}

    const tz: string = emailData.settings?.timezone

    const sendAt = moment()
      .tz(tz)
      .add(1, 'minute')
      .format('YYYY-MM-DD HH:mm')

    const params = {
      send_at: sendAt,
      send_at_timezone: tz,
    }

    if ([...selectedPhones.clients, ...selectedPhones.other].length) {
      smsNotificationData = {
        body: removeSpace(removeTagsSms(SMSData.body)),
        media: SMSData.media,
        from_number: selectedCompanyPhone,
        recipients: {
          clients: parsePhoneNumbersBeforeSend(selectedPhones.clients),
          other: parsePhoneNumbersBeforeSend(selectedPhones.other),
        },
      }
    }

    if ([...selectedEmails.clients, ...otherEmails.emails].length) {
      const {
        subject,
        message,
        data: { from_name, reply_to },
      } = emailData

      emailNotificationData = {
        subject: `${subject}`,
        from_name: from_name,
        reply_to: reply_to,
        body: `${message}`,
        recipients: {
          clients: selectedEmails.clients,
          other: otherEmails.emails,
        },
      }
    }

    if (phones.length && smsNotificationData) {
      data.sms = smsNotificationData
    }

    if (
      selectedEmails?.clients?.length ||
      (otherEmails?.emails?.length && otherEmails?.isChecked)
    ) {
      data.email = emailNotificationData
    }

    sendRequestForReviewNotification(appointment?.id, { ...params, ...data })
      .then(() => {
        changeStatus(2)
        setShowModal(false)
      })
      .then(() => {
        openNotificationWithIcon('success', {
          message: t('notifications.messageSent'),
          description: '',
          key: 'send-email-success',
        })
      })
      .catch(e => console.error(e))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmitSendForms = () => {
    setLoading(true)

    let emailNotificationData
    // const data = {}

    const tz: string = emailData.settings?.timezone

    const sendAt = moment()
      .tz(tz)
      .add(1, 'minute')
      .format('YYYY-MM-DD HH:mm')

    const params = {
      send_at: sendAt,
      send_at_timezone: tz,
    }

    if ([...selectedEmails.clients, ...otherEmails.emails].length) {
      const {
        subject,
        message,
        data: { from_name, reply_to },
      } = emailData

      emailNotificationData = {
        custom_forms: entityIds,
        subject: `${subject}`,
        from_name: from_name,
        reply_to: reply_to,
        body: `${message}`,
        recipients: {
          clients: selectedEmails.clients,
          other: otherEmails.emails,
        },
      }
    }

    // if (
    //   selectedEmails?.clients?.length ||
    //   (otherEmails?.emails?.length && otherEmails?.isChecked)
    // ) {
    //   data = { ...data, ...emailNotificationData }
    // }

    sendFormsNotification({ ...params, ...emailNotificationData })
      // .then(() => {
      //   changeStatus(2)
      //   setShowModal(false)
      // })
      .then(() => {
        setShowModal()
        openNotificationWithIcon('success', {
          message: t('notifications.messageSent'),
          description: '',
          key: 'send-email-success',
        })
      })
      .catch(e => console.error(e))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmitNotification = async (
    isSendLater: boolean | undefined,
    isSendNow: boolean | undefined,
  ): void => {
    let emailNotificationData
    let smsNotificationData
    const format = 'HH:mm:ss'
    const tz: string = emailData.settings?.timezone
    const startTime = moment(emailData.settings.time_from, format).format(
      format,
    )
    const endTime = moment(emailData.settings.time_to, format).format(format)
    const timeNow = moment.tz(tz).format(format)

    const sendAt = isSendLater
      ? moment(emailData.settings.time_from, 'HH:mm')
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm')
      : moment()
          .tz(tz)
          .add(1, 'minute')
          .format('YYYY-MM-DD HH:mm')

    const data = {
      type: notificationType,
      send_at: sendAt,
      send_at_timezone: tz,
    }

    if ([...selectedPhones.clients, ...selectedPhones.other].length) {
      smsNotificationData = {
        ...data,
        body: removeSpace(removeTagsSms(SMSData.body)),
        media: SMSData.media,
        from_number: selectedCompanyPhone,
        recipients: {
          clients: parsePhoneNumbersBeforeSend(selectedPhones.clients),
          other: parsePhoneNumbersBeforeSend(selectedPhones.other),
        },
      }
    }

    if ([...selectedEmails.clients, ...otherEmails.emails].length) {
      const {
        subject,
        message,
        data: { from_name, reply_to },
      } = emailData

      emailNotificationData = {
        ...data,
        subject: `${subject}`,
        from_name: from_name,
        reply_to: reply_to,
        body: `${message}`,
        recipients: {
          clients: selectedEmails.clients,
          other: otherEmails.emails,
        },
      }
    }

    const isCanSendNow = currentTimeIsBetweenTzTime(
      timeNow,
      tz,
      startTime,
      endTime,
      format,
    )
    const canSendNow = isSendLater || isSendNow || isCanSendNow

    if (canSendNow) {
      try {
        if (emailNotificationData && appointment?.id) {
          await sendEmailNotification(appointment.id, emailNotificationData)
        }

        if (phones.length && smsNotificationData && appointment) {
          await sendSMSNotification(appointment.id, smsNotificationData)
        }

        await customSubmitFunc?.()
        openNotificationWithIcon('success', {
          message: t('notifications.messageSent'),
        })
        setShowModal(false)
      } catch (error) {
        const errorKeysArr = Object.keys(
          error?.response?.data?.errors || {},
        )?.[0]?.split('.')
        const message = errorKeysArr?.reduce(
          (acc, key) => (acc = acc?.[key]),
          emailNotificationData,
        )
        openNotificationWithIcon('error', {
          message: message
            ? `${message} must be a valid.`
            : 'Something went wrong!',
        })
        setShowConfirmModal(false)
      }
    } else {
      setShowConfirmModal(true)
    }
  }

  const handleOtherEmailsInputChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target

    setOtherEmails(prev => ({
      ...prev,
      isChecked: !!value?.length,
      inputValue: value,
    }))

    const isValidEmailArray: boolean[] = []
    // const emailsArray = value.trim().split(', ')
    const emailsArray = value
      .split(',')
      .map(email => email.trim())
      .filter(email => email !== '')

    if (type === 'onChange') {
      emailsArray.forEach((email: string): void => {
        if (email.length)
          isValidEmailArray.push(Boolean(validator.isEmail(email)))
      })

      if (isValidEmailArray.some(el => !el)) {
        setOtherEmails(prev => ({
          ...prev,
          isValid: false,
          error: { 'other-email-input': ['Invalid email(s)'] },
        }))
      } else {
        setOtherEmails(prev => ({ ...prev, isValid: true, error: {} }))
      }

      if (isValidEmailArray.every(el => el)) {
        setOtherEmails(prev => ({
          ...prev,
          isValid: true,
          error: {},
          emails: emailsArray,
        }))
      }

      if (serverError) setServerError('')
    } /* else if (type === 'onBlur' && isValidEmailArray.every(el => el)) {
      setOtherEmails(prev => ({ ...prev, isValid: true, error: {}, emails: emailsArray }))
    } */

    if (value === '')
      setOtherEmails(prev => ({ ...prev, /* isChecked: false, */ emails: [] }))
  }

  const sendLetterWithNativeEmail = (): void => {
    let emailsList: string[] = []

    // otherEmails.emails?.map?.(otherEmail => {
    //   emailsList.push(otherEmail)
    // })
    selectedEmails?.clients?.map(email => emailsList.push(email))

    // eslint-disable-next-line no-useless-escape
    // const linkRegex = /(?<![a-zA-Z0-9`~!@#$%^&*()-_+={}\[\]|\\:;"'<>,.?/])link(?![a-zA-Z0-9`~!@#$%^&*()-_+={}\[\]|\\:;"'<>,.?/])/gi
    const linkRegex = /(^|[^a-zA-Z0-9`~!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/])link([^a-zA-Z0-9`~!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/]|$)/gi

    // eslint-enable no-useless-escape
    const cleanedMessage = emailData.message
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/<\/?[^>]+(>|$)/g, ' ')
      .replace(linkRegex, `${proposalLink}`)

    const toEmails = encodeURIComponent(emailsList.join(','))
    const subject = encodeURIComponent(emailData.subject)

    const body = encodeURIComponent(cleanedMessage)

    const prepareEmail = `mailto:${toEmails}?subject=${subject}&body=${body}`

    const prepareEmailWithLink = prepareEmail

    window.location.href = prepareEmailWithLink
    setShowModal(false)

    emailsList = []
  }

  const handleChangeCompanyPhones = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedCompanyPhone(e.target.value)
  }

  const handleChangeOtherPhoneInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target

    setOtherPhone(prev => ({
      ...prev,
      phone: value,
      error: value.includes('X'),
      isChecked: !!value?.length,
    }))
  }

  const onAttachPdfToEmail = (tabId: number) => {
    let tabsIds: number[] = [...emailData?.pdf_tab_ids]

    if (tabsIds.includes(tabId)) {
      tabsIds = tabsIds.filter(id => id !== tabId)
    } else {
      tabsIds.push(tabId)
    }

    setEmailData(p => ({
      ...p,
      has_attachments: !!tabsIds?.length,
      pdf_tab_ids: tabsIds,
    }))
  }

  const handleSelectTemplate = async e => {
    const { value } = e.target

    try {
      const template = await getTemplateById(value)
      setSMSData(prev => ({
        ...prev,
        body: parseEmailMessage(template?.sms.body, 'sms', proposalLink),
      }))

      setEmailData(prev => ({
        ...prev,
        id: template.id,
        data: {
          from_name: parseEmailMessage(
            template?.email?.from_name,
            'email',
            proposalLink,
          ),

          reply_to: parseEmailMessage(
            template?.email?.reply_to,
            'email',
            proposalLink,
          ),
        },
        subject: parseEmailMessage(
          template?.email?.subject,
          'email',
          proposalLink,
        ),
        message: parseEmailMessage(
          template?.email?.body,
          'email',
          proposalLink,
        ),
      }))
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmitRequestStatus = e => {
    const selectedStatus = e.target.checked ? Number(e.target.value) : 1

    updateAppointmentReviewsStatus(appointment.id, selectedStatus)
      .then(() => {
        changeStatus(selectedStatus)
      })
      .catch(error =>
        openNotificationWithIcon('error', { message: error.message }),
      )
  }

  useEffect(() => {
    if (didLoad) {
      const obj = {
        from_name: emailData.data?.from_name,
        reply_to: emailData.data?.reply_to,
        subject: emailData?.subject,
        message: emailData?.message,
        ...(canUseMessaging && !!phones?.length && { sms: SMSData?.body }),
      }

      const errors = {}

      Object.entries(obj).forEach(([key, value]) => {
        if (!value?.length) {
          errors[key] = false
          setProposalViewErrors(p => ({
            ...p,
            [key]: 'Field can not be empty!',
          }))
        } else if (key === 'reply_to' && !validator.isEmail(value)) {
          errors[key] = false
          setProposalViewErrors(p => ({
            ...p,
            [key]: 'Must be valid email!',
          }))
        } else {
          errors[key] = true
          setProposalViewErrors(p => ({
            ...p,
            [key]: '',
          }))
        }
      })

      if (Object.values(errors).every(Boolean)) {
        setIsSubmitDisabledByErrors(false)
      } else {
        setIsSubmitDisabledByErrors(true)
      }
    }
  }, [emailData, SMSData])

  useEffect(() => {
    if (
      otherPhone.phone.length === 14 &&
      !otherPhone.phone.includes('X') &&
      otherPhone.isChecked
    ) {
      setSelectedPhones(p => ({ ...p, other: [otherPhone.phone] }))
    } else {
      setSelectedPhones(p => ({ ...p, other: [] }))
    }
  }, [otherPhone])

  useEffect(() => {
    if (!showModal) {
      setNotificationHistory(null)
      setClientsList(null)
      setSelectedEmails(null)
      setSelectedPhones(null)
      setSelectedCompanyPhone(null)
    }
  }, [showModal])

  useEffect(() => {
    document.body?.classList?.add('no-scroll')
    return () => document.body?.classList?.remove('no-scroll')
  }, [])

  useEffect(() => {
    if (phones.length) setSelectedCompanyPhone(phones?.[0]?.number)
  }, [phones])

  //FOR SEND PROJECT
  useEffect(() => {
    const getNotification = async () => {
      setLoading(true)
      const link = await getProposalLink(proposalForm.id)
      setProposalLink(link)

      await getTemplateByTypyAndIndustry(
        notificationType,
        proposalForm.industry_id,
      )
        .then(({ email, sms, id }) => {
          setSMSData(prev => ({
            ...prev,
            body: parseEmailMessage(sms.body, 'sms', link),
          }))
          setEmailData({
            id,
            data: {
              from_name: parseEmailMessage(email?.from_name, 'email', link),
              reply_to: parseEmailMessage(email?.reply_to, 'email', link),
            },
            subject: parseEmailMessage(email?.subject, 'email', link),
            message: parseEmailMessage(email?.body, 'email', link),
            pdf_tab_ids: [],
          })
        })
        .then(() => {
          setTimeout(() => {
            setDidLoad(true)
          }, 1000)
          setLoading(false)
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            setSMSData(prev => ({
              ...prev,
              body: parseEmailMessage(
                DEFAULT_TEMPLATES.email_proposal.sms.body,
                'sms',
                link,
              ),
            }))
            setEmailData({
              data: {
                from_name: parseEmailMessage(
                  DEFAULT_TEMPLATES.email_proposal.email?.from_name,
                  'email',
                  link,
                ),
                reply_to: parseEmailMessage(
                  DEFAULT_TEMPLATES.email_proposal.email?.reply_to,
                  'email',
                  link,
                ),
              },
              subject: parseEmailMessage(
                DEFAULT_TEMPLATES.email_proposal.email?.subject,
                'email',
                link,
              ),
              message: parseEmailMessage(
                DEFAULT_TEMPLATES.email_proposal.email?.body,
                'email',
                link,
              ),
              pdf_tab_ids: [],
            })
          }
        })

      await getEmailHistory(proposalForm.id, entityType)
        .then(r => setNotificationHistory(parseEmailProposalHistory(r)))
        .catch(e => console.error(e))

      await getProjectSMSNotification<{ data: ISMSProjectHistory[] }>(
        proposalForm.id,
      )
        .then(({ data }) => {
          const parsedData = parseProjectSMSHistory(data)

          return setNotificationHistory(prev => [...prev, ...parsedData])
        })
        .catch(e => console.error(e))
    }

    if (proposalForm?.id)
      getNotification().finally(() => setTimeout(() => setLoading(false), 500))
  }, [])

  //FOR APPOINTMENT
  useEffect(() => {
    const getAppointmentTemplate = async () => {
      setLoading(true)
      await getTemplateByTypyAndIndustry(
        notificationType,
        appointment.industry_id,
      )
        .then(({ email, sms, id }) => {
          setSMSData(prev => ({
            ...prev,
            body: parseEmailMessage(sms.body),
          }))

          setEmailData(prev => ({
            ...prev,
            id,
            subject: parseEmailMessage(email.subject),
            message: parseEmailMessage(email.body),
            data: {
              from_name: parseEmailMessage(email.from_name),
              reply_to: parseEmailMessage(email.reply_to),
            },
          }))
        })
        .then(() => setDidLoad(true))
        .catch(e => {
          if (!!e?.response?.status) {
            const templateType =
              notificationType === NotificationModalType.UPCOMING
                ? 'upcoming_appointment'
                : notificationType === NotificationModalType.EN_ROUTE
                ? 'in_route'
                : notificationType === NotificationModalType.CANCELED
                ? 'canceled'
                : notificationType === NotificationModalType.REQUEST_FOR_REVIEW
                ? 'request_for_review'
                : 'appointment_reminder'

            setSMSData(prev => ({
              ...prev,
              body: parseEmailMessage(DEFAULT_TEMPLATES[templateType].sms.body),
            }))

            setEmailData(prev => ({
              ...prev,
              subject: parseEmailMessage(
                DEFAULT_TEMPLATES[templateType].email.subject,
              ),
              message: parseEmailMessage(
                DEFAULT_TEMPLATES[templateType].email.body,
              ),
              data: {
                from_name: parseEmailMessage(
                  DEFAULT_TEMPLATES[templateType].email.from_name,
                ),
                reply_to: parseEmailMessage(
                  DEFAULT_TEMPLATES[templateType].email.reply_to,
                ),
              },
            }))
            return
          }
          openNotificationWithIcon('error', {
            message: `Error: ${e?.response?.status}`,
            description: e?.response?.statusText,
          })
        })
        .finally(() => setTimeout(() => setLoading(false), 500))
    }

    if (appointment) getAppointmentTemplate()
  }, [])

  //FOR SEND

  useEffect(() => {
    const fetchTemplabeByType = async () => {
      try {
        const res = await getTemplatesListByType(templateType)

        setTemplatesList(
          res.map(item => ({ value: item.id, label: item.name })),
        )
        if (res?.[0]?.id) {
          handleSelectTemplate({ target: { value: res[0].id } })
        } else {
          setEmailData(prev => ({
            ...prev,
            subject: parseEmailMessage(
              DEFAULT_TEMPLATES.sent_form.email.subject,
            ),
            message: parseEmailMessage(DEFAULT_TEMPLATES.sent_form.email.body),
            data: {
              from_name: parseEmailMessage(
                DEFAULT_TEMPLATES.sent_form.email.from_name,
              ),
              reply_to: parseEmailMessage(
                DEFAULT_TEMPLATES.sent_form.email.reply_to,
              ),
            },
          }))
        }

        setOtherEmails(prev => ({
          ...prev,
          emails: otherClientEmails,
          inputValue: otherClientEmails?.length
            ? otherClientEmails.join(',')
            : '',
          isChecked: !!otherClientEmails?.length,
          isValid: true,
        }))
      } catch (error) {
        console.error(error)
      }
    }
    if (!!entityIds?.length) fetchTemplabeByType()
  }, [entityIds?.length])

  useEffect(() => {
    const fetchEmailNotificationHistory = async () => {
      try {
        if (notificationType === NotificationModalType.REQUEST_FOR_REVIEW) {
          const res = await fetchReviewsHistory(appointment.id)

          setNotificationHistory(p => [...p, ...res])
          return
        }
        const res = await fetchEmailHistory(appointment.id, notificationType)
        setNotificationHistory(p => [...p, ...res])
      } catch (error) {
        console.error(error)
      }
    }
    if (appointment) fetchEmailNotificationHistory()
  }, [])

  useEffect(() => {
    const fetchSmsNotificationHistory = async () => {
      try {
        const res = await fetchSMSHistory(appointment.id, notificationType)
        setNotificationHistory(p => [...p, ...res])
      } catch (error) {
        console.error(error)
      }
    }
    if (appointment) fetchSmsNotificationHistory()
  }, [])

  useEffect(() => {
    const getTemplatesOptions = async () => {
      try {
        const res = await getTemplatesListByType(templateType)
        setTemplatesList(
          res.map(item => ({ value: item.id, label: item.name })),
        )
      } catch (error) {
        console.error(error)
      }
    }
    if (templateType && !entityIds) getTemplatesOptions()
  }, [templateType])

  useEffect(() => {
    const getTimeSettinsg = async () => {
      try {
        const settings = await getTimeSettings()

        setEmailData(prev => ({ ...prev, settings }))
      } catch (error) {
        console.error(error)
      }
    }
    if (showModal) getTimeSettinsg()
  }, [showModal])

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res = await getContacts(appointment?.id, 'appointments')
        setClientsList(res.contacts)
      } catch (error) {
        console.error(error)
      }
    }

    if (appointment && !appointment?.proposal_id) fetchContacts()
  }, [])

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res = await getContacts(
          contactsSourceId || proposalForm.id,
          'proposals',
        )
        setClientsList(res.contacts)
      } catch (error) {
        console.error(error)
      }
    }
    if (proposalForm) fetchContacts()
  }, [])

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res = await getContacts(
          appointment?.wo_proposal_id || appointment?.proposal_id,
          'proposals',
        )
        setClientsList(res.contacts)
      } catch (error) {
        console.error(error)
      }
    }
    if (appointment?.proposal_id) fetchContacts()
  }, [])

  useEffect(() => {
    if (
      otherEmails.emails.length ||
      selectedEmails.clients.length ||
      [...selectedPhones.clients, ...selectedPhones.other].length
    )
      setIsSubmitDisable(false)
    else setIsSubmitDisable(true)
  }, [otherEmails, selectedEmails, selectedPhones])

  const handleChangeProjectNotifyStatus = e => {
    const { checked, name } = e.target
    const prevStatus = !checked

    const updateFunction = proposalForm.invoice_amount
      ? invoiceFieldUpdated
      : projectFieldUpdated
    // dispatch(onSetField(checked, name))
    dispatch(updateFunction({ [name]: checked }))

    updateNotifyStatus(proposalForm.id, checked).catch(() =>
      dispatch(updateFunction({ [name]: prevStatus })),
    )
  }

  const prepareTabsForAttachment = tabs => {
    if (proposalForm?.multiple_tabs) return tabs

    return tabs?.[0] ? [tabs?.[0]] : []
  }

  const isSendingRFRDisabled = () => {
    return (
      notificationType === NotificationModalType.REQUEST_FOR_REVIEW &&
      appointment?.review_status === 3
    )
  }

  return (
    <Modal
      wrapClassName='notification-modal-wrapper'
      title={title}
      visible={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={750}
      footer={
        <ControlsRow>
          <MainButton
            title={`${
              createAppointmentView
                ? 'Skip & Create Appointment'
                : t('button.cancel', { ns: 'common' })
            }`}
            type='cancel'
            onClick={() => {
              setShowModal(false)
              customSubmit && customSubmitFunc?.()
            }}
          />
          {!permissions?.schedule_can_read_notification_history &&
          window.location.pathname === '/schedule' ? (
            <></>
          ) : (
            <AccessControl
              author={proposalForm?.user_id}
              scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
            >
              {' '}
              <MainButton
                style={{
                  backgroundColor: 'rgba(52,159,166,.75)',
                  borderColor: 'rgba(52, 159, 166, 0.75)',
                }}
                title={t('showHistory')}
                onClick={() => setShowNotificationHistory(prev => !prev)}
              />
            </AccessControl>
          )}

          <AccessControl
            author={proposalForm?.user_id}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            {proposalForm?.id && (
              <Popover content={'Copied to clipboard!'} trigger={'click'}>
                <MainButton
                  title={t('copyLink')}
                  onClick={handleCopyLinkToClipboard}
                />
              </Popover>
            )}
          </AccessControl>

          <MainButton
            disabled={!otherEmails.isValid || isSendingRFRDisabled()}
            title={t('openEmailClient')}
            onClick={() => sendLetterWithNativeEmail()}
          />
          <Tooltip
            title={
              isSendingRFRDisabled()
                ? `Disabled due to 'Do not send request' status. To enable, update the request status.`
                : ''
            }
          >
            <div>
              <MainButton
                disabled={
                  validateCheckboxes() ||
                  !otherEmails.isValid ||
                  isSubmitDisable ||
                  isSubmitDisabledByErrors ||
                  isSendingRFRDisabled()
                }
                title={t('send')}
                isFetching={loading}
                onClick={() => {
                  proposalForm?.id
                    ? handleSubmitSendProposalEmail()
                    : notificationType ===
                      NotificationModalType.REQUEST_FOR_REVIEW
                    ? handleSubmitRequestForReview()
                    : entityIds
                    ? handleSubmitSendForms()
                    : handleSubmitNotification(undefined, undefined)
                }}
              />
            </div>
          </Tooltip>
        </ControlsRow>
      }
      style={{ top: 20 }}
      destroyOnClose
      bodyStyle={{ height: '80vh', overflow: 'scroll' }}
    >
      <Wrapper data-testid='project-notification--modal'>
        <Spin spinning={loading}>
          {proposalForm?.id && (
            <div
              style={{
                display: 'flex',
                paddingBottom: '25px',
              }}
            >
              <div className='round'>
                <input
                  type='checkbox'
                  checked={proposalForm.had_been_sent}
                  name='had_been_sent'
                  onChange={handleChangeProjectNotifyStatus}
                  id='checkbox'
                />
                <label htmlFor='checkbox'></label>
              </div>
              <span style={{ paddingLeft: '1rem' }}>{t('markAsSent')}</span>
            </div>
          )}

          {notificationType === NotificationModalType.REQUEST_FOR_REVIEW && (
            <div className='statuses__wrapper'>
              <div>
                <Checkbox
                  checked={appointment.review_status === 2}
                  onChange={handleSubmitRequestStatus}
                  className={`checkbox ${
                    appointment.review_status === 3
                      ? 'checkbox-status__hidden'
                      : ''
                  }`}
                  style={{ whiteSpace: 'nowrap' }}
                  value={2}
                  disabled={appointment.review_status === 3}
                >
                  <span className='ml-2'>{t('reviewHadBeenRequested')}</span>
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={appointment.review_status === 3}
                  onChange={handleSubmitRequestStatus}
                  className='checkbox'
                  style={{ whiteSpace: 'nowrap' }}
                  value={3}
                >
                  <span className='ml-2'>{t('doNotSendRequest')}</span>
                </Checkbox>
              </div>
              <Divider />
            </div>
          )}

          <EmailsList
            phones={phones}
            clientsList={clientsList}
            setClientsList={setClientsList}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
            selectedPhones={selectedPhones}
            setSelectedPhones={setSelectedPhones}
          />

          <div
            style={{ width: '100%', paddingTop: '10px' }}
            className='other-email-row'
          >
            <p className='other-email-text'>
              {t('otherContacts.otherEmails')}:
            </p>
            <Checkbox
              className='checkbox'
              checked={otherEmails.isChecked}
              style={{ marginTop: '4px' }}
              onChange={() =>
                setOtherEmails(prev => ({
                  ...prev,
                  isChecked: !prev.isChecked,
                }))
              }
            />
            <TextField
              value={otherEmails.inputValue}
              style={{ width: '100%' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOtherEmailsInputChange('onChange', e)
              }
              name='other-email-input'
              error={otherEmails.error?.['other-email-input'] || serverError}
              className='other-email-input'
              placeholder='first@example.com, next@example.com'
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOtherEmailsInputChange('onBlur', e)
              }
              maxLength={300}
              helperText={t('maxCharacters')}
            />
            {/* <InputField
              value={otherEmails.inputValue}
              style={{ width: '100%' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOtherEmailsInputChange('onChange', e)
              }
              name='other-email-input'
              error={otherEmails.error?.['other-email-input']}
              inputProps={undefined}
              className='other-email-input'
              placeholder='first@example.com, next@example.com'
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOtherEmailsInputChange('onBlur', e)
              }
            /> */}
          </div>

          {phones.length > 0 && !entityIds ? (
            <div style={{ width: '100%' }} className='other-email-row'>
              <p className='other-email-text'>
                {t('otherContacts.otherPhone')}:
              </p>

              <Checkbox
                className='checkbox'
                checked={otherPhone.isChecked}
                style={{ marginTop: '4px' }}
                onChange={() =>
                  setOtherPhone(p => ({ ...p, isChecked: !p.isChecked }))
                }
              />

              <ReactInputMask
                mask='(999) 999-9999'
                maskChar='X'
                value={otherPhone.phone}
                onChange={handleChangeOtherPhoneInput}
                onBlur={handleChangeOtherPhoneInput}
              >
                {/* {() => (
                  <InputField
                    name='other-phone-input'
                    error={
                      !!otherPhone.error && {
                        'other-phone-input': 'Invalid phone number',
                      }
                    }
                    className='other-email-input'
                    placeholder='(XXX)XXX-XXXX'
                  />
                )} */}
                {() => (
                  <TextField
                    name='other-phone-input'
                    error={!!otherPhone.error && 'Invalid phone number'}
                    className='other-email-input'
                    placeholder='(XXX)XXX-XXXX'
                  />
                )}
              </ReactInputMask>
            </div>
          ) : null}

          {!!templatesList?.length && !!emailData?.id && (
            <div style={{ width: '100%' }} className='other-email-row'>
              <p className='other-email-text'>{t('contactsList.template')}:</p>

              <Select
                value={emailData?.id}
                onChange={handleSelectTemplate}
                style={{ width: '100%' }}
                options={templatesList}
                size='middle'
              />
              {/* <Select
                value={emailData?.id}
                onChange={handleSelectTemplate}
                className='other-email-input'
                style={{ width: '100%' }}
                fullWidth
                options={templatesList}
              ></Select> */}
            </div>
          )}

          <Tabs defaultActiveKey='1'>
            <Tabs.TabPane tab={t('email')} key='1'>
              <PreviewHOC
                industryId={
                  appointment?.industry_id || proposalForm?.industry_id
                }
                withInfo={!!entityIds?.length}
                projectLink={proposalLink}
                email={{
                  ...emailData.data,
                  subject: emailData.subject,
                  body: emailData.message,
                  type: proposalForm?.id ? 2 : null,
                }}
                attachments={prepareTabsForAttachment(
                  { ...proposalForm }?.tabs_info,
                )?.map(
                  (tab, idx, arr) =>
                    emailData?.pdf_tab_ids.includes(tab.id) && (
                      <Attachment
                        key={tab.id}
                        idx={idx}
                        onDelete={() =>
                          setEmailData(p => ({
                            ...p,
                            has_attachments: !p.has_attachments,
                          }))
                        }
                        onAttach={onAttachPdfToEmail}
                        isMultipleTabs={arr?.length > 1}
                        proposalId={proposalForm?.id}
                        code={proposalForm?.code}
                        tabId={tab.id}
                        title={tab?.title}
                        tabIds={emailData.pdf_tab_ids}
                        onLoadAndOpenFileHeandler={onLoadAndOpenFileHeandler}
                      />
                    ),
                )}
              >
                <div className='email-inputs-container'>
                  <TextField
                    value={emailData.data.from_name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmailData(prev => ({
                        ...prev,
                        data: {
                          ...prev.data,
                          from_name: e.target.value,
                        },
                      }))
                    }}
                    label={`${t('senderName')}*`}
                    name='from_name'
                    error={proposalViewErrors?.from_name}
                    disabled={loading}
                  />
                  {/* <InputField
                    value={emailData.data.from_name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmailData(prev => ({
                        ...prev,
                        data: {
                          ...prev.data,
                          from_name: e.target.value,
                        },
                      }))
                    }}
                    label='Sender name*'
                    name='from_name'
                    error={proposalViewErrors?.from_name}
                    inputProps={undefined}
                    className='subject-input'
                    disabled={loading}
                    helperText={proposalViewErrors?.from_name}
                  /> */}

                  <TextField
                    value={emailData.data.reply_to}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmailData(prev => ({
                        ...prev,
                        data: {
                          ...prev.data,
                          reply_to: e.target.value,
                        },
                      }))
                    }}
                    label={`${t('replyTo')}*`}
                    name='reply_to'
                    error={proposalViewErrors?.reply_to}
                    disabled={loading}
                  />
                  {/* <InputField
                    value={emailData.data.reply_to}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmailData(prev => ({
                        ...prev,
                        data: {
                          ...prev.data,
                          reply_to: e.target.value,
                        },
                      }))
                    }}
                    label='Reply to*'
                    name='reply_to'
                    error={proposalViewErrors?.reply_to}
                    helperText={proposalViewErrors?.reply_to}
                    inputProps={undefined}
                    className='subject-input'
                    disabled={loading}
                  /> */}

                  <TextField
                    value={emailData.subject}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmailData(prev => ({
                        ...prev,
                        subject: e.target.value,
                      }))
                    }
                    label={`${t('subject')}*`}
                    name='subject'
                    error={proposalViewErrors?.subject}
                    disabled={loading}
                    style={{ marginBottom: '20px' }}
                  />
                  {/* <InputField
                    value={emailData.subject}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmailData(prev => ({
                        ...prev,
                        subject: e.target.value,
                      }))
                    }
                    label='Subject*'
                    name='subject'
                    error={proposalViewErrors?.subject}
                    helperText={proposalViewErrors?.subject}
                    inputProps={undefined}
                    className='subject-input'
                    disabled={loading}
                    style={{ marginBottom: '20px' }}
                  /> */}
                  <AccessControl
                    visible={!proposalForm?.user_id}
                    author={proposalForm?.user_id}
                    scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
                  >
                    {proposalForm?.id && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <p style={{ fontWeight: '500' }}>Attachment:</p>

                        {prepareTabsForAttachment(
                          { ...proposalForm }?.tabs_info,
                        )?.map((tab, idx, arr) => (
                          <Attachment
                            key={tab.id}
                            idx={idx}
                            onDelete={() =>
                              setEmailData(p => ({
                                ...p,
                                has_attachments: !p.has_attachments,
                              }))
                            }
                            onAttach={onAttachPdfToEmail}
                            isMultipleTabs={arr?.length > 1}
                            proposalId={proposalForm?.id}
                            code={proposalForm?.code}
                            tabId={tab.id}
                            title={tab?.title}
                            tabIds={emailData.pdf_tab_ids}
                            onLoadAndOpenFileHeandler={
                              onLoadAndOpenFileHeandler
                            }
                          />
                        ))}
                      </div>
                    )}

                    <Editor
                      apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
                      init={initEditorSettings}
                      ref={editorRef}
                      value={emailData.message}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onEditorChange={newValue =>
                        setEmailData(prev => ({ ...prev, message: newValue }))
                      }
                      disabled={loading}
                    />

                    {proposalViewErrors?.message && (
                      <span style={{ color: 'red' }}>
                        Message cannot be empty!
                      </span>
                    )}
                  </AccessControl>
                </div>
              </PreviewHOC>
            </Tabs.TabPane>
            {!entityIds && (
              <Tabs.TabPane tab={t('sms')} key='2'>
                {canUseMessaging && !!phones?.length ? (
                  <PreviewHOC
                    industryId={
                      appointment?.industry_id || proposalForm?.industry_id
                    }
                    sms={{
                      ...SMSData,
                      phone: phones?.find(
                        item => item.number === selectedCompanyPhone,
                      ),
                    }}
                  >
                    <div className='sms-container'>
                      <Select
                        label={`${t('fromNumber')}*`}
                        value={selectedCompanyPhone}
                        onChange={handleChangeCompanyPhones}
                        style={{ marginBottom: '10px', width: '100%' }}
                        options={phones.map(({ number, alias = '' }) => ({
                          value: number,
                          label: `${alias ?? ''} ${parsePhoneNumber(number)}`,
                        }))}
                      />
                      {/* <InputField
                      label='From number'
                      value={selectedCompanyPhone}
                      onChange={handleChangeCompanyPhones}
                      style={{ marginBottom: '10px' }}
                      select
                    >
                      {phones.map(({ id, number, alias = '' }) => (
                        <MenuItem key={id} value={number}>
                          {`${alias ?? ''} ${parsePhoneNumber(number)}`}
                        </MenuItem>
                      ))}
                    </InputField> */}

                      <AccessControl
                        visible={!proposalForm?.user_id}
                        author={proposalForm?.user_id}
                        scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
                      >
                        <Editor
                          apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
                          init={{
                            branding: false,
                            height: 200,
                            menubar: false,
                            statusbar: false,
                            toolbar: false,
                            browser_spellcheck: true,
                          }}
                          value={SMSData.body}
                          onEditorChange={newValue =>
                            setSMSData(prev => ({ ...prev, body: newValue }))
                          }
                          disabled={loading}
                        />
                        {proposalViewErrors?.sms && (
                          <span style={{ color: 'red' }}>
                            {t('prompts.messageEmpty')}
                          </span>
                        )}
                        <MessagesCount value={SMSData.body} />
                      </AccessControl>
                    </div>
                  </PreviewHOC>
                ) : (
                  <MESSAGING_SERVICE_IS_NOT_SETUP_TEXT />
                )}
              </Tabs.TabPane>
            )}
          </Tabs>

          {showNotificationHistory && !appointment && (
            <HistoryList
              historyArray={notificationHistory}
              onOpenFileHeandler={onOpenFileHeandler}
            />
          )}

          {!!appointment && (
            <AppointmentNotificationHistory
              appointment={appointment}
              showModal={showNotificationHistory}
            />
          )}

          <DocViewer
            documents={emailFiles}
            currentDocument={currentFileIdx}
            isModalVisible={showViewModal}
            handleCancel={onCloseViewModal}
          />

          <Modal
            wrapClassName='confirm-modal-wrapper'
            title={t('confirmation')}
            visible={showConfirmModal}
            onOk={handleConfirmOk}
            onCancel={handleConfirmCancel}
            width={750}
            footer={null}
            destroyOnClose
          >
            <p>
              {`${t('prompts.notWorkingTime')} (${moment(
                emailData.settings?.time_from,
                'HH:mm:ss',
              ).format('hh:mm a')} - ${moment(
                emailData.settings?.time_to,
                'HH:mm:ss',
              ).format('hh:mm a')}) ${t('prompts.inSettings')}.
           ${t('prompts.pressToSend')}`}
            </p>
            <div className='buttons-row'>
              <MainButton
                disabled={isSubmitDisable}
                title={t('sendLater')}
                onClick={() => handleSubmitNotification(true, false)}
              />
              <MainButton
                disabled={isSubmitDisable}
                title={t('sendNow')}
                onClick={() => handleSubmitNotification(false, true)}
              />
            </div>
          </Modal>
        </Spin>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  min-height: 260px;
`

const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
