import { Popover, Spin, Switch, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  Description,
  Section,
  SectionSettings,
  SectionSettingsItem,
  SectionTitle,
  Title,
  Wrapper,
} from './InvoiceSettings.styles'
import { useState } from 'react'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  selectInovoicesSlice,
  selectInvoiceSettings,
  updateInvoiceSettings,
} from 'features/Invoice/store/invoicesSlice'
import useInvoicePreviewSettings from './hooks/useInvoicePreviewSettings'
import PaymentProcessingFee from 'features/Project/components/EditProject/components/ProjectSettingsModal/components/PaymentProcessingFee/PaymentProcessingFee'
import { ProjectSettings } from 'features/Project/types'

export const InvoiceSettings = () => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const { currentInvoice: invoice, status } = useAppSelector(
    selectInovoicesSlice,
  )
  const settings = useAppSelector(selectInvoiceSettings)

  const projectPreviewSettings = useInvoicePreviewSettings()

  const canEditCustomerView = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW],
    [invoice.user_id, ...(invoice?.extraAssignedUsers || [])],
    me.id,
  )

  const [loading, setLoading] = useState(false)

  const handleSettingsChange = async (field: Partial<ProjectSettings>) => {
    if (!canEditCustomerView)
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })

    setLoading(true)
    await dispatch(
      updateInvoiceSettings({
        projectId: invoice.id,
        field,
      }),
    )
    setLoading(false)
  }

  return (
    <Spin spinning={loading || status === 'loading'}>
      <Wrapper>
        {projectPreviewSettings.map(section => (
          <Section key={section.title}>
            <SectionTitle>{section.title}</SectionTitle>
            <SectionSettings>
              {section.settings.map(setting => (
                <>
                  {setting.field_name === 'payment_processing_fee' ? (
                    <div>
                      <PaymentProcessingFee
                        title='Payment processing fee'
                        description={setting.description}
                        value={settings?.payment_processing_fee || 0}
                        hasPermission={true}
                        disabled={!!setting.disabled}
                        onChange={field => handleSettingsChange(field)}
                      />
                    </div>
                  ) : (
                    <SectionSettingsItem key={setting.field_name}>
                      <Tooltip
                        title={
                          setting.field_name === 'require_signature' &&
                          setting.disabled
                            ? 'This project has already been signed'
                            : setting.field_name === 'hide_zero_price' &&
                              setting.disabled
                            ? 'Can be changed if "Show price per item" enabled'
                            : setting.disabled
                            ? 'Can be changed if project is in Estimate status or Edit Contract mode'
                            : ''
                        }
                      >
                        <Switch
                          checked={(settings as any)?.[setting.field_name]}
                          onChange={value =>
                            handleSettingsChange({
                              [setting.field_name]: value,
                            })
                          }
                          disabled={!!setting.disabled}
                        />
                      </Tooltip>
                      <Title>{setting.title}</Title>

                      {setting.description && (
                        <Popover
                          title={setting.title}
                          content={
                            <Description>{setting.description}</Description>
                          }
                          placement='right'
                          trigger={'click'}
                        >
                          <span
                            style={{
                              marginLeft: 5,
                              cursor: 'help',
                            }}
                          >
                            <QuestionCircleOutlined />
                          </span>
                        </Popover>
                      )}
                    </SectionSettingsItem>
                  )}
                </>
              ))}
            </SectionSettings>
          </Section>
        ))}
      </Wrapper>
    </Spin>
  )
}
