import { EditProjectCode } from 'containers/MainContent/Orcatec/Proposals/components/Form/Header/components/EditProjectCode'
import { Invoice } from 'features/Invoice/types'
import styled from 'styled-components'
import { InvoiceActions } from '../InvoiceActions/InvoiceActions'
import { ProjectStatusDropdown } from 'features/Project/components/ProjectStatus/ProjectStatusDropdown'
import { BalanceStatusEnum, PaymentBalance } from 'features/Project/types'
import dayjs from 'dayjs'
import { Button } from 'components/UIKit'
import { Tooltip } from 'antd'
import { BackButtonOutlined } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'

interface Props {
  data: Invoice
  balance: PaymentBalance
  onCodeSave: (code: string) => void
  onStatusChange: (field: Partial<PaymentBalance>) => void
  onPay: () => void
  onGoBack: () => void
}

export const Heading = ({
  data,
  balance,
  onCodeSave,
  onStatusChange,
  onPay,
  onGoBack,
}: Props) => {
  const InvoiceStatus = {
    [BalanceStatusEnum.Due]: {
      id: BalanceStatusEnum.Due,
      display_name: 'Due',
      color: '#206FED',
      disabled: dayjs(balance?.due_date).isBefore(
        dayjs(new Date()).subtract(1, 'days'),
      ),
    },
    [BalanceStatusEnum.Overdue]: {
      id: BalanceStatusEnum.Overdue,
      display_name: 'Overdue',
      color: '#F3A533',
      disabled: !dayjs(balance?.due_date).isBefore(
        dayjs(new Date()).subtract(1, 'days'),
      ),
    },
    [BalanceStatusEnum.Paid]: {
      id: BalanceStatusEnum.Paid,
      display_name: 'Paid',
      color: '#00A643',
    },
    [BalanceStatusEnum.Cancelled]: {
      id: BalanceStatusEnum.Cancelled,
      display_name: 'Cancelled',
      color: '#F1453D',
      disabled: !(
        !!balance?.payment_transactions?.length &&
        balance?.payment_transactions?.every(tr =>
          ['Declined', 'Rejected', 'Voided'].some(
            d => d === tr.transaction_status,
          ),
        )
      ),
    },
    [BalanceStatusEnum.Pending]: {
      id: BalanceStatusEnum.Pending,
      display_name: 'Pending',
      color: '#9d9d9d',
      disabled: true,
    },
  }

  return (
    <Wrapper>
      <Tooltip title='Go back' mouseLeaveDelay={0}>
        <span onClick={onGoBack} style={{ height: 24 }}>
          <BackButtonOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
        </span>
      </Tooltip>

      <Title>Edit Invoice</Title>

      <EditProjectCode
        project={data}
        loading={false}
        onSave={onCodeSave}
        disabled={false}
        entityName='Invoice'
      />

      <ProjectStatusDropdown
        statusId={balance?.status}
        loading={false}
        onChange={status =>
          onStatusChange({
            status: status.id,
            due_date:
              status.id === BalanceStatusEnum.Paid
                ? dayjs().format('YYYY-MM-DD')
                : balance.due_date,
          })
        }
        statusList={Object.values(InvoiceStatus)}
        disabled={balance?.payment_transactions?.some(
          tr =>
            tr.transaction_status === 'Accepted' ||
            tr.transaction_status === 'Queued for Capture',
        )}
      />

      {balance && canPay(balance) && (
        <Button type='primary' onClick={onPay} style={{ marginLeft: 30 }}>
          Pay
        </Button>
      )}

      <div style={{ marginRight: 5, marginLeft: 'auto' }}>
        <InvoiceActions />
      </div>
    </Wrapper>
  )
}

const hasPending = (balance: PaymentBalance) =>
  balance?.payment_transactions?.some(
    tr => tr.transaction_status === 'Queued for Capture',
  )

const canPay = (balance: PaymentBalance) =>
  [BalanceStatusEnum.Due, BalanceStatusEnum.Overdue].includes(balance.status) &&
  (balance?.accept_card || balance?.can_pay_by_check) &&
  !hasPending(balance) &&
  balance?.amount > 0

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`
export const Title = styled.h4`
  margin-bottom: 0;
`
