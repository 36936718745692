import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProject } from 'features/Project/projectSelectors'
import { InvoicesList } from './components/InvoicesList/InvoicesList'

export const Invoices = () => {
  const project = useAppSelector(selectProject)

  return (
    <ListWrapper>
      <Heading>
        <p className='section-title'>Invoices</p>
      </Heading>
      <InvoicesList projectId={project.id} />
    </ListWrapper>
  )
}

const Heading = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .section-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(38, 38, 38, 1);
  }
`

const ListWrapper = styled.div``
