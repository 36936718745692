import dayjs from 'dayjs'
import { PaymentTransaction } from 'features/Project/types'
import styled from 'styled-components'

export const PaymentTransactions = ({
  data,
  isCustomerView,
}: {
  data: PaymentTransaction[]
  isCustomerView?: boolean
}) => {
  return (
    <Wrapper>
      <div className='tr_row heading'>
        <p className='id'>Transaction ID</p>
        <p className='acc_holder'>Account Holder</p>
        <p className='acc_number'>Account #</p>
        <p className='check_number'>Check #</p>
        <p className='method'>Method</p>
        {!isCustomerView && <p className='processed_by'>Processed by</p>}
        {!isCustomerView && <p className='date'>Date</p>}
        <p className='status'>Status</p>
      </div>
      {data.map(transaction => (
        <div className='tr_row' key={transaction.id}>
          <p className='id'>{transaction.transaction_id ?? ''}</p>
          <p className='acc_holder'>{transaction.card_holder || '-'}</p>
          <p className='acc_number'>{`#*******${transaction.card}`}</p>
          <p className='check_number'>{transaction.check_number || '-'}</p>
          <p className='method'>{transaction?.type === 1 ? 'Card' : 'ACH'}</p>
          {!isCustomerView && (
            <p className='processed_by'>{transaction?.payer || '-'}</p>
          )}
          {!isCustomerView && (
            <p className='date'>
              {dayjs(transaction.created_at).format('MM/DD/YYYY, h:mm a')}
            </p>
          )}
          <p className='status'>{transaction.transaction_status}</p>
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 12px;
  overflow: auto;
  border-radius: 4px;
  margin-top: 18px;
  margin-left: 10px;
  flex-basis: 100%;

  .tr_row {
    display: flex;

    p {
      padding: 5px;
      flex: 0 0 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.id {
        flex: 0 0 100px;
      }
      &.acc_holder,
      &.processed_by,
      &.status {
        flex: 0 0 110px;
      }
      &.check_number,
      &.acc_number {
        flex: 0 0 90px;
      }
      &.method {
        flex: 0 0 60px;
      }
    }

    &.heading {
      color: #000000;
      opacity: 0.5;
    }
  }
`
