import { Result, Spin } from 'antd'

import ProjectLayout from 'features/Project/ProjectLayout'
import { ProjectView } from 'features/Project/types'
import { useHistory, useParams } from 'react-router-dom'
import { InvoiceContent } from './components/InvoiceContent/InvoiceContent'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { getInvoice, selectInovoicesSlice } from './store/invoicesSlice'
import { getProject } from 'features/Project/slices/projectSlice'
import { getRelationContactToProject } from 'features/Project/slices/projectContactsSlice'
import { InvoiceSidebar } from './components/InvoiceSidebar'
import InvoicePreviewSidebar from './components/InvoicePreviewSidebar'
import { InvoiceCustomerView } from './components/InvoiceCustomerView/InvoiceCustomerView'
import { makeBrowserTitle } from 'helpers/makeBrowserTitle'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { InvoicesOperation } from './types'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { useListenProjectSigned } from 'containers/MainContent/Orcatec/Matrix/listeners'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { Button } from 'components/UIKit'

export const Invoice = () => {
  const { READ, UPDATE } = usePermissionsByModule(
    InvoicesOperation,
    false,
    ModuleName.INVOICES,
  )

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { id, preview: isPreview } = useParams<{
    id: string
    preview: string | undefined
  }>()

  const { currentInvoice: invoice, status } = useAppSelector(
    selectInovoicesSlice,
  )

  const [wasSigned, setWasSigned] = useState(false)

  const updateSignature = useCallback(
    (event: {
      data: {
        proposalId: number
      }
    }) => {
      if (event?.data?.invoiceId === invoice.id) {
        setWasSigned(true)
      }
    },
    [invoice?.id],
  )

  useListenProjectSigned(updateSignature)

  useEffect(() => {
    const getData = async () => {
      const res = await dispatch(getInvoice(id))

      if ([404].includes(res?.payload?.response?.status)) {
        return history.push(`/404/invoice`)
      }

      if ([403].includes(res?.payload?.response?.status)) {
        return history.push(`/403/invoice`)
      }
    }

    if (id) getData()
  }, [id, isPreview])

  useEffect(() => {
    makeBrowserTitle({ prefixForTitle: invoice?.code })
  }, [invoice])

  useEffect(() => {
    if (invoice.parent_id) {
      dispatch(getProject({ projectId: invoice.parent_id }))
      dispatch(getRelationContactToProject({ projectId: invoice.parent_id }))
    }
  }, [invoice.parent_id])

  const handleProjectViewChange = (view: ProjectView) =>
    history.push(
      view === ProjectView.EDIT ? `/invoices/${id}` : `/invoices/${id}/preview`,
    )

  const PROJECT_VIEW = isPreview ? ProjectView.PREVIEW : ProjectView.EDIT

  const renderContent = () =>
    PROJECT_VIEW === ProjectView.EDIT ? (
      <InvoiceContent data={invoice} />
    ) : (
      <InvoiceCustomerView />
    )

  if (!READ || !UPDATE)
    return (
      <Result
        status='403'
        subTitle='Sorry, you have no permissions to perform this action.'
      />
    )

  const handleReaload = () => {
    window.location.reload()
  }

  return (
    <ProjectLayout
      sidebarContent={
        <Spin spinning={status === 'loading'}>
          {PROJECT_VIEW === ProjectView.EDIT ? (
            <InvoiceSidebar />
          ) : (
            <InvoicePreviewSidebar />
          )}
        </Spin>
      }
      projectView={PROJECT_VIEW}
      onProjectViewChange={handleProjectViewChange}
    >
      <Spin spinning={status === 'loading'}>
        {renderContent()}

        {wasSigned && (
          <Modal
            onCancel={handleReaload}
            footer={<Button onClick={handleReaload}>Close</Button>}
            open
            title='Invoice updated'
          >
            <p
              style={{
                textAlign: 'center',
                marginBottom: 20,
                fontSize: 22,
                fontWeight: 500,
              }}
            >
              The invoice {invoice.code} was paid.
              <br /> Page will be reloaded
            </p>
          </Modal>
        )}
      </Spin>
    </ProjectLayout>
  )
}
