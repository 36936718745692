import styled from 'styled-components'
import ProjectDetails from './components/ProjectDetails'
import SidebarBlock from './components/SidebarBlock'
import ProjectContacts from './components/ProjectContacts'

export const ProjectSidebar = () => {
  return (
    <Wrapper>
      <ProjectContacts />

      <SidebarBlock
        title='Project Details'
        data-testid='project--project-details'
      >
        <ProjectDetails />
      </SidebarBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div``
