export enum Companies {
  'SD Hair Extensions Studio' = 43,
  OrcatecCorporate = 47,
  'SoCal' = 264,
  'WestCost' = 286,
  'Safety Ney' = 372,
  'C3 Property' = 356,
  HVAC = 36,
  Progressive = 237,
  GARANT = 197,
  SafetyNey = 372,
  'Big Joe Appliance Repair Service' = 375,
  DivineEnergySolutions = 312,
  Sony = 124,
  REMOVAL_PRO = 217,
  AirLoop = 302,
  BuildX = 380,
  Zipfixx = 431,
  'Trilogy Surgical Solutions' = 450,
  'Tytum Construction' = 380,
  'Tytum HVAC' = 302,
  LJLInc = 461,
  BucknDumpsters = 471,
  EJArtMoverLLC = 477,
  ApplianceServiСenter = 197,
  ManifestServices = 488,
  ParamountPaintingInc = 481,
  'Asia Shvei Prom New' = 834,
}
