import styled from 'styled-components'
import SidebarBlock from 'features/Project/components/EditProject/components/ProjectSidebar/components/SidebarBlock'
import InvoiceSettings from './components/ProjectSettings'

export const InvoicePreviewSidebar = () => {
  return (
    <Wrapper>
      <SidebarBlock>
        <Section>
          <SectionTitle>Settings</SectionTitle>
          <InvoiceSettings />
        </Section>
      </SidebarBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Section = styled.div``
const SectionTitle = styled.h6``
