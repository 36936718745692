import styled from 'styled-components'
import { Heading } from '../Heading/Heading'
import ProjectName from 'features/Project/components/EditProject/components/ProjectName'
import { InvoiceInfo } from '../InvoiceInfo/InvoiceInfo'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import { Invoice } from 'features/Invoice/types'
import { InvoiceSection } from '../InvoiceSection/InvoiceSection'
import {
  getInvoice,
  selectInvoiceBalance,
  selectInvoiceFee,
  updateInvoicePayment,
  updateInvoiceSection,
  updateProjectInvoiceField,
} from 'features/Invoice/store/invoicesSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { PaymentBalance } from 'features/Project/types'
import CustomerInfo from 'features/Project/components/EditProject/components/CustomerInfo'
import { InvoiceStatus } from '../InvoiceStatus/InvoiceStatus'
import { InvoicesList } from 'features/Project/components/Invoices/components/InvoicesList/InvoicesList'
import { PaymentInfo } from '../PaymentInfo/PaymentInfo'
import PaymentProcess from 'containers/MainContent/Orcatec/Proposals/components/PaymentProcess'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { useState } from 'react'
import states from 'helpers/states'
import { useHistory } from 'react-router-dom'
import { ProjectHeadingTabs } from 'features/Project/components/EditProject/EditProject'
import { Totals } from '../InvoiceTotals'

interface Props {
  data: Invoice
}

export const InvoiceContent = ({ data }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const balance = useAppSelector(selectInvoiceBalance)
  const fee = useAppSelector(selectInvoiceFee)
  const authUser = useAppSelector(selectAuthUser)
  // const invoiceSettings = useAppSelector(selectInvoiceSettings)

  const [paymentModal, showPaymentModal] = useState(false)

  async function handleProjectInvoiceFieldUpdate(field: Partial<Invoice>) {
    const res = await dispatch(updateProjectInvoiceField(field))

    if (res?.payload?.errors?.code) throw new Error(res.payload.errors.code)

    return res
  }

  async function handleBalanceUpdate(field: Partial<PaymentBalance>) {
    const res = await dispatch(
      updateInvoicePayment({
        ...balance,
        ...field,
      }),
    )

    if (res?.payload?.errors?.code) throw new Error(res.payload.errors.code)

    return res
  }

  function handleBalancePaid() {
    dispatch(getInvoice(data.id))
  }

  const primaryClient = data?.contacts?.[0]
  const primaryProperty = data?.properties?.[0]

  return (
    <Wrapper>
      <Heading
        data={data}
        onCodeSave={code => handleProjectInvoiceFieldUpdate({ code })}
        balance={balance}
        onStatusChange={handleBalanceUpdate}
        onPay={() => showPaymentModal(true)}
        onGoBack={() =>
          history.push({
            pathname: `/projects/${data?.parent_id}`,
            state: { tabId: ProjectHeadingTabs.INVOICES },
          })
        }
      />

      <ContentContainer>
        <ContentContainerInner>
          <ProjectName
            name={data.name}
            entityName='Invoice'
            onSave={name => handleProjectInvoiceFieldUpdate({ name })}
          />
        </ContentContainerInner>

        <Content>
          <ContentContainerInner>
            <Section>
              <InvoiceInfo
                data={data}
                balance={balance}
                onChange={handleBalanceUpdate}
              />
            </Section>

            {data.id && (
              <Notes
                route={`proposal-tabs/${data.proposal_tabs?.[0]?.id}/notes`}
              />
            )}
          </ContentContainerInner>

          <InvoiceStatus balanceStatus={balance?.status} />

          <ContentContainerInner>
            {data.proposal_tabs?.[0].proposal_sections.map(section => (
              <InvoiceSection
                key={section.id}
                data={section}
                assignee={[data.user_id, ...(data.extraAssignedUsers || [])]}
                onSectionDescriptionChange={value =>
                  dispatch(
                    updateInvoiceSection({
                      sectionId: section.id,
                      fields: { description: value },
                    }),
                  )
                }
              />
            ))}
          </ContentContainerInner>

          <ContentContainerInner>
            <Totals total={data.invoice_amount} feeSum={fee} />
          </ContentContainerInner>

          <ContentContainerInner>
            <PaymentInfo onPay={() => showPaymentModal(true)} />
          </ContentContainerInner>

          <ContentContainerInner>
            <CustomerInfo
              data={data}
              onUpdate={data => dispatch(updateProjectInvoiceField(data))}
            />
          </ContentContainerInner>
        </Content>
      </ContentContainer>

      {data.parent_id && (
        <ListWrapper>
          <p style={{ fontSize: 16, fontWeight: 500 }}>
            Other invoices for Project #{data.proposal_code}
          </p>
          <InvoicesList projectId={data.parent_id} currentInvoiceId={data.id} />
        </ListWrapper>
      )}

      {paymentModal && balance && (
        <Modal
          open
          className={'paymenta-modal'}
          width='auto'
          title={`Payment for Project - ${data.proposal_code}`}
          centered
          destroyOnClose
          onCancel={() => showPaymentModal(false)}
          maskClosable={false}
          footer={null}
        >
          <PaymentProcess
            balance={balance}
            cardInfo={{
              card_holder: primaryClient?.name || '',
              billing_address: {
                address: primaryProperty?.address || '',
                city: primaryProperty?.city || '',
                region: states[primaryProperty?.state] || '',
                postal: primaryProperty?.postcode || '',
              },
            }}
            transactionFee={+data?.setting?.payment_processing_fee}
            settings={{
              disableTips: true,
            }}
            onCancel={() => showPaymentModal(false)}
            onPaid={handleBalancePaid}
            payer={authUser?.full_name}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const ListWrapper = styled.div`
  margin-top: 30px;
`
const ContentContainer = styled.div`
  background-color: #fff;
  border: 1px solid #e6e8ec;
  margin-top: 32px;
`

const ContentContainerInner = styled.div`
  padding: 20px;
`

const Content = styled.div`
  background-color: #fff;
  border-top: 1px solid #e6e8ec;
  overflow: auto;
`

const Section = styled.div`
  margin-bottom: 24px;
`
