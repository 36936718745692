import { Button } from 'components/UIKit'
import dayjs from 'dayjs'
import { InvoicesOperation } from 'features/Invoice/types'
import { PaymentBalance } from 'features/Project/types'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  data: PaymentBalance['invoice']
  onGenerateInvoice: () => Promise<void>
  primary?: boolean
}

export const InvoiceData = ({ data, primary, onGenerateInvoice }: Props) => {
  const { CREATE } = usePermissionsByModule(
    InvoicesOperation,
    false,
    ModuleName.INVOICES,
  )

  const [loading, setLoading] = useState(false)

  async function handleClick() {
    if (!CREATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    setLoading(true)

    try {
      await onGenerateInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      {!data ? (
        <StyledButton
          type={primary ? 'primary' : 'link'}
          onClick={handleClick}
          disabled={loading}
        >
          + Generate invoice
        </StyledButton>
      ) : (
        <Text>
          Invoice <Link to={`/invoices/${data.id}`}>{data.code}</Link> has been
          generated by {data.created_by} at{' '}
          {dayjs(data.created_at).format('MM/DD/YYYY hh:mm a')}
        </Text>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const StyledButton = styled(Button)`
  padding: ${props => (props.type === 'primary' ? '4px 15px' : 0)};
  margin: ${props => (props.type === 'primary' ? 'initial' : '5px 0 -5px;')};
`
const Text = styled.p`
  margin-top: 12px;
  color: ${props => props.theme.font.secondary};
`
