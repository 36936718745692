import { Invoice } from 'features/Invoice/types'
import states from 'helpers/states'
import styled from 'styled-components'

interface Props {
  data: Invoice
  companyName: string
}

export const ContactsSection = ({ data, companyName }: Props) => {
  const primaryContact = data.contacts[0]
  const primaryProperty = data.properties[0]

  return (
    <Wrapper>
      <Block>
        <Title>From:</Title>

        <div>
          <Name>{companyName}</Name>

          {data?.setting?.show_tech && (
            <div>
              <Name>{data?.user?.full_name}</Name>
              {data?.user?.email && (
                <Text>
                  Cell: <span>{data?.user?.phone}</span>
                </Text>
              )}
              {data?.user?.email && (
                <Text>
                  Email: <span>{data?.user?.email}</span>
                </Text>
              )}
            </div>
          )}
        </div>
      </Block>

      {(primaryContact || primaryProperty) && (
        <Block>
          <Title>To:</Title>

          <div>
            {!!primaryContact && (
              <div style={{ marginBottom: 30 }}>
                <Name>{primaryContact.name}</Name>
                {primaryContact?.phones?.[0]?.phone && (
                  <Text>
                    Cell: <span>{primaryContact.phones[0].phone}</span>
                  </Text>
                )}
                {primaryContact?.emails?.[0]?.email && (
                  <Text>
                    Email: <span>{primaryContact.emails[0].email}</span>
                  </Text>
                )}
              </div>
            )}

            {!!primaryProperty && (
              <div>
                <Name>{primaryProperty?.address}</Name>

                <Text>
                  <span>
                    {primaryProperty?.city}, {primaryProperty?.postcode}{' '}
                  </span>
                </Text>

                <Text>
                  <span>
                    {states[primaryProperty?.state] || primaryProperty?.state}
                  </span>
                </Text>
              </div>
            )}
          </div>
        </Block>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  margin-top: 20px;
  padding: 15px 50px 15px 15px;
  font-size: 12px;

  display: flex;
  justify-content: space-between;
`
const Block = styled.div`
  display: flex;
  gap: 10px;
`
const Title = styled.p`
  font-weight: 500;
`
const Name = styled.p`
  font-weight: 500;
`
const Text = styled.p``
