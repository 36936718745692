import { WarningFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Invoice } from 'features/Invoice/types'
import { priceNumber } from 'helpers/numbers'
import styled from 'styled-components'

interface Props {
  data: Invoice
}

export const TotalsInfo = ({ data }: Props) => {
  return (
    <Wrapper>
      <Row>
        <span>Total Project Amount:</span>
        <span>{priceNumber(data.proposal_amount || 0)}</span>{' '}
      </Row>
      <Row>
        <span>Total amount Paid on this Project:</span>
        <span>{priceNumber(data.amount_paid || 0)}</span>{' '}
      </Row>
      <Row>
        <span>This invoice amount:</span>
        <span>{priceNumber(data.invoice_amount || 0)}</span>{' '}
      </Row>
      <Row>
        <span>Total remaining amount after this invoice:</span>
        <span>
          {data.amount_remaining < 0 && (
            <Tooltip title='Project Total and Invoices Total amounts are mismatch'>
              <WarningFilled
                style={{
                  color: '#faad14',
                  fontSize: '1.2rem',
                  margin: '-2px 5px 0 0',
                }}
              />
            </Tooltip>
          )}
          {priceNumber(data.amount_remaining || 0)}
        </span>{' '}
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  max-width: 420px;
  margin: 20px 0 30px auto;
  text-align: initial;
`
const Row = styled.p`
  display: grid;
  grid-template-columns: 1fr 120px;
  margin: 2px 0;

  span {
    overflow: hidden;
    &:nth-child(2) {
      font-weight: 500;
      text-align: right;
    }
  }
`
