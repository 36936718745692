import { CheckOutlined } from '@ant-design/icons'
import { TextField } from 'components/UIKit'
import { StyledEditIcon } from 'features/Project/Taxes/Taxes.style'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface Props {
  name: string
  disabled?: boolean
  entityName?: string
  onSave: (name: string) => void
}

export const ProjectName = ({
  name,
  disabled,
  entityName = 'Project',
  onSave,
}: Props) => {
  const [projectName, setProjectName] = useState('')
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setProjectName(name)
  }, [name])

  const handleModeChange = () => {
    if (disabled) return

    setEditMode(!editMode)
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProjectName(e.target.value)

  const handleSave = () => {
    // if (disabled) return

    setEditMode(false)
    onSave?.(projectName)
    // dispatch(updateProjectField({ name: projectName }))
  }

  return (
    <Wrapper data-testid='project-name'>
      {editMode ? (
        <>
          <TextField
            autoFocus
            value={projectName}
            onChange={handleNameChange}
            placeholder={`${entityName} name`}
            onBlur={handleSave}
            maxLength={100}
            suffix={<StyledCheckOutlined />}
          />
          {/* <StyledCheckOutlined onClick={handleSave} /> */}
        </>
      ) : (
        <div style={{ display: 'flex', gap: 10 }}>
          <Name isEmpty={!projectName} onClick={handleModeChange}>
            {projectName || `{No ${entityName} name}`}
          </Name>
          {!disabled && (
            <StyledEditIcon
              style={{ fontSize: 20 }}
              onClick={handleModeChange}
            />
          )}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  /* max-width: 500px; */
`
const Name = styled.p<{ isEmpty: boolean }>`
  font-size: 24px;
  color: ${props => (props.isEmpty ? '#b2b2b2' : 'inherit')};
  font-style: ${props => (props.isEmpty ? 'italic' : 'initial')};
`

const StyledCheckOutlined = styled(CheckOutlined)`
  position: absolute;
  right: 8px;
  top: 8px;
`
