import { PaymentBalance } from 'features/Project/types'

// export const getPaymentStatus = (
//   balances: PaymentBalance[],
//   total_to_pay: number | string,
// ): number => {
//   const overdueBalances = balances?.some(b => b.status === 2)
//   const sumOfPaidBalances = balances
//     ?.filter(b => b.status === 3)
//     .reduce((a, b) => a + +b.amount, 0)
//   const isAnyDueOverDuePending = balances?.some(
//     b => b.status === 1 || b.status === 2 || b.status === 5,
//   )
//
//   if (!balances?.length) return 1
//   if (
//     +(+sumOfPaidBalances).toFixed(2) === +(+total_to_pay).toFixed(2) &&
//     !isAnyDueOverDuePending
//   ) {
//     return 4
//   } else if (overdueBalances) {
//     return 3
//   } else return 2
// }

export const getPaymentStatus = (
  balances: PaymentBalance[],
  total_to_pay: number | string,
): number => {
  if (!balances?.length) return 1

  let sumOfPaidBalances = 0
  let hasOverdueBalance = false
  let hasPendingBalance = false

  for (const b of balances) {
    if (b.status === 2) hasOverdueBalance = true
    if (b.status === 1 || b.status === 2 || b.status === 5)
      hasPendingBalance = true
    if (b.status === 3) sumOfPaidBalances += +b.amount
  }

  if (
    +sumOfPaidBalances.toFixed(2) === +(+total_to_pay).toFixed(2) &&
    !hasPendingBalance
  ) {
    return 4 // Paid in full
  }
  if (hasOverdueBalance) return 3

  return 2 //return due
}
