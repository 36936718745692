import { EditOutlined } from '@ant-design/icons'
import { Skeleton, Tooltip } from 'antd'
import { DatePicker, Select, TextField } from 'components/UIKit'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import { selectProject } from 'features/Project/projectSelectors'
import {
  selectWorkOrderSlice,
  updateWorkorderField,
} from 'features/Project/slices/projectWorkOrdersSlice'
import {
  ModuleName,
  ProjectPermissions,
  TypeSourcePermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'

export const MainInfo = () => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const { current: workOrder, status } = useAppSelector(selectWorkOrderSlice)
  const project = useAppSelector(selectProject)
  const techs = useAppSelector(state => state.orcatec.company.technicians)
  // const { invoicesList } = useAppSelector(selectInovoicesSlice)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const canEditProjectDetails = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_RELATIONS],
    [project.user_id, ...(project?.extraAssignedUsers || [])],
    me?.id,
  )

  const [workOrderName, setWorkOrderName] = useState('')

  useEffect(() => {
    setWorkOrderName(workOrder.name)
  }, [workOrder.name])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setWorkOrderName(e.target.value)

  const handleInfoUpdate = e => {
    const { name, value } = e.target

    const field = { [name]: value }
    dispatch(updateWorkorderField(field))
  }

  const handleTechUpdate = (e: { target: { name: string; value: number } }) => {
    const { value } = e.target

    dispatch(
      updateWorkorderField({
        user_id: value,
        user: techs.find(tech => tech.id === value),
      }),
    )
  }

  if (status === 'loading') return <Skeleton active />

  return (
    <ErrorBoundary>
      <WrapperInnner>
        <Wrapper>
          <Row>
            <Title>Work order name:</Title>
            <TextField
              name='name'
              value={workOrderName}
              onChange={handleNameChange}
              placeholder='{No Work order name}'
              onBlur={handleInfoUpdate}
              className='input-with-padding'
            />
            <EditIcon />
          </Row>

          <Row>
            <Title>Created from:</Title>
            <p>{project.code}</p>
          </Row>

          <Row>
            <Title>Created by:</Title>
            <p style={{ whiteSpace: 'break-spaces' }}>
              {workOrder.created_by?.full_name}{' '}
              {moment
                .utc(workOrder.created_at)
                .local()
                .format('[on] MM/DD/YYYY [at] hh:mm a')}
            </p>
          </Row>

          <Row>
            <Title>Assigned to:</Title>
            <Select
              value={workOrder.user_id}
              name='user_id'
              options={techs}
              onChange={handleTechUpdate}
              showSearch={false}
              fieldNames={{ label: 'full_name', value: 'id' }}
            />
            <EditIcon />
          </Row>

          <Row disabled={!canEditProjectDetails}>
            <Title>Date of completion:</Title>
            <Tooltip
              mouseLeaveDelay={0}
              title={
                !canEditProjectDetails
                  ? 'You do not have permissions to perform this action'
                  : ''
              }
            >
              <DatePicker
                value={workOrder?.date_of_completion}
                onChange={(_, value) =>
                  handleInfoUpdate({
                    target: {
                      name: 'date_of_completion',
                      value,
                    },
                  })
                }
                allowClear={false}
                disabled={!canEditProjectDetails}
              />
            </Tooltip>
          </Row>

          <Row disabled={!canEditProjectDetails}>
            <Title>Type:</Title>

            <AppointmentTypes
              name='type_id'
              value={workOrder.type_id}
              onChange={handleInfoUpdate}
              isLead={false}
              required={false}
              disableCRUD={!canEditTypeSource}
              withoutLabel
              withoutColor
              disabled={!canEditProjectDetails}
              disableClearable
            />
            <EditIcon
              disabled={!canEditProjectDetails}
              style={{ cursor: 'pointer' }}
            />
          </Row>
        </Wrapper>

        {!!workOrder?.proposal_tabs?.[0]?.id && (
          <NotesWrapper>
            <Notes
              label='Work order notes'
              route={`proposal-tabs/${workOrder?.proposal_tabs?.[0]?.id}/notes`}
            />
          </NotesWrapper>
        )}
      </WrapperInnner>
    </ErrorBoundary>
  )
}

const WrapperInnner = styled.div`
  padding: 16px;
  border: 1px solid #f1f2fd;
  border-radius: 4px;
  margin-bottom: 16px;
`

const NotesWrapper = styled.div`
  margin-top: 16px;
  max-width: 450px;
`

const Wrapper = styled.div`
  max-width: 450px;

  .input-with-padding {
    padding-right: 20px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input,
  span {
    font-size: 14px;
    padding: 0 !important;
    background: none !important;
    border: none;
  }

  /* .ant-input, */
  .ant-select-selector,
  fieldset,
  .MuiAutocomplete-root,
  .ant-picker {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  .MuiAutocomplete-input {
    padding-right: 20px !important;
    margin-top: 2px;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select {
    top: -3px;
    height: 20px;
  }
  .orcatec-input {
    /* margin-left: -5px; */
    .MuiInputBase-root {
      padding-left: 0 !important;
    }
  }

  /*   .ant-select-selection-item {
    max-width: 75%;
  } */

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`
const Row = styled.div<{ disabled?: boolean }>`
  display: flex;
  position: relative;

  .MuiAutocomplete-input {
    color: ${props => (props.disabled ? '#898989' : 'initial')};
  }

  .ant-picker-suffix {
    color: ${props => (props.disabled ? '#bfbfbf' : '#4186f4')};
  }

  & > div {
    width: 100%;
  }
`
const Title = styled.p`
  color: #999999;
  flex: 0 0 40%;
`

const EditIcon = styled(EditOutlined)<{ disabled?: boolean }>`
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 15px;
  cursor: pointer;
  color: ${props => (props.disabled ? '#bfbfbf' : '#4186f4')};
  pointer-events: none;
`
