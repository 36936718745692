import { DraggableIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import {
  BalanceName as IBalanceName,
  BalanceStatusEnum,
  PaymentBalance as IPaymentBalance,
  PaymentMethod,
  ProjectStatusGroup,
} from 'features/Project/types'
import styled from 'styled-components'
import { BalanceName } from '../BalanceName/BalanceName'
import { DatePicker, NumberField, TextArea } from 'components/UIKit'
import { BalanceStatus } from '../BalanceStatus/BalanceStatus'
import { PaymentMethods } from '../PaymentMethods/PaymentMethods'
import { PaymentTransactions } from '../PaymentTransactions/PaymentTransactions'
import { PaymentActions } from '../PaymentActions/PaymentActions'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useCallback } from 'react'
import { InvoiceData } from '../InvoiceData/InvoiceData'
import moment from 'moment-timezone'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import dayjs from 'dayjs'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { companiesWithNullableDueDate } from 'constants/hardcodes'

interface Props {
  provided?: DraggableProvided
  data: IPaymentBalance
  disabled: boolean
  availablePaymentMethods: {
    bank_check: boolean
    credit_card: boolean
  }
  isInvoice?: boolean
  hasActiveMerchant: boolean
  balanceNames: IBalanceName[]
  loadingBalanceNames: boolean
  paymentMethods: PaymentMethod[]
  projectStatus: ProjectStatusGroup
  onBalanceNameAdd: (newValue: string) => Promise<void>
  onBalanceNameDelete: (id: number) => Promise<void>
  onChange: (balance: IPaymentBalance) => void
  onDelete?: () => void
  onGenerateInvoice?: (
    balance: IPaymentBalance,
  ) => Promise<IPaymentBalance['invoice']>
}

export const PaymentBalance = ({
  provided,
  data,
  disabled,
  availablePaymentMethods,
  hasActiveMerchant,
  balanceNames,
  loadingBalanceNames,
  paymentMethods,
  projectStatus,
  onBalanceNameAdd,
  onBalanceNameDelete,
  onChange,
  onDelete,
  onGenerateInvoice,
  isInvoice,
}: Props) => {
  const company = useAppSelector(selectCompany)

  const hasTransactions = !!data.payment_transactions?.length
  const hasAccepted = data.payment_transactions?.some(tr =>
    ['Accepted', 'Queued for Capture'].includes(tr.transaction_status),
  )
  const allRejected =
    hasTransactions &&
    data.payment_transactions?.every(tr =>
      ['Declined', 'Rejected', 'Voided'].includes(tr.transaction_status),
    )

  const handleBalanceChange = useCallback(
    (value: Partial<IPaymentBalance>) => {
      onChange({ ...data, ...value })
    },
    [data, onChange],
  )

  const handleInvoiceGeneration = useCallback(async () => {
    if (projectStatus === ProjectStatusGroup.Estimate) {
      return openNotificationWithIcon('warning', {
        message: 'Oops',
        description:
          'You cannot generate an Invoice for a project in the Estimate status',
      })
    }

    onGenerateInvoice?.(data)

    /* if (onGenerateInvoice) {
      const invoiceData = await onGenerateInvoice(data)
      handleBalanceChange({ invoice: invoiceData })
    } */
  }, [data, onGenerateInvoice, projectStatus, handleBalanceChange])

  const paidBalance = data.status === BalanceStatusEnum.Paid

  return (
    <Wrapper onBlur={() => onChange(data)}>
      {provided && (
        <div {...provided?.dragHandleProps}>
          <DraggableIcon />
        </div>
      )}

      <Balance filled={hasTransactions}>
        <BalanceContent>
          <Fields>
            <BalanceName
              loading={loadingBalanceNames}
              options={balanceNames}
              label='Payment name'
              value={data.name_balance}
              onChange={value => handleBalanceChange({ name_balance: value })}
              onAdd={newValue => onBalanceNameAdd(newValue)}
              onDelete={balanceNameId => {
                handleBalanceChange({ name_balance: 'Balance' })
                return onBalanceNameDelete(balanceNameId)
              }}
              disabled={disabled}
            />

            <DatePicker
              label={`${paidBalance ? 'Payment' : 'Due'} Date`}
              value={data.due_date}
              onChange={value => {
                const newDateValue = value ? value.format('YYYY-MM-DD') : null
                handleBalanceChange({
                  due_date: newDateValue,
                  status: getBalanceStatus(value, data),
                })
              }}
              disabled={disabled}
              placeholder='No due date'
              allowClear={companiesWithNullableDueDate.includes(company.id)}
            />

            <NumberField
              label='Amount'
              value={data.amount}
              disabled={disabled || hasTransactions || paidBalance || isInvoice}
              prefix='$'
              onChange={value => handleBalanceChange({ amount: value })}
              onBlur={e => {
                if (!e.target.value) handleBalanceChange({ amount: 0 })
              }}
            />

            <BalanceStatus
              data={data}
              onChange={value => {
                if (projectStatus === ProjectStatusGroup.Estimate) {
                  return openNotificationWithIcon('warning', {
                    message: 'Oops',
                    description:
                      'Payment status cannot be changed for a project in the Estimate status',
                  })
                }

                const dueDate =
                  value === BalanceStatusEnum.Paid
                    ? dayjs().format('YYYY-MM-DD')
                    : data.due_date

                handleBalanceChange({
                  status: value,
                  due_date: dueDate,
                })
              }}
              disabled={disabled || !!hasAccepted}
              allRejected={!!allRejected}
            />

            <Span3>
              <TextArea
                label='Payment Description'
                value={data.description}
                onChange={event =>
                  handleBalanceChange({ description: event.target.value })
                }
                maxLength={3000}
              />
            </Span3>

            <PaymentMethods
              options={paymentMethods}
              value={data.payment_method}
              onChange={value => handleBalanceChange({ payment_method: value })}
              disabled={
                disabled ||
                data.status !== BalanceStatusEnum.Paid ||
                !!hasAccepted
              }
            />
          </Fields>

          <Actions>
            <PaymentActions
              disabled={
                disabled ||
                hasTransactions ||
                data.status === BalanceStatusEnum.Paid
              }
              onChange={handleBalanceChange}
              onDelete={onDelete}
              data={data}
              hasActiveMerchant={hasActiveMerchant}
              availablePaymentMethods={availablePaymentMethods}
            />
          </Actions>
        </BalanceContent>

        {hasTransactions && (
          <PaymentTransactions data={data.payment_transactions || []} />
        )}

        {onGenerateInvoice && (
          <InvoiceData
            data={data.invoice}
            onGenerateInvoice={handleInvoiceGeneration}
          />
        )}
      </Balance>
    </Wrapper>
  )
}

function getBalanceStatus(
  dueDate: moment.Moment | null,
  balance: IPaymentBalance,
) {
  return ![BalanceStatusEnum.Due, BalanceStatusEnum.Overdue].includes(
    balance.status,
  )
    ? balance.status
    : dueDate && dueDate.isBefore(moment(), 'day')
    ? BalanceStatusEnum.Overdue
    : BalanceStatusEnum.Due
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`
const Balance = styled.div<{ filled: boolean }>`
  border: 1px solid;
  border-radius: 8px;
  border-color: #e6e8ec;
  background-color: ${props => (props.filled ? '#f5f5f5' : '#fff')};
  padding: 12px;
  width: 100%;
`
const BalanceContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  gap: 12px;
`
const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 12px;
`

const Span3 = styled.div`
  grid-column: span 3;
`

const Actions = styled.div``
// const InvoiceSection = styled.div`
//   margin-top: 12px;
// `
