import {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  MutableRefObject,
} from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Checkbox, Modal, Popconfirm, Tooltip } from 'antd'
import { getAllIndustries } from 'api/settings/Industries'
import {
  getAppointment,
  createAppointment,
  deleteAppointment,
  dublicateAppointment,
  fetchAppointmentById,
  updateAppoinment,
  // getActualDate,
  createAppointmentChild,
  detachEntityFromAppointment,
} from 'api/Appointment'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import AppointmentSection from './components/AppointmentSection'
import ClientForm from 'containers/MainContent/Orcatec/Clients/components/Form/components/Form/index.js'
import LoadingScreen from '../../components/LoadingScreen'
import MainButton from '../../components/buttons/MainButton'
import PropertyForm from 'containers/MainContent/Orcatec/Properties/components/PropertyForm'
import {
  getMeSelector,
  getWasEditSelector,
  // isShowUpcomingNotificationSelector,
} from 'store/Orcatec/selectors/appointment'
import './styles.scss'
import moment from 'moment-timezone'

import { BlockRoutingModal } from '../../components/BlockRoutingModal'
import { setCurrentAppointmentId } from 'store/Orcatec/actions/appointment'
// import { getMatrixLog, getMatrixQueues } from 'store/Orcatec/actions/matrix/matrixLog'
import AppointmentLocation from './components/AppointmentLocation/AppointmentLocation'

// import { getClientsForNotificationModal } from '../../../../../api/Matrix-log'
// import {
//   Button as RfRButton,
//   Modal as RfRModal,
// } from '../../components/RequestForReviewModal'
import SelectField from '../../components/Select'
import { filterDeletedEntities } from 'helpers/filterDeletedEntities'
import {
  AppointmentEntityType,
  EventKind,
  AppointmentStatusID,
  // RecurringInterval,
  // RecurringMonthlyType,
  IAppointment,
  Props,
  IIndustries,
  IEntity,
  ActionScope,
} from 'types/Appointment'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  isSameDate,
  makePromisedTimeStart,
  makePromisedTimeEnd,
} from './helpers/helpers'
import { useReactToPrint } from 'react-to-print'
import { IndividualPrintStyle } from '../../Jobs/components/JobPage/components/JobForm/JobForm.styles'
import styled from 'styled-components'
import AppointmentPrintPDF from '../AppointmentForm/components/AppointmentPrintPDF/AppointmentPrintPDF'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { expandQueue } from '../helpers'
import { CloseOutlined } from '@ant-design/icons'
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer'
import {
  eventDeleted,
  getMatrixLog,
  getQueueEvents,
  selectDispatchSettings,
  setMatrixEvent,
  resetCreated,
} from 'features/Dispatch/dispatchSlice'
// import { useQueryClient } from '@tanstack/react-query'
import { mapUpdateNeeded } from 'store/Orcatec/actions/map'
// import usePreferredRoute from '../../Map/components/hooks/usePreferredRoute'
import AppointmentPreferredTech from './components/AppointmentPreferredTech/AppointmentPreferredTech'
import useHashRouteToggle from 'hooks/useHashRouteToggle'
import { ConfirmationModal } from '../../components/UI/Modal'
import { getRoutesSettings } from 'api/map'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import { Companies } from 'types/Company'
import { useEventAssignToOptions } from 'hooks/useEventAssignToOptions'

import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
  NotificationTitleType,
} from 'features/Notification/types'
import { getNotificationSettings } from 'api/NotificationModal'
import useEventSettings from 'hooks/useEventSettings'
import { useColumns } from 'hooks/useColumns'
import { selectQueueSettings } from 'features/Dispatch/queueSlice'

import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  DispatchPermission,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import {
  selectModulePermissions,
  selectUserPermissionsByModule,
} from 'features/Settings/UsersAndGroups/permissionSlice'

import { ReviewStatus } from 'features/Reviews/components/ReviewStatus/ReviewStatus'
import { FormsBlock } from 'features/Forms/components/FormsBlock/FormsBlock'
import {
  attachRelatedFormsToEvent,
  createRelatedFormToJob,
  getRelatedFormToJob,
} from 'api/CustomForms'
import { CustomFormEntityType } from 'features/Forms/types'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'
// import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { DATE_FORMAT } from 'constants/dateFormats'
import { GroupEvents } from 'features/Visits/components/Group/GroupEvents'
import { GroupModal } from 'features/Visits/components/Group/GroupModal'
import { NotificationModalV2 } from 'features/Notification/modals/NotificationModal/NotificationModalV2'
import { EventGroupAPI, RecurringEventsAPI } from 'features/Visits/api'
import { RecurringEvents } from 'features/Visits/components/Recurring/RecurringEvents'
import {
  RecurringModal,
  RecurringOptions,
} from 'features/Visits/components/Recurring/RecurringModal'
// import { INITIAL_EVENT_COUNT, LEAD_TIME } from 'features/Visits/constants'
import ActionScopeModal from 'features/Visits/components/Recurring/components/ActionScopeModal'
import { useTranslation } from 'react-i18next'
import UserGuide, {
  guideStepsByModule,
} from 'features/Support/components/UserGuide'
import { useTourGuide } from 'features/Support/hooks/useTourGuide'

const Wrapper = styled.div`
  & .create-appointment-form__buttons_fix {
    display: flex;
    margin: 40px 0 50px;
    grid-gap: 1rem;
    gap: 1rem;
  }
  & .create-appointment-form__buttons_fix.new {
    justify-content: end;
  }
`
const HeaderWrapper = styled.div`
  & .button-custom-secondary:focus {
    background-color: #edeffe;
    color: #4285f4;
  }
  & .rfr-button {
    height: 30px;
    display: flex;
    align-items: center;
  }
`
export const initialAppointment: IAppointment = {
  appointment_source_id: null,
  appointment_type_id: null,
  column_template_id: [],
  canceled_info: null,
  date: null,
  entity_id: 0,
  entity_type: AppointmentEntityType.PROPERTY,
  industry_id: 0,
  is_recurring: false,
  matrix_time_end: null,
  matrix_time_start: null,
  notes: [],
  preferred_technician_id: null,
  status: 1,
  time_end: null,
  time_start: null,
  current_end_time: null,
  current_start_time: null,
  times_needed: 1,
  primary_entity_id: null,
  company_start_time: null,
  company_end_time: null,
  timezone: null,

  //recurring
  /* each: [],
  ending_options: {
    end_type: 1,
    value: null,
  },
  interval: RecurringInterval.DAY,
  initial_event_count: INITIAL_EVENT_COUNT,
  lead_time: LEAD_TIME,
  make_first_appointment: false,
  monthly_type: RecurringMonthlyType.DAILY,
  repeat_every: 1,
  start_date: null, */
}
export const initialErrors: Error = {
  preferred_technician_id: null,
  matrix_time_end: null,
  matrix_time_start: null,
  appointment_source_id: null,
  appointment_type_id: null,
  appointed_technician_id: null,
  time_end: null,
  time_start: null,
  date: null,
  start_date: null,
  ending_options: null,
}
export type Ref = MutableRefObject<HTMLElement | null>
export const AppointmentForm = forwardRef<Ref, Props>(
  (
    {
      appointmentId,
      drawerView,
      onCloseDrawer,
      visibleDrawer = false,
      matrix = false,
      currentDate,
      onGroupEventClick,
    },
    ref,
  ) => {
    const { t } = useTranslation(['appointment', 'common'])
    const {
      isActive: tourIsActive,
      nextTourScheduled,
      currentStep,
      startTour,
      handleTourCallback,
    } = useTourGuide('appointment')

    const history = useHistory()
    const dispatch = useDispatch()
    const { columns } = useColumns(moment().format('MM/DD/YYYY'))
    // const dictionary = useAppSelector(selectJobDictionary)

    //SELECTORS
    const me = useAppSelector(getMeSelector)

    const isSavedChangesFields = useAppSelector(getWasEditSelector)
    // const isShowUpcomingNotification = useAppSelector(
    //   isShowUpcomingNotificationSelector,
    // )
    const permissions = useAppSelector(selectModulePermissions())

    const modulePermission = useAppSelector(
      selectUserPermissionsByModule(ModuleName.DISPATCH),
    )

    const dispatchSettings = useAppSelector(selectDispatchSettings)
    const isSoCalCompany = useAppSelector(
      state => state?.orcatec?.company?.id === 264,
    ) //id=264
    const company = useAppSelector(state => state?.orcatec?.company) //id=264
    const userCameFrom = useAppSelector(state => state.orcatec?.userTransition)
    const matrixSettings = getValueFromLS('matrixSettings')

    const [appointment, setAppointment] = useState(initialAppointment)
    const [entity, setEntity] = useState<IEntity | null>(null)
    const [loading, setLoading] = useState(false)
    const [resetPropertyForm, setResetPropertyForm] = useState(false)
    const [edited, setEdited] = useState(false)
    const [
      showNotificationUpcomingAppointmentModal,
      setShowNotificationUpcomingAppointmentModal,
    ] = useState(false)
    const [justSendNotification, setJustSendNotification] = useState(false)
    const [fullAppointment, setFullAppointment] = useState(null)
    // const [
    //   clientsForNotificationModal,
    //   setClientsForNotificationModal,
    // ] = useState([])
    const [blockedRouteParams, setBlockedRouteParams] = useState({
      blockRoutingModal: false,
      nextLocation: '',
    })
    const [showDropModal, setShowDropModal] = useState(false)
    const [isShownRfRModal, setIsShownRfRModal] = useState(false)
    const [showError, setShowError] = useState(false)
    const [checkLocation, setCheckLocation] = useState(false)
    const [isEmptyEntities, setIsEmptyEntities] = useState(false)
    const [entityData, setEntityData] = useState(null)
    const [industries, setIndustries] = useState<IIndustries[]>([])
    // const [notesEdit, setNotesEdit] = useState(false)
    const [industrieId, setIndustrieId] = useState<number | null>(null)
    const [error, setError] = useState(initialErrors)
    const [ApptDate, setApptDate] = useState(null)
    const [wasClientDeleted, setWasClientDeleted] = useState(false)
    const [entityName, setEntityName] = useState('')
    // const [openPdfModal, setOpenPdfModal] = useState(false)
    // const [appointmentType, setAppointmentType] = useState([])
    // const [technician, setTechnician] = useState([])
    const [apptHasEntity, setApptHasEntity] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    // const [daysAhead, setDaysAhead] = useState(1) // settings foroptimal routes
    const [initialEvent, setInitialEvent] = useState<IAppointment | null>(null)
    const [isActive, toggleActive] = useHashRouteToggle('#log-sheet')
    const [routesSettings, setRoutesSettings] = useState(null)
    const [addressError, setAddressError] = useState(false)
    const [assignToUpdated, setAssignToUpdated] = useState(false)
    const [isCloseClientForm, setIsCloseClientForm] = useState(false)

    const [groupModal, showGroupModal] = useState(false)
    const [revalidateGroupEvents, setRevalidateGroupEvents] = useState(0)

    const [
      recurringOptions,
      setRecurringOptions,
    ] = useState<RecurringOptions | null>(null)
    const [recurrenceModal, showRecurrenceModal] = useState(false)
    const [recurringEdited, setRecurringEdited] = useState(false)
    const [actionScope, setActionScope] = useState('')
    const [isProcessingRecurring, setIsProcessingRecurring] = useState(false)

    const openModal = () => toggleActive(true)
    //VARIABLES
    // const hasChildrenAppointments = !!appointment?.children_appointments?.length

    const { data: assignToOptions } = useEventAssignToOptions(
      (Array.isArray(appointment.date) && !appointment.is_recurring
        ? appointment.date?.[0]
        : appointment.start_date || appointment.date) || currentDate,
    )
    const { defaultQueues } = useAppSelector(selectQueueSettings)
    const eventSettings = useEventSettings()
    //EFFECTS

    useEffect(() => {
      if (assignToOptions?.length && nextTourScheduled === 'appointment') {
        startTour()
      }
    }, [assignToOptions?.length])

    useEffect(() => {
      const getRecurrenceSettings = async (parentId: number) => {
        const data = await RecurringEventsAPI.getRecurringSettings(parentId)

        setIsProcessingRecurring(!data.is_completed)
      }

      if (appointment.parent_id) getRecurrenceSettings(appointment.parent_id)
    }, [appointment.parent_id])

    const fetchData = async () => {
      setLoading(true)

      try {
        const event = await fetchAppointmentById(appointmentId)
        setAppointment({
          ...event,
          time_start:
            event.time_start || event.time_start === 0
              ? moment(moment().format('YYYY-MM-DD') + ' ' + event.time_start)
              : null,
          time_end: event.time_end
            ? moment(moment().format('YYYY-MM-DD') + ' ' + event.time_end)
            : null,
          matrix_time_end: event?.matrix_time_end
            ? moment()
                .hours(event.matrix_time_end)
                .set('minute', 0)
            : null,
          matrix_time_start:
            event?.matrix_time_start || event?.matrix_time_start === 0
              ? moment()
                  .hours(event.matrix_time_start)
                  .set('minute', 0)
              : null,
          times_needed: event?.matrix_time_end - event?.matrix_time_start || 1,
          column_template_id:
            event.column_template_id ||
            (event.queue_id ? event.queue_id.toString() : null),
        })
        setApptDate(event?.date)
        setApptHasEntity(event?.entity_id)
        setInitialEvent(event)
      } catch (error) {
        if (
          error?.status === 404 ||
          (!!error?.response && error?.response?.status === 404)
        ) {
          history.replace(`/404/event`)
        }
        setError(error?.response?.data)
      }
      setLoading(false)
    }

    useEffect(() => {
      if (
        !appointment.id &&
        assignToOptions?.length &&
        !appointment?.column_template_id?.filter(
          id => id != defaultQueues?.['app'],
        )?.length
      )
        setAppointment(prev => ({
          ...prev,
          column_template_id: defaultQueues?.['app']
            ? [defaultQueues?.['app'].toString()]
            : [],
        }))
    }, [assignToOptions])

    useEffect(() => {
      if (appointmentId) fetchData()

      return () => handleClearForm()
    }, [appointmentId])

    useEffect(() => {
      if (showDropModal && appointment?.confirm) {
        handleCreateAppointment()
      }
    }, [showDropModal, appointment?.confirm])

    useEffect(() => {
      if (eventSettings?.industry_id) setIndustrieId(eventSettings.industry_id)
    }, [eventSettings])

    useEffect(() => {
      getAllIndustries()
        .then(data => {
          setIndustries(data?.map(i => ({ id: i.id, name: i.name })))
        })
        .catch(e =>
          openNotificationWithIcon('error', {
            message: 'Error',
            description: e?.response?.statusText,
          }),
        )
    }, [])

    //METHODS
    const redirectToMatrix = async (appointment: IAppointment) => {
      await dispatch(setCurrentAppointmentId(appointment.id))
      if (appointment.date)
        await dispatch(
          getMatrixLog({ date: moment(appointment.date).format('MM/DD/YYYY') }),
        )
      if (appointment.queue_id) dispatch(getQueueEvents())

      return matrix ? null : history.push('/dispatch')
    }

    const wasClientDeletedHeandler = (val: boolean) => {
      setWasClientDeleted(val)
    }

    const handleSetEntityId = ({
      id: entity_id,
      entity_type,
    }: Pick<IAppointment, 'entity_id' | 'entity_type'>) => {
      setEdited(true)

      setAppointment(prev => ({
        ...prev,
        entity_id,
        entity_type,
      }))

      if (!!appointment.parent_id) {
        setRecurringEdited(true)
      }
    }

    const mapUpdateNeededHeandle = () => {
      dispatch(mapUpdateNeeded(true))
    }

    const handleChangeAppointment = (e: {
      target: { name: string; value: any; checked: boolean }
    }) => {
      setEdited(true)

      let { name, value, checked } = e.target

      if (error?.[name]) {
        if (name === 'time_start') {
          setError(prev => ({
            ...prev,
            time_start: null,
            time_end: null,
          }))
        }

        if (name === 'matrix_time_start') {
          setError(prev => ({
            ...prev,
            matrix_time_start: null,
            matrix_time_end: null,
          }))
        }

        setError(prev => ({
          ...prev,
          [name]: null,
        }))
      }

      if (name === 'ending_options') {
        setError(prev => ({
          ...prev,
          ['ending_options.value']: null,
        }))
      }

      if (name === 'start_date' && recurringOptions?.date) {
        setRecurringOptions(prev => ({
          ...prev,
          date: value,
        }))

        if (
          recurringOptions.ending_options.end_type === 1 &&
          moment(recurringOptions.ending_options.value).isBefore(moment(value))
        ) {
          setRecurringOptions(prev => ({
            ...prev,
            ending_options: {
              ...recurringOptions.ending_options,
              value: moment(value).format('YYYY-MM-DD'),
            },
          }))
        }
      }

      if (name === 'entity_type') value = checked ? 2 : 1
      if (name === 'time_start' && !!value && !appointment.time_end) {
        setAppointment(prev => ({
          ...prev,
          time_end: moment(value).add(+eventSettings.arrival_window, 'h'),
        }))
      }
      if (name === 'time_end' && !!value && !appointment.time_start) {
        setAppointment(prev => ({
          ...prev,
          time_start: moment(value).add(-2, 'h'),
        }))
        setError(prev => ({ ...prev, time_start: null }))
      }
      if (name === 'appointment-status') {
        setAppointment(prev => ({
          ...prev,
          status: value,
          status_logs: [
            ...prev.status_logs,
            {
              status: AppointmentStatusID[value],
              created_at: moment.utc().format('YYYY/MM/DD hh:mm A'),
            },
          ],
        }))
      }

      if (name === 'is_recurring') {
        setAppointment(prev => ({
          ...prev,
          // column_template_id: value
          //   ? defaultQueues?.['r_app']
          //     ? defaultQueues?.['r_app'].toString()
          //     : null
          //   : defaultQueues?.['app']
          //   ? [defaultQueues?.['app'].toString()]
          //   : [],
          column_template_id: [],
        }))

        if (value) showRecurrenceModal(true)
      }

      if (name === 'column_template_id') {
        if (
          appointment.is_recurring &&
          Array.isArray(appointment.column_template_id) &&
          !!appointment.column_template_id?.length &&
          value.some((id: number | string) => typeof id !== typeof value[0])
        ) {
          return openNotificationWithIcon('info', {
            message: t('notification.chooseQueueOrColumn', { ns: 'common' }),
          })
        }
      }

      if (name === 'matrix_time_start' && !!value) {
        setAppointment(prev => ({
          ...prev,
          matrix_time_end: moment(value).add(
            company.id === Companies.WestCost ? 2 : 1,
            'h',
          ),
        }))
      }

      if (name === 'monthly_type') {
        setAppointment(prev => ({
          ...prev,
          each: [],
        }))
      }

      dispatch(resetCreated())

      if (
        !appointment.id &&
        name === 'column_template_id' &&
        !assignToUpdated &&
        value.length > 1
      ) {
        value = value?.slice(1)
      }

      setAssignToUpdated(true)
      setAppointment(prev => ({ ...prev, [name]: value }))

      if (
        !!appointment.parent_id &&
        [
          'appointment_source_id',
          'appointment_type_id',
          'column_template_id',
          'industry_id',
          'preferred_technician_id',
          'timezone',
          'time_start',
          'time_end',
          'matrix_time_start',
          'matrix_time_end',
        ].includes(name)
      ) {
        setRecurringEdited(true)
      }
    }

    const heandleChangeLocation = () => {
      setCheckLocation(prev => !prev)
    }
    const changeEntityHeandler = (entity_id: number, entity_type: number) => {
      setEdited(true)

      setAppointment(prev => ({ ...prev, entity_type, entity_id }))
      setCheckLocation(prev => !prev)
    }
    const handleDuplicateAppointment = async () => {
      setLoading(true)
      await dublicateAppointment(appointment?.id)

      dispatch(getQueueEvents())

      openNotificationWithIcon('success', {
        message: t('notification.appointmentDuplicated', { ns: 'common' }),
        description: '',
        key: 'appointments-dublicate-success',
      })
      setLoading(false)
      await redirectToMatrix(appointment)
    }

    const heandleGetEntities = (entity: IEntity) => {
      if (appointment.entity_type === 1) {
        const clients = filterDeletedEntities([].concat(entity.clients ?? []))

        if (clients.length === 0) {
          return setIsEmptyEntities(true)
        }
        setIsEmptyEntities(false)
        setEntityData({ clients })
      }

      if (appointment.entity_type === 2) {
        const properties = filterDeletedEntities(
          [].concat(entity.client_properties.map(p => p.value)),
        )
        if (properties.length === 0) {
          return setIsEmptyEntities(true)
        }
        setIsEmptyEntities(false)
        setEntityData(() => ({ properties }))
      }

      if (appointment.entity_type === 3) {
        const properties = filterDeletedEntities(
          [].concat(entity.properties.map(p => p.value)),
        )
        if (properties.length === 0) {
          return setIsEmptyEntities(true)
        }
        setIsEmptyEntities(false)
        setEntityData(() => ({ properties }))
      }
    }

    const handleCreateAppointment = async () => {
      if (addressError) {
        return openNotificationWithIcon('error', {
          message: t('notification.invalidPropertyAddress', { ns: 'common' }),
        })
      }

      if (
        (appointment.is_recurring && !appointment.column_template_id) ||
        !appointment?.column_template_id?.length
      ) {
        return setError({
          column_template_id: t('notification.fieldRequired', { ns: 'common' }),
        })
      }

      setLoading(true)
      if (showDropModal) setShowDropModal(false)

      const appointmentToCreate = {
        ...appointment,
        timezone: eventSettings?.display_timezone
          ? appointment?.timezone || eventSettings?.timezone
          : null,
        time_start: makePromisedTimeStart(
          !eventSettings?.show_promise_time,
          appointment,
        ),
        time_end: makePromisedTimeEnd(
          !eventSettings?.show_promise_time,
          appointment,
        ),
        matrix_time_start:
          appointment.matrix_time_start || appointment.matrix_time_start === 0
            ? moment(appointment.matrix_time_start)
                .set('minute', 0)
                .format('HH:mm')
            : null,
        matrix_time_end:
          appointment.matrix_time_end === 0
            ? '24:00'
            : appointment.matrix_time_end
            ? moment(appointment.matrix_time_end)
                .set('minute', 0)
                .format('HH:mm')
            : null,
        current_start_time:
          appointment.matrix_time_start || appointment.matrix_time_start === 0
            ? moment(appointment.matrix_time_start).hours() +
              (dispatchSettings?.end_hour > 24 &&
              moment(appointment.matrix_time_start).hours() < 12
                ? 24
                : 0)
            : null,
        current_end_time:
          appointment.matrix_time_end === 0
            ? 24
            : appointment.matrix_time_end
            ? +moment(appointment.matrix_time_end).hours() +
              (dispatchSettings?.end_hour > 24 &&
              moment(appointment.matrix_time_end).hours() < 12
                ? 24
                : 0)
            : null,
        notes: appointment.notes,
        industry_id: appointment?.industry_id
          ? appointment?.industry_id
          : industrieId,
        date: Array.isArray(appointment.date)
          ? appointment.date.map(date => moment(date).format('YYYY-MM-DD'))
          : typeof appointment.date === 'string'
          ? appointment.date
          : null,
        primary_entity_type: appointment?.primary_entity_type
          ? appointment?.primary_entity_type
          : entity?.clients?.filter(item => !item.deleted_at)?.[0]?.type === 2
          ? AppointmentEntityType.CLIENT
          : AppointmentEntityType.ORGANIZATION,
        primary_entity_id: appointment.primary_entity_id
          ? appointment.primary_entity_id
          : entity?.clients?.filter(item => !item.deleted_at)?.[0]?.id,
        column_template_id: Array.isArray(appointment.column_template_id)
          ? appointment.column_template_id?.filter(
              value => typeof value === 'number',
            )
          : typeof appointment.column_template_id === 'number'
          ? [appointment.column_template_id]
          : [],
        queue_id: Array.isArray(appointment.column_template_id)
          ? appointment.column_template_id
              ?.filter(value => typeof value === 'string')
              ?.map((value: string) => +value)
          : typeof appointment.column_template_id === 'string'
          ? [+appointment.column_template_id]
          : [],
        ...(appointment.is_recurring
          ? { ...recurringOptions, date: [recurringOptions?.date] }
          : {}),
      }

      try {
        const {
          appointment: arrayOfCreatedAppointments,
        } = await createAppointment(appointmentToCreate)
        setEdited(false)

        // openNotificationWithIcon('success', {
        //   message: 'Appointment has been created successfully',
        // })

        /**
         * If appointment assign to matrix (schedule) need to open Notification Modal V2
         */
        mapUpdateNeededHeandle()

        if (!arrayOfCreatedAppointments.length) {
          onCloseDrawer?.()

          return setLoading(false)
        }

        const createdAppointment = await getAppointment(
          arrayOfCreatedAppointments[0]?.id,
        )
        const notificationSettings = await getNotificationSettings(
          appointment.industry_id || industrieId,
        )

        dispatch(setMatrixEvent(arrayOfCreatedAppointments))

        const isShowNotification = notificationSettings?.should_display_upcoming

        if (
          Array.isArray(appointment?.column_template_id) &&
          appointment?.column_template_id?.filter(id => typeof id === 'number')
            ?.length &&
          !appointment.confirm &&
          !appointment.is_recurring &&
          isShowNotification
        ) {
          await setFullAppointment({
            ...createdAppointment,
            date: appointmentToCreate.date,
            column_template_id: appointmentToCreate.column_template_id,
          })
          // const r = await getClientsForNotificationModal(createdAppointment?.id)
          // await setClientsForNotificationModal(r)
          await setShowNotificationUpcomingAppointmentModal(true)
          await dispatch(
            getMatrixLog({
              date: moment(createdAppointment?.date || new Date()).format(
                DATE_FORMAT,
              ),
            }),
          )
        } else {
          onCloseDrawer?.()
          redirectToMatrix(createdAppointment)
          mapUpdateNeededHeandle()
        }
      } catch (e) {
        const errors = e?.response?.data
        let message = e?.response?.data?.message

        if (e?.response?.data?.count_days) {
          setShowDropModal(true)
          message = t('notification.timeTaken', { ns: 'common' })
        }

        setError(prev => ({ ...prev, ...errors }))

        openNotificationWithIcon('error', {
          message: t('notification.oops', { ns: 'common' }),
          description:
            message || t('notification.fieldNeedsAttention', { ns: 'common' }),
        })
      }
      setLoading(false)
      dispatch(resetCreated())
    }

    const handleUpdateAppointment = async () => {
      if (
        appointment.parent_id &&
        recurringEdited &&
        !appointment.action_scope
      ) {
        setAppointment(prev => ({
          ...prev,
          action_scope: ActionScope.OnlyThisEvent,
        }))
        return setActionScope('Edit')
      }
      setLoading(true)

      const userIsChangeDate = isSameDate(ApptDate, appointment.date)
      const userIsAddedEntity = !apptHasEntity && !!appointment?.entity_id
      const userIsChangeEntity = appointment?.entity_id !== apptHasEntity
      const isTimeChanged =
        initialEvent?.time_start !==
          moment(appointment.time_start).format('HH:mm') ||
        initialEvent?.time_end !== moment(appointment.time_end).format('HH:mm')

      const notificationSettings = await getNotificationSettings(
        appointment.industry_id,
      )

      const isShowNotification = notificationSettings?.should_display_upcoming

      const isNeedToShowNotification =
        typeof appointment.column_template_id === 'number' &&
        isShowNotification &&
        (userIsChangeEntity ||
          userIsAddedEntity ||
          !userIsChangeDate ||
          isTimeChanged) &&
        appointment.status === 1

      if (isSavedChangesFields) {
        return setShowError(true)
      }
      setShowError(false)

      try {
        const apptDataForUpdate = {
          ...appointment,
          timezone: eventSettings?.display_timezone
            ? appointment?.timezone || eventSettings?.timezone
            : null,
          time_start: makePromisedTimeStart(
            !eventSettings?.show_promise_time,
            appointment,
          ),
          time_end: makePromisedTimeEnd(
            !eventSettings?.show_promise_time,
            appointment,
          ),
          matrix_time_start: appointment.matrix_time_start
            ? moment(appointment.matrix_time_start).format('HH:mm')
            : null,
          matrix_time_end: appointment.matrix_time_end
            ? moment(appointment.matrix_time_end).format('HH:mm')
            : null,
          current_start_time:
            appointment.matrix_time_start || appointment.matrix_time_start === 0
              ? moment(appointment.matrix_time_start).hours() +
                (dispatchSettings?.end_hour > 24 &&
                moment(appointment.matrix_time_start).hours() < 12
                  ? 24
                  : 0)
              : null,
          current_end_time:
            appointment.matrix_time_end === 0
              ? 24
              : appointment.matrix_time_end
              ? +moment(appointment.matrix_time_end).hours() +
                (dispatchSettings?.end_hour > 24 &&
                moment(appointment.matrix_time_end).hours() < 12
                  ? 24
                  : 0)
              : null,
          notes: null,
          industry_id: appointment?.industry_id
            ? appointment?.industry_id
            : industrieId,
          column_template_id:
            typeof appointment.column_template_id === 'number'
              ? appointment.column_template_id
              : null,
          queue_id:
            typeof appointment.column_template_id === 'string'
              ? +appointment.column_template_id
              : null,
          date: appointment.date
            ? moment(appointment.date).format('YYYY-MM-DD')
            : null,
        }

        const updatedAppointment = await updateAppoinment(
          appointment.id,
          apptDataForUpdate,
        )
        setAppointment(prev => ({ ...updatedAppointment, ...prev }))
        setEdited(false)

        // openNotificationWithIcon('success', {
        //   message: 'Appointment has been updated successfully',
        //   description: '',
        //   key: 'appointment-update',
        // })

        expandQueue('queue')

        if (drawerView) {
          dispatch(setMatrixEvent([updatedAppointment]))
        }

        dispatch(setCurrentAppointmentId(updatedAppointment?.id))

        if (isNeedToShowNotification) {
          await setFullAppointment({
            ...updatedAppointment,
            column_template_id: updatedAppointment?.column_template_id
              ? [updatedAppointment?.column_template_id]
              : null,
            date: updatedAppointment.date ? [updatedAppointment.date] : null,
          })
          // const r = await getClientsForNotificationModal(updatedAppointment?.id)
          // await setClientsForNotificationModal(r)
          await setShowNotificationUpcomingAppointmentModal(true)
          // await dispatch(getMatrixLog({ date: moment(appointment?.date ?? new Date()).format('MM/DD/YYYY') }))
        } else {
          onCloseDrawer?.()
          mapUpdateNeededHeandle()
          await redirectToMatrix(updatedAppointment)
        }

        return updatedAppointment
      } catch (error) {
        const asyncErrors = error?.response?.data?.errors

        setShowNotificationUpcomingAppointmentModal(false)

        if (asyncErrors?.time) {
          setError(prev => ({
            ...prev,
            matrix_time_end: asyncErrors?.time?.[0],
            matrix_time_start: asyncErrors?.time?.[0],
          }))
        } else {
          setError(error?.response?.data)
        }

        openNotificationWithIcon('error', {
          message: t('notification.oops', { ns: 'common' }),
          description: Object.values(
            error.response?.data?.message ||
              t('notification.fieldNeedsAttention', { ns: 'common' }),
          ),
          key: 'appointment-error',
        })

        // throw new Error(error)
        return error
      } finally {
        setLoading(false)
        dispatch(resetCreated())
        if (actionScope) setActionScope('')
      }
    }

    const handleDeleteAppointment = async () => {
      // if (hasChildrenAppointments)
      //   return openNotificationWithIcon('error', {
      //     message: 'Appointment has children',
      //     description: 'Delete child appointments before deleting parent',
      //   })
      if (appointment.parent_id && !appointment.action_scope) {
        setAppointment(prev => ({
          ...prev,
          action_scope: ActionScope.OnlyThisEvent,
        }))
        return setActionScope('Delete')
      }

      setLoading(true)

      await deleteAppointment(
        appointment.id,
        appointment?.action_scope
          ? { action_scope: appointment.action_scope }
          : undefined,
      )

      setEdited(false)

      dispatch(eventDeleted(appointment.id))

      openNotificationWithIcon('success', {
        message: t('notification.appointmentDeleted'),
        description: '',
        key: 'appointments-dublicate-success',
      })
      setLoading(false)
      onCloseDrawer?.()
    }

    const handleCancel = () => {
      history.goBack()
    }

    const handleClearForm = () => {
      setAppointment(initialAppointment)
      setResetPropertyForm(true)
      setEntityName('')
      setEntity(null)
      setTimeout(() => {
        setResetPropertyForm(false)
      }, 1000)
    }

    const handleBlockedRoute = (nextLocation: string) => {
      setBlockedRouteParams({
        blockRoutingModal: true,
        nextLocation: nextLocation,
      })
      return false
    }

    const handleChangeRfRStatus = (status: any) => {
      setAppointment(p => ({ ...p, review_status: status }))
    }

    const redirectToMatrixPage = () => {
      history.push('/dispatch')
    }

    // const getClientsForRfRModal = () => {
    //   getClientsForNotificationModal(appointment?.id)
    //     .then(r => setClientsForNotificationModal(r))
    //     .catch(e => console.error(e))
    // }

    const handleClickRfRModal = () => {
      setIsShownRfRModal(true)
      // getClientsForRfRModal()
    }

    const handleChangePrimaryClient = (id: number) => {
      const choosenClient = [...entity?.clients]?.find(item => item.id === id)

      setAppointment(prev => ({
        ...prev,
        primary_entity_id: id,
        primary_entity_type:
          choosenClient?.type === 2
            ? AppointmentEntityType.CLIENT
            : AppointmentEntityType.ORGANIZATION,
      }))

      // setEdited(true)
    }

    const heandleClearError = () => {
      setShowError(false)
    }

    const updateAndDupicateAppointment = async () => {
      if (isSavedChangesFields) {
        return setShowError(true)
      }
      setShowError(false)
      setLoading(true)

      try {
        const apptDataForUpdate = {
          ...appointment,
          time_start: makePromisedTimeStart(
            !eventSettings?.show_promise_time,
            appointment,
          ),
          time_end: makePromisedTimeEnd(
            !eventSettings?.show_promise_time,
            appointment,
          ),
          matrix_time_start: appointment.matrix_time_start
            ? moment(appointment.matrix_time_start).format('HH:mm')
            : null,
          matrix_time_end: appointment.matrix_time_end
            ? moment(appointment.matrix_time_end).format('HH:mm')
            : null,
          notes: null,
          industry_id: appointment?.industry_id
            ? appointment?.industry_id
            : industrieId,
        }
        const updatedAppointment = await updateAppoinment(
          appointment.id,
          apptDataForUpdate,
        )
        setAppointment(prev => ({ ...updatedAppointment, ...prev }))
        setEdited(false)

        expandQueue('queue')
        dispatch(setCurrentAppointmentId(updatedAppointment?.id))
        await dublicateAppointment(appointment?.id)

        openNotificationWithIcon('success', {
          message: t('notification.appointmentSavedAndDuplicated'),
          description: '',
          key: 'appointments-dublicate-success',
        })
        setLoading(false)
        await redirectToMatrix(appointment)
      } catch (error) {
        const asyncErrors = error?.response?.data?.errors
        if (asyncErrors?.time) {
          setError(prev => ({
            ...prev,
            matrix_time_end: asyncErrors?.time?.[0],
            matrix_time_start: asyncErrors?.time?.[0],
          }))
        }
        openNotificationWithIcon('error', {
          message: t('notification.error', { ns: 'common' }),
          description: Object.values(
            error.response?.data?.errors ||
              t('notification.somethingWentWrong', { ns: 'common' }),
          ),
          key: 'appointment-error',
        })
      } finally {
        setLoading(false)
      }
    }

    const heandleDeleteEntity = async () => {
      try {
        if (appointment.entity_type === AppointmentEntityType.PROPERTY) {
          if (!appointment.id) {
            setEntityName('')
            setAppointment(prev => ({
              ...prev,
              entity_id: 0,
              primary_entity_id: null,
              entity_type: AppointmentEntityType.PROPERTY,
            }))

            setResetPropertyForm(true)
            setEntity(null)
            setTimeout(() => {
              setResetPropertyForm(false)
            }, 1000)

            return
          }

          await detachEntityFromAppointment(appointment.id, {
            property_id: appointment.entity_id,
          })
        }
        if (
          appointment.entity_type === AppointmentEntityType.CLIENT ||
          appointment.entity_type === AppointmentEntityType.ORGANIZATION
        ) {
          if (appointment.id) {
            await detachEntityFromAppointment(appointment.id, {
              contact_id: appointment.entity_id,
            })
          }
        }
        setResetPropertyForm(true)
        setEntityName('')
        setIsCloseClientForm(true)
        setAppointment(prev => ({
          ...prev,
          entity_id: 0,
          entity_type:
            appointment.entity_type === AppointmentEntityType.PROPERTY
              ? AppointmentEntityType.PROPERTY
              : AppointmentEntityType.CLIENT,
        }))

        setEntity(null)
        setTimeout(() => {
          setResetPropertyForm(false)
        }, 1000)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: t('notification.somethingWentWrong', { ns: 'common' }),
        })
      }
    }

    useEffect(() => {
      const fetchRoutesSettings = async () => {
        try {
          const settings = await getRoutesSettings()
          setRoutesSettings(prev => ({ ...prev, ...settings }))
        } catch (error) {
          console.error(error)
        }
      }

      fetchRoutesSettings()
    }, [])

    useEffect(() => {
      if (entity?.clients && !appointment?.primary_entity_id) {
        handleChangePrimaryClient(entity?.clients?.[0]?.id)
      }
      //  else {
      //   setAppointment(prev => ({
      //     ...prev,
      //     primary_entity_id: null,
      //   }))
      // }
    }, [entity?.clients?.length])

    const getContentToPrint = useCallback(() => {
      return ref?.current
    }, [ref?.current])

    const handlePrint = useReactToPrint({
      content: getContentToPrint,
      pageStyle: IndividualPrintStyle,
    })

    const handleAddressError = (error: boolean) => {
      setAddressError(error)
    }

    const handleCrateNextRecurringChild = async () => {
      setLoading(true)

      const child = await createAppointmentChild({
        parent_id: appointment?.id,
      })

      setLoading(false)

      if (!child?.id)
        return openNotificationWithIcon('error', {
          message: t('notification.noChildAppointmentsLeft'),
        })

      setAppointment(prev => ({
        ...prev,
        children_appointments: [
          ...(appointment?.children_appointments || []),
          child,
        ],
      }))
    }

    const handleGroupModalOpen = async () => {
      showGroupModal(true)
    }

    const handleRecurrenceModalOpen = async () => {
      showRecurrenceModal(true)
    }

    const handleGroupDelete = () => {
      showGroupModal(false)

      if (!onCloseDrawer) return history.push('/dispatch')

      onCloseDrawer?.()
    }

    const handleGroupSave = () => {
      showGroupModal(false)

      setTimeout(() => {
        setRevalidateGroupEvents(prev => prev + 1)
      }, 3000)

      if (!onCloseDrawer) return history.push('/dispatch')

      onCloseDrawer?.()
    }

    const handleNotificationSend = async (groupId?: number) => {
      if (justSendNotification) return

      setJustSendNotification(true)

      let event = {
        ...appointment,
        column_template_id: [appointment.column_template_id],
        date: appointment.date ? [appointment.date] : null,
      }

      if (groupId) {
        const group = await EventGroupAPI.getEventGroup(groupId)

        event = {
          ...appointment,
          ...group,
          isGroup: true,
        }
      }

      setFullAppointment({
        ...event,
        matrix_time_start: moment(event.current_start_time, 'HH').format(
          'HH:mm',
        ),
        matrix_time_end: moment(event.current_end_time, 'HH').format('HH:mm'),
        industry_id: event.industry_id,
        // wo_proposal_id: event.wo_proposal_id,
        // proposal_id: event.proposal_id,
      })

      setShowNotificationUpcomingAppointmentModal(true)
      setJustSendNotification(true)
    }

    const handleNotificationSubmited = () => {
      if (!justSendNotification) onCloseDrawer?.()
      mapUpdateNeededHeandle()

      !matrix && redirectToMatrixPage()

      setShowNotificationUpcomingAppointmentModal(false)
      setJustSendNotification(false)
    }

    const handleNotificationModalClose = () => {
      if (!appointment.id) {
        return handleNotificationSubmited()
      }

      if (!justSendNotification) onCloseDrawer?.()
      setShowNotificationUpcomingAppointmentModal(false)
      setJustSendNotification(false)
    }

    const handleRecurrenceSave = (data?: RecurringOptions) => {
      if (data) {
        setRecurringOptions(data)
        setAppointment(prev => ({
          ...prev,
          start_date: data.date,
        }))
      }

      showRecurrenceModal(false)
    }

    return (
      <CustomDrawer
        withDrawer={drawerView}
        destroyOnClose={true}
        extra={
          <HeaderWrapper className='button-wrapper'>
            {appointment?.id && (
              <MainButton
                title={t('button.print', { ns: 'common' })}
                onClick={handlePrint}
              />
            )}
            {!!appointment?.id && !!permissions?.reviews && (
              <MainButton
                type='cancel'
                onClick={handleClickRfRModal}
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    <p>
                      <ReviewStatus status={appointment?.review_status} />
                    </p>
                    <p style={{ paddingTop: '1px' }}>
                      {t('heading.requestForReview')}
                    </p>
                  </div>
                }
              />
            )}
            <AccessControl
              additionalAccess={!!appointment?.id}
              scopes={[DispatchPermission.DISPATCH_CAN_DUPLICATE_EVENT]}
            >
              {!appointment?.is_recurring &&
                (edited ? (
                  <Popconfirm
                    title={t('prompt.appointmentChanged')}
                    onConfirm={updateAndDupicateAppointment}
                    okText={t('heading.yes', { ns: 'common' })}
                    cancelText={t('heading.no', { ns: 'common' })}
                    onCancel={handleDuplicateAppointment}
                  >
                    <MainButton
                      title={t('heading.duplicate', { ns: 'common' })}
                      // onClick={handleDuplicateAppointment}
                      className='button-custom-secondary'
                    />
                  </Popconfirm>
                ) : (
                  <MainButton
                    title={t('heading.duplicate', { ns: 'common' })}
                    onClick={handleDuplicateAppointment}
                    className='button-custom-secondary'
                  />
                ))}
            </AccessControl>
            {isSoCalCompany && appointment?.id && (
              <MainButton
                title={t('heading.foremanLogSheet')}
                onClick={openModal}
                disabled={!appointment?.id}
              />
            )}

            <AccessControl
              scopes={[DispatchPermission.DISPATCH_CAN_DELETE_EVENT]}
            >
              {appointment?.id ? (
                <Popconfirm
                  title={t('prompt.deleteAppointmentConfirm')}
                  onConfirm={handleDeleteAppointment}
                  okText={t('heading.yes', { ns: 'common' })}
                  cancelText={t('heading.no', { ns: 'common' })}
                >
                  <MainButton
                    type='delete'
                    title={t('button.delete', { ns: 'common' })}
                  />
                </Popconfirm>
              ) : null}
            </AccessControl>

            <AccessControl
              scopes={[DispatchPermission.DISPATCH_CAN_EDIT_EVENT]}
            >
              <MainButton
                title={
                  appointment?.id
                    ? t('button.save', { ns: 'common' })
                    : t('button.create', { ns: 'common' })
                }
                onClick={
                  appointment?.id
                    ? handleUpdateAppointment
                    : handleCreateAppointment
                }
                isFetching={loading}
              />
            </AccessControl>
          </HeaderWrapper>
        }
        mask={false}
        placement='right'
        closeIcon={
          edited ? (
            <div onClick={() => setShowConfirmationModal(true)}>
              <CloseOutlined />
            </div>
          ) : (
            // </Popconfirm>
            <div
              onClick={() => {
                onCloseDrawer()
                dispatch(resetCreated())
                matrixSettings?.pageView !== 2 &&
                  dispatch(
                    getMatrixLog({
                      date: moment(currentDate || new Date()).format(
                        'MM/DD/YYYY',
                      ),
                    }),
                  )
              }}
            >
              <CloseOutlined />
            </div>
          )
        }
        visible={visibleDrawer}
        headerStyle={{ padding: 0 }}
        width={850}
        style={{ zIndex: 1000 }}
      >
        <Wrapper ref={ref}>
          <div
            style={
              !appointment?.entity_id
                ? { marginBottom: '250px' }
                : { marginBottom: '0' }
            }
            className='create-appointment-form'
          >
            <>
              <PlanLimits entity='events' />

              <div className='create-appointment-form__appoitment-section'>
                {
                  <ErrorBoundary>
                    <AppointmentSection
                      industries={industries}
                      assignToOptions={assignToOptions.filter(option =>
                        appointment.id &&
                        appointment.parent_id &&
                        typeof appointment.column_template_id !== 'string'
                          ? option.source === 'Columns'
                          : option,
                      )}
                      data={appointment}
                      loading={loading}
                      onChange={handleChangeAppointment}
                      industrieId={industrieId}
                      error={error}
                      me={me}
                      setEdited={setEdited}
                      onCreateNexRecurringtChild={handleCrateNextRecurringChild}
                      onStatusUpdated={() => {
                        setRevalidateGroupEvents(prev => prev + 1)

                        mapUpdateNeededHeandle()
                      }}
                      eventSettings={eventSettings}
                      onNotificationSend={handleNotificationSend}
                      recurringOptions={recurringOptions}
                      onOpenRecurringOptions={handleRecurrenceModalOpen}
                    />
                  </ErrorBoundary>
                }

                {!!appointment.group_id && (
                  <div style={{ margin: '1rem 0' }}>
                    <GroupEvents
                      eventType={'appointment'}
                      groupId={appointment.group_id}
                      currentEventId={appointment.id}
                      onEditGroup={handleGroupModalOpen}
                      onClick={event => {
                        if (!onGroupEventClick)
                          return window.open(
                            `/appointment/${event.id}`,
                            '_blank',
                            'noreferrer',
                          )

                        onGroupEventClick?.({
                          ...event,
                          kind: EventKind.APPOINTMENT,
                        })
                      }}
                      onSendGroupNotification={() => {
                        if (!appointment.group_id) return

                        handleNotificationSend(appointment.group_id)
                      }}
                      revalidate={revalidateGroupEvents}
                    />
                  </div>
                )}

                {!!appointment.parent_id && (
                  <div style={{ margin: '1rem 0' }}>
                    <RecurringEvents
                      eventType={'appointment'}
                      groupId={appointment.parent_id}
                      currentEventId={appointment.id}
                      onEditRecurrence={handleRecurrenceModalOpen}
                      onClick={event => {
                        if (!onGroupEventClick)
                          return window.open(
                            `/appointment/${event.id}`,
                            '_blank',
                            'noreferrer',
                          )

                        onGroupEventClick?.({
                          ...event,
                          kind: EventKind.APPOINTMENT,
                        })
                      }}
                      isGenerating={isProcessingRecurring}
                      //                       onSendGroupNotification={() => {
                      //                         if (!appointment.group_id) return
                      //
                      //                         handleNotificationSend(appointment.group_id)
                      //                       }}
                      // revalidate={revalidateGroupEvents}
                    />
                  </div>
                )}

                {appointment?.id && !appointment.is_recurring && (
                  <div
                    style={{
                      margin: '20px 0 30px',
                    }}
                  >
                    <ErrorBoundary>
                      <FormsBlock
                        entityId={appointment?.id}
                        entityType={CustomFormEntityType.APPOINTMENT}
                        id={appointment?.id}
                        createRelatedForm={createRelatedFormToJob}
                        getRelatedForm={getRelatedFormToJob}
                        onAttachTemplates={attachRelatedFormsToEvent}
                        // module={ModuleName.DISPATCH}
                        // getAttachedTemplate={getAttachedTemplatesToProject}
                        // getCount={getCountFormsJob}
                        // title='Appointment'
                        // hideTemplates
                      />
                    </ErrorBoundary>
                  </div>
                )}

                {(window.location.pathname === '/dispatch' ||
                  window.location.pathname === '/map') &&
                  routesSettings?.is_enabled && (
                    <AppointmentPreferredTech
                      onChange={setAppointment}
                      property={entity ? { ...entity } : null}
                      appointment={appointment}
                      settings={routesSettings}
                    />
                  )}

                {!!entity && [...entity?.clients]?.length > 1 && (
                  <SelectField
                    label={t('heading.primaryClient')}
                    options={[
                      ...entity?.clients?.filter(item => !item.deleted_at),
                    ]}
                    value={
                      appointment.primary_entity_id ||
                      entity?.clients?.find(
                        client => client.name === appointment?.client_name,
                      )?.id ||
                      entity?.clients?.filter(client => !client.deleted_at)?.[0]
                    }
                    name='primary_entity_id'
                    onChange={e => handleChangePrimaryClient(e.target.value)}
                  />
                )}
              </div>

              {!wasClientDeleted && (
                <p className='appt-property__title'>
                  {t('heading.appointment')}{' '}
                  {appointment?.entity_type === 1 ? (
                    entityName ? (
                      <span>
                        {t('heading.for', { ns: 'common' })} {entityName}
                      </span>
                    ) : (
                      t('heading.property', { ns: 'common' })
                    )
                  ) : entityName ? (
                    <span>
                      {t('heading.for', { ns: 'common' })} {entityName}
                    </span>
                  ) : (
                    t('heading.client', { ns: 'common' })
                  )}
                </p>
              )}
            </>

            {!appointment?.entity_id && (
              <div data-testid='appointment-use-office-location'>
                <Checkbox
                  name='entity_type'
                  onChange={handleChangeAppointment}
                  checked={appointment.entity_type !== 1}
                  disabled={!!appointment?.entity_id}
                >
                  {t('prompt.useOfficeLocation')}
                </Checkbox>
              </div>
            )}

            <div className='office-location__wrapper'>
              {!!appointment?.entity_id && !wasClientDeleted && (
                <AppointmentLocation
                  entities={entityData}
                  isEmptyEntities={isEmptyEntities || !entityData}
                  entityType={appointment.entity_type}
                  changeEntityHeandler={changeEntityHeandler}
                  checkLocation={checkLocation}
                  heandleChangeLocation={heandleChangeLocation}
                />
              )}
              {!!appointment?.entity_id && (
                <Tooltip
                  placement='left'
                  title={`${t('heading.detach', { ns: 'common' })} ${
                    appointment?.entity_type === 1
                      ? t('heading.property', { ns: 'common' })
                      : t('heading.contact', { ns: 'common' })
                  }`}
                >
                  <Popconfirm
                    title={`${t('prompt.doYouWantTo', { ns: 'common' })} ${
                      appointment?.entity_type === 1
                        ? t('heading.property', { ns: 'common' })
                        : t('heading.contact', { ns: 'common' })
                    }?`}
                    onConfirm={() => heandleDeleteEntity()}
                    okText={t('heading.detach', { ns: 'common' })}
                    cancelText={t('button.cancel', { ns: 'common' })}
                  >
                    <div className='office-location__icon'>
                      {/* <DeleteIcon /> */}
                      <MainButton
                        // type='delete'
                        className='button-custom-secondary'
                        title={`${t('heading.detach', { ns: 'common' })} ${
                          appointment?.entity_type === 1
                            ? t('heading.property', { ns: 'common' })
                            : t('heading.contact', { ns: 'common' })
                        }`}
                      />
                    </div>
                  </Popconfirm>
                </Tooltip>
              )}
            </div>

            <div
              className='create-appointment-form__entity-section'
              data-testid='appointment-location'
            >
              {appointment.entity_type === 1 ? (
                <PropertyForm
                  isAppointment
                  propertyId={appointment?.entity_id}
                  setEntityId={handleSetEntityId}
                  resetFromParent={resetPropertyForm}
                  setEntity={setEntity}
                  heandleGetEntities={heandleGetEntities}
                  setEntityName={setEntityName}
                  withModal={true}
                  onAddressError={handleAddressError}
                />
              ) : (
                <ClientForm
                  clientId={appointment?.entity_id}
                  setEntityId={handleSetEntityId}
                  isOrganization={appointment.entity_type === 3}
                  heandleGetEntities={heandleGetEntities}
                  wasClientDeletedHeandler={wasClientDeletedHeandler}
                  setEntityName={setEntityName}
                  setEntity={setEntity}
                  isAppointment
                  reloadOnCancel
                  withoutClose
                  withModal={true}
                  isCloseClientForm={isCloseClientForm}
                  setIsCloseClientForm={setIsCloseClientForm}
                />
              )}
            </div>
            {!appointment?.id && !drawerView && (
              <div className='create-appointment-form__buttons_fix new'>
                <MainButton
                  type='cancel'
                  title={t('button.cancel', { ns: 'common' })}
                  onClick={handleCancel}
                />
                <MainButton
                  type='cancel'
                  title={t('button.clearForm', { ns: 'common' })}
                  onClick={handleClearForm}
                  className='button-custom-secondary'
                />
                <MainButton
                  title={t('heading.createAppointment')}
                  // disabled={!appointment?.entity_id}
                  onClick={handleCreateAppointment}
                  isFetching={loading}
                />
              </div>
            )}
            {!drawerView && !!appointmentId && (
              <div className='create-appointment-form__buttons_fix'>
                <MainButton
                  type='cancel'
                  title={t('button.goBack', { ns: 'common' })}
                  onClick={() => {
                    history.push(
                      userCameFrom?.difference
                        ? userCameFrom.from
                        : '/dispatch',
                    )
                  }}
                />

                <Popconfirm
                  title={t('prompt.deleteAppointmentConfirm')}
                  onConfirm={handleDeleteAppointment}
                  okText={t('heading.yes', { ns: 'common' })}
                  cancelText={t('heading.no', { ns: 'common' })}
                >
                  <MainButton
                    type='delete'
                    title={t('heading.deleteAppointment')}
                  />
                </Popconfirm>

                {!appointment.is_recurring && (
                  <MainButton
                    title={t('heading.duplicateAppointment')}
                    onClick={handleDuplicateAppointment}
                    className='button-custom-secondary'
                  />
                )}
                {!!permissions?.reviews && (
                  <MainButton
                    type='cancel'
                    onClick={handleClickRfRModal}
                    title={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '3px',
                        }}
                      >
                        <p>
                          <ReviewStatus status={appointment?.review_status} />
                        </p>
                        <p style={{ paddingTop: '1px' }}>
                          {t('heading.requestForReview')}
                        </p>
                      </div>
                    }
                  />
                )}

                {!isSavedChangesFields && edited && (
                  <MainButton
                    title={t('heading.updateAppointment')}
                    onClick={handleUpdateAppointment}
                    className='update'
                  />
                )}
              </div>
            )}

            <Modal
              title={t('notification.oops', { ns: 'common' })}
              visible={showDropModal}
              onOk={() => {
                setAppointment(prev => ({ ...prev, confirm: true }))
              }}
              okText={t('heading.proceed', { ns: 'common' })}
              onCancel={() => setShowDropModal(false)}
            >
              <h6>{t('notification.timeSlotConflicts')}: </h6>

              {error?.count_days && (
                <div
                  style={{
                    whiteSpace: 'pre',
                    marginBottom: 10,
                  }}
                >
                  {Object.entries(error?.count_days)?.map(([id]) => {
                    return (
                      <p key={id} style={{ fontWeight: 'bold' }}>
                        {assignToOptions?.find(column => column.id == id)?.name}
                      </p>
                    )
                  })}
                </div>
              )}

              <p>{t('prompt.putConflictAppointmentInQueue')}</p>
            </Modal>

            {loading && <LoadingScreen />}

            {!matrix && (
              <>
                {' '}
                <BlockRoutingModal
                  status={!!blockedRouteParams.blockRoutingModal}
                  handleCancel={() =>
                    setBlockedRouteParams({
                      blockRoutingModal: false,
                      nextLocation: '',
                    })
                  }
                  handleDiscard={() => {
                    history.push(blockedRouteParams.nextLocation)
                  }}
                  handleSave={
                    appointment.id
                      ? handleUpdateAppointment
                      : handleCreateAppointment
                  }
                  title={t('prompt.haveUnsavedChanges', { ns: 'common' })}
                  buttonsTitle={{
                    save: t('button.saveAndContinue', { ns: 'common' }),
                    discard: t('button.discardAndContinue', { ns: 'common' }),
                    cancel: t('button.cancel', { ns: 'common' }),
                  }}
                  className='appt-routing-modal'
                  // isLoading={isButtonsDisabled}
                />
                <Prompt
                  when={edited && !blockedRouteParams.blockRoutingModal}
                  message={handleBlockedRoute}
                />
              </>
            )}

            {showNotificationUpcomingAppointmentModal && (
              <NotificationModalV2
                title={t('heading.upcomingAppointmentNotification')}
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.UPCOMING}
                industryId={fullAppointment?.industry_id}
                entity='appointments'
                entityId={fullAppointment?.id}
                onSubmit={handleNotificationSubmited}
                onClose={handleNotificationModalClose}
                techs={assignToOptions
                  .filter(
                    column =>
                      fullAppointment?.column_template_id?.includes(
                        column.id,
                      ) && !!column.main_tech_id,
                  )
                  .map(column => ({
                    id: column.main_tech_id,
                    name: column.main_tech_name,
                    emails: [column.main_tech_email],
                    phones: [column.main_tech_phone],
                  }))}
                event={fullAppointment}
              />
            )}

            {/* {showNotificationUpcomingAppointmentModal && (
              <NotificationModal
                title={`Upcoming ${
                  appointment.kind === EventKind.JOB
                    ? dictionary.singular
                    : 'Appointment'
                } Notification`}
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.UPCOMING}
                showModal={showNotificationUpcomingAppointmentModal}
                setShowModal={setShowNotificationUpcomingAppointmentModal}
                // clients={clientsForNotificationModal}
                appointment={appointment?.id ? appointment : fullAppointment}
                customSubmit
                customSubmitFunc={() => {
                  onCloseDrawer?.()
                  mapUpdateNeededHeandle()

                  !matrix && redirectToMatrixPage()
                }}
              />
            )} */}

            {isShownRfRModal && (
              <NotificationModal
                title={NotificationTitleType.REQUEST_FOR_REVIEW}
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.REQUEST_FOR_REVIEW}
                showModal={isShownRfRModal}
                setShowModal={setIsShownRfRModal}
                // clients={clientsForNotificationModal}
                appointment={appointment?.id ? appointment : fullAppointment}
                changeStatus={handleChangeRfRStatus}
                customSubmit
                // customSubmitFunc={() => {
                //   onCloseDrawer?.()
                //   mapUpdateNeededHeandle()

                //   !matrix && redirectToMatrixPage()
                // }}
              />
            )}

            {!!actionScope && (
              <ActionScopeModal
                actionScope={actionScope as 'Delete' | 'Edit'}
                value={appointment.action_scope || 1}
                loading={loading}
                onCancel={() => {
                  setActionScope('')
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { action_scope, ...rest } = appointment
                  setAppointment(rest)
                }}
                onSave={
                  actionScope === 'Edit'
                    ? handleUpdateAppointment
                    : handleDeleteAppointment
                }
                onChange={value =>
                  setAppointment(prev => ({
                    ...prev,
                    action_scope: value,
                  }))
                }
              />
            )}

            {/* <Modal
                open
                title='Edit recurring event'
                onOk={handleUpdateAppointment}
                onCancel={() => {
                  showUpdateScopeModal(false)
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { action_scope, ...rest } = appointment
                  setAppointment(rest)
                }}
                okButtonProps={{ loading }}
                centered
              >
                <Spin spinning={loading}>
                  <Radio.Group
                    onChange={e =>
                      setAppointment(prev => ({
                        ...prev,
                        action_scope: e.target.value,
                      }))
                    }
                    value={appointment?.action_scope}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }}
                    >
                      <Radio value={1}>This event</Radio>
                      <Radio value={2}>This and following events</Radio>
                      <Radio value={3}>All events</Radio>
                    </div>
                  </Radio.Group>
                </Spin>
              </Modal> 
            )}*/}

            {isSavedChangesFields &&
              showError &&
              openNotificationWithIcon(
                'error',
                { message: t('notification.shouldSaveProperty') },
                15,
                heandleClearError,
              )}
          </div>

          {!!drawerView && (
            <AppointmentPrintPDF
              appointment={appointment}
              openModal={isActive}
              onCloseModal={() => toggleActive(false)}
              // onCloseModal={() => setOpenPdfModal(false)}
              entity={entity}
              // appointmentType={appointmentType}
              entityData={entityData}
              // technician={technician}
              entityName={entityName}
              // columns={columns}
              column={columns?.find(
                column =>
                  column.column_template_id === appointment.column_template_id,
              )}
            />
          )}

          <ConfirmationModal
            visible={showConfirmationModal}
            onSave={
              !!modulePermission[DispatchPermission.DISPATCH_CAN_EDIT_EVENT]
                ? () =>
                    appointment?.id
                      ? handleUpdateAppointment().then(() => {
                          mapUpdateNeededHeandle()
                          onCloseDrawer()
                          dispatch(resetCreated())
                        })
                      : handleCreateAppointment()
                : null
            }
            onCancel={() => {
              onCloseDrawer()
              dispatch(resetCreated())
              matrixSettings?.pageView !== 2 &&
                dispatch(
                  getMatrixLog(
                    moment(currentDate || new Date()).format('MM/DD/YYYY'),
                  ),
                )
            }}
            onClose={() => {
              setShowConfirmationModal(false)
              dispatch(resetCreated())
            }}
          />

          {groupModal && appointment.group_id && (
            <GroupModal
              eventSettings={eventSettings}
              groupId={appointment.group_id}
              eventKind={appointment.kind}
              onClose={() => showGroupModal(false)}
              onGroupDelete={handleGroupDelete}
              onSave={handleGroupSave}
            />
          )}

          {recurrenceModal && (
            <RecurringModal
              data={recurringOptions}
              parentId={appointment.parent_id}
              onSave={handleRecurrenceSave}
              onClose={() => {
                if (!recurringOptions) {
                  setAppointment(prev => ({
                    ...prev,
                    is_recurring: false,
                  }))
                }
                showRecurrenceModal(false)
              }}
              error={error}
            />
          )}

          {tourIsActive && (
            <UserGuide
              steps={guideStepsByModule['appointment'].steps}
              onCallback={handleTourCallback}
              isActive={tourIsActive}
              stepIndex={currentStep}
              disableScrolling
            />
          )}
        </Wrapper>
      </CustomDrawer>
    )
  },
)

AppointmentForm.displayName = 'AppointmentForm'
