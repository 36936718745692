import { useState, useEffect, FC, ChangeEvent } from 'react'
import { Modal, Spin, Checkbox } from 'antd'
import styled from 'styled-components'
import { InputField } from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'
import { generateNextProjectCode } from 'api/Project'
import { useAppSelector } from 'store/Orcatec/hooks'
import { meSelector } from 'store/SuperAdmin/selectors'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { SettingsRow } from 'components/UI/SettingsRow/SettingsRow'
import { EditableBlock } from 'containers/MainContent/Orcatec/components/UI/EditableBlock/EditableBlock'
import { Info } from '@material-ui/icons'

import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { useHistory } from 'react-router-dom'

interface IProps {
  visible: boolean
  proposalID: number
  onClose: () => void
  handleDuplicate: (proposalId: number, proposalCode: string) => void
  proposalCode: string
  assignedTo: string
}

export const ModalDuplicateProject: FC<IProps> = ({
  visible,
  onClose,
  handleDuplicate,
  proposalID,
  proposalCode,
  assignedTo,
}) => {
  const history = useHistory()

  const me = useAppSelector(meSelector)

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({ code: '' })
  const [error, setError] = useState({ code: '' })
  const [openProject, setOpenProject] = useState(false)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState(prev => ({ ...prev, [name]: value }))
    setError({ code: '' })
  }

  const validateInput = () => {
    if (!state?.code?.trim()) {
      return {
        valid: false,
        error: 'Project ID is required',
      }
    }
    if (state?.code?.trim() === proposalCode) {
      return {
        valid: false,
        error: 'Project ID can not be the same.',
      }
    }
    return {
      valid: true,
      error: '',
    }
  }

  const onDuplicate = async () => {
    const { valid, error } = validateInput()
    if (!valid) return setError({ code: error })

    setLoading(true)
    try {
      const res = await handleDuplicate(proposalID, state.code)

      if (openProject) {
        window.open(`/projects/${res.proposalId}`, '_blank')
      } else {
        // await dispatch(getProject({ projectId: res.proposalId }))
        history.push(`/projects/${res.proposalId}`)
      }
      onClose()
    } catch (error) {
      // const message =
      //   Object.values(error?.response?.data?.errors || {})?.[0] ||
      //   'Something went wrong!'
      // openNotificationWithIcon('error', { message })
      setError(error?.response?.data?.errors)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const getProjectPattern = async () => {
      setLoading(true)
      try {
        const res = await generateNextProjectCode(assignedTo || me.id)

        setState(res)
      } catch (error) {
        const message = Object.values(error?.response?.data?.errors || {})[0]
        openNotificationWithIcon('error', { message })
        setError(error?.response?.data?.errors)
      } finally {
        setLoading(false)
      }
    }
    if (visible) getProjectPattern()
    if (!visible) {
      setError({ code: '' })
      setState({ code: '' })
    }
  }, [visible])

  const checkIsEditable = () => {
    if (!permissions?.project_can_change_code) {
      return false
    }
    if (permissions?.project_can_change_code === 1) {
      return true
    }
    if (permissions?.project_can_change_code > 1) {
      return assignedTo === me.id
    }
  }
  return (
    <Modal
      title='Duplicate Project'
      footer={
        <Controls>
          <MainButton onClick={onClose} type='cancel' title='Cancel' />
          <MainButton
            isFetching={loading}
            onClick={onDuplicate}
            title='Duplicate'
          />
        </Controls>
      }
      centered
      onCancel={onClose}
      destroyOnClose
      visible={visible}
    >
      <Spin spinning={loading}>
        <Wrapper data-testid='project-duplicate--modal'>
          <HeadingRow>
            <span>{<Info style={{ color: '#4285f4' }} />}</span>
            <span>
              Are you sure you want to duplicate the{' '}
              <b>{proposalCode || '-'}</b> project?
            </span>
          </HeadingRow>
          <Row>
            <SettingsRow
              width={150}
              description='The code was generated according to the given pattern in the settings. '
              withInfoIcon
              title='New Project ID'
            >
              <EditableBlock editMode={true} value={state.code}>
                <InputField
                  name='code'
                  value={state?.code}
                  error={error?.code}
                  onChange={onChange}
                  disabled={!checkIsEditable()}
                />
              </EditableBlock>
            </SettingsRow>
          </Row>
          <Row>
            <SettingsRow
              description='Open duplicated project in the new tab.'
              width={150}
              title='Open in the new tab?'
            >
              <Checkbox
                onChange={e => setOpenProject(e.target.checked)}
                checked={openProject}
              />
            </SettingsRow>
          </Row>
        </Wrapper>
      </Spin>
    </Modal>
  )
}

const Wrapper = styled.div``

const Row = styled.div``
const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`
