import { WarningFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { DatePicker } from 'components/UIKit'
import { Invoice } from 'features/Invoice/types'
import { BalanceStatusEnum, PaymentBalance } from 'features/Project/types'
import { priceNumber } from 'helpers/numbers'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  data: Invoice
  balance: PaymentBalance
  onChange: (due_date: Partial<PaymentBalance>) => void
}

export const InvoiceInfo = ({ data, balance, onChange }: Props) => {
  const isWO = !!balance?.wo_id

  return (
    <Wrapper>
      <Row>
        <Title>Created from {isWO ? 'work order' : 'project'}:</Title>
        {isWO ? (
          <Value>{data.proposal_code}</Value>
        ) : (
          <Link to={`/projects/${data.parent_id}`}>{data.proposal_code}</Link>
        )}
      </Row>
      {isWO && (
        <Row>
          <Title>Related project:</Title>
          <Link to={`/projects/${data.parent_id}`}>{data.project_code}</Link>
        </Row>
      )}
      <Row>
        <Title>Total Project amount:</Title>
        <Value>{priceNumber(data.proposal_amount || 0)}</Value>
      </Row>
      <Row>
        <Title>Total amount paid:</Title>
        <Value>{priceNumber(data.amount_paid || 0)}</Value>
      </Row>
      <Row>
        <Title>Total remaining amount after this invoice:</Title>
        <Value>
          {priceNumber(data.amount_remaining || 0)}{' '}
          {data.amount_remaining < 0 && (
            <Tooltip title='Project Total and Invoices Total amounts are mismatch'>
              <WarningFilled
                style={{
                  color: '#faad14',
                  fontSize: '1.2rem',
                  margin: '-2px 5px 0 0',
                }}
              />
            </Tooltip>
          )}
        </Value>
      </Row>
      <Row withBorder>
        <Title>Total amount unpaid:</Title>
        <Value>{priceNumber(data.amount_unpaid || 0)}</Value>
      </Row>
      <Row>
        <Title>This invoice total:</Title>
        <Value>{priceNumber(data.invoice_amount || 0)}</Value>
      </Row>
      <Row>
        <Title>
          {balance?.status === BalanceStatusEnum.Paid ? 'Payment' : 'Due'} Date:
        </Title>
        <DatePicker
          bordered={false}
          value={balance?.due_date}
          placeholder='No due date'
          width='150px'
          style={{ marginLeft: -11 }}
          allowClear
          onChange={value =>
            onChange({ due_date: value ? value.format('YYYY-MM-DD') : null })
          }
        />
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #f1f2fd;
  padding: 16px;
  border-radius: 4px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }

  & > *:last-child {
    margin-top: -3px;
  }
`

const Row = styled.div<{ withBorder?: boolean }>`
  border-bottom: ${props => (props.withBorder ? '1px solid #e5e6ef' : 'none')};
  display: grid;
  grid-template-columns: 280px 200px;
  align-items: center;
  gap: 16px;
`

const Title = styled.p`
  color: ${props => props.theme.font.tertiary};
`

const Value = styled.p``
