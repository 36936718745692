import ProjectContacts from 'features/Project/components/EditProject/components/ProjectSidebar/components/ProjectContacts'
import SidebarBlock from 'features/Project/components/EditProject/components/ProjectSidebar/components/SidebarBlock'
import styled from 'styled-components'
import InvoiceDetails from './InvoiceDetails'

export const InvoiceSidebar = () => {
  return (
    <Wrapper>
      <ProjectContacts />

      <SidebarBlock
        title='Invoice Details'
        data-testid='project--project-details'
      >
        <InvoiceDetails />
      </SidebarBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div``
