import { round } from 'helpers/Math'
import { priceNumber } from 'helpers/numbers'
import styled from 'styled-components'

export const Totals = ({
  total,
  feeSum,
}: {
  total: number
  feeSum: number
}) => {
  return (
    <Wrapper>
      {!!feeSum && (
        <>
          <span>Subtotal: </span>
          <span>{priceNumber(round(total - (feeSum || 0)))}</span>
          <span>Payment processing fee: </span>
          <span>{priceNumber(feeSum)}</span>
        </>
      )}
      <Total>Invoice Total: </Total>
      <Total>{priceNumber(total)}</Total>
    </Wrapper>
  )
}

const Wrapper = styled.p`
  font-weight: 500;
  display: grid;
  grid-template-columns: 160px 150px;
  justify-content: flex-end;

  span {
    &:nth-child(2n) {
      text-align: right;
    }
  }
`

const Total = styled.span`
  font-size: 21px;
`
