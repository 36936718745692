import { CustomerInformation } from 'containers/MainContent/Orcatec/Proposals/components/CustomerInformation/CustomerInformation'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
/* import {
  // selectProjectSlice,
  updateProjectField,
} from 'features/Project/slices/projectSlice' */
import { Project } from 'features/Project/types'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { useState } from 'react'
// import { useDispatch } from 'react-redux'
// import { useAppSelector } from 'store/Orcatec/hooks'
import { Media } from 'types/Media'

interface State {
  documents: Media[]
  currentFileIdx: number
}
const initialState = { documents: [], currentFileIdx: 0 }

interface Props {
  data: Project
  onUpdate: (data: Partial<Project>) => void
}

export const CustomerInfo = ({ data, onUpdate }: Props) => {
  // const dispatch = useDispatch()

  const [state, setState] = useState<State>(initialState)
  const [docViewer, setDocViewer] = useState(false)

  const toggleOpenDocViewerModal = () => setDocViewer(!docViewer)

  const getFileForModalDocViewer = (
    documents: Media[],
    currentFileIdx: number,
  ) => {
    setState({ documents, currentFileIdx })
    toggleOpenDocViewerModal()
  }

  const handleCustomerInfoUpdate = (fieldsToUpdate: Partial<Project>) => {
    // dispatch(updateProjectField(fieldsToUpdate))
    onUpdate(fieldsToUpdate)
  }

  return (
    <div data-testid='project--customer-info'>
      <ErrorBoundary>
        <div className='project-customer-info'>
          <CustomerInformation
            dataSource={data}
            label='Information for customer'
            notesFieldName='info_for_customer'
            filesFieldName='files'
            getFileForModalDocViewer={getFileForModalDocViewer}
            onUpdate={handleCustomerInfoUpdate}
            filesLimit={100}
          />
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <CustomerInformation
          dataSource={data}
          label='Information from customer'
          notesFieldName='info_from_customer'
          filesFieldName='files_from_customer'
          getFileForModalDocViewer={getFileForModalDocViewer}
          restrictUpload
          onUpdate={handleCustomerInfoUpdate}
          filesLimit={100}
        />
      </ErrorBoundary>

      {docViewer && (
        <DocViewer
          documents={state.documents}
          currentDocument={state.currentFileIdx}
          isModalVisible={true}
          handleCancel={toggleOpenDocViewerModal}
        />
      )}
    </div>
  )
}
