import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { Invoice } from '../types'
import InvoiceAPI, { InoicesListResp } from '../api'
import { AppStateType } from 'store'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { AsyncThunkAPI } from 'store/types'
import ProjectItemsAPI, {
  patchProjectSettings,
  ProjectAPI,
  ProjectSectionsAPI,
} from 'api/Project'
import { Error } from 'types/Error'
import {
  PaymentBalance,
  ProjectItem,
  ProjectSection,
  ProjectSettings,
} from 'features/Project/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import ProjectPaymentAPI from 'api/Payment'
import { getValueFromLS } from 'hooks/useLocalStorage'

const settingsFromLC = getValueFromLS(`invoices-list-table_v1`)

interface InvoicesSlice {
  status: 'idle' | 'loading' | 'error' | 'updating'
  error: Error | null
  currentInvoice: Invoice
  invoicesList: {
    data: object[]
    pagination: {
      current_page: number
      per_page: number
      total: number
    }
  }
}

const initialState: InvoicesSlice = {
  status: 'idle',
  error: null,
  currentInvoice: {} as Invoice,
  invoicesList: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: settingsFromLC?.page_size || 25,
      total: 0,
    },
  },
}

const projectInvoicesSlice = createSlice({
  name: 'projectInvoicesSlice',
  initialState,
  reducers: {
    invoiceFieldUpdated: (
      state,
      { payload }: PayloadAction<Partial<Invoice>>,
    ) => {
      state.currentInvoice = {
        ...state.currentInvoice,
        ...payload,
      }
    },
    invoiceSettingsUpdated: (
      state,
      { payload }: PayloadAction<PaymentBalance>,
    ) => {
      state.currentInvoice.setting = {
        ...state.currentInvoice.setting,
        ...payload,
      }
    },
    invoiceBalanceSet: (state, { payload }) => {
      state.currentInvoice.proposal_tabs[0].payment.payment_balances = [payload]
    },
    sectionUpdated: (state, { payload }) => {
      state.currentInvoice.proposal_tabs[0].proposal_sections[0] = {
        ...state.currentInvoice.proposal_tabs[0].proposal_sections[0],
        ...payload,
      }
    },
    invoiceResetted: state => {
      state.currentInvoice = {} as Invoice
      state.invoicesList = {
        data: [],
        pagination: {
          current_page: 1,
          per_page: settingsFromLC?.page_size || 25,
          total: 0,
        },
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getInvoice.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.status = 'idle'
      state.currentInvoice = action.payload
    })
    builder.addCase(getInvoice.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(getInvoicesList.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getInvoicesList.fulfilled, (state, action) => {
      const { data, meta } = action.payload

      state.status = 'idle'
      state.invoicesList = {
        data: data.map((item, index) => ({
          ...item,
          key: index,
        })),
        pagination: meta,
      }
    })
    builder.addCase(getInvoicesList.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(updateProjectInvoiceField.pending, state => {
      state.status = 'updating'
    })
    builder.addCase(
      updateProjectInvoiceField.fulfilled,
      (state, { payload }) => {
        state.status = 'idle'
        state.currentInvoice = {
          ...state.currentInvoice,
          ...payload,
        }
      },
    )
    builder.addCase(updateProjectInvoiceField.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })
  },
})

export default projectInvoicesSlice.reducer
export const {
  invoiceFieldUpdated,
  invoiceSettingsUpdated,
  invoiceBalanceSet,
  sectionUpdated,
  invoiceResetted,
} = projectInvoicesSlice.actions

//Thunks

export const getInvoice = createAsyncThunk<Invoice, number | string>(
  'invoice/getInvoice',
  async (invoiceId, { rejectWithValue }) => {
    try {
      const invoice = await InvoiceAPI.getInvoice(invoiceId)

      return invoice
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getInvoicesList = createAsyncThunk<
  InoicesListResp,
  GeneralTableParams & { proposal_id: number }
>('invoice/getInvoicesList', async (params, { rejectWithValue }) => {
  try {
    const invoicesList = await InvoiceAPI.getList(params)

    return invoicesList
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateProjectInvoiceField = createAsyncThunk<
  Partial<Invoice>,
  Partial<Invoice>,
  AsyncThunkAPI
>(
  'invoice/updateProjectInvoiceField',
  async (fieldsToUpdate, { getState, dispatch, rejectWithValue }) => {
    const invoiceId = getState().orcatec.invoicesSlice.currentInvoice.id
    if (!invoiceId) return

    try {
      if (!('status' in fieldsToUpdate) && !('code' in fieldsToUpdate))
        dispatch(invoiceFieldUpdated(fieldsToUpdate))

      const res = await ProjectAPI.updateProjectField(invoiceId, fieldsToUpdate)

      return res
    } catch (error) {
      // dispatch(getProject({ projectId }))

      return rejectWithValue(error.response.data)
    }
  },
)

export const updateInvoiceField = createAsyncThunk<
  Partial<Invoice>,
  Partial<Invoice>,
  AsyncThunkAPI
>(
  'invoice/updateInvoiceField',
  async (fieldsToUpdate, { getState, dispatch, rejectWithValue }) => {
    const invoiceId = getState().orcatec.invoicesSlice.currentInvoice.id
    if (!invoiceId) return

    try {
      dispatch(invoiceFieldUpdated(fieldsToUpdate))

      const res = await InvoiceAPI.updateField(invoiceId, fieldsToUpdate)

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateExtraAssignedUsers = createAsyncThunk<
  Partial<Invoice>,
  number[],
  AsyncThunkAPI
>(
  'invoice/updateExtraAssignedUsers',
  async (userIds, { getState, dispatch, rejectWithValue }) => {
    const invoiceId = getState().orcatec.invoicesSlice.currentInvoice.id
    if (!invoiceId) return

    try {
      dispatch(
        invoiceFieldUpdated({
          extraAssignedUsers: userIds,
        }),
      )

      const res = await ProjectAPI.updateExtraAssignedUsers(invoiceId, userIds)

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateInvoiceSection = createAsyncThunk<
  ProjectSection,
  { sectionId: number; fields: Partial<ProjectSection> },
  AsyncThunkAPI
>(
  'invoiceSection/updateSection',
  async ({ sectionId, fields }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(sectionUpdated(fields))

      return await ProjectSectionsAPI.updateSectionTitle(sectionId, fields)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateInvoiceItem = createAsyncThunk<
  ProjectItem,
  ProjectItem,
  AsyncThunkAPI
>('projectItems/updateItem', async (item, { rejectWithValue }) => {
  try {
    // dispatch(itemUpdated(item))

    return await ProjectItemsAPI.updateItem(item)
  } catch (error) {
    return rejectWithValue(error.response?.data?.errors)
  }
})

export const deleteInvoice = createAsyncThunk<void, number>(
  'invoice/deleteInvoice',
  async (invoiceId, { rejectWithValue }) => {
    try {
      return await InvoiceAPI.deleteInvoice(invoiceId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateInvoiceSettings = createAsyncThunk<
  void,
  {
    projectId: number
    field: Partial<ProjectSettings>
  }
>('invoice/updateSettings', async ({ projectId, field }, { dispatch }) => {
  try {
    await patchProjectSettings(projectId, field)

    dispatch(invoiceSettingsUpdated(field))
  } catch (error) {
    console.error(error)
    openNotificationWithIcon('error', {
      message: error?.response?.data?.errors
        ? Object.values(error?.response?.data?.errors)
        : error?.response?.data?.message,
    })
  }
})

export const updateInvoicePayment = createAsyncThunk<
  void,
  PaymentBalance,
  AsyncThunkAPI
>(
  'projectPayment/savePayment',
  async (balance, { getState, dispatch, rejectWithValue }) => {
    const payment = getState().orcatec.invoicesSlice.currentInvoice
      ?.proposal_tabs?.[0]?.payment

    if (!payment) return

    try {
      await ProjectPaymentAPI.putPayments(payment.id, {
        ...payment,
        payment_balances: [balance],
      })

      dispatch(invoiceBalanceSet(balance))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

//Selectors
export const selectInovoicesSlice = (state: AppStateType) =>
  state.orcatec.invoicesSlice

const selectCurrentInvoice = (state: AppStateType) =>
  state.orcatec.invoicesSlice.currentInvoice

export const selectInvoiceTab = createSelector(
  selectCurrentInvoice,
  invoice => invoice.proposal_tabs?.[0],
)

export const selectInvoiceBalance = createSelector(
  selectInvoiceTab,
  tab => tab?.payment?.payment_balances?.[0],
)
export const selectInvoiceFee = createSelector(
  selectInvoiceTab,
  tab => tab?.fees?.[0]?.price,
)

export const selectInvoiceSettings = createSelector(
  selectCurrentInvoice,
  invoice => invoice.setting,
)
