import { selectMe, selectOrcatecState, selectOwnerEmail } from './selectors'

import { useAppSelector } from 'store/Orcatec/hooks'
import { getWorkerContact } from './utils'
import moment from 'moment'

import getStatus from 'containers/MainContent/Orcatec/Proposals/helpers/getTabStatus'
import { selectColumnById } from 'features/Dispatch/dispatchSlice'

import { selectProjectActiveTab } from 'features/Project/projectSelectors'
import { DATE_FORMAT } from 'constants/dateFormats'
import { BalanceStatusEnum, Project, ProjectItem } from 'features/Project/types'

interface Props {
  appointment: any
  proposalStatus: any
  customerView: boolean
  projectID: string
  proposalLink: string
  proposalView: boolean
  proposalFormPreview?: object | null
  scopeOfWork?: ProjectItem[]
  workOrder?: Project
  proposalForm?: Project
}

const formatTime = time => {
  if (typeof time === 'string') {
    return moment(time, 'HH:mm').format('hh:mm A')
  } else if (moment.isMoment(time)) {
    return time.format('hh:mm A')
  } else {
    return null
  }
}

export const parseMessageVariables = ({
  appointment,
  proposalLink,
  proposalForm,
}: // scopeOfWork,
// workOrder,
Props) => {
  const orcatecState = useAppSelector(selectOrcatecState)
  const ownerEmail = useAppSelector(selectOwnerEmail)
  const me = useAppSelector(selectMe)
  const column = useAppSelector(
    selectColumnById(appointment ? appointment.column_template_id : null),
  )
  const projectTech = proposalForm?.user

  const currentTab = useAppSelector(selectProjectActiveTab)

  const { phone, email, name } = getWorkerContact(
    column?.technicians?.find(tech => tech.user_id === column.main_tech_id),
    projectTech,
    proposalForm,
  )

  const parseEmailMessage = (
    message: string,
    type?: 'sms' | 'email',
    link?: string,
  ): string => {
    let newMessage = message

    const companyName = orcatecState.company?.company_name
    const userEmail = orcatecState.user.me?.email
    const userName = orcatecState.user.me?.full_name
    const userPhone = orcatecState.user.me?.phone
    const companyEmail =
      orcatecState.company?.company_email ?? ownerEmail ?? me?.email
    const companyPhone = orcatecState.company?.company_phone ?? me?.phone

    const { time_start, time_end, matrix_time_start, matrix_time_end } =
      appointment || {}

    const arrivalTimeFormat = (time_start: string, time_end: string) => {
      if (formatTime(time_start) === formatTime(time_end)) {
        return time_start ? moment(time_start, 'HH:mm').format('hh:mm A') : '-'
      }
      return `${
        time_start ? moment(time_start, 'HH:mm').format('hh:mm A') : '-'
      } - ${time_end ? moment(time_end, 'HH:mm').format('hh:mm A') : '-'}`
    }

    const promisedArrivalTime = arrivalTimeFormat(time_start, time_end)
    const dispatchTime = arrivalTimeFormat(matrix_time_start, matrix_time_end)

    const date =
      typeof appointment?.date === 'string'
        ? moment(appointment?.date).format(DATE_FORMAT)
        : Array.isArray(appointment?.date)
        ? appointment?.date
            .map(date => moment(date).format(DATE_FORMAT))
            .join(`,<br/>`)
        : null

    const isSMS = type === 'sms' && link
    const obj: { [key: string]: string } = {
      // Proposal
      '[link to proposal]': isSMS
        ? link
        : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link ||
            proposalLink}>link</a>`,
      '[link_to_proposal]': isSMS
        ? link
        : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link ||
            proposalLink}>link</a>`,
      // Project
      '[link to project]': isSMS
        ? link
        : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link ||
            proposalLink}>link</a>`,
      '[link_to_project]': isSMS
        ? link
        : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link ||
            proposalLink}>link</a>`,
      '[link_to_invoice]': isSMS
        ? link
        : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link ||
            proposalLink}>link</a>`,
      '[tech_name]': proposalForm?.user?.full_name || name || userName || '',
      '[tech_email]': proposalForm?.user?.email || email || userEmail || '',
      '[tech_phone_number]':
        proposalForm?.user?.phone || phone || userPhone || '',
      '[recipient_name]': proposalForm?.contacts?.[0]?.name || '',
      '[company_name]': companyName, //Company name
      '[user_name]': userName || '',
      '[user_email]': userEmail || '', //User email. We are using the current user from matrix on that assigned appointment. When appt in queue or misc we are using authenticated user
      '[user_phone]': userPhone || '',
      '[company_email]': companyEmail, //Company email
      '[company_phone_number]': companyPhone, //Comany phone number
      '[promised_arrival_time]': promisedArrivalTime,
      '[dispatch_time]': dispatchTime,
      '[date]': proposalForm?.created_at
        ? moment(proposalForm?.created_at).format('MM/DD/YYYY')
        : `<br/>${date}`,
      '[appointment_date]': proposalForm?.created_at
        ? moment(proposalForm?.created_at).format('MM/DD/YYYY')
        : `<br/>${date}`,
      '[project_id]': proposalForm?.code || '',
      '[invoice_id]': proposalForm?.code || '',
      '[invoice_header]':
        proposalForm?.proposal_tabs?.[0]?.payment?.payment_balances?.[0]
          ?.status === BalanceStatusEnum.Paid
          ? 'Receipt'
          : 'Invoice',
      // Proposal
      '[ProposalStatus (Estimate, Invoice, etc.)]':
        (proposalForm && proposalForm && getStatus(proposalForm, currentTab)) ||
        '',
      '[ProposalStatus(Estimate, Invoice, etc.)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProposalHeader (Estimate, Invoice, etc.)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProposalHeader(Estimate || Invoice || Receipt)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProposalHeader (Estimate || Invoice || Receipt)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      // Project
      '[ProjectStatus (Estimate, Invoice, etc.)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProjectStatus(Estimate, Invoice, etc.)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProjectHeader (Estimate, Invoice, etc.)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProjectHeader(Estimate || Invoice || Receipt)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProjectHeader (Estimate || Invoice || Receipt)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[ProjectHeader (Estimate, etc.)]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[projectheader (estimate, invoice, etc.)]':
        (proposalForm && getStatus(proposalForm)) || '',
      '[proposal status]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[project status]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
      '[Project status]':
        (proposalForm && getStatus(proposalForm, currentTab)) || '',
    }

    Object.keys(obj).forEach(key => {
      if (message?.includes(key)) {
        newMessage = newMessage.replaceAll(key, obj[key])
      }
    })
    return newMessage
  }

  return { parseEmailMessage }
}
