import { GhostInput } from 'components/UI/GhostInput/GhostInput'
import { updateInvoiceItem } from 'features/Invoice/store/invoicesSlice'
import { ProjectItem } from 'features/Project/types'
// import { priceNumber } from 'helpers/numbers'
import { useState } from 'react'
import { useAppDispatch } from 'store/Orcatec/hooks'
import styled from 'styled-components'

interface Props {
  data: ProjectItem
}

export const ItemRow = ({ data }: Props) => {
  const [item, setItem] = useState(data)

  const dispatch = useAppDispatch()

  function handleItemChange(value: string, name: string) {
    setItem(prev => ({
      ...prev,
      [name]: value,
    }))

    dispatch(
      updateInvoiceItem({
        ...data,
        [name]: value,
      }),
    )
  }

  return (
    <Wrapper key={item.id}>
      <p>
        <GhostInput
          value={item.name}
          onChange={value => handleItemChange(value, 'name')}
          allowEmpty={false}
          placeholder='Item name'
          maxLength={3000}
        />
      </p>
      {/*  <p>{priceNumber(item.retail_price)}</p>
      <p>{item.qty}</p>
      <p>QTY</p>
      <p>{priceNumber(item.retail_price * (item.qty || 0))}</p> */}
      <Description>
        <GhostInput
          value={item.description}
          onChange={value => handleItemChange(value, 'description')}
          placeholder='Item description'
          maxLength={5000}
        />
      </Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr 100px 80px 100px 100px; */
  /* gap: 5px; */
`

const Description = styled.p`
  /* grid-column: span 4; */
  margin-top: 8px;
`
