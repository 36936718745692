import axiosOrcatec from 'api/axiosInstance'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { Invoice } from './types'

function getInvoice(invoiceId: number | string) {
  return axiosOrcatec
    .get(`proposal/invoices/${invoiceId}`)
    .then(res => res.data)
}

export interface InoicesListResp {
  data: object[]
  meta: {
    current_page: number
    per_page: number
    total: number
  }
}

function getList(params?: GeneralTableParams & { proposal_id: number }) {
  return axiosOrcatec
    .get<InoicesListResp>(`/proposal/invoices`, { params })
    .then(res => res.data)
}

function generateInvoice(data: {
  proposal_id: number
  balance_id?: number
  work_order_id?: number
}) {
  return axiosOrcatec
    .post(`/proposal/invoices`, data)
    .then(res => res && res.data)
}

function updateField(invoiceId: number, data: Partial<Invoice>) {
  return axiosOrcatec
    .patch(`/proposal/invoices/${invoiceId}`, data)
    .then(res => res && res.data)
}

function deleteInvoice(invoiceId: number) {
  return axiosOrcatec
    .delete(`/proposal/invoices/${invoiceId}`)
    .then(res => res && res.data)
}

const InvoiceAPI = {
  getInvoice,
  getList,
  generateInvoice,
  updateField,
  deleteInvoice,
}

export default InvoiceAPI
