import { ModuleName } from 'features/Settings/UsersAndGroups'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
// import { CustomFormsOperation } from '../types'
import { useHistory } from 'react-router-dom'

export const usePermissionsByModule = (
  operation: { [s: string]: string },
  isPublicView?: boolean,
  moduleName?: ModuleName,
) => {
  if (isPublicView) {
    return {
      CREATE: true,
      READ: true,
      UPDATE: true,
      DELETE: true,
    }
  }
  const history = useHistory()

  const module = moduleName || getModuleNameBySlug(history.location.pathname)

  const permissions = useAppSelector(selectUserPermissionsByModule(module))

  return {
    CREATE: !!permissions[`${module}_${operation.CREATE}`],
    READ: !!permissions[`${module}_${operation.READ}`],
    UPDATE: !!permissions[`${module}_${operation.UPDATE}`],
    DELETE: !!permissions[`${module}_${operation.DELETE}`],
  }
}

const getModuleNameBySlug = (slug: string) => {
  if (slug.includes('projects')) return ModuleName.PROJECT
  if (slug.includes('dispatch') || slug.includes('appointment'))
    return ModuleName.DISPATCH
  if (slug.includes('schedule')) return ModuleName.SCHEDULE
  if (slug.includes('properties-form')) return ModuleName.PROPERTIES
  if (slug.includes('jobs')) return ModuleName.JOBS

  return ModuleName.PROJECT
}
