import {
  BalanceStatusEnum,
  PaymentBalance,
  ProjectPayment,
} from 'features/Project/types'
import { useAvailablePaymentMethods } from './useAvailablePaymentMethods'
import { useEffect, useMemo, useState } from 'react'
import { useBalanceNames } from './useBalanceNames'
import { usePaymentMethods } from './usePaymentMethods'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProjectSettings,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import { round } from 'helpers/Math'
import {
  deletePaymentBalances,
  getProjectPayment,
  saveProjectPayment,
  selectProjectPaymentBalances,
  selectProjectPaymentSlice,
} from 'features/Project/slices/projectPaymentSlice'
import { getInvoicesList } from 'features/Invoice/store/invoicesSlice'
import { transformArrayToObj } from 'features/Dispatch/helpers'

export const usePayments = (projectId: number, tabId: number) => {
  const dispatch = useAppDispatch()

  const { status, payment: initialPayment } = useAppSelector(
    selectProjectPaymentSlice,
  )

  const paymentBalances = useAppSelector(selectProjectPaymentBalances)

  const projectTotal = useAppSelector(selectProjectTotal)
  const { availablePaymentMethods, hasMerchant } = useAvailablePaymentMethods()
  const {
    balanceNames,
    loading: loadingBalanceNames,
    refetch: refetchBalanceNames,
    addBalanceName,
    deleteBalanceName,
  } = useBalanceNames()
  const { paymentMethods } = usePaymentMethods()

  const defaultPaymentDueDate =
    useAppSelector(selectProjectSettings)?.default_payment_due_date || 0

  const [payment, setPayment] = useState<
    Omit<ProjectPayment, 'payment_balances'>
  >(initialPayment || getInitialPayment(projectId, tabId))

  const initialBalance = {
    accept_card: false,
    amount: 0,
    can_pay_by_check: false,
    description: '',
    due_date: dayjs().format('YYYY-MM-DD'),
    is_verified: false,
    invoice: null,
    name_balance: 'Balance',
    payment_method: '',
    position: 0,
    status: BalanceStatusEnum.Due,
  }

  const [balances, setBalances] = useState<{
    ids: number[]
    data: Record<number, PaymentBalance>
  }>(paymentBalances)
  const [isEdited, setIsEdited] = useState(false)
  const [balanceIdsToDelete, setBalanceIdsToDelete] = useState<number[]>([])

  const sumOfBalances = useMemo(
    () =>
      balances.ids.reduce(
        (sum, id) =>
          balances.data[id]?.status !== BalanceStatusEnum.Cancelled
            ? round(sum + balances.data[id].amount)
            : sum,
        0,
      ),
    [...balances.ids.map(id => balances.data[id].amount), balances.ids.length],
  )

  const sumOfPaidBalances = useMemo(
    () =>
      balances.ids.reduce(
        (sum, id) =>
          balances.data[id]?.status === BalanceStatusEnum.Paid
            ? round(sum + balances.data[id].amount)
            : sum,
        0,
      ),
    [
      ...balances.ids.map(id => balances.data[id].amount),
      ...balances.ids.map(id => balances.data[id].status),
      balances.ids.length,
    ],
  )

  const remainingBalanceToSplit = useMemo(
    () => round(projectTotal - sumOfBalances),
    [sumOfBalances],
  )

  const dueAmount = useMemo(() => round(projectTotal - sumOfPaidBalances), [
    sumOfPaidBalances,
  ])

  useEffect(() => {
    if (projectId && tabId) {
      getPayment()
    }
  }, [])

  useEffect(() => {
    if (!balances?.ids?.length) {
      const id = Date.now()

      const newBalance = {
        ...initialBalance,
        id,
        amount: remainingBalanceToSplit,
        accept_card: availablePaymentMethods.credit_card,
        can_pay_by_check: availablePaymentMethods.bank_check,
      }

      return setBalances({
        ids: [id],
        data: {
          [id]: newBalance,
        },
      })
    }

    // setBalances(balances)
  }, [])

  async function getPayment() {
    await dispatch(getProjectPayment({ projectId, tabId }))
  }

  async function savePayment() {
    let result

    if (balanceIdsToDelete.length) {
      result = await dispatch(deletePaymentBalances(balanceIdsToDelete))
    }

    const initialPayment = {
      entity_id: projectId,
      entity_type: 1,
      id: 0,
      info: '',
      remaining_balance: 0,
      tab_id: tabId,
      total_balance: 0,
    }

    const paymentInfo = payment || initialPayment

    const paymentData = {
      ...paymentInfo,
      payment_balances: balances.ids.map((id, idx) => ({
        ...balances.data[id],
        position: idx,
      })),
    }

    result = await dispatch(saveProjectPayment(paymentData))

    const updatedBalances: PaymentBalance[] =
      result?.payload?.data?.payment_balances

    if (updatedBalances?.length) {
      setBalances({
        ids: updatedBalances.map(balance => balance.id),
        data: transformArrayToObj(updatedBalances),
      })
    }

    dispatch(getInvoicesList({ proposal_id: projectId }))

    if (result.meta.requestStatus === 'rejected') {
      return Promise.reject(result.payload)
    }

    return result
  }

  function changePayment(value: Partial<ProjectPayment>) {
    setPayment(prev => ({
      ...prev,
      ...value,
    }))
  }

  function addBalance() {
    const maxPosition = Math.max(
      ...balances.ids.map(id => balances.data[id].position),
    )

    const id = Date.now()

    setBalances(prev => ({
      ...prev,
      ids: [...prev.ids, id],
      data: {
        ...prev.data,
        [id]: {
          ...initialBalance,
          id,
          due_date: dayjs()
            .add(!balances.ids.length ? 0 : defaultPaymentDueDate, 'days')
            .format('YYYY-MM-DD'),
          amount: remainingBalanceToSplit,
          position: !balances.ids.length ? 0 : maxPosition + 1,
          accept_card: availablePaymentMethods.credit_card,
          can_pay_by_check: availablePaymentMethods.bank_check,
        },
      },
    }))
  }

  function changeBalance(balanceId: number, value: Partial<PaymentBalance>) {
    setIsEdited(true)
    const editedBalance = { ...balances.data[balanceId] }

    setBalances(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [balanceId]: {
          ...editedBalance,
          ...value,
        },
      },
    }))
  }

  function deleteBalance(balanceId: number) {
    setIsEdited(true)
    const isNewBalance = dayjs().isSame(dayjs(balanceId), 'month')

    if (!isNewBalance) {
      setBalanceIdsToDelete(prev => [...prev, balanceId])
    }

    setBalances(prev => ({
      ...prev,
      ids: balances.ids.filter(id => id !== balanceId),
    }))
  }

  function reorderBalances(newOrder: number[]) {
    setIsEdited(true)
    setBalances(prev => ({
      ...prev,
      ids: newOrder,
    }))
  }

  return {
    isEdited,
    availablePaymentMethods,
    balances: balances,
    balanceNames,
    hasMerchant,
    loading: status === 'loading',
    loadingBalanceNames,
    payment,
    paymentMethods,
    remainingBalanceToSplit,
    dueAmount,
    projectTotal,
    sumOfBalances,
    refetchPayment: getPayment,
    addBalance,
    changeBalance,
    deleteBalance,
    reorderBalances,
    addBalanceName,
    deleteBalanceName,
    refetchBalanceNames,
    savePayment,
    changePayment,
  }
}

function getInitialPayment(projectId: number, tabId: number) {
  return {
    entity_id: projectId,
    entity_type: 1,
    id: 0,
    info: '',
    remaining_balance: 0,
    tab_id: tabId,
    total_balance: 0,
  }
}
