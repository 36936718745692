import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { PaymentBalance, ProjectPayment } from '../types'
import { Error } from 'types/Error'
import ProjectPaymentAPI, { getMerchantPaymentMethods } from 'api/Payment'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { AppStateType } from 'store'
import { AsyncThunkAPI } from 'store/types'
import dayjs from 'dayjs'

interface AvailablePaymentMethods {
  bank_check: boolean
  credit_card: boolean
}
interface PaymentMerchantData extends AvailablePaymentMethods {
  merchantId: number | null
}

interface ProjectPaymentSlice {
  payment: Omit<ProjectPayment, 'payment_balances'> | null
  payment_balances: { ids: number[]; data: Record<number, PaymentBalance> }
  balanceIdsToDelete: number[]
  merchantData: PaymentMerchantData | null
  status: 'idle' | 'loading' | 'error'
  error: Error | null
  isEdited: boolean
}

const initialState: ProjectPaymentSlice = {
  payment: null,
  payment_balances: {
    ids: [],
    data: {},
  },
  balanceIdsToDelete: [],
  merchantData: null,
  status: 'idle',
  error: null,
  isEdited: false,
}

const projectPaymentSlice = createSlice({
  name: 'projectPaymentSlice',
  initialState,
  reducers: {
    paymentSet: (state, action: PayloadAction<ProjectPayment | null>) => {
      if (!action.payload?.id) {
        state.payment = null
        state.payment_balances = {
          ids: [],
          data: {},
        }
        state.isEdited = false

        return
      }

      const { payment_balances, ...payment } = action.payload

      state.payment = payment
      state.payment_balances = {
        ids: payment_balances.map(balance => balance.id),
        data: transformArrayToObj(payment_balances),
      }
    },
    balancedAdded: (state, action: PayloadAction<PaymentBalance>) => {
      const balance = action.payload

      state.payment_balances.ids.push(balance.id)
      state.payment_balances.data[balance.id] = balance
    },
    balanceUpdated: (state, action: PayloadAction<PaymentBalance>) => {
      const balance = action.payload

      state.isEdited = true
      state.payment_balances.data[balance.id] = balance
    },
    balanceOrderUpdated: (state, action: PayloadAction<number[]>) => {
      state.payment_balances.ids = action.payload
    },
    balanceDeleted: (state, action: PayloadAction<number>) => {
      const balanceId = action.payload

      state.payment_balances.ids = state.payment_balances.ids.filter(
        id => id !== balanceId,
      )

      const isNewBalance = dayjs().isSame(dayjs(balanceId), 'month')
      if (!isNewBalance) {
        state.balanceIdsToDelete.push(balanceId)
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProjectPayment.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getProjectPayment.fulfilled, (state, { payload }) => {
      state.status = 'idle'

      if (!payload?.id) return

      const { payment_balances, ...payment } = payload

      state.payment = payment

      state.payment_balances = {
        ids: payment_balances.map(balance => balance.id),
        data: transformArrayToObj(payment_balances),
      }
    })
    builder.addCase(getProjectPayment.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(getAvailablePaymentMethods.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      getAvailablePaymentMethods.fulfilled,
      (state, { payload }) => {
        state.merchantData = payload
      },
    )
    builder.addCase(getAvailablePaymentMethods.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(saveProjectPayment.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(saveProjectPayment.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(saveProjectPayment.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(deletePaymentBalances.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deletePaymentBalances.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(deletePaymentBalances.rejected, state => {
      state.status = 'error'
    })
  },
})

export default projectPaymentSlice.reducer
export const {
  paymentSet,
  balancedAdded,
  balanceUpdated,
  balanceOrderUpdated,
  balanceDeleted,
} = projectPaymentSlice.actions

//Thunks

export const getProjectPayment = createAsyncThunk<
  ProjectPayment,
  { projectId: number; tabId: number }
>(
  'projectPayment/getPayment',
  async ({ projectId, tabId }, { rejectWithValue }) => {
    try {
      const payment: ProjectPayment = await ProjectPaymentAPI.getProjectPayment(
        {
          entity_id: projectId,
          entity_type: 1,
          tab_id: tabId,
        },
      )

      return payment
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getAvailablePaymentMethods = createAsyncThunk<
  PaymentMerchantData,
  number,
  AsyncThunkAPI
>(
  'projectPayment/getPaymentMethods',
  async (merchantId, { rejectWithValue }) => {
    try {
      const {
        payment_methods,
      }: {
        payment_methods: AvailablePaymentMethods
      } = await getMerchantPaymentMethods(merchantId)

      return {
        merchantId,
        bank_check: payment_methods.bank_check,
        credit_card: payment_methods.credit_card,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const saveProjectPayment = createAsyncThunk<
  ProjectPayment,
  ProjectPayment
>(
  'projectPayment/savePayment',
  async (paymentData: ProjectPayment, { dispatch, rejectWithValue }) => {
    try {
      let result

      if (paymentData.id) {
        result = await ProjectPaymentAPI.putPayments(
          paymentData.id,
          paymentData,
        )
      } else {
        result = await ProjectPaymentAPI.postPayments(paymentData)
      }

      dispatch(paymentSet(result.data))

      /* dispatch(
        getProjectPayment({
          projectId: paymentData.entity_id,
          tabId: paymentData.tab_id,
        }),
      ) */

      return result
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deletePaymentBalances = createAsyncThunk<void, number[]>(
  'projectPayment/deleteBalances',
  async balanceIdsToDelete => {
    try {
      return ProjectPaymentAPI.deletePayments(balanceIdsToDelete)
    } catch (error) {
      console.error(error)
    }
  },
)

//Selectors

export const selectProjectPaymentSlice = (state: AppStateType) =>
  state.orcatec.projectPaymentSlice
export const selectProjectPaymentInfo = (state: AppStateType) =>
  state.orcatec.projectPaymentSlice.payment
// export const selectProjectPaymentBalances = (state: AppStateType) =>
//   state.orcatec.projectPaymentSlice.payment_balances.ids.map(
//     id => state.orcatec.projectPaymentSlice.payment_balances.data[id],
//   )
export const selectProjectPaymentBalances = createSelector(
  selectProjectPaymentSlice,
  slice => slice.payment_balances,
)

export const selectSortedProjectPaymentBalances = createSelector(
  selectProjectPaymentBalances,
  balances => balances.ids.map(id => balances.data[id]),
)

export const selectPaymentMerchantData = (state: AppStateType) =>
  state.orcatec.projectPaymentSlice.merchantData
