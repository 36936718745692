import { Client } from './Client'
import { Media } from './Media'
import { Property } from './Property'

export interface BalanceTransaction {
  id: number
  transaction_id: string
  card_holder: string
  card: string
  check_number: string
  payer: string
  type: number
  created_at: string
  transaction_status: string
}

export enum BalanceStatus {
  Due = 1,
  Overdue,
  Paid,
  Cancelled,
  Pending,
}

export interface IPaymentMethods {
  id: number
  method_name: string
}
export interface ProjectPayment {
  payment_balances: PaymentBalance[]
}

//Proposal Tab
export interface ITabModule {
  name: string
  notes: string | null
  status: number
  disabled?: boolean
}

export interface IProposalTab {
  id: number
  payment: ProjectPayment
  status: number
  payment_status: number
  modules: ITabModule[]
  notes_for_installers: string
  total_to_pay: string
  proposal_notes: string | null
  files: Media[]
}

export interface IProposalModule {
  name: string
  notes: string | null
  status: number
}

export enum ProposalStatus {
  Estimate = 1,
  Contract = 2,
  Completed = 3,
  Canceled = 4,
  Cold = 5,
}
export interface IProposalsTableItem {
  id: number
  code: string
  name: string
  industry_name: string
  full_address: string
  client_name: string
  created_at: string
  installation_dates: string[]
  status: number
  payment_status: number
  equipment_status: number
  module_0: IProposalModule
  module_1: IProposalModule
  module_2: IProposalModule
  total_to_pay: string
  total_balance_due: string
  total_balance_overdue: string
  total_balance_paid: string
  total_unpaid_amount: string
  proposal_notes: string
  key: number
  filterType?: 'default' | 'date'
  public_link: string
  proposal_tab_id: number
}

export interface Proposal {
  clients: Client[]
  contract_date: string | null
  created_by: {
    id: number
    full_name: string
  }
  created_at: string
  files: Media[]
  files_for_worker: Media[]
  files_from_customer: Media[]
  id: number
  ip_client: string
  organizations: Client[]
  properties: Property[]
  proposal_tabs: IProposalTab[]
  signature: string
  signature_date: string | null
  signatory_name: string
  signed_by: string
  source: {
    id: number
    name: string
  } | null
  status: ProposalStatus
  template_name: string | null
  type: {
    id: number
    name: string
  } | null
}
