import { useAvailablePaymentMethods } from 'features/Project/components/PaymentSchedule/hooks/useAvailablePaymentMethods'

const useInvoicePreviewSettings = () => {
  const { hasMerchant } = useAvailablePaymentMethods()

  const PROJECT_PREVIEW_SETTINGS = [
    {
      title: 'Contacts section',
      settings: [
        {
          title: 'Show tech contacts',
          description:
            'Technician contact information will be visible to customers',
          field_name: 'show_tech',
        },
      ],
    },
    {
      title: 'Table section',
      settings: [
        {
          title: 'Show description only',
          description: 'Only item description will be visible',
          field_name: 'show_item_description_only',
        },
        {
          title: 'Show Payment Summary',
          description:
            'When enabled, the invoice will display a financial summary, including total project cost, total payments, and remaining balance',
          field_name: 'show_items_qty',
        },
      ],
    },
    {
      title: 'Price section',
      hide: !hasMerchant,
      settings: [
        {
          title: 'Payment processing fee',
          description:
            'Automatically add a service fee to every credit card payment',
          field_name: 'payment_processing_fee',
          field_type: 'input',
        },
      ],
    },
    /* {
      title: 'Contacts section',
      settings: [
        {
          title: 'Require signature',
          description:
            'To accept the project, clients must provide an electronic signature, their name, and agree to your Terms and Conditions',
          field_name: 'require_signature',
          disabled: invoice?.signature,
        },
      ],
    }, */
  ]

  return PROJECT_PREVIEW_SETTINGS.filter(section => !section.hide)
}

export default useInvoicePreviewSettings
