import { ProjectItem } from 'features/Project/types'
// import { priceNumber } from 'helpers/numbers'
import styled from 'styled-components'

interface Props {
  data: ProjectItem
  options: {
    hidePrice: boolean
    hideQty: boolean
    hideName: boolean
  }
}

export const Item = ({ data, options }: Props) => {
  return (
    <Wrapper key={data.id}>
      {!options.hideName && <Name>{data.name}</Name>}
      <div>
        <Description>{data.description}</Description>

        {/* <PriceSection>
          <p>{options.hideQty ? '' : `${data.unit || 'Qty'} ${data.qty}`}</p>
          <p>
            {options.hidePrice ? '' : priceNumber(data.retail_price * data.qty)}
          </p>
        </PriceSection> */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 5px 5px 5px 20px;
  font-size: 12px;
`

const Name = styled.p`
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
`
const Description = styled.p`
  white-space: pre-line;
`

/* const PriceSection = styled.div`
  display: grid;
  grid-template-columns: 80px 90px;
  justify-content: flex-end;
`
 */
