import { useMemo, useState } from 'react'
import './TabPaymentDescription.scss'
import MainButton from '../../../../../components/buttons/MainButton'
import { priceToView } from '../../../../../../../../helpers/thousandSeparator'
// import moment from 'moment-timezone'
import BalanceTable from '../../../BalanceTable'
import { hasActiveMerchant } from 'store/Orcatec/reducers/settings/company'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  selectBalancesPaidTotal,
  selectProject,
  selectProjectActiveTab,
  selectProjectSettings,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import PaymentProcess from '../../../PaymentProcess'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import states from 'helpers/states'
import {
  selectPrimaryProjectClient,
  selectPrimaryProjectProperty,
} from 'features/Project/slices/projectContactsSlice'
import { getProjectPayment, postPayments, putPayments } from 'api/Payment'
import moment from 'moment-timezone'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { getProject } from 'features/Project/slices/projectSlice'
import { fetchPaymentsList } from 'store/Orcatec/actions/payments'
import { useModulePermissions } from 'features/Project/components/EditProject/components/ProjectModules/useModulePermissions'
import { Tooltip } from 'antd'
import { WarningFilled } from '@ant-design/icons'

const TabPaymentDescription = ({
  payments,
  openModalPayment,
  proposalStatus,
  // totalToPay,
  // onPayBalance,
  isPaymentModuleStatusChanging,
  isTabContract,
}) => {
  const dispatch = useAppDispatch()

  const perm = useModulePermissions()

  const me = useAppSelector(selectAuthUser)
  const project = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const hasMerchant = useSelector(hasActiveMerchant)
  const projectTotal = useAppSelector(selectProjectTotal)
  const sumOfPaidBalances = useAppSelector(selectBalancesPaidTotal)
  const primaryProperty = useAppSelector(selectPrimaryProjectProperty)
  const primaryClient = useAppSelector(selectPrimaryProjectClient)

  const [selectedBalance, setSelectedBalance] = useState(null)
  const [paymentModal, setPaymentModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const sumOfTransactions = useMemo(
    () =>
      payments?.payment_balances?.length
        ? payments?.payment_balances
            .filter(bal => bal.status !== 4)
            .map(bal => Number(bal.amount))
            .reduce((a, b) => a + b, 0)
        : 0,
    [payments?.payment_balances],
  )

  const remainingBalanceToSplit = +projectTotal - sumOfTransactions.toFixed(2)

  const totalBalanceDue = () => {
    const res = payments?.payment_balances
      ?.filter(b => b.status !== 3)
      ?.filter(b => b.status !== 4)
      .reduce((a, b) => {
        return a + +b.amount
      }, 0)
    return (res || 0) + remainingBalanceToSplit
  }

  const togglePaymentModal = () => setPaymentModal(!paymentModal)

  const handlePayBalance = async balance => {
    if (loading) return

    setLoading(true)

    if (!balance) {
      const newBalance = {
        accept_card: true,
        amount: projectTotal,
        description: '',
        due_date: moment().format('YYYY-MM-DD'),
        id: 0,
        name_balance: 'Balance',
        payment_method: '',
        position: 0,
        status: 1,
      }

      let payment = {
        entity_id: project.id,
        entity_type: 1,
        id: 0,
        info: '',
        remaining_balance: projectTotal,
        tab_id: currentTab.id,
        total_balance: projectTotal,
        payment_balances: [newBalance],
      }

      if (currentTab?.payment?.id) {
        payment = {
          ...currentTab?.payment,
          payment_balances: [newBalance],
        }
        await putPayments(payment?.id, payment)
      } else {
        await postPayments(payment)
      }

      const { payment_balances } = await getProjectPayment({
        entity_id: project.id,
        tab_id: currentTab.id,
      })
      dispatch(fetchPaymentsList(project.id, 1, currentTab.id))

      balance = payment_balances?.[0]
    }
    setLoading(false)

    setSelectedBalance(balance)
    togglePaymentModal(true)
  }

  const handleBalancePaid = () => {
    dispatch(getProject({ projectId: project?.id }))
  }

  return (
    <div className='proposal-form-tabs_content-payment_description'>
      <div className='proposal-form-tabs_content-payment_description-title'>
        <span>Payment Schedule</span>
        <Tooltip
          title={
            !perm.payment && `You don't have permission to edit this module`
          }
        >
          <div style={{ marginLeft: '10px' }}>
            <MainButton
              title='Edit'
              onClick={openModalPayment}
              isFetching={isPaymentModuleStatusChanging}
              disabled={!isTabContract || !perm.payment}
            />
          </div>
        </Tooltip>

        {proposalStatus === 2 &&
          isTabContract &&
          !payments?.payment_balances?.length &&
          hasMerchant &&
          +projectTotal > 0 && (
            <MainButton title='Pay' onClick={() => handlePayBalance(null)} />
          )}
      </div>

      {!!payments?.payment_balances?.length && (
        <BalanceTable
          balances={payments?.payment_balances}
          remainigTitle='Remaining Balance to split:'
          remaining={remainingBalanceToSplit}
          onClickPay={proposalStatus === 2 && handlePayBalance}
        />
      )}

      {projectTotal !== 0 && (
        <>
          {totalBalanceDue() ? (
            <table className='proposal-form-tabs_content-end_of_system-total_table'>
              <tbody>
                <tr>
                  <td>
                    {sumOfPaidBalances > projectTotal && (
                      <Tooltip title='There is an overpayment from the client.  You cannot mark the project as "Completed" while the total paid amount exceeds the project total.'>
                        <WarningFilled
                          style={{
                            color: '#faad14',
                            fontSize: '1.2rem',
                            margin: '0px 5px 0 0',
                          }}
                        />
                      </Tooltip>
                    )}
                    Total Balance Due:
                  </td>
                  <td>{priceToView(totalBalanceDue())}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className='proposal-form-tabs_content-end_of_system-total_table'>
              <tbody>
                <tr>
                  <td>Paid in full:</td>
                  <td>{priceToView(sumOfPaidBalances)}</td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}

      {paymentModal && selectedBalance && (
        <Modal
          className={'paymenta-modal'}
          width='auto'
          title={`Payment for Project - ${project.code}`}
          footer={null}
          visible
          centered
          destroyOnClose
          onCancel={togglePaymentModal}
          maskClosable={false}
        >
          <PaymentProcess
            balance={selectedBalance}
            cardInfo={{
              owner_user_id: project.owner_user_id,
              card_holder: primaryClient?.name || '',
              billing_address: {
                address: primaryProperty?.address || '',
                city: primaryProperty?.city || '',
                region: states[primaryProperty?.state] || '',
                postal: primaryProperty?.postcode || '',
              },
            }}
            transactionFee={+project?.setting?.payment_processing_fee}
            settings={{
              disableTips: !projectSettings.show_tips,
            }}
            onCancel={togglePaymentModal}
            onPaid={handleBalancePaid}
            payer={me?.full_name}
          />
        </Modal>
      )}
    </div>
  )
}

export default TabPaymentDescription
