import React, { useState, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import validator from 'validator'
import FeedbackModal from '../../../Feedback'
import * as actions from '../../../../../../../../store/Orcatec/actions'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'
import TableProperties from '../TableProperties'
import useSearch from '../../../../../components/SearchHook'
import {
  searchClients,
  attachOrganizationToClient,
  detachOrganizations,
  updateAssociation,
  searchClientsV2,
} from '../../../../../../../../api/Client'
import {
  attachContact,
  searchOrganizations,
  detachContacts,
} from '../../../../../../../../api/Organizations'
import './style.scss'
import Preloader from '../../../../../components/Preloader'
import MainButton from '../../../../../components/buttons/MainButton'
import { addTag } from '../../../../../../../../helpers/tags/addTag'
import { withRouter } from 'react-router-dom'
import Tabs from '../../../../../components/Tabs/Tabs'
import TableProposals from '../TableProposals'
import PersonalInfo from './PersonalInfo/PersonalInfo'
import ClientsTable from '../../../../../CreateAppointment/components/Clients'
import SureModal from '../../../../../components/SureModal'
import second from '../../../../../Proposals/components/Modals/ModalConfirmIfThereIsNotSavedNewItems/ModalConfirmIfThereIsNotSavedNewItems'
import { openNotificationWithIcon } from '../../../../../../../../helpers/notifications/openNotificationWithIcon'
import Appointments from 'containers/MainContent/Orcatec/CreateAppointment/components/Appointments'
import { Result, Popover, Alert, Spin, Skeleton } from 'antd'
import AppointmentForm from 'containers/MainContent/Orcatec/Properties/components/AppointmentForm'

import moment from 'moment-timezone'
import { apiNotes } from '../../../../../../../../api/Notes'
import { createAppointment } from '../../../../../../../../store/Orcatec/actions/appointment'
import { BlockRoutingModal } from '../../../../../../Orcatec/components/BlockRoutingModal/index'
import Location from './Location/Location'
import { History } from '@material-ui/icons'
import HistoryTable from './HistoryTable'
import { messagingAPI } from '../../../../../../../../api/MessagingAPI'
import { AppStateType } from '../../../../../../../../store'
import InfoIcon from '@material-ui/icons/Info'
import { getClientsForNotificationModal } from 'api/Matrix-log'

import { initialErrors } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/AppointmentForm'
import { WithModal } from 'hoc/WithModal'
import { getAppointment } from 'api/Appointment'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { setValueToLS } from 'hooks/useLocalStorage'
import MembershipEntries from 'features/Membership/MembershipEntries'
import { Companies } from 'types/Company'

import { CallsHistoryTable } from 'features/Calls'
import { getCallDataByClient } from 'api/Calls'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
} from 'features/Notification/types'
import { useQueryClient } from '@tanstack/react-query'

import {
  ModuleName,
  ContactsPermissions,
  ProjectPermissions,
  MessagingPermissions,
  MembershipsPermissions,
  CallTrackingPermissions,
  PropertyPermissions,
  SchedulePermissions,
} from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectModulePermissions } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  attachPropertyToContact,
  detachPropertyFromContact,
} from 'api/Contacts/Contacts'
import { ClientType } from 'types/Client'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'
import { useTranslation } from 'react-i18next'

const ClientForm = ({
  //requires to pass from parent
  clientId,
  closeForm, //or closeModal
  company,
  fetchClientsList = () => {},
  propertyRelation, //boolean
  propertyRelationNote,
  propertyRelationId,
  onAddAttachedClient,
  isAddButtonClicked,
  isServiceContract, // or Proposal
  isAppointment,
  /////////////////////////////
  ///add to props by connect
  currentClient,
  currentOrganization,
  getClientById,
  feedbacks,
  fetchProperty,
  setCurrentClient,
  createClient,
  updateClient,

  getClientFeedback,
  updateClientFeedback,
  createClientFeedback,
  deleteClientFeedback,
  createRelationProperty,
  changeClientField,
  dellAttachedProperty,
  createClientAssociation,
  dellClientAssociation,
  isUpdateClient = false,
  onChangeClientId,
  updateClientList = () => {},
  setEntityId = () => {},
  allreadyAttachedClients,
  isSchedule,
  history,
  isPageClientForm,
  currentCompanyOwnerId,
  organizationId = 0,
  reloadOnCancel,
  wasEditorField,
  getOrganizationById,
  setCurrentOrganization,
  createOrganization,
  updateOrganization,
  getOrganizationFeedback,
  updateOrganizationFeedback,
  createOrganizationFeedback,
  deleteOrganizationFeedback,
  createOrganizationRelationProperty,
  createOrganizationAssociation,
  dellOrganizationAttachedProperty,
  dellOrganizationAssociation,
  changeOrganizationField,
  contactRelationId,
  isOrganization,
  isAssociation,
  parentAssociations,
  isClient,
  isContact,
  changeModalTitle = false,
  setCurrentNameToModalTitle,
  stepOfOpenedForm = 0,
  toggleEditClient = () => {},
  deleteClient,
  deleteOrganization,
  attachClientAfterSave,
  isProposal,
  proposalButtonAttach,
  // createAppointment,
  isCloseClientForm,
  setIsCloseClientForm,
  setWasEditedFields,
  heandleGetEntities,
  wasClientDeletedHeandler = () => null,
  isPropertyForm = false,
  withModal,
  setEntityName,
  onSubmitting,
  setDetached_contacts_ids,
  setDetached_properties_ids,
}) => {
  const queryClient = useQueryClient()
  const valueFromLC = getValueFromLS('contacts-type')
  const initialPhone = {
    value: {
      id: 0,
      phone: '',
      type: 'cell',
      preference: '',
    },
    error: '',
  }
  const initialEmail = {
    value: {
      id: 0,
      email: '',
      preference: false,
      send_promo: false,
      send_reminder: false,
    },
  }

  const typeToShow = () => {
    if (!organizationId) return valueFromLC || ClientType.INDIVIDUAL

    if (isOrganization) return ClientType.ORGANIZATION

    if (!isOrganization) return ClientType.INDIVIDUAL
  }

  const initialFormValues = {
    //property_relation: { value: '', error: '' },
    //property_id: null,
    relation: { value: '', error: '' },
    // relations_with_property: [],
    name: { value: '', error: '' },
    website: { value: '', error: '' },
    industry: { value: '', error: '' },
    id: { value: '', error: '' },
    type: {
      value: typeToShow(),
      // value: !clientId
      //   ? valueFromLC || 2
      //   : !isOrganization
      //   ? 2
      //   : isOrganization
      //   ? 1
      //   : 3,

      error: '',
    }, // value = 1 - organization | 2- individual  | 3 - contact
    phones: [initialPhone],
    emails: [initialEmail],
    note: {
      value: '',
      error: '',
    },
    tags: [],
    feedback_count: { value: 0, error: '' },
    vote: { value: 0, error: '' },
    client_properties: [],

    new_client_properties: [], // new objects
    deleted_client_properties: [], //id's
    client_associations: [],
    deleted_association_ids: [],
    new_client_associations: [], // new objects
    deleted_client_associations: [], //id's
    detached_organizations_ids: [],
    detached_contacts_ids: [],
    feedbacks: [],
    contacts: [],
    organizations: [],
    proposals: [],
    appointments: [],
    deleted_phone_ids: [],
    edited_phone_ids: [],
    deleted_email_ids: [],
    edited_email_ids: [],
  }

  let clientInputRow = React.useRef()
  let clientInputPhone = React.useRef()
  let clientInputEmail = React.useRef()

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.SCHEDULE),
  )

  const { memberships, messaging, call_tracking, project } = useAppSelector(
    selectModulePermissions(),
  )

  const { t } = useTranslation(['contact', 'property', 'common'])

  const [clientFormValues, setClientFormValues] = useState(initialFormValues)

  // const [tempContactValues, setTempContactValues] = useState(initialFormValues)
  const [newContactForm, showNewContactForm] = useState(false)
  const [newAssociationForm, showNewAssociationForm] = useState(false)
  const [clientFeedbacks, setClientFeedbacks] = useState([])
  const [feedbackModal, toggleFeedbackModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [searchValue, setSearchValue] = useState('')
  const [searchField, setSearchField] = useState('')
  //const [searchBy, setSearchBy] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [preloader, setPreloader] = useState(false)

  const [nameSearchValue, setNameSearchValue] = useState('')
  const [phoneSearchValue, setPhoneSearchValue] = useState('')
  const [emailSearchValue, setEmailSearchValue] = useState('')
  const [tempTagValue, setTempTagValue] = useState('')
  const [tempTagValueError, setTempTagValueError] = useState('')
  const [wasEdited, setWasEdited] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [saveClientBtnText, setSaveClientBtnText] = useState(
    clientId
      ? t('button.save', { ns: 'common' })
      : t('button.create', { ns: 'common' }),
  )

  const [newRow, showNewRow] = useState(false)
  const [newProposalForm, showNewProposalForm] = useState(false)
  const [isClientDeleted, setIsClientDeleted] = useState(false)
  const [appointmentForm, showAppointmentForm] = useState(false)

  const [appointmentError, setAppointmentError] = useState(initialErrors)
  const [loading, setLoading] = useState(false)
  const [clientLoading, setClientLoading] = useState(false)
  const [toggleModalHeandler, setToggleModalHeandler] = useState(false)
  const [activeTab, setActiveTab] = useState('Personal Info')
  const [entity_id, setEntity_Id] = useState(
    clientFormValues?.client_properties?.[0]?.value?.id,
  )
  const [entityType, setEntityType] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const [fullAppointment, setFullAppointment] = useState(null)
  const [
    clientsForNotificationModal,
    setClientsForNotificationModal,
  ] = useState([])
  const [
    showNotificationUpcomingAppointmentModal,
    setShowNotificationUpcomingAppointmentModal,
  ] = useState(false)
  const [clientForm, showClientForm] = useState(false)

  const [callsHistory, setCallsHistory] = useState([])

  const feedbackModalHeandle = value => {
    toggleFeedbackModal(value)
  }
  const setEntityIdHeandler = id => {
    setEntity_Id(id)
  }
  const setEntityTypeHeandler = type => {
    setEntityType(type)
  }

  const toggleClientTrue = () => {
    showClientForm(prev => true)
    // showNewContactForm(true)
  }
  const toggleClientFalse = () => {
    showClientForm(prev => false)
  }
  const changeEntityHeandler = e => {
    const { checked } = e.target
    const { value } = clientFormValues?.type
    setIsChecked(prev => !prev)
    if (!checked) {
      value === 1
        ? setEntityTypeHeandler(!isChecked ? 3 : 1)
        : setEntityTypeHeandler(!isChecked ? 1 : 2)
      setEntity_Id(
        entity_id || clientFormValues?.client_properties?.[0]?.value?.id,
      )
      return
    }
    onResetValue()
  }

  const onResetValue = () => {
    setEntityIdHeandler(null)
    setEntityTypeHeandler(null)
  }

  const toggleNewProposalForm = () => {
    showNewProposalForm(!newProposalForm)
  }
  const toggleAppointmentForm = () => showAppointmentForm(!appointmentForm)

  const [renderCounter, setRenderCounter] = useState(0)

  const [modal, setModal] = useState(false)

  const toggleModal = () => setModal(!modal)

  //this hooks just for running search when some of search values changes
  const [resultSearchValue, setResultSearchValue] = useState({
    field: '',
    value: '',
  })
  useEffect(
    () => () => {
      setNameSearchValue('')
      setPhoneSearchValue('')
      setEmailSearchValue('')
      setResultSearchValue('')
    },
    [],
  )

  useEffect(() => {
    const resultSearchValue = nameSearchValue
    setShowSuggestions(!!resultSearchValue)
    setResultSearchValue({ field: 'name', value: resultSearchValue })
  }, [nameSearchValue])
  useEffect(() => {
    const resultSearchValue = phoneSearchValue
    setShowSuggestions(!!resultSearchValue)
    setResultSearchValue({ field: 'phone', value: resultSearchValue })
  }, [phoneSearchValue])
  useEffect(() => {
    const resultSearchValue = emailSearchValue
    setShowSuggestions(!!resultSearchValue)
    setResultSearchValue({ field: 'email', value: resultSearchValue })
  }, [emailSearchValue])

  useEffect(() => {
    if (!!onSubmitting) onSubmitting(isSubmitting)
  }, [isSubmitting])
  //////////////////////////////////////////////////////////////////////////
  const getRelevantAction = clientType => {
    const props = {
      getClientById: null,
      setCurrentClient: null,
      createClient: null,
      updateClient: null,
      getClientFeedback: null,
      updateClientFeedback: null,
      createClientFeedback: null,
      deleteClientFeedback: null,
      createRelationProperty: null,
      dellAttachedProperty: null,
      createClientAssociation: null,
      dellClientAssociation: null,
      searchClients: null,
      currentClient: null,
      changeField: null,
      deleteClient: null,
    }

    if (clientType !== 1 && !isOrganization) {
      props.getClientById = getClientById
      props.setCurrentClient = setCurrentClient
      props.createClient = createClient
      props.updateClient = updateClient
      props.getClientFeedback = getClientFeedback
      props.updateClientFeedback = updateClientFeedback
      props.createClientFeedback = createClientFeedback
      props.deleteClientFeedback = deleteClientFeedback
      props.createRelationProperty = createRelationProperty
      props.dellAttachedProperty = dellAttachedProperty
      props.createClientAssociation = createClientAssociation
      props.dellClientAssociation = dellClientAssociation
      props.searchClients = searchClientsV2
      props.currentClient = currentClient
      props.changeClientField = changeClientField
      props.deleteClient = deleteClient
    } else {
      props.getClientById = getOrganizationById
      props.setCurrentClient = setCurrentOrganization
      props.createClient = createOrganization
      props.updateClient = updateOrganization
      props.getClientFeedback = getOrganizationFeedback
      props.updateClientFeedback = updateOrganizationFeedback
      props.createClientFeedback = createOrganizationFeedback
      props.deleteClientFeedback = deleteOrganizationFeedback
      props.createRelationProperty = createOrganizationRelationProperty
      props.dellAttachedProperty = dellOrganizationAttachedProperty
      props.createClientAssociation = dellOrganizationAssociation
      props.dellClientAssociation = createOrganizationAssociation
      props.searchClients = searchClientsV2
      props.currentClient = currentClient
      props.changeClientField = changeOrganizationField
      props.deleteClient = deleteOrganization
    }
    return props
  }
  let findedClients = useSearch(
    resultSearchValue.value,
    () => {
      const relevantProps = getRelevantAction(clientFormValues.type.value)
      const searchType = { type: clientFormValues.type.value === 1 ? 1 : 2 }
      return relevantProps.searchClients({
        [resultSearchValue.field]: resultSearchValue.value,
        ...searchType,
      })
    },
    200,
    'address',
    1,
    10,
  )
  if (!!findedClients && !Array.isArray(findedClients))
    findedClients = Object.values(findedClients)
  if (
    Array.isArray(findedClients) &&
    (organizationId || clientFormValues.id.value)
  ) {
    const id = clientFormValues.id.value
      ? clientFormValues.id.value
      : organizationId
    findedClients = findedClients?.filter(item => item.id !== id)
  }
  if (
    Array.isArray(findedClients) &&
    Array.isArray(allreadyAttachedClients) &&
    allreadyAttachedClients.length
  ) {
    findedClients = findedClients?.filter(
      client =>
        !allreadyAttachedClients.find(
          attachedClient => client?.id === attachedClient?.id,
        ),
    )
  }

  const getClient = (id, justContacts) => {
    setPreloader(true)
    const relevantProps = getRelevantAction(clientFormValues.type.value)

    return relevantProps
      .getClientById(id)
      .then(res => {
        setIsClientDeleted(!!res?.deleted_at)
        setSaveClientBtnText('Save')
        if (propertyRelationNote) {
          //res.property_relation = propertyRelationNote
          res.relation = propertyRelationNote
        }
        res.property_id = propertyRelationId //need to update relation name in client and property
        res.contact_relation_id = contactRelationId
        if (!res.type) res.type = ''
        const formatedClient = Object.keys(res).reduce((acc, curr) => {
          if (Array.isArray(res[curr])) {
            if (res[curr].length) {
              acc[curr] = res[curr].map((item, idx) => ({
                value: item,
                error: '',
              }))
            } else {
              if (curr === 'phones') {
                acc[curr] = [
                  {
                    value: {
                      id: 0,
                      phone: '',
                      type: 'cell',
                      preference: '',
                    },
                    error: '',
                  },
                ]
              } else if (curr === 'emails') {
                acc[curr] = [
                  {
                    value: {
                      id: 0,
                      email: '',
                      preference: false,
                      send_promo: false,
                      send_reminder: false,
                    },
                    error: '',
                  },
                ]
              } else acc[curr] = []
            }
          } else {
            acc[curr] = { value: res[curr], error: '' }
          }
          return acc
        }, {})
        if (setCurrentNameToModalTitle && !justContacts)
          setCurrentNameToModalTitle(res.name)
        setClientFormValues(prev =>
          justContacts
            ? {
                ...prev,
                contacts: formatedClient.contacts
                  ? formatedClient.contacts
                  : [],
                organizations: formatedClient.organizations
                  ? formatedClient.organizations
                  : [],
                client_associations: formatedClient.client_associations
                  ? formatedClient.client_associations
                  : [],
              }
            : { ...prev, ...formatedClient },
        )
        formatedClient && heandleGetEntities(formatedClient)
        setWasEdited(prev => (justContacts ? prev : false))
        setIsClientDeleted(!!res?.deleted_at)

        wasClientDeletedHeandler(!!res?.deleted_at)
        setEntityName?.(res?.name)
        // setEntity?.(formatedClient)
        return res
      })

      .then(res => {
        return setClientFeedbacks(res.feedbacks)
        // return setClientFeedbacks(feedbaks.data)
      })
      .catch(error => {
        if (error?.response?.status === 404) setIsClientDeleted(true)
      })
      .finally(() => setPreloader(false))
  }
  useEffect(() => {
    if (!!findedClients && !!findedClients.length) {
      findedClients = []
      setShowSuggestions(false)
    }
  }, [clientFormValues.type.value])

  useEffect(() => {
    if (clientId) {
      getClient(clientId?.id || clientId)
      if (propertyRelation) {
        let top = 800
        if (isAppointment) top = 1250
        if (isServiceContract) top = 200
        window.scrollTo({
          top,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
    if (!!findedClients && !!findedClients.length) {
      findedClients = []
    }
  }, [clientId])

  useEffect(() => {
    if (clientFormValues.isProposalFormSetClient) {
      setClientFormValues(prev => ({
        ...prev,
        isProposalFormSetClient: false,
      }))
      onSubmit()
      const data = prepareValuesToServer()
      setCurrentClient(data)
    }
  }, [clientFormValues.client_associations])

  // useEffect(() => {
  //   if (clientFormValues.isAttachProperies) {
  //     setClientFormValues(prev => ({
  //       ...prev,
  //       isAttachProperies: false,
  //     }))
  //     onSubmit()
  //     // const data = prepareValuesToServer()
  //     // setCurrentClient(data)
  //   }
  // }, [clientFormValues.client_properties])

  useEffect(() => {
    if (isCloseClientForm) {
      resetState()
      setIsCloseClientForm(false)
    }
  }, [isCloseClientForm])

  // useEffect(() => {
  //   setEntity_Id(clientFormValues?.id?.value)
  //   setEntityType(+clientFormValues?.type?.value === 1 ? 3 : 2)
  // }, [clientFormValues])

  //10.05.2020 - from now phones and emails will be delete/edit on save all form on backend
  /* const checkEditingOfItem = (name, i, param, isValid = true) => {
    const isAlreadyExist = param[`edited_${name}`].find(item => item.value.id === param[name][i].value.id)
    if (param[name][i].value.id && isValid) {
      if (param[`edited_${name}`].length && isAlreadyExist) {
        const indexOfExistEditedItem = param[`edited_${name}`].findIndex(
          item => item.value.id === param[name][i].value.id,
        )
        param[`edited_${name}`][indexOfExistEditedItem] = param[name][i]
      } else {
        param[`edited_${name}`].push(param[name][i])
      }
    }
  } */

  const validateField = (e, i) => {
    const { name, value } = e.target
    let param = { ...clientFormValues }

    if (!value) return

    if (name === 'phones') {
      let x = value.replace(/\D/g, '')

      const isFullPhone = !x || x.length === 10
      const isAlreadyExist = isFullPhone
        ? !!param[name].find(
            (item, idx) => item.value.phone === value && i !== idx,
          ) && !!value
        : false

      param[name][i].error = isFullPhone
        ? !value
          ? 'Please input phone number'
          : false
        : 'Please input valid phone number'
      const isValid = (isFullPhone && !isAlreadyExist) || !x
      if (isAlreadyExist)
        param[name][i].error =
          'That phone already exist. Please input another one'

      setIsPhoneValid(isValid)
    }

    if (name === 'emails') {
      const isEmail = validator.isEmail(value)
      const isAlreadyExist =
        param[name].find(
          (item, idx) =>
            item.value.email.toLowerCase() ===
              e.currentTarget.value.toLowerCase() && i !== idx,
        ) && value
      if (!isEmail) param[name][i].error = 'Please input valid email address'
      if (isAlreadyExist) {
        param[name][i].error = 'That email is already exist - input another one'
      }
      setIsEmailValid(isEmail && !isAlreadyExist)
    }
  }

  const onChange = (e, i) => {
    let { name, value } = e.currentTarget
    let param = { ...clientFormValues }
    let isValid = true
    if (name === 'emails') {
      //setShowSuggestions(true)
      // const isEmail = validator.isEmail(value)
      // const isAlreadyExist =
      //   param[name].find(
      //     (item, idx) =>
      //       item.value.email === e.currentTarget.value && i !== idx,
      //   ) && value
      // isValid = (isEmail && !isAlreadyExist) || !value
      // if (!isEmail) param[name][i].error = 'Please input valid email address'
      // if (isAlreadyExist) {
      //   param[name][i].error = 'That email is already exist - input another one'
      // }
      // const isEmailSecond = validator.isEmail(value) && !isAlreadyExist
      // if (e.currentTarget.value.length !== 0) setIsEmailValid(isEmailSecond)

      setEmailSearchValue(value)
      if (param[name][i].value.id) {
        if (value) {
          param.deleted_email_ids = param.deleted_email_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          const isExistInEdited =
            param.edited_phone_ids.findIndex(
              item => item.value === param[name][i].value.id,
            ) >= 0
          if (!isExistInEdited)
            param.edited_email_ids.push({ value: param[name][i].value.id })
        } else {
          param.edited_email_ids = param.edited_email_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          /* const isExistInDeleted = param.deleted_email_ids.findIndex(item => item.value === param[name][i].value.id) >= 0
          if (!isExistInDeleted)
          param.deleted_email_ids.push({value: param[name][i].value.id}) */
        }
      }
      //setSearchBy('email')
      //setSearchValue(e.currentTarget.value)
    }

    /* if (clientId) {
      if (name === 'emails') {
        checkEditingOfItem(name, i, param, isValid)
      }
    } */

    if (name === 'name') {
      value = value
        .trimStart()
        .replace(/\s/gis, ' ')
        .replace(/[!!@#$%^*(){}\[\]"|,`\/\\=?+<>]/gi, '')
      // .replace(/[^a-zA-Z'\s]/gi, '')
      isValid = !!value
      param[name].error = isValid ? '' : 'Please input user name'

      setNameSearchValue(value)
      /* setSearchBy('name')
      setSearchValue(value) */
    }

    if (name === 'phones') {
      //setShowSuggestions(true)
      value = e.target.value
      if (value === '(XXX) XXX-XXXX') value = ''
      let x = value.replace(/\D/g, '')
      //       const isFullPhone = !x || x.length === 10
      //       const isAlreadyExist = isFullPhone
      //         ? !!param[name].find((item, idx) => item.value.phone === value && i !== idx) && !!value
      //         : false
      //
      //       param[name][i].error = isFullPhone
      //         ? !value
      //           ? 'Please input phone number'
      //           : false
      //         : 'Please input valid phone number'
      //       isValid = (isFullPhone && !isAlreadyExist) || !x
      //       if (isAlreadyExist) param[name][i].error = 'That phone already exist. Please input another one'
      //       /* if (i === 0) { */
      setPhoneSearchValue(
        value
          .replace(/X/g, '')
          .replace(/-$/, '')
          .replace(/\s+$/, '')
          .replace(/\)$/, ''),
      )
      // /*  } */
      // setIsPhoneValid(isValid)
      if (param[name][i].value.id) {
        if (x) {
          param.deleted_phone_ids = param.deleted_phone_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          const isExistInEdited =
            param.edited_phone_ids?.findIndex(
              item => item.value === param[name][i].value.id,
            ) >= 0
          if (!isExistInEdited)
            param.edited_phone_ids?.push({ value: param[name][i].value.id })
        } else {
          param.edited_phone_ids = param.edited_phone_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          /* const isExistInDeleted = param.deleted_phone_ids.findIndex(item => item.value === param[name][i].value.id) >= 0
          if (!isExistInDeleted)
          param.deleted_phone_ids.push({value: param[name][i].value.id}) */
        }
      }
    }

    const nameOfValue = name.replace(/.$/, '')
    if (typeof i === 'undefined') {
      param[name].value = value
      if (name === 'relation') {
        param.property_relation = value
      }
    } else {
      param[name][i].value[nameOfValue] = value
    }
    if (name !== 'notes') {
      if (e.currentTarget.value.length > 500) isValid = false
      if (!isValid) {
      } else {
        e.currentTarget.classList.remove('has_error')
        e.currentTarget.classList.remove('av-invalid')
        e.currentTarget.classList.remove('is-invalid')
        e.currentTarget.classList.remove('is-pristine')

        e.currentTarget.classList.remove('is-touched')
        typeof i === 'undefined'
          ? (param[name].error = false)
          : (param[name][i].error = false)
      }
    }
    ///////////
    /* if (clientId) {
      if (name === 'phones') {
        checkEditingOfItem(name, i, param, isValid)
      }
    } */
    /////////
    setWasEdited(true)

    setSearchField(prev =>
      (name === 'name' || name === 'emails' || name === 'phones') && !!value
        ? name
        : prev,
    )
    if (name === 'name') {
      //const { changeClientField } = getRelevantAction(clientFormValues.type.value)
      if (setCurrentNameToModalTitle) setCurrentNameToModalTitle(value)
      //changeClientField(name, value)
    }

    setClientFormValues(param)
  }

  const addRow = type => {
    let param = { ...clientFormValues }
    let value
    if (type === 'phones') {
      value = {
        value: {
          id: 0,
          phone: '',
          type: 'cell',
          preference: '',
        },
        error: '',
      }
    }
    if (type === 'emails') {
      value = {
        value: {
          id: 0,
          email: '',
          preference: false,
          send_promo: false,
          send_reminder: false,
        },
        error: '',
      }
    }
    param[type].push(value)
    setWasEdited(true)

    setClientFormValues(param)
  }
  const removeRow = (type, i) => {
    let param = { ...clientFormValues }

    if (param[type][i].value.id) {
      param[`edited_${type.replace(/s$/, '')}_ids`] = param[
        `edited_${type.replace(/s$/, '')}_ids`
      ].filter(item => item.value !== param[type][i].value.id)
      const isExist =
        param[`deleted_${type.replace(/s$/, '')}_ids`].findIndex(
          item => item.value === param[type][i].value.id,
        ) >= 0
      if (!isExist)
        param[`deleted_${type.replace(/s$/, '')}_ids`].push({
          value: param[type][i].value.id,
        })
    }
    if (param[type].length === 1) {
      // param[type][i].value[type.replace(/s$/, '')] = ''
      param[type][i] = type === 'phones' ? initialPhone : initialEmail
    } else {
      param[type].splice(i, 1)
    }
    setWasEdited(true)

    setClientFormValues(param)
  }

  const onChangeSelect = (value, i) => {
    const param = { ...clientFormValues }
    param.phones[i].value.type = value
    //checkEditingOfItem('phones', i, param)
    setWasEdited(true)

    setClientFormValues(param)
  }

  const onChangeType = e => {
    const { name, value } = e.target
    const param = { ...clientFormValues }
    param[name].value = value
    localStorage.setItem('contacts-type', value)
    setWasEdited(true)
    setClientFormValues(param)
  }

  const handleChangePhonePref = (i, value) => {
    let form = { ...clientFormValues }
    if (form.phones[i].value.preference !== value) {
      setWasEdited(true)
    }
    form.phones[i].value.preference = value
    setClientFormValues(form)
  }

  useEffect(() => {
    const propertyId = clientFormValues?.client_properties?.[0]?.value?.id
    if (propertyId) {
      setEntity_Id(propertyId)
      setEntityType(1)
    }

    return () => {
      setEntity_Id(null)
      setEntityType(null)
    }
  }, [clientFormValues])

  const handleCreateAppointment = async data => {
    if (!data?.column_template_id?.length)
      return setAppointmentError(prev => ({
        ...prev,
        column_template_id: 'This field is required',
      }))

    setLoading(true)
    try {
      const response = await createAppointment({
        ...data,
        entity_id: entity_id ? entity_id : clientFormValues?.id?.value,
        entity_type: entityType
          ? entityType
          : +clientFormValues?.type?.value === 1
          ? 3
          : 2,
        date: data?.date?.map(date => moment(date).format('YYYY-MM-DD')),
        time_start:
          data?.time_start || data?.time_start === 0
            ? moment(data?.time_start).format('HH:mm')
            : null,
        time_end: data?.time_end
          ? moment(data?.time_end).format('HH:mm')
          : null,
        matrix_time_start:
          data.matrix_time_start || data.matrix_time_start === 0
            ? moment(data.matrix_time_start).format('HH:mm')
            : null,
        matrix_time_end: data.matrix_time_end
          ? moment(data.matrix_time_end).format('HH:mm')
          : null,
        column_template_id: data.column_template_id?.filter(
          value => typeof value === 'number',
        ),
        queue_id: data.column_template_id
          ?.filter(value => typeof value === 'string')
          ?.map((value: string) => +value),
      })

      showAppointmentForm(false)

      // const createdAppointment = await getAppointment(
      //   response.appointment?.[0]?.id,
      // )
      const createdAppointment = response.appointment?.[0]

      setClientFormValues(prev => ({
        ...prev,
        appointments: [
          ...prev.appointments,
          { value: createdAppointment, error: '' },
        ],
      }))

      // if (data?.assign_to_matrix) {
      //   const createdAppointment = await getAppointment(
      //     response.appointment?.[0],
      //   )

      //   setFullAppointment(createdAppointment)
      //   setShowNotificationUpcomingAppointmentModal(true)
      // }

      // getClient(clientId)

      openNotificationWithIcon('success', {
        message: 'Appointment has beed created successfully',
      })
    } catch (error) {
      let errors = error?.response?.data
      let message = error?.response?.data?.message || 'Invalid data'

      if (!!error?.response?.data?.count_days) {
        message = 'This time had been taken by another appointment'
      }

      setAppointmentError(errors)
      openNotificationWithIcon('error', { message })
    } finally {
      setLoading(false)
    }
    //////
    /* setLoading(true)
    createAppointment({
      ...data,
      entity_id: entity_id ? entity_id : clientFormValues?.id?.value,
      entity_type: entityType ? entityType : +clientFormValues?.type?.value === 1 ? 3 : 2,
      date: data.date ? moment(data.date).format('YYYY-MM-DD') : null,
      time_start: data?.time_start || data?.time_start === 0 ? moment(data?.time_start).format('HH:mm') : null,
      time_end: data?.time_end ? moment(data?.time_end).format('HH:mm') : null,
      matrix_time_start:
        data.matrix_time_start || data.matrix_time_start === 0 ? moment(data.matrix_time_start).format('HH:mm') : null,
      matrix_time_end: data.matrix_time_end ? moment(data.matrix_time_end).format('HH:mm') : null,
    })
      .then(r => {
        // if (data.notes.length) {
        //   apiNotes
        //     .postEntityNote(`/appointments/${r?.appointment?.id}/notes`, { text: data.notes })
        //     .then(r => r)
        //     .catch(e => console.error(e))
        // }
        if (data.assign_to_matrix) {
          getClientsForNotificationModal(r.appointment.id).then()
          setFullAppointment(r?.appointment)
          setShowNotificationUpcomingAppointmentModal(true)
        }
      })
      .then(() => {
        showAppointmentForm(false)
        getClient(clientId)

        openNotificationWithIcon('success', { message: 'Appointment has been created successfully' })
      })
      .catch(error => {
        setAppointmentError(error.response.data?.errors)
        openNotificationWithIcon('error', { message: 'Invalid data' })
      })
      .finally(() => {
        setLoading(false)
      }) */
  }

  const onCheck = (e, type, i, phone1, phone2) => {
    let param = { ...clientFormValues }
    switch (type) {
      case 'voice':
        param.phones[i].value.preference = e.target.checked
          ? 'voice'
          : phone2
          ? 'text'
          : null
        break
      case 'text':
        param.phones[i].value.preference = e.target.checked
          ? 'text'
          : phone1
          ? 'voice'
          : null
        break
      case 'send_promo':
        param.emails[i].value.send_promo = e.target.checked
        break
      case 'send_reminder':
        param.emails[i].value.send_reminder = e.target.checked
        break
      case 'preference': {
        if (!param.emails[i].value.preference) {
          param.emails.map((item, idx) => {
            if (item.value.preference) {
              item.value.preference = false
            }
          })
          param.emails[i].value.preference = e.target.checked
        } else {
          param.emails = param.emails.map((item, idx) => ({
            ...item,
            value: { ...item.value, preference: false },
          }))
        }
        break
      }
      default:
        break
    }

    setWasEdited(true)

    setClientFormValues(param)
  }

  const onAddTag = () => {
    let param = { ...clientFormValues }
    const setTag = tag => {
      param.tags.push({ value: tag, error: '' })
      setClientFormValues(param)
      setWasEdited(true)
    }
    addTag(
      tempTagValue,
      clientFormValues.tags.map(item => item.value),
      setTag,
      () => setTempTagValue(''),
    )
  }

  const onChangeTempTagValue = e => {
    const { value } = e.target
    const error =
      value && clientFormValues.tags.length >= 20 ? 'Maximum 20 tags' : ''
    setTempTagValueError(error)
    setTempTagValue(value)
  }

  const onDeleteTag = i => {
    let param = { ...clientFormValues }
    param.tags.splice(i, 1)
    setClientFormValues(param)
    setWasEdited(true)
  }

  const resetState = () => {
    setClientFormValues(initialFormValues)
    setCurrentClient({})
    setClientFeedbacks([])
    feedbackModalHeandle(false)
    setIsSubmitting(false)
    setShowSuggestions(false)
    setWasEdited(false)
    setModal(false)
    setIsDeleting(false)
  }

  const onClose = e => {
    if (reloadOnCancel && (clientId || organizationId)) {
      return getClient(clientId)
    }
    resetState()
    setRenderCounter(0)

    if (isAppointment && !!toggleEditClient) toggleEditClient()

    if (closeForm) closeForm(e)
  }

  const onCancel = e => {
    if (!!organizationId || isClient) {
      onClose(e)
      setToggleModalHeandler()
    } else {
      feedbackModalHeandle(false)
      setIsSubmitting(false)
      setIsDeleting(false)
      setShowSuggestions(false)
      setWasEdited(false)
      setModal(false)
    }
  }

  const onDeleteClient = e => {
    setIsDeleting(true)
    const relevantProps = getRelevantAction(clientFormValues.type.value)
    relevantProps
      .deleteClient(clientId)
      .then(() => {
        openNotificationWithIcon('success', {
          message: 'Client was successfully deleted',
          description: '',
          key: 'client-delete-success',
        })
        onClose(e)
        fetchClientsList()
        if (call_tracking) {
          queryClient.invalidateQueries({ queryKey: 'calls/incoming_table' })
        }
      })
      .catch(() => {
        setIsDeleting(false)
      })
  }

  const closeFormOnSave = e => {
    if (!isPageClientForm) onCancel(e)
    else if (history) {
      resetState()
      history.push('/clients')
    }
  }

  const checkIsValid = () => {
    const obj = JSON.parse(JSON.stringify(clientFormValues))
    const isEmails = obj.emails?.filter(item => item.value?.email?.length)
      .length
    let isValid = true
    if (
      obj.phones?.filter(item => item.error === true).length ||
      !isPhoneValid
    ) {
      isValid = false
    } /* else {
      const phones = obj.phones.filter(item => !!item.value.phone)
      if (phones.length > 0 && !!obj.phones[0]) {
        obj.phones[0].error = 'Please input phone number'
        isValid = false
      }
    } */
    if (
      obj.emails?.filter(item => item.error === true).length ||
      (isEmails && !isEmailValid)
    ) {
      isValid = false
    }

    if (
      !obj.type.value &&
      !organizationId &&
      !isOrganization &&
      !isAssociation &&
      !isContact &&
      !isClient
    ) {
      obj.type.error = 'Please select type'
      isValid = false
    }
    if (!obj.name.value) {
      obj.name.error = 'Please input user name'
      isValid = false
    }

    setClientFormValues(prev => (isValid ? prev : obj))
    return isValid
  }

  const prepareValuesToServer = () => {
    const data = Object.keys(clientFormValues).reduce((acc, curr) => {
      if (Array.isArray(clientFormValues[curr])) {
        acc[curr] = clientFormValues[curr].map(item => item?.value)
        return acc
      } else {
        if (clientFormValues[curr]?.value) {
          acc[curr] = clientFormValues[curr]?.value
        }
        return acc
      }
    }, {})

    if (!data.owner_user_id) data.owner_user_id = currentCompanyOwnerId

    if (organizationId) {
      data.type = 3
    }
    if (isOrganization) {
      data.type = 1
    }
    if (isAssociation || isClient) {
      data.type = 2
    }
    if (isContact) {
      data.type = 3
    }

    data.type = clientFormValues.type.value

    if (Array.isArray(data.phones)) {
      data.phones = data.phones.reduce((acc, item) => {
        if (item.phone) {
          // if (!item.preference) item.preference = 'voice'
          if (!item.entity_type) item.entity_type = data.type === 1 ? 1 : 2
          acc.push(item)
        } else if (item.id) {
          data.deleted_phone_ids.push(item.id)
        }
        return acc
      }, [])
    } else {
      data.phones = []
    }

    data.contact_role = data.relation
    if (Array.isArray(data.emails)) {
      data.emails = data.emails.reduce((acc, item) => {
        if (item.email) {
          if (!item.entity_type) item.entity_type = data.type === 1 ? 1 : 2
          acc.push(item)
        } else if (item.id) {
          data.deleted_email_ids.push(item.id)
        }
        return acc
      }, [])
      /* if (!!data.emails.length && data.emails.every(email => !email.preference)) {
        data.emails[0].preference = true
      } */
    } else {
      data.emails = []
    }

    // if (
    //   !!data.relations_with_property.length &&
    //   typeof data.relations_with_property[0] === 'object' &&
    //   !Object.keys(data.relations_with_property[0]).length
    // )
    //   data.relations_with_property = []
    if (!!organizationId && !data.organization_id)
      data.organization_id = organizationId

    data.type = clientFormValues.type.value

    data.is_individual = data.type === 2 || data.type === 3

    //if (!data.relations_with_property.length) delete data.relations_with_property
    return data
  }

  const onSubmit = async () => {
    const isValid = checkIsValid()
    if (isValid) {
      setIsSubmitting(true)
      if (isAssociation && parentAssociations?.length) {
        const associationToUpdate = parentAssociations?.find(
          ass => ass.client_id === clientId,
        )
        const currentAssociationNote = associationToUpdate?.note
        const newAssociationNote = clientFormValues.relation?.value

        if (
          associationToUpdate &&
          currentAssociationNote !== newAssociationNote
        ) {
          await updateAssociation(
            associationToUpdate.id,
            newAssociationNote,
          ).catch(e => {
            openNotificationWithIcon('error', {
              message: e?.response?.data?.name[0],
            })
          })
        }
      }
      const data = prepareValuesToServer()
      const relevantProps = getRelevantAction(clientFormValues.type.value)

      if (!data.id) {
        setPreloader(true)
        let clientId
        relevantProps
          .createClient(data)
          .then(response => {
            const { id, type, note } = response || {}
            clientId = response.id

            if (setEntityId)
              setEntityId({
                id: response.id,
                entity_type: response?.type === 1 ? 3 : 2,
              })

            openNotificationWithIcon('success', {
              message: `${
                type === 1
                  ? t('heading.organizatoin', { ns: 'common' })
                  : t('heading.contact', { ns: 'common' })
              } has been created successfully`,
              description: '',
              key: 'client-create-success',
            })

            setClientFormValues(prev => ({
              ...prev,
              id: { ...prev.id, value: response.id },
            }))
            //onClose()

            if (!!note) {
              apiNotes
                .postEntityNote(
                  `/${type === 1 ? 'organizations' : 'clients'}/${id}/notes`,
                  { text: note },
                )
                .catch(e => console.error(e))
            }

            return response
          })
          .then(res => {
            if (typeof attachClientAfterSave === 'function' && isProposal)
              attachClientAfterSave(res)
          })
          .then(() => {
            if (onAddAttachedClient) {
              onAddAttachedClient({
                relation_name: data.relation,
                id: clientId,
              })
              closeFormOnSave()
              return 'submitting finished'
            } else {
              closeFormOnSave()
              return 'submitting finished'
            }
            // if (onAddAttachedClient) {
            //   relevantProps.getClientById(clientId).then(resp => {
            //     onAddAttachedClient({
            //       relation_name: data.relation,
            //       ...resp,
            //     })
            //     closeFormOnSave()
            //     return 'submitting finished'
            //   }
            //   )
            // }
            // else {
            //   //closeFormOnSave()
            //   return 'submitting finished'
            // }
          })
          .then(() => {
            if (!propertyRelationId) {
              return fetchClientsList()
            } else fetchProperty(propertyRelationId)
          })
          .then(() => {
            getClient(clientId)
            updateClientList && updateClientList()
          })
          .catch(e => {
            setIsSubmitting(false)
            console.error(e)
            openNotificationWithIcon('error', {
              message: 'Error!',
              description: `${e?.response?.data?.name?.[0] ||
                'Something went wrong!'}`,
            })
            // notification.error({ message: e?.response?.data?.name[0] })
          })
          .finally(() => {
            /* if (isProposal)  */
            setSaveClientBtnText('Save')
            setPreloader(false)
            setShowSuggestions(false)
            setIsSubmitting(false)
            showNewContactForm(false)
          })
      } else {
        relevantProps
          .updateClient(data.id, data)
          .then(response => {
            setClientFormValues(prev => ({
              ...prev,
              client_associations: response.client_associations?.map(ass => ({
                value: ass,
                error: '',
              })),
            }))
            if (onAddAttachedClient)
              onAddAttachedClient({
                relation_name: data.relation,
                ...data,
              }).then(() => {
                let top = 800
                if (isAppointment) top = 1250
                if (isServiceContract) top = 200
                window.scrollTo({
                  top,
                  left: 0,
                  behavior: 'smooth',
                })
              })
            openNotificationWithIcon('success', {
              message: `${
                data.type === 1
                  ? t('heading.organizatoin', { ns: 'common' })
                  : t('heading.contact', { ns: 'common' })
              } ${t('hasBeenUpdate')}`,
              description: '',
              key: 'client-update-success',
            })
            return 'ok' //Promise.all(promises)
          })
          .then(() => closeFormOnSave())
          .then(() => {
            if (!propertyRelationId) {
              return fetchClientsList()
            } else {
              fetchProperty(propertyRelationId)
              fetchClientsList()
            }
          })
          .finally(() => {
            // getClient(clientFormValues?.id?.value)
            updateClientList()
            setPreloader(false)
            setShowSuggestions(false)
            setIsSubmitting(false)
          })
          .catch(e => {
            setIsSubmitting(false)
            console.log(e)
            openNotificationWithIcon('error', {
              message: 'Error!',
              description: e?.response?.data?.name?.[0] || '',
            })
          })
      }
    } else {
      if (onAddAttachedClient) {
        let top = 200
        if (isAppointment) top = 1250
        if (isServiceContract) top = 200
        window.scrollTo({
          top,
          left: 0,
          behavior: 'smooth',
        })
      } else {
        const modal = document.getElementsByClassName('fade modal show')[0]
        const scrollToRef = ref => {
          const top = ref?.current?.offsetTop || 0
          modal &&
            modal.scrollTo({
              top,
              left: 0,
              behavior: 'smooth',
            })
        }
        scrollToRef(clientInputRow)
      }
      return
    }

    // if (clientFormValues.detached_organizations_ids.length) {
    //   detachOrganizations(clientFormValues.id.value, {
    //     organization_ids: [
    //       ...new Set(clientFormValues.detached_organizations_ids),
    //     ],
    //   }).then(() => {
    //     setClientFormValues(prev => ({
    //       ...prev,
    //       detached_organizations_ids: [],
    //     }))
    //   })
    // }

    // if (clientFormValues.detached_contacts_ids.length) {
    //   detachContacts(clientFormValues.id.value, {
    //     client_ids: [...new Set(clientFormValues.detached_contacts_ids)],
    //   }).then(() => {
    //     setClientFormValues(prev => ({
    //       ...prev,
    //       detached_contacts_ids: [],
    //     }))
    //   })
    // }
  }

  const onCreateFeedback = (id, data) => {
    const relevantProps = getRelevantAction(clientFormValues.type.value)

    const relevantData = {
      ...data,
      entity_type: clientFormValues.type.value === 1 ? 1 : 2,
    }

    return relevantProps.createClientFeedback(id, relevantData).then(() => {
      if (propertyRelationId) fetchProperty(propertyRelationId)
      return relevantProps
        .getClientById(id)
        .then(res => setClientFeedbacks(res.feedbacks))
    })
  }
  const onDeleteFeedback = (id, userId) => {
    const relevantProps = getRelevantAction(clientFormValues.type.value)
    return relevantProps.deleteClientFeedback(id).then(() => {
      if (propertyRelationId) fetchProperty(propertyRelationId)
      return relevantProps
        .getClientById(userId)
        .then(res => setClientFeedbacks(res.feedbacks))
    })
  }
  const onSaveFeedback = (id, data, userId) => {
    const relevantProps = getRelevantAction(clientFormValues.type.value)
    return relevantProps.updateClientFeedback(id, data).then(() => {
      if (propertyRelationId) fetchProperty(propertyRelationId)
      return relevantProps
        .getClientById(userId)
        .then(res => setClientFeedbacks(res.feedbacks))
    })
  }

  const onAddAssociation = data => {
    return Promise.resolve().then(() => {
      setClientFormValues(prev => ({
        ...prev,
        isProposalFormSetClient: true,
        client_associations: [
          ...prev.client_associations,
          {
            value: {
              id: 0,
              client_id: data.id,
              association_client_id: clientFormValues.id.value,
              note: data.relation_name,
              client: data,
            },
            error: '',
          },
        ],
      }))
      setIsCloseClientForm?.(false)

      return setWasEdited(false)
    })
  }

  const onDellAssociation = client => {
    return Promise.resolve().then(() => {
      const associations = [...clientFormValues.client_associations]
      const deletedIdx = clientFormValues.client_associations.findIndex(
        item => item?.value?.client?.id === client?.id,
      )
      const deletedAssociationId =
        clientFormValues.client_associations[deletedIdx].value.id
      associations.splice(deletedIdx, 1)
      setClientFormValues(prev => ({
        ...prev,
        client_associations: associations,
        deleted_association_ids: deletedAssociationId
          ? [...prev.deleted_association_ids, { value: deletedAssociationId }]
          : prev,
      }))
      return setWasEdited(prev => (deletedAssociationId ? true : prev))
    })
  }
  const handleAddAttachedProperty = async data => {
    const { client_id, property_id, property_relation: note } = data

    const res = await attachPropertyToContact(client_id, property_id, { note })

    //if (!clientFormValues.id.value) {
    const newValues = { ...clientFormValues }
    // newValues.isAttachProperies = true
    newValues.client_properties = [
      ...newValues.client_properties,
      { value: { ...res, id: property_id }, error: '' },
    ]
    // newValues.relations_with_property = [
    //   {
    //     value: newValues.client_properties.reduce((acc, item) => {
    //       acc[item.value.property_id] = {
    //         note: item.value.relation_note
    //           ? item.value.relation_note
    //           : item.value.relation
    //           ? item.value.relation
    //           : item.value.note
    //           ? item.value.note
    //           : item.value.property_relation
    //           ? item.value.property_relation
    //           : '',
    //       }
    //       return acc
    //     }, {}),
    //     error: '',
    //   },
    // ]
    setClientFormValues(newValues)
    heandleGetEntities?.(newValues)
  }
  const onDellAttachedProperty = async i => {
    const newValues = { ...clientFormValues }
    //newValues.client_properties = [
    //  ...newValues.client_properties.slice(0, i),
    //  ...newValues.client_properties.slice(i + 1),
    //]

    const [removedElement] = newValues.client_properties.splice(i, 1)

    await detachPropertyFromContact(
      clientFormValues.id.value,
      removedElement.value.id,
    )

    setDetached_properties_ids?.(prev => [...prev, removedElement.value.id])

    // newValues.relations_with_property = [
    //   {
    //     value: newValues.client_properties.reduce((acc, item) => {
    //       acc[item.value.property_id] = {
    //         note: item.value.relation_note
    //           ? item.value.relation_note
    //           : item.value.relation
    //           ? item.value.relation
    //           : item.value.note
    //           ? item.value.note
    //           : '',
    //       }
    //       return acc
    //     }, {}),
    //     error: '',
    //   },
    // ]
    setClientFormValues(newValues)
    heandleGetEntities?.(newValues)

    // setWasEdited(true)
  }

  const useClientFromSuggestions = client => {
    if (typeof attachClientAfterSave === 'function' && isProposal)
      attachClientAfterSave(client)
    if (isAppointment && !!toggleEditClient) toggleEditClient()
    // const relevantProps = getRelevantAction(client.type)

    if (onAddAttachedClient) {
      setShowSuggestions(false)
      onCancel()
      setClientLoading(false)
      onAddAttachedClient({
        ...client,
        note: clientFormValues.relation.value,
        relation_name: clientFormValues.relation.value,
      })

      window.scrollTo({
        top: !isServiceContract ? 800 : 200,
        left: 0,
        behavior: 'smooth',
      })
    } else {
      getClient(client.id)
      setEntityId({ id: client.id, entity_type: client?.type === 1 ? 3 : 2 })
      setShowSuggestions(false)
    }

    // if (onAddAttachedClient) {
    //   setClientLoading(true)
    //   relevantProps
    //     .getClientById(client.id)
    //     .then(res => {
    //       return onAddAttachedClient({
    //         note: clientFormValues.relation.value,
    //         relation_name: clientFormValues.relation.value,
    //         ...res,
    //       })
    //     })
    //     .then(() => {
    //       window.scrollTo({
    //         top: !isServiceContract ? 800 : 200,
    //         left: 0,
    //         behavior: 'smooth',
    //       })
    //       setShowSuggestions(false)
    //       onCancel()
    //     })
    //     .finally(() => setClientLoading(false))
    // } else {
    //   getClient(client.id)
    //   //onUseThisClient(client)
    //   setEntityId({ id: client.id, entity_type: client?.type === 1 ? 3 : 2 })
    //   setShowSuggestions(false)
    // }
  }
  const onAddContact = async contact => {
    updateClientList && updateClientList()

    await attachContact(
      clientFormValues.id.value,
      contact.id,
      contact.relation_name,
    )
      .then(data => {
        setClientFormValues(prev => ({
          ...prev,
          contacts: [{ value: data, error: '' }, ...prev.contacts],
        }))
      })
      .catch(error => console.log(error))
      .finally(() => showNewContactForm(false))
  }

  const handleDetachContact = contact => {
    return Promise.resolve().then(() => {
      detachContacts(clientFormValues.id.value, {
        client_ids: [contact.id],
      }).then(() => {
        setDetached_contacts_ids?.(prev => [...prev, contact.id])
        setClientFormValues(prev => ({
          ...prev,
          contacts: prev.contacts?.filter(c => c.value.id !== contact.id),
          // detached_contacts_ids: [...prev.detached_contacts_ids, contact.id],
        }))
      })

      //setWasEdited(true)
    })
  }

  const handleDetachOrganization = org => {
    return Promise.resolve().then(() => {
      detachOrganizations(clientFormValues.id.value, {
        organization_ids: [org.id],
      }).then(() => {
        setDetached_contacts_ids?.(prev => [...prev, org.id])
        setClientFormValues(prev => ({
          ...prev,
          organizations: prev.organizations?.filter(
            organization => organization.value.id !== org.id,
          ),
          // detached_organizations_ids: [
          //   ...prev.detached_organizations_ids,
          //   org.id,
          // ],
        }))
      })

      // setWasEdited(true)
    })
  }

  const onAddOrganization = async contact => {
    attachOrganizationToClient(clientFormValues.id.value, {
      organization_id: contact.id,
      contact_role: contact.relation_name,
    })
      .then(() => getClient(clientFormValues.id.value, false))
      .then(data => {
        showNewContactForm(false)
      })
  }
  const getPopupContainer = () =>
    document.getElementsByClassName('formClientWrapper')[0]
  useEffect(() => {
    setWasEditedFields(wasEdited)
  }, [wasEdited])

  const handleChangeTab = tab => setActiveTab(tab)
  const typeOfClient =
    clientFormValues.type.value === 1 ? 'organization_id' : 'client_id'

  /**
   * HistoryModalPart
   */
  const [historyTableData, setHistoryTableData] = useState({})

  useEffect(() => {
    if (!clientId || !messaging) {
      return
    }

    messagingAPI
      .getRelatedChats(isOrganization, clientId)
      .then(r => setHistoryTableData(r))
      .catch(e => console.error(e))

    return () => setHistoryTableData({})
  }, [])

  useEffect(() => {
    if (clientId && call_tracking) {
      getCallDataByClient(clientId)
        .then(data => setCallsHistory(data))
        .catch(e => console.error(e))
    }

    return () => setCallsHistory([])
  }, [clientId])

  const infoForDeletedClient = () => {
    const isOrg = isOrganization ? 'organization' : 'client'
    const fieldStyle = {
      fontWeight: 'bold',
      marginLeft: '5px',
    }
    const content = (
      <>
        <p>
          Name:
          <span style={fieldStyle}>
            {clientFormValues?.name?.value || 'no name'}
          </span>{' '}
        </p>
        <p>
          Email:
          <span style={fieldStyle}>
            {clientFormValues?.emails[0]?.value?.email || 'no email'}
          </span>{' '}
        </p>
        <p>
          Phone:
          <span style={fieldStyle}>
            {clientFormValues?.phones[0]?.value?.phone || 'no phone'}
          </span>{' '}
        </p>
      </>
    )
    const msg = (
      <p>
        This {isOrg} has been deleted:{' '}
        <span style={{ fontWeight: 'bold' }}>
          {moment(clientFormValues?.deleted_at?.value).format('MM/DD/YYYY') ||
            ''}
        </span>
      </p>
    )
    const infoContent = (
      <Alert message={msg} description={content} type='info' showIcon />
    )
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p>
          Sorry, this {isOrganization ? 'organization' : 'client'} has beed
          deleted.
        </p>
        <span>
          <Popover content={infoContent}>
            <InfoIcon
              style={{ marginLeft: '5px', fontSize: '20px', color: '#4c53ef' }}
            />
          </Popover>
        </span>
      </div>
    )
  }

  if (isClientDeleted) return <Result subTitle={infoForDeletedClient()} />

  return (
    <Spin spinning={clientLoading}>
      <div
        data-testid='contact-form'
        className={
          isAppointment || isSchedule
            ? 'formClientWrapper formClientWrapper-color'
            : 'formClientWrapper'
        }
      >
        <div
          className={`add-or-update-client-form${
            !clientFormValues?.id?.value ? ' new' : ''
          }`}
        >
          {preloader ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              <Tabs
                noMarginAndPaddingOfTabContent={{
                  rule: clientFormValues.type.value === 1,
                  for: 'Contacts',
                }}
                onChangeTab={handleChangeTab}
                openedTab={activeTab}
              >
                <div
                  label={
                    !permissions[
                      SchedulePermissions
                        .SCHEDULE_CAN_READ_RELATED_ENTITY_MAIN_INFO
                    ] && isSchedule
                      ? ''
                      : t('personalInfo', { ns: 'contact' })
                  }
                  title={`${
                    clientFormValues.type?.value === 1
                      ? 'Company Info'
                      : 'Personal Info'
                  }`}
                >
                  <PersonalInfo
                    isUpdateClient={isUpdateClient}
                    tempTagValue={tempTagValue}
                    tempTagValueError={tempTagValueError}
                    clientFormValues={clientFormValues}
                    onAddTag={onAddTag}
                    onDeleteTag={onDeleteTag}
                    onChangeTempTagValue={onChangeTempTagValue}
                    onChange={onChange}
                    onChangeType={onChangeType}
                    clientInputRow={clientInputRow}
                    propertyRelation={propertyRelation}
                    showSuggestions={showSuggestions}
                    findedClients={findedClients}
                    searchField={searchField}
                    useClientFromSuggestions={useClientFromSuggestions}
                    addRow={addRow}
                    removeRow={removeRow}
                    clientInputPhone={clientInputPhone}
                    onCheck={onCheck}
                    onCheckPhonePref={handleChangePhonePref}
                    onChangeSelect={onChangeSelect}
                    isPhoneValid={isPhoneValid}
                    setIsPhoneValid={setIsPhoneValid}
                    clientInputEmail={clientInputEmail}
                    isEmailValid={isEmailValid}
                    organizationId={organizationId}
                    isOrganization={isOrganization}
                    isAssociation={isAssociation}
                    isAppointment={isAppointment}
                    isContact={isContact}
                    isClient={isClient}
                    onOpenFeedbackModal={() => feedbackModalHeandle(true)}
                    isPropertyForm={isPropertyForm}
                    validateField={validateField}
                  />
                </div>

                {!!clientFormValues.type && clientFormValues.type.value === 1 && (
                  <div
                    label={
                      !permissions[
                        SchedulePermissions
                          .SCHEDULE_CAN_READ_RELATED_PROPERTY_CONTACTS
                      ] && isSchedule
                        ? ''
                        : t('tabs.contacts.title', { ns: 'property' })
                    }
                    onAdd={() => showNewContactForm(!newContactForm)}
                    count={clientFormValues.contacts.length}
                    disabled={!clientFormValues.id.value}
                  >
                    <ClientsTable
                      form={newContactForm}
                      clients={clientFormValues.contacts.map(
                        contact => contact.value,
                      )}
                      propertyId={clientFormValues.id.value}
                      onAttachClient={onAddContact}
                      deleteClient={handleDetachContact}
                      onChoseProperty={getClient}
                      toggleFormClient={() =>
                        showNewContactForm(!newContactForm)
                      }
                      organizationId={clientFormValues.id.value}
                      isContact={true}
                      stepOfOpenedForm={stepOfOpenedForm + 1}
                      toggleFormClientFalse={toggleClientFalse}
                      toggleClientTrue={toggleClientTrue}
                      withModal={withModal}
                      onUpdateRelation={() =>
                        getClient(clientFormValues.id.value, false)
                      }
                      // isOrganization={clientFormValues.type.value === 1}
                    />
                  </div>
                )}

                <div
                  label={
                    !permissions[
                      SchedulePermissions
                        .SCHEDULE_CAN_READ_RELATED_CONTACT_PROPERTIES
                    ] && isSchedule
                      ? ''
                      : t('tabs.properties.title', { ns: 'property' })
                  }
                  onAdd={() => showNewRow(!newRow)}
                  count={clientFormValues.client_properties.length}
                  disabled={wasEditorField || !clientFormValues.id.value}
                >
                  <TableProperties
                    clientId={
                      clientFormValues.id ? clientFormValues.id.value : null
                    }
                    properties={clientFormValues?.client_properties?.sort(
                      (a, b) => a?.id - b?.id,
                    )}
                    newRow={newRow}
                    showNewRow={showNewRow}
                    onAddAttachedProperty={handleAddAttachedProperty}
                    onDellAttachedProperty={onDellAttachedProperty}
                    onUpdateRelation={() =>
                      getClient(clientFormValues.id.value, false)
                    }
                    setWasEdited={setWasEditedFields}
                    withModal={true}
                    isAppointment={isAppointment}
                  />
                </div>
                <div
                  label={
                    !permissions[
                      SchedulePermissions
                        .SCHEDULE_CAN_READ_RELATED_ENTITY_APPOINTMENTS
                    ] && isSchedule
                      ? ''
                      : t('tabs.appointments.title', { ns: 'property' })
                  }
                  onAdd={toggleAppointmentForm}
                  count={
                    clientFormValues.appointments
                      ? clientFormValues.appointments.length
                      : 0
                  }
                  disabled={!clientFormValues.id.value}
                >
                  {appointmentForm && (
                    <WithModal
                      withModal={withModal}
                      toggleModal={() => {
                        showAppointmentForm(false)
                        setWasEditedFields(false)
                      }}
                      showModal={appointmentForm}
                      title={t('tabs.appointments.createEvent', {
                        ns: 'property',
                      })}
                    >
                      <PlanLimits entity='events' />

                      <Location
                        properties={clientFormValues.client_properties}
                        setEntityIdHeandler={setEntityIdHeandler}
                        formType={clientFormValues.type.value}
                        entity_id={entity_id}
                        changeEntityHeandler={changeEntityHeandler}
                        isChecked={isChecked}
                      />
                      <AppointmentForm
                        className='appointment-tab'
                        error={appointmentError}
                        setError={setAppointmentError}
                        onClose={() => {
                          showAppointmentForm(false)
                          setWasEditedFields(false)
                        }}
                        onCreateAppointment={handleCreateAppointment}
                        isLoading={loading}
                        withButton={false}
                      />
                    </WithModal>
                  )}
                  {!appointmentForm && (
                    <Appointments
                      appointments={clientFormValues.appointments?.map(
                        app => app.value,
                      )}
                      emptyText={t('tabs.appointments.noAppointments', {
                        ns: 'property',
                      })}
                      toggleAppointmentForm={toggleAppointmentForm}
                      isAppointment={isAppointment}
                    />
                  )}
                </div>

                {project && (
                  <div
                    count={(clientFormValues.proposals || []).length}
                    onAdd={!!project && toggleNewProposalForm}
                    disabled={!clientFormValues.id.value}
                    label={
                      !permissions[
                        SchedulePermissions
                          .SCHEDULE_CAN_READ_RELATED_ENTITY_PROJECTS
                      ] &&
                      !permissions[ProjectPermissions.PROJECT_CAN_READ] &&
                      isSchedule
                        ? ''
                        : t('tabs.projects.title', { ns: 'property' })
                    }
                  >
                    <TableProposals
                      entity={{
                        id: clientFormValues.id.value,
                        type: typeOfClient,
                      }}
                      proposals={clientFormValues.proposals?.map(
                        proposal => proposal.value,
                      )}
                      newProposalForm={newProposalForm}
                      toggleNewProposalForm={toggleNewProposalForm}
                      closeClientForm={onClose}
                    />
                  </div>
                )}

                {!!clientFormValues.type &&
                  (clientFormValues.type.value === 2 ||
                    clientFormValues.type.value === 3) && (
                    <div
                      onAdd={() => showNewContactForm(!newContactForm)}
                      count={clientFormValues.organizations.length}
                      disabled={!clientFormValues.id.value}
                      label={
                        !permissions[
                          SchedulePermissions
                            .SCHEDULE_CAN_READ_RELATED_CONTACT_ORGANIZATIONS
                        ] && isSchedule
                          ? ''
                          : t('tabs.organizations.title', { ns: 'property' })
                      }
                    >
                      <ClientsTable
                        form={newContactForm}
                        clients={clientFormValues.organizations.map(
                          organization => organization.value,
                        )}
                        propertyId={clientFormValues.id.value}
                        onAttachClient={onAddOrganization}
                        deleteClient={handleDetachOrganization}
                        onChoseProperty={getClient}
                        toggleFormClient={() =>
                          showNewContactForm(prev => !prev)
                        }
                        organizationId={clientFormValues.id.value}
                        isOrganization={true}
                        stepOfOpenedForm={stepOfOpenedForm + 1}
                        toggleFormClientFalse={toggleClientFalse}
                        toggleClientTrue={toggleClientTrue}
                        onSubmit={onSubmit}
                        withModal={withModal}
                      />
                    </div>
                  )}
                {!clientFormValues.type ||
                  (!!clientFormValues.type &&
                    clientFormValues.type.value !== 1 && (
                      <div
                        onAdd={() =>
                          showNewAssociationForm(!newAssociationForm)
                        }
                        count={
                          clientFormValues.client_associations?.filter(
                            contact => contact.value.client,
                          ).length
                        }
                        disabled={!clientFormValues.id.value}
                        label={
                          !permissions[
                            SchedulePermissions
                              .SCHEDULE_CAN_READ_RELATED_CONTACT_ASSOCIATIONS
                          ] && isSchedule
                            ? ''
                            : t('tabs.associations.title', { ns: 'property' })
                        }
                      >
                        <ClientsTable
                          form={newAssociationForm}
                          clients={clientFormValues.client_associations
                            ?.filter(contact => contact.value.client)
                            .map(contact => {
                              return {
                                ...contact.value.client,
                                contact_role: contact.value.note,
                              }
                            })}
                          propertyId={clientFormValues.id.value}
                          onAttachClient={onAddAssociation}
                          deleteClient={onDellAssociation}
                          onChoseProperty={getClient}
                          toggleFormClient={() =>
                            showNewAssociationForm(prev => !prev)
                          }
                          organizationId={clientFormValues.id.value}
                          isAssociation={true}
                          getPopupContainer={getPopupContainer}
                          stepOfOpenedForm={stepOfOpenedForm + 1}
                          parentAssociations={clientFormValues.client_associations?.map(
                            ass => ass.value,
                          )}
                          fetchClientsList={fetchClientsList}
                          toggleFormClientFalse={toggleClientFalse}
                          toggleClientTrue={toggleClientTrue}
                          assotiationsId={
                            clientFormValues.client_associations?.map(
                              ass => ass?.value.id,
                            )?.[0]
                          }
                          withModal={withModal}
                        />
                        {/* <TableAssociation
                          associations={clientFormValues.client_associations}
                          onAddAssociation={onAddAssociation}
                          onDellAssociation={onDellAssociation}
                          newRow={newRow}
                          showNewRow={showNewRow}
                          clientId={clientFormValues.id ? clientFormValues.id.value : null}
                        /> */}
                      </div>
                    ))}

                {!!messaging && (
                  <div
                    label={
                      !permissions[
                        SchedulePermissions
                          .SCHEDULE_CAN_READ_RELATED_CONTACT_MESSAGING
                      ] && isSchedule
                        ? ''
                        : t('tabs.messaging.title', { ns: 'property' })
                    }
                    count={historyTableData.chats?.length}
                  >
                    <HistoryTable
                      tableData={historyTableData}
                      isOrganization={isOrganization}
                    />
                  </div>
                )}

                {!!call_tracking && (
                  <div
                    label={
                      !permissions[
                        SchedulePermissions
                          .SCHEDULE_CAN_READ_RELATED_CONTACT_CALLS
                      ] && isSchedule
                        ? ''
                        : t('tabs.calls.title', { ns: 'property' })
                    }
                    count={callsHistory?.length}
                  >
                    <CallsHistoryTable
                      tableData={callsHistory}
                      isOrganization={isOrganization}
                    />
                  </div>
                )}

                {memberships && (
                  <div
                    label={
                      !permissions[
                        SchedulePermissions
                          .SCHEDULE_CAN_READ_RELATED_ENTITY_MEMBERSHIPS
                      ] && isSchedule
                        ? ''
                        : t('tabs.memberships.title', { ns: 'property' })
                    }
                    count={clientFormValues?.memberships?.length}
                  >
                    <MembershipEntries
                      entityId={clientFormValues?.id?.value}
                      entityType='clients'
                      data={clientFormValues?.memberships?.map(
                        obj => obj.value,
                      )}
                      onUpdate={() => getClient(clientFormValues.id.value)}
                    />
                  </div>
                )}
              </Tabs>

              {
                <FeedbackModal
                  feedback={clientFeedbacks}
                  modal={feedbackModal}
                  clientId={
                    clientFormValues.id ? clientFormValues.id.value : null
                  }
                  close={() => {
                    getClient(clientFormValues.id.value).then(() => {
                      feedbackModalHeandle(false)
                    })
                  }}
                  onSaveFeedback={onSaveFeedback}
                  onCreateFeedback={onCreateFeedback}
                  onDeleteFeedback={onDeleteFeedback}
                />
              }
            </>
          )}
        </div>
        {!preloader && (
          <div className='controlButtons'>
            <AccessControl
              additionalAccess={
                !!clientId &&
                !isAppointment &&
                fetchClientsList &&
                activeTab === 'Personal Info'
              }
              module={ModuleName.CONTACTS}
              scopes={[ContactsPermissions.CONTACTS_CAN_DELETE]}
            >
              <MainButton
                title={`${t('button.delete', { ns: 'common' })} ${
                  isOrganization
                    ? t('heading.organizatoin', { ns: 'common' })
                    : t('heading.contact', { ns: 'common' })
                } `}
                type='delete'
                className='deleteBtn'
                onClick={() => setModal(true)}
              />
            </AccessControl>

            {activeTab === 'Properties' && wasEditorField && (
              <MainButton
                title={t('button.cancel', { ns: 'common' })}
                type='custom-cancel'
                className='mr-3'
                onClick={() => {
                  setWasEditedFields(false)
                  showNewRow(false)
                }}
              />
            )}

            {wasEdited && (
              <MainButton
                title={t('button.cancel', { ns: 'common' })}
                type='custom-cancel'
                className='mr-3'
                onClick={onClose}
              />
            )}
            {wasEdited ? (
              <MainButton
                title={
                  (onAddAttachedClient && !clientId
                    ? t('button.attach', { ns: 'common' })
                    : saveClientBtnText) +
                  ' ' +
                  (isOrganization
                    ? t('heading.organizatoin', { ns: 'common' })
                    : t('heading.contact', { ns: 'common' }))
                }
                type='custom-info'
                onClick={onSubmit}
                isFetching={isSubmitting}
              />
            ) : (
              isProposal && proposalButtonAttach
            )}
          </div>
        )}
        <SureModal
          title={`${t('prompt.delete', { ns: 'common' })} ${
            isOrganization
              ? t('heading.organizatoin', { ns: 'common' })
              : t('heading.contact', { ns: 'common' })
          }?`}
          dialogClassName={'confirm-client-delete-modal'}
          modal={modal}
          apply={onDeleteClient}
          close={toggleModal}
          isLoading={isDeleting}
        />
      </div>

      {showNotificationUpcomingAppointmentModal && (
        <NotificationModal
          title={`Upcoming Event Notification`}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.UPCOMING}
          showModal={showNotificationUpcomingAppointmentModal}
          setShowModal={setShowNotificationUpcomingAppointmentModal}
          appointment={fullAppointment}
        />
      )}
    </Spin>
  )
}

const mapStateToProps = state => {
  return {
    currentClient:
      typeof state.orcatec !== 'undefined'
        ? state.orcatec.client.currentClient
        : undefined,
    currentOrganization:
      typeof state.orcatec !== 'undefined'
        ? state.orcatec.organizations.currentOrganization
        : undefined,
    feedbacks:
      typeof state.orcatec !== 'undefined' ? state.orcatec.feedback : undefined,
    currentCompanyOwnerId:
      typeof state.orcatec !== 'undefined'
        ? state.orcatec.company.owner_id
        : undefined,
    wasEditorField: state.orcatec.ui.wasEdit,
    company: state.orcatec.company,
  }
}

const mapDispatchToProps = dispatch => ({
  getClientById: (...props) => dispatch(actions.client.getClientById(...props)),
  setCurrentClient: (...props) =>
    dispatch(actions.client.setCurrentClient(...props)),
  createClient: (...props) => dispatch(actions.client.createClient(...props)),
  updateClient: (...props) => dispatch(actions.client.updateClient(...props)),
  getClientFeedback: (...props) =>
    dispatch(actions.feedback.getClientFeedback(...props, 2)),
  updateClientFeedback: (...props) =>
    dispatch(actions.feedback.updateClientFeedback(...props)),
  createClientFeedback: (...props) =>
    dispatch(actions.feedback.createClientFeedback(...props)),
  deleteClientFeedback: (...props) =>
    dispatch(actions.feedback.deleteClientFeedback(...props)),
  createRelationProperty: (...props) =>
    dispatch(actions.client.createRelationProperty(...props)),
  createClientAssociation: (...props) =>
    dispatch(actions.client.createClientAssociation(...props)),
  dellAttachedProperty: (...props) =>
    dispatch(actions.client.dellAttachedProperty(...props)),
  dellClientAssociation: (...props) =>
    dispatch(actions.client.dellClientAssociation(...props)),
  changeClientField: (...props) =>
    dispatch(actions.client.changeField(...props)),
  deleteClient: (...props) => dispatch(actions.client.deleteClient(...props)),

  // createAppointment: data => dispatch(actions.appointment.createAppointment(data)),

  getOrganizationById: (...props) =>
    dispatch(actions.organizations.getOrganizationById(...props)),
  setCurrentOrganization: (...props) =>
    dispatch(actions.organizations.setCurrentOrganization(...props)),
  createOrganization: (...props) =>
    dispatch(actions.organizations.createOrganization(...props)),
  updateOrganization: (...props) =>
    dispatch(actions.organizations.updateOrganization(...props)),
  getOrganizationFeedback: (...props) =>
    dispatch(actions.feedback.getClientFeedback(...props, 1)),
  updateOrganizationFeedback: (...props) =>
    dispatch(actions.feedback.updateClientFeedback(...props)),
  createOrganizationFeedback: (...props) =>
    dispatch(actions.feedback.createClientFeedback(...props)),
  deleteOrganizationFeedback: (...props) =>
    dispatch(actions.feedback.deleteClientFeedback(...props)),
  createOrganizationRelationProperty: (...props) =>
    dispatch(actions.organizations.createRelationProperty(...props)),
  createOrganizationAssociation: (...props) =>
    dispatch(actions.organizations.createOrganizationAssociation(...props)),
  dellOrganizationAttachedProperty: (...props) =>
    dispatch(actions.organizations.dellAttachedProperty(...props)),
  dellOrganizationAssociation: (...props) =>
    dispatch(actions.organizations.dellOrganizationAssociation(...props)),
  changeOrganizationField: (...props) =>
    dispatch(actions.organizations.changeField(...props)),
  deleteOrganization: (...props) =>
    dispatch(actions.organizations.deleteOrganization(...props)),
  setWasEditedFields: state => dispatch(actions.modals.wasEditField(state)),
  setIsModalClose: state => dispatch(actions.modals.isCloseModal(state)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClientForm))
