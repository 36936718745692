import { CustomFormTemplate } from 'features/Forms/types'
import { createContext, useState } from 'react'

interface FormTemplate extends Omit<CustomFormTemplate, 'id'> {
  componentIds: string[]
  id?: number
  has_forms?: boolean
  action?: string
}

const initialForm = {
  title: 'Custom form',
  body: {},
  componentIds: [],
  main_field_id: null,
  form_name: [],
}

const defaultValue: {
  form: FormTemplate
  wasEdited: boolean
  updateForm?: (form: FormTemplate) => void
  setForm: (form: CustomFormTemplate) => void
  resetForm: () => void
} = {
  form: initialForm,
}

const FormBuilderContext = createContext(defaultValue)

interface Props {
  children: React.ReactNode
}

const FormBuilderProvider = ({ children }: Props) => {
  const [formTemplate, setFormTemplate] = useState<FormTemplate>({
    title: 'Custom Form',
    body: {},
    componentIds: [],
    main_field_id: null,
    form_name: [],
  })
  const [edited, setEdited] = useState(false)

  const handleUpdateForm = (form: FormTemplate) => {
    setFormTemplate(form)
    setEdited(true)
  }

  const handleSetForm = (form: CustomFormTemplate) =>
    setFormTemplate({
      ...form,
      componentIds: Object.keys(form.body),
      // id: form.id,
      // title: form.title,
      // body: form.body,
      // has_forms: form?.has_forms,
      // action: form?.action,
    })

  const handleResetForm = () => {
    setFormTemplate(initialForm)
    setEdited(false)
  }

  return (
    <FormBuilderContext.Provider
      value={{
        form: formTemplate,
        wasEdited: edited,
        updateForm: handleUpdateForm,
        resetForm: handleResetForm,
        setForm: handleSetForm,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  )
}

export { FormBuilderContext, FormBuilderProvider }
