import { useAvailablePaymentMethods } from 'features/Project/components/PaymentSchedule/hooks/useAvailablePaymentMethods'

const useInvoiceSettings = () => {
  const { hasMerchant } = useAvailablePaymentMethods()

  const PROJECT_SETTINGS = [
    {
      title: 'Customer view',
      hasPermissions: true,
      settings: [
        {
          title: 'Show tech contacts',
          description:
            'Technician contact information will be visible to customers',
          field_name: 'show_tech',
        },
        {
          title: 'Show description only',
          description: 'Only item description will be visible',
          field_name: 'show_item_description_only',
        },
        {
          title: 'Show Payment Summary',
          description:
            'When enabled, the invoice will display a financial summary, including total project cost, total payments, and remaining balance',
          field_name: 'show_items_qty',
        },
      ],
    },
    {
      title: 'Payment',
      hasPermissions: true,
      hide: !hasMerchant,
      settings: [
        {
          title: 'Payment processing fee',
          description:
            'Automatically add a service fee to every credit card payment',
          field_name: 'payment_processing_fee',
          field_type: 'input',
        },
      ],
    },
  ]

  return PROJECT_SETTINGS.filter(section => !section.hide)
}

export default useInvoiceSettings
