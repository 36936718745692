import ProposalSettings from 'containers/MainContent/Orcatec/Proposals/components/ProposalSettings'
import styled from 'styled-components'
import { InvoiceStatus } from '../../InvoiceStatus/InvoiceStatus'
import { Item } from './Item'
import InformationForCustomer from 'features/Project/components/ProjectPrevew/components/ProjectPreviewContent/components/InformationForCustomer'
import { ContactsSection } from './ContactsSection'
import { InvoiceInfo } from './InvoiceInfo'
import { Invoice } from 'features/Invoice/types'
import TermAndConditionsLink from 'containers/MainContent/Orcatec/components/TermAndConditionsLink/TermAndConditionsLink'
import { Button } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import PaymentProcess from 'containers/MainContent/Orcatec/Proposals/components/PaymentProcess'
import states from 'helpers/states'
import { BalanceStatusEnum, PaymentBalance } from 'features/Project/types'
import BalanceTable from 'containers/MainContent/Orcatec/Proposals/components/BalanceTable'
import { TotalsInfo } from './TotalsInfo'
import {
  selectInvoiceFee,
  selectInvoiceSettings,
} from 'features/Invoice/store/invoicesSlice'
import { Totals } from '../../InvoiceTotals'
import { selectCompany } from 'store/Orcatec/selectors/company'

interface Props {
  data: Invoice
  isLoading: boolean
  onChange: (fieldsToUpdate: Partial<Invoice>) => void
  onBalancePaid: () => void
}

export const InvoiceCustomerViewContent = ({
  data,
  isLoading,
  onChange,
  onBalancePaid,
}: Props) => {
  const authUser = useAppSelector(selectAuthUser)
  const invoiceSettings = useAppSelector(selectInvoiceSettings)
  const fee = useAppSelector(selectInvoiceFee)
  const company = useAppSelector(selectCompany)

  const [paymentModal, showPaymentModal] = useState(false)

  const balance = data.proposal_tabs?.[0]?.payment?.payment_balances?.[0]
  const primaryClient = data?.contacts?.[0]
  const primaryProperty = data?.properties?.[0]

  if (!data || !balance) return null

  const section = data.proposal_tabs?.[0]?.proposal_sections?.[0]

  return (
    <Wrapper>
      <ProposalSettings settings={data.setting} />

      <Section>
        <InvoiceInfo data={data} balance={balance} />
      </Section>

      <Section>
        <ContactsSection data={data} companyName={company.company_name} />
      </Section>

      <ContentWrapper>
        <InvoiceStatus balanceStatus={balance?.status} />
        {section?.description && (
          <Description>{section?.description}</Description>
        )}
        {section?.items.map(item => (
          <Item
            key={item.id}
            data={item}
            options={{
              hidePrice: !data.setting.show_price,
              hideQty: !data.setting.show_items_qty,
              hideName: data.setting.show_item_description_only,
            }}
          />
        ))}

        <Totals total={data.invoice_amount} feeSum={fee} />

        {invoiceSettings?.show_items_qty && <TotalsInfo data={data} />}

        {balance.status === BalanceStatusEnum.Paid && (
          <BalanceTable
            balances={[balance]}
            remainigTitle='Remaining Balance to split:'
          />
        )}

        {canPay(balance) && (
          <PaymentInfo>
            <Button type='primary' onClick={() => showPaymentModal(true)}>
              Pay
            </Button>

            {!!data?.term_and_conditions?.file0 && (
              <TermAndConditionsLink file={data.term_and_conditions?.file0} />
            )}
          </PaymentInfo>
        )}
        <Section>
          <InformationForCustomer
            project={data}
            isLoading={isLoading}
            onChange={onChange}
          />
        </Section>
      </ContentWrapper>

      {paymentModal && balance && (
        <Modal
          open
          className={'paymenta-modal'}
          width='auto'
          title={`Payment for Project - ${data.proposal_code}`}
          centered
          destroyOnClose
          onCancel={() => showPaymentModal(false)}
          maskClosable={false}
          footer={null}
        >
          <PaymentProcess
            balance={balance}
            cardInfo={{
              card_holder: primaryClient?.name || '',
              billing_address: {
                address: primaryProperty?.address || '',
                city: primaryProperty?.city || '',
                region: states[primaryProperty?.state] || '',
                postal: primaryProperty?.postcode || '',
              },
            }}
            transactionFee={+data?.setting?.payment_processing_fee}
            settings={{
              disableTips: true,
            }}
            onCancel={() => showPaymentModal(false)}
            onPaid={onBalancePaid}
            payer={authUser?.full_name}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

const hasPending = (balance: PaymentBalance) =>
  balance?.payment_transactions?.some(
    tr => tr.transaction_status === 'Queued for Capture',
  )

const canPay = (balance: PaymentBalance) =>
  [BalanceStatusEnum.Due, BalanceStatusEnum.Overdue].includes(balance.status) &&
  (balance?.accept_card || balance?.can_pay_by_check) &&
  !hasPending(balance) &&
  balance?.amount > 0

const Wrapper = styled.div``
const Section = styled.section`
  margin-top: 30px;
`
const ContentWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  margin-top: 20px;
  padding: 15px;
`

const PaymentInfo = styled.div`
  text-align: center;
  margin-top: 30px;
`

const Description = styled.p`
  white-space: pre-wrap;
  margin: 10px 0;
`
