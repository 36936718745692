import {
  createPaymentBalanceName,
  deletePaymentBalanceName,
  getPaymentBalanceNames,
} from 'api/Payment'
import { BalanceName } from 'features/Project/types'
import { useEffect, useState } from 'react'

export const useBalanceNames = () => {
  const [balanceNames, setBalanceNames] = useState<BalanceName[]>([])
  const [loading, setLoading] = useState(false)

  async function getBalanceNames() {
    setLoading(true)
    try {
      const data = await getPaymentBalanceNames()

      setBalanceNames(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function addBalanceName(newValue: string) {
    try {
      const newName = await createPaymentBalanceName({ name_balance: newValue })

      setBalanceNames(prev => [...prev, newName])
    } catch (error) {
      console.error(error)
    }
  }

  async function deleteBalanceName(id: number) {
    try {
      await deletePaymentBalanceName(id)

      setBalanceNames(balanceNames?.filter(name => name.id !== id))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getBalanceNames()
  }, [])

  return {
    balanceNames,
    loading,
    refetch: getBalanceNames,
    addBalanceName,
    deleteBalanceName,
  }
}
