// import { setPayments } from 'store/Orcatec/actions/payments'
import { discountsSet } from '../slices/projectDiscountsSlice'
import { itemsGroupsSet } from '../slices/projectGroupsSlice'
import { itemsSet } from '../slices/projectItemsSlice'
import { rebatesSet } from '../slices/projectRebatesSlice'
import { sectionsSet } from '../slices/projectSectionsSlice'
import { resetProjectSlice } from '../slices/projectSlice'
import { feesSet, taxSet } from '../slices/projectTaxAndFeesSlice'
import { IProjectTab } from '../types'
import { paymentSet } from '../slices/projectPaymentSlice'

interface ItemWithPosition {
  position?: number
  order_option?: number
}

export function reorderEntities<T extends ItemWithPosition>(
  list: T[],
  startIndex: number,
  endIndex: number,
  key: 'position' | 'order_option',
): T[] {
  const result = [...list].sort((a, b) => a[key] - b[key])

  const [removed] = result.splice(startIndex, 1)
  if (removed) result.splice(endIndex, 0, removed)

  return result.map((item, index) => ({
    ...item,
    [key]: index,
  }))
}

export function gatherGroupsFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.groups)
    return allItems
  }, [])
}

export function gatherItemsFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.items)
    return allItems
  }, [])
}

export function gatherDisocuntsFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.discounts)
    return allItems
  }, [])
}

export function gatherRebatesFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.rebates)
    return allItems
  }, [])
}

export const populateProjectTabWithEntities = (tab: IProjectTab, dispatch) => {
  dispatch(resetProjectSlice())

  // dispatch(setPayments(tab.payment))
  dispatch(paymentSet(tab.payment))
  dispatch(sectionsSet(tab.proposal_sections))
  dispatch(itemsGroupsSet(gatherGroupsFromSections(tab)))
  dispatch(itemsSet(gatherItemsFromSections(tab)))
  dispatch(taxSet(tab.tax))
  dispatch(feesSet(tab.fees))
  dispatch(discountsSet(gatherDisocuntsFromSections(tab)))
  dispatch(rebatesSet(gatherRebatesFromSections(tab)))
}
