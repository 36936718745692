import {
  getAvailablePaymentMethods,
  selectPaymentMerchantData,
} from 'features/Project/slices/projectPaymentSlice'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'

export const useAvailablePaymentMethods = () => {
  const dispatch = useAppDispatch()
  const merchantData = useAppSelector(selectPaymentMerchantData)
  const merchantId = useAppSelector(
    state => state.orcatec.company?.merchant?.id,
  )

  useEffect(() => {
    if (!merchantData && !!merchantId) {
      dispatch(getAvailablePaymentMethods(merchantId))
    }
  }, [merchantData])

  return {
    availablePaymentMethods: {
      bank_check: !!merchantData?.bank_check,
      credit_card: !!merchantData?.credit_card,
    },
    hasMerchant: !!merchantData?.merchantId,
  }
}
