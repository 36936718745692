import { Spin, Tooltip } from 'antd'
import { priceToView } from 'helpers/thousandSeparator'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  PaymentBalance as IPaymentBalance,
  ProjectStatusGroup,
} from 'features/Project/types'
import { InfoBlock } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/InfoBlock'
import { selectProjectActiveTab } from 'features/Project/projectSelectors'
import { PaymentBalance } from './components/PaymentBalance/PaymentBalance'
import ModuleStatus from 'containers/MainContent/Orcatec/Proposals/components/ModuleStatus'
import { usePayments } from './hooks/usePayments'
import { Button, TextArea } from 'components/UIKit'
import styled from 'styled-components'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { getPaymentStatus } from 'containers/MainContent/Orcatec/Proposals/helpers/getPaymentStatus'
import { ChangeEvent, useState } from 'react'
import { WarningFilled } from '@ant-design/icons'
import InvoiceAPI from 'features/Invoice/api'

const statusList = [
  {
    id: 1,
    name: 'No Action Needed',
  },
  {
    id: 2,
    name: 'Balance Due',
  },
  {
    id: 3,
    name: 'Balance Overdue',
  },
  {
    id: 4,
    name: 'Complete',
  },
]

const PaymentSchedule = props => {
  const {
    tabId,
    entityId,
    openCloseModalPayment,
    changePaymentStatus,
    proposalStatus: projectStatus,
    totalToPay,
    onRemount,
  } = props

  const {
    payment,
    balances,
    loading,
    isEdited,
    balanceNames,
    loadingBalanceNames,
    paymentMethods,
    hasMerchant,
    availablePaymentMethods,
    remainingBalanceToSplit,
    dueAmount,
    projectTotal,
    sumOfBalances,
    refetchPayment,
    addBalance,
    deleteBalance,
    addBalanceName,
    deleteBalanceName,
    changeBalance,
    savePayment,
    reorderBalances,
    changePayment,
  } = usePayments(entityId, tabId)

  const { payment_status: paymentStatus } = useAppSelector(
    selectProjectActiveTab,
  )

  const [overpayModal, showOverpayModal] = useState(false)
  const [
    balanceToCreate,
    setBalanceToCreate,
  ] = useState<IPaymentBalance | null>(null)

  const onInfoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    changePayment({ info: e.target.value })
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    const updatedItems = [...balances.ids]

    const [movedItem] = updatedItems.splice(source.index, 1)

    updatedItems.splice(destination.index, 0, movedItem)

    reorderBalances(updatedItems)
  }

  async function handlePaymentSave(checkTotals?: boolean) {
    if (remainingBalanceToSplit < 0 && !!checkTotals) {
      showOverpayModal(true)

      // return Promise.reject('overpay')
      return
    }

    try {
      await savePayment()

      changePaymentStatus(
        getPaymentStatus(
          balances.ids.map(id => balances.data[id]),
          projectTotal,
        ),
      )

      openCloseModalPayment()
    } catch (error) {
      if (error?.response?.data?.message) {
        openNotificationWithIcon('error', {
          message: error?.response?.data?.message,
        })
      }
    }
  }

  async function handleInvoiceCreate(
    balance: IPaymentBalance,
    ignoreOverPaid?: boolean,
  ) {
    if (
      !ignoreOverPaid &&
      (sumOfBalances > projectTotal || balance.amount === 0)
    ) {
      return setBalanceToCreate(balance)
    }

    let balanceId = balance.id

    const newlyGeneratedBalance = /^\d{13}$/.test(balanceId.toString())

    try {
      const updatedPayment = await savePayment()

      if (newlyGeneratedBalance) {
        const balancePosition = balances.ids.findIndex(id => id === balanceId)
        balanceId =
          updatedPayment.payload.data.payment_balances.find(
            bal => bal.position === balancePosition,
          )?.id || balance.id
      }

      const res = await InvoiceAPI.generateInvoice({
        proposal_id: entityId,
        balance_id: balanceId,
      })

      changeBalance(balanceId, { ...balance, invoice: res })

      openNotificationWithIcon('success', {
        message: 'The invoice has been generated successfully',
      })

      if (newlyGeneratedBalance) {
        await refetchPayment()
        onRemount()
      }

      // dispatch(getInvoicesList({ proposal_id: entityId }))

      return res
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
      return Promise.reject(error)
    }
  }

  return (
    <>
      <Modal
        open
        onOk={() => handlePaymentSave(true)}
        onCancel={() => openCloseModalPayment()}
        aria-labelledby='modal-payment-label'
        centered
        wrapClassName='modal-proposal-payment'
        showConfirmationOnExit={isEdited}
        footer={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '10px',
            }}
          >
            <Button onClick={openCloseModalPayment}>Cancel</Button>
            <Button
              onClick={() => handlePaymentSave(true)}
              disabled={loading}
              type='primary'
            >
              Save
            </Button>
          </div>
        }
        width={920}
      >
        <Spin spinning={loading}>
          <div style={{ paddingTop: 0 }} className='mod-paywrap'>
            <h4 style={{ marginTop: '15px', textAlign: 'center' }}>
              Payment Schedule
            </h4>

            <ModuleStatus
              statusList={statusList}
              currentStatus={paymentStatus}
              payment
            />

            {[ProjectStatusGroup.Completed].includes(projectStatus) && (
              <InfoBlock
                type='info'
                style={{
                  marginBottom: 20,
                  fontSize: '0.85rem',
                }}
              >
                You can&apos;t apply any changes to the payments if the project
                is in{' '}
                <span style={{ fontWeight: 500 }}>&quot;Completed&quot;</span>{' '}
                status
              </InfoBlock>
            )}

            <Total>
              Total:{' '}
              <span style={{ fontWeight: 500 }}>{priceToView(totalToPay)}</span>
            </Total>

            <Balances>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='payments'>
                  {provided => (
                    <BalancesList
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {balances?.ids
                        ?.map(id => balances.data[id])
                        ?.map((balance, i) => {
                          return (
                            <Draggable
                              key={balance.id}
                              index={i}
                              draggableId={balance?.id?.toString()}
                            >
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <PaymentBalance
                                    projectStatus={projectStatus}
                                    provided={provided}
                                    data={balance}
                                    disabled={
                                      projectStatus ===
                                      ProjectStatusGroup.Completed
                                    }
                                    paymentMethods={paymentMethods}
                                    balanceNames={balanceNames}
                                    loadingBalanceNames={loadingBalanceNames}
                                    hasActiveMerchant={hasMerchant}
                                    availablePaymentMethods={
                                      availablePaymentMethods
                                    }
                                    onChange={balance =>
                                      changeBalance(balance.id, balance)
                                    }
                                    onBalanceNameAdd={addBalanceName}
                                    onBalanceNameDelete={deleteBalanceName}
                                    onDelete={() => deleteBalance(balance.id)}
                                    onGenerateInvoice={handleInvoiceCreate}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                      {provided.placeholder}
                    </BalancesList>
                  )}
                </Droppable>
              </DragDropContext>
            </Balances>

            <Button
              type='primary'
              disabled={[ProjectStatusGroup.Completed].includes(projectStatus)}
              onClick={addBalance}
            >
              + Add new Balance
            </Button>

            <DueSection>
              {!!remainingBalanceToSplit && (
                <DueSectionRow>
                  <span>
                    {remainingBalanceToSplit < 0 && (
                      <Tooltip title='Project Total and Payments Total amounts are mismatch'>
                        <WarningFilled
                          style={{
                            color: '#faad14',
                            fontSize: '1.2rem',
                            margin: '-2px 5px 0 0',
                          }}
                        />
                      </Tooltip>
                    )}
                    Remaining Balance to Split:
                  </span>

                  <Amount
                    style={{
                      color: 'red',
                    }}
                  >
                    {priceToView(remainingBalanceToSplit)}
                  </Amount>
                </DueSectionRow>
              )}

              {!!dueAmount && dueAmount !== remainingBalanceToSplit && (
                <DueSectionRow>
                  Total Balance Due:
                  <Amount>{priceToView(dueAmount)}</Amount>
                </DueSectionRow>
              )}
            </DueSection>

            <TextArea
              label='Internal payment information'
              placeholder='This section is not visible to the client.'
              value={payment?.info}
              name='info'
              onChange={onInfoChange}
              maxLength={3000}
              autoSize={{ minRows: 4, maxRows: 10 }}
              showCount
            />
          </div>
        </Spin>
      </Modal>

      <Modal
        open={overpayModal}
        title='Potential Payment Discrepancy'
        onCancel={() => showOverpayModal(false)}
        onOk={() => {
          showOverpayModal(false)

          handlePaymentSave()
        }}
        okText='Confirm'
      >
        <p>
          Before submitting, please note that the total payments exceed the
          Project Total. This may cause reporting inconsistencies.
        </p>
        <p>Do you want to continue?</p>
      </Modal>

      {balanceToCreate && (
        <Modal
          open
          onOk={() => {
            handleInvoiceCreate(balanceToCreate, true)
            setBalanceToCreate(null)
          }}
          onCancel={() => setBalanceToCreate(null)}
          title={
            balanceToCreate.amount
              ? 'Potential Payment Discrepancy'
              : 'Creating a zero-total Invoice'
          }
          okText='Generate invoice'
          width={600}
        >
          {balanceToCreate.amount ? (
            <>
              <p>
                Creating this invoice will result in total payments exceeding
                the Project Total. This may cause reporting inconsistencies.
              </p>
              <p>Do you still want to proceed?</p>
            </>
          ) : (
            <>
              <p>
                Since this Payment has a total of $0, the invoice will also be
                $0.
              </p>
              <p>Would you like to proceed?</p>
            </>
          )}
        </Modal>
      )}
    </>
  )
}

export default PaymentSchedule

const BalancesList = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

const DueSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 500;
  margin: 20px 0;
`

const DueSectionRow = styled.p`
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 10px;
`

const Amount = styled.span`
  text-align: right;
`

const Balances = styled.div`
  margin: 30px 0 20px;
`

const Total = styled.p`
  font-size: 22px;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
`
