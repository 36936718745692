import { EditOutlined } from '@ant-design/icons'
import { TextAreaProps } from 'antd/lib/input'
import { TextArea } from 'components/UIKit'
import { useState } from 'react'
import styled from 'styled-components'

interface Props extends Omit<TextAreaProps, 'onChange'> {
  value: string
  allowEmpty?: boolean
  onChange: (value: string) => void
}

export const GhostInput = ({
  value,
  onChange,
  allowEmpty = true,
  ...rest
}: Props) => {
  const [inputMode, setInputMode] = useState(false)
  const [error, setError] = useState(false)

  async function handleInputBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
    const { value } = e.target

    if (!value && !allowEmpty) return setError(true)

    try {
      onChange(value)

      setInputMode(false)
    } catch (error) {
      console.error(error)
    }
  }

  return inputMode ? (
    <TextArea
      autoFocus
      defaultValue={value}
      onBlur={handleInputBlur}
      onChange={() => {
        if (error) setError(false)
      }}
      error={error ? 'Required field' : undefined}
      {...rest}
    />
  ) : (
    <Text>
      {value || <Placeholder>{rest?.placeholder}</Placeholder>}{' '}
      <EditOutlined
        onClick={() => setInputMode(true)}
        style={{ color: '#1890ff' }}
      />
    </Text>
  )
}

const Text = styled.p`
  white-space: pre-wrap;
`
const Placeholder = styled.span`
  font-style: italic;
  opacity: 0.3;
`
